import React, { useContext, useEffect, useRef, useState } from 'react';
import Images from '../../../assets/images';
import { Translation } from 'react-i18next';
import RFIDProgress from './RFIDProgress';
import HelpButton from '../../global/HelpButton';
import globalContext from '../../../context/global-context';
import RFIDHelp from './RFIDHelp';
import RFIDError from './RFIDError';

var abortCon = new AbortController();

const RegisterRFID = props => {

    const context = useContext(globalContext);

    const [step, setStep] = useState(1);
    const [scanning, setScanning] = useState(false);
    const [chargePointID, setChargePointID] = useState('')
    const [label, setLabel] = useState('')
    const [helpOpen, setHelpOpen] = useState(null);
    const [err, setErr] = useState(null);
    const [clock, setClock] = useState(0);
    const panels = useRef(null);
    const main = useRef(null);

    let clockTimeout;


    //
    //  Cancel and close the whole thing
    //
    const handleClose = () => {
        handleReset();
        props.close();
    }


    //
    //  Reset the component back to orginal state
    //
    const handleReset = () => {
        abortCon.abort()
        setStep(1);
        setChargePointID('');
        setLabel('');
        setScanning(false);
        setHelpOpen(false);
        setErr(null);
    }

    useEffect(() => {

        return () => {
            abortCon.abort();
        }
    }, [])


    //
    //  Control the scroll when the steps change
    //
    useEffect(() => {
        panels.current.scrollLeft = 240*(step-1);
        main.current.scrollTop = 0;

        if(step === 2 || step === 4)
            handlePairing();
    }, [step])



    //
    //
    //  Handle the clock ticking
    //
    //
    useEffect(() => {

        if(clock <= 0 && scanning)
            abortCon.abort()

        if(typeof clockTimeout != 'undefined')
            clearTimeout(clockTimeout);

        if(clock > 0)
            clockTimeout = setTimeout(() => setClock(clock - 1), 1000);

        return () => {
            if(typeof clockTimeout != 'undefined')
                clearTimeout(clockTimeout);
        }
    }, [clock])




    //
    //  Start the RFID pairing process
    //
    const handleStart = () => {

        if(chargePointID === null || chargePointID.length === 0)
            setErr('Enter a charge point ID to start pairing.');

        if(chargePointID !== null && chargePointID.length > 0)
            context.RFIDPairingStart(chargePointID).then(
                res => {
                    switch(res) {
                        case 'STARTED':
                            setStep(2);
                            setErr(null);
                            break;
                        case 'NOT_ELIGIBLE':
                            setStep(1);
                            setErr('This charge point is not equiped with an RFID reader.');
                            break;
                        default:
                            handleReset();
                    }
                },
                err => {
                    setStep(1);
                    setErr('An error occured trying to start the RFID process, please try again.');
                }
            )
        else{
            setStep(1);
        }
    }

    const handlePairing = () => {

        setScanning(true);
        setClock(30);
        abortCon = new AbortController();

        context.RFIDPairingTag(chargePointID, abortCon).then(
            res => {
                setScanning(false);
                switch(res) {
                    case 'NO_MATCH':
                        setErr('The tag you scanned wasn\'t matched');
                        break;
                    case 'RECEIVED':
                        setStep(step+1);
                        break;
                    case 'NOT_RECEIVED':
                        setErr('We didn\'t receive a presented RFID card or an error occured.');
                        break;
                    case 'TIMEOUT':
                        handleReset();
                        setErr('Your pairing session expired, please try again.');
                        break;
                    default:
                        handleReset();
                        setErr('An error occured trying to start the RFID process, please try again.');
                }
            },
            err => {
                setScanning(false);
                if(err.name !== 'AbortError')
                    setErr('We didn\'t receive a presented RFID card or an error occured.');
            }
        );

    }

    const handleFinish = () => {

        

        if(label === null || label.length === 0)
            setErr('Enter a name for this RFID device.');

        if(label !== null && label.length !== 0) {

            abortCon = new AbortController();

            context.RFIDPairingFinish(chargePointID, label, abortCon).then(
                res => {
                    setScanning(false);
                    switch(res) {
                        case 'TAG_MISMATCH':
                            setErr('The RFID tags you scanned didn\'t match, try again or try a different RFID device.');
                            break;
                        case 'COMPLETE':
                            handleClose();
                            break;
                        case 'TAG_UNCONFIRMED':
                            setErr('We didn\'t receive two valid scanned RFID tags.');
                            break;
                        case 'NOT_STARTED':
                            setErr('You pairing session has expired, close the window and try again.');
                            break;
                        default:
                            handleReset();
                            setErr('An error occured trying to start the RFID process, please try again.');
                    }
                },
                err => {
                    setScanning(false);
                    switch(err.result) {
                        case 'TAG_MISMATCH':
                            setErr('The RFID tags you scanned didn\'t match, try again or try a different RFID device.');
                            break;
                        case 'TAG_UNCONFIRMED':
                            setErr('We didn\'t receive two valid scanned RFID tags.');
                            break;
                        case 'NOT_STARTED':
                            setErr('You pairing session has expired, close the window and try again.');
                            break;
                        default:
                            handleReset();
                            setErr('We didn\'t receive a presented RFID card or an error occured.');
                    }
                    
                }
            );


        }

    }

    const handleConfirm = () => {
        setStep(4);
    }

    const handleDecline = () => {
        setStep(2);
    }


    const handleChargePointChange = e => {

        // Get the current value
        let cp = e.target.value;

        // Helper to ensure always uppercase
        cp = cp.toUpperCase();
        
        // Does it have a hiphen?
        let conInx = cp.indexOf('-');
        if(conInx === -1)
            conInx = cp.length;
        
        // If the value before the hiphen is an integer add UKEV to the front 
        if(!isNaN(parseInt(cp.slice(0, conInx))))
            cp = 'UKEV' + cp;

        setChargePointID(cp)
    }

    return (
        <Translation>{t => 
            <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
                <div style={styles.wrapper}>
                    <button style={styles.closeButton} onClick={handleClose}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>

                    <div style={styles.header}>
                        <h2 style={styles.title}>{t('RFID_PAIR')}</h2>
                    </div>

                    <div style={styles.main} ref={main}>
                        <div style={styles.rfid_progress}>
                            <RFIDProgress step={step} scanning={scanning} />
                            {step > 1 && 
                            <div style={styles.charge_point_heading}>
                                <span style={styles.charge_point}>{chargePointID}</span>
                            </div>}
                        </div>

                        <div style={styles.rfid_steps}>

                            <div style={styles.panel_wrapper}>
                                <div style={styles.panels} ref={panels}>

                                    {step === 1 &&
                                        <div style={Object.assign({}, styles.panel, step === 1?styles.panelIn:styles.panelOut)} className="animate">
                                            <div style={styles.panel_body}>
                                                <p style={styles.para}>{t('RFID_STEP_1')}</p>
                                                <div style={styles.form_control}>
                                                    <input type={'text'} name={'point_id'} autoComplete="no" style={styles.input} placeholder="UKEV****" value={chargePointID} onChange={handleChargePointChange} />
                                                </div>
                                                <button onClick={handleStart} style={styles.button}>{t('RFID_START')}</button>
                                                <HelpButton size={18} spacing={10} handleClick={() => setHelpOpen(true)} />
                                            </div>
                                        </div>
                                    }
                                    
                                    {step === 2 &&
                                    <div style={Object.assign({}, styles.panel, step === 2?styles.panelIn:styles.panelOut)} className="animate pulse">
                                        <div style={styles.panel_body}>
                                            <h5 style={styles.subheading}>{t('RFID_STEP_2')}</h5>
                                            {scanning && <span style={styles.clock}>{clock}</span>}
                                            {!scanning && <button onClick={handlePairing} style={styles.button}>Try again</button>}
                                            <button onClick={handleReset} style={styles.buttonLink}>{t('RFID_START_AGAIN')}</button>
                                        </div>
                                    </div>
                                    }
                                    
                                    {step === 3 &&
                                    <div style={Object.assign({}, styles.panel, step === 3?styles.panelIn:styles.panelOut)} className="animate pulse">
                                        <div style={styles.panel_body}>
                                            <h5 style={styles.subheading}>{t('RFID_STEP_3')}</h5>
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <button onClick={handleDecline} style={styles.button}>{t('NO')}</button>
                                                <button onClick={handleConfirm} style={styles.buttonPrimary}>{t('YES')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    
                                    {step === 4 &&
                                    <div style={Object.assign({}, styles.panel, step === 4?styles.panelIn:styles.panelOut)} className="animate pulse">
                                        <div style={styles.panel_body}>
                                            <h5 style={styles.subheading}>{t('RFID_STEP_4')}</h5>
                                            {scanning && <span style={styles.clock}>{clock}</span>}
                                            {!scanning && <button onClick={handlePairing} style={styles.button}>{t('RFID_TRY_AGAIN')}</button>}
                                            <button onClick={() => {}} style={styles.buttonLink}></button>
                                        </div>
                                    </div>
                                    }
                                    
                                    {step === 5 &&
                                    <div style={Object.assign({}, styles.panel, step === 5?styles.panelIn:styles.panelOut)} className="animate pulse">
                                        <div style={styles.panel_body}>
                                            <h5 style={styles.subheading}>{t('RFID_STEP_5')}</h5>
                                            <div style={styles.form_control}>
                                                <input type="text" autoComplete="no" name="label" style={styles.input} placeholder="e.g. Rolec RFID" value={label} onChange={e => setLabel(e.target.value)} />
                                            </div>
                                            <button onClick={handleFinish} style={styles.buttonSuccess}>{t('RFID_FINISH')}</button>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <RFIDHelp step={step} open={helpOpen} close={() => setHelpOpen(null)} />
                    <RFIDError open={err !== null} close={() => setErr(null)} msg={err} />
                    
                </div>
            </div>
        }</Translation>
    )

}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: '#fbfbfb',
        height: '100%',
        width: '100%',
        display: 'block',
        overflow: 'hidden',
        zIndex: 88
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    main: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        scrollBehavior: 'smooth'
    },
    closeButton: {
        border: 'none',
        backgroundColor: 'transparent',
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99
    },
    header: {
        paddingTop: 40,
        display: 'flex',
        width: '100%',
        height: 60,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: '#6CB33F',
    },
    panel_wrapper: {
        width: 240,
        overflow: 'hidden'
    },
    panels: {
        display: 'flex',
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
    },
    panel: {
        flexShrink: 0,
        width: 240,
        position: 'relative',
        display: 'flex',
        overflow: 'hidden'
    },
    panelIn: {
        opacity: 1
    },
    panelOut: {
        opacity: 0,
        visibility: 'hidden'
    },
    panel_body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    present_rfid: {
        width: 60
    },
    rfid_progress: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 300,
        justifyContent: 'center',
        alignItems: 'center'
    },
    rfid_steps: {
        position: 'relative',
        paddingBottom: 30,
        width: 240
    },
    subheading: {
        marginBottom: 10,
        marginTop: 10,
        fontSize: 18,
        lineHeight: 1.3,
        color: '#464646',
        fontWeight: 700,
        textAlign: 'center'
    },
    subtle: {
        color: '#6e6e6e',
        lineHeight: 1.3,
        fontSize: 14
    },
    para: {
        color: '#101010',
        lineHeight: 1.3,
        fontSize: 14
    },
    form_control: {
        width: '100%',
        height: 46,
        border: 'none',
        borderBottom: '1px solid #6CB33F',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e2e2e2'
    },
    input: {
        width: '90%',
        height: '100%',
        fontSize: 18,
        fontWeight: 400
    },
    buttonPrimary: {
      backgroundColor: '#6CB33F',
      height: 36,
      minWidth: 80,
      padding: '0 10px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    buttonSuccess: {
      backgroundColor: '#6CB33F',
      height: 36,
      minWidth: 80,
      padding: '0 10px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    button: {
      backgroundColor: '#464646',
      height: 36,
      minWidth: 80,
      padding: '0 10px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    buttonLink: {
      backgroundColor: 'transparent',
      border: 'none',
      height: 40,
      padding: 0,
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#101010',
      textDecoration: 'underline',
    },
    charge_point_heading: {
        textAlign: 'center',
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
    },
    using: {
        fontSize: 18,
        color: '#8e8e8e',
        fontWeight: 600
    },
    charge_point: {
        fontSize: 18,
        color: '#a8a8a8',
        fontWeight: 600
    },
    clock: {
        fontSize: 50, 
        textAlign: 'center', 
        color: '#d2d2d2', 
        fontWeight: 900
    }
}

export default RegisterRFID;