import React from 'react'
import images from '../../assets/images/index';
import { Translation } from 'react-i18next';

const HelpButton = props => {

    const styles = {
        helpButton: {
            display: 'flex',
            flexDirection: 'row',
            border: 'none',
            background: 'transparent',
            height: props.size || 32,
            margin: props.spacing || 0,
            alignItems: 'center'
        },
        helpImage: {
            height: props.size || 32,
            width: props.size || 32,
            marginRight: 6
        }
    }

    const handleClick = e => {
        e.preventDefault();
        props.handleClick();
    }

    return (
        <Translation>{t => 
            <button style={styles.helpButton} onClick={handleClick}><img src={images.help} style={styles.helpImage} alt="help" />{t('HELP')}</button>
        }</Translation>
    )
}

export default HelpButton