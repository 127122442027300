import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const OperationalHours = props => {

    const {t} = useTranslation();

    const [formattedHours, setFormattedHours] = useState([]);

    const isAllDay = str => {
        if(str.replace(/\s/g,'').toUpperCase() === '12AM-12AM')
            return t('All day');

        return str;
    }

    const formatTime = (time) => {

        if(parseInt(time.slice(-2)) === 59){
            const newHr = parseInt(time.slice(0,2))+1;
            time = `${newHr<10?'0'+newHr:newHr}:00`;
        }

        const timeInt = parseInt(time.replace(':', ''));

        if(timeInt === 0 || timeInt === 2400)
            return '12'+t('AM');

        const hrInt = parseInt(timeInt/100);
        const min = timeInt.toString().slice(-2);
        const isPM = Boolean(hrInt > 12);
        return (isPM ? hrInt-12 : hrInt === 0 ? '12' : hrInt)+(parseInt(min) > 0 ? ':'+min : '')+(isPM?t('PM'):t('AM'));
    }

    useEffect(() => {

        const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        let newFormattedHours = [];

        /**
         * Add a record for every day of the week
         */
        days.map((d, idx) => {
            const key = idx === 0 ? 7 : idx;
            newFormattedHours.push({
                key: key,
                label: d,
                hoursStr: '',
                hoursArr: []
            });
        })

        /**
         * Add all the operational hours into their relevant day
         */
        props.site.vlm.operationalHours.map(h => {
            const key = h.day === 0 ? 7 : h.day;
            const idx = newFormattedHours.findIndex(d => d.key === key);
            if(idx > -1)
                newFormattedHours[idx].hoursArr.push({
                    start: h.start,
                    end: h.end
                })
        })

        /**
         * Run through the operational hours and apply a formatted text description 
         */
        newFormattedHours.map(d => {
            // Sort the times first so the earliest times come first
            d.hoursArr = d.hoursArr.sort((a,b) => {
                if(parseInt(a.start.replace(':', '')) < parseInt(b.start.replace(':', '')))
                    return -1;
                
                if(parseInt(a.start.replace(':', '')) > parseInt(b.start.replace(':', '')))
                    return 1;
                
                return 0;
            })
            
            d.hoursArr.map(h => d.hoursStr += isAllDay(`${formatTime(h.start)} - ${formatTime(h.end)}`)+', ');
            d.hoursStr = d.hoursStr.length > 2 ? d.hoursStr.slice(0, -2) : t('NOT_OPERATIONAL');
        })

        newFormattedHours = newFormattedHours.sort((a, b) => {
            if(a.key < b.key)
                return -1;
            
            if(a.key > b.key)
                return 1;
            
            return 0;
        });

        setFormattedHours(newFormattedHours);

    }, [props.site])

    return (
        <React.Fragment>
            <div style={Object.assign({}, styles.dialogBg, props.show && styles.dialogIn)}>
                <div style={styles.dialog} className="animate">
                    <div style={styles.dialogWrapper}>
                        <div style={styles.dialogBody}>
                            <span style={styles.header}>{t('OPERATIONAL_HOURS_HEADER')}</span>
                            <span style={styles.content}>{t('OPERATIONAL_HOURS_DESC')}</span>

                            <div style={styles.operationalHours}>

                                {formattedHours.map(d => (
                                    <div key={d.key} style={styles.operationalHour}>
                                        <span style={styles.day}>{t(d.label)}: </span>
                                        <span style={styles.hour}>{d.hoursStr}</span>
                                    </div>
                                ))}

                            </div>

                            <button style={styles.close} onClick={props.onClose}>{t('OK')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const styles = {
    dialogBg: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: '50%',
        left: '50%',
        backgroundColor: 'rgba(0,0,0,.3)',
        zIndex: 989,
        transform: 'translate(-50%, 100%)',
        visibility: 'hidden',
        opacity: 0,
    },
    dialogIn: {
        transform: 'translate(-50%, -50%)',
        visibility: 'visible',
        opacity: 100,
    },
    dialog: {
        display: 'block',
        width: '96%',
        maxWidth: 320,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        boxShadow: '0 0 6px rgba(0,0,0,.3)',
        zIndex: 990,
        borderRadius: 12
    },
    dialogWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
    },
    header: {
        fontSize: 18,
        marginTop: 15,
        color: '#484848',
        fontWeight: 600,
        marginBottom: 5,
        alignSelf: 'center'
    },
    content: {
        fontSize: 13,
        fontWeight: 400,
        marginBottom: 10,
        color: '#484848'
    },
    dialogBody: {
        flex: 1,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    close: {
        width: 90,
        height: 30,
        backgroundColor: '#6CB33F',
        border: 'none',
        color: '#fff',
        borderRadius: 6,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        alignSelf: 'center'
    },
    operationalHours: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px'
    },
    operationalHour: {
        display: 'flex',
        flexDirection: 'column'
    },
    day: {
        fontWeight: 600,
        fontSize: 13,
        color: '#888'
    },
    hour: {
        fontWeight: 400,
        fontSize: 16
    }
};

export default OperationalHours;