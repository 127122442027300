import React from 'react'
import Input from '../input/input'
import Select from '../select/select'
import TextArea from '../textArea/textArea'

const elements = {
    input: Input,
    select: Select,
    textArea: TextArea
}

export default function FormBuilder({ element, ...props }) {
    const ResolvedElement = elements[element]
    if (!ResolvedElement) return <div>Error</div>
    return <ResolvedElement {...props} />
}
