import React, { useContext, useEffect, useState } from 'react';
import Images from '../../assets/images/index';
import ActivityIndicator from '../global/ActivityIndicator';
import { useTranslation } from 'react-i18next';
import globalContext from '../../context/global-context';
import GlobalSwitch from '../global/Switch';
import PriceTicker from '../../common/PriceTicker';
import { Alert, Collapse } from '@mui/material';
import API from '../../context/api';
import ProgressButton from '../../common/ProgressButton';


const AutoTopupScreen = props => {

    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [enabled, setEnabled] = useState(context.wallet.autoTopUpEnabled);
    const [amount, setAmount] = useState(context.wallet.autoTopUpAmount);
    const [trigger, setTrigger] = useState(context.wallet.autoTopUpBalanceTrigger);

    const toggleEnabled = () => {
        setEnabled(!enabled);
    }


    const close = () => {
        if(typeof props.close === 'function')
            props.close();
    }

    const handleSubmit = async () => {

        try {

            setLoading(true);
            setError(false);
            setSuccess(false);

            let params = {
                AutoTopUpEnabled: enabled,
                AutoTopUpAmount: amount,
                AutoTopUpBalanceTrigger: trigger
            }

            const update = await API.req('/user/wallet/autotopup', 'PUT', params);

            if(update.success){
                setSuccess(true);
                context.userWalletGet();
            }


        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        if(success === true)
            setTimeout(() => setSuccess(false), 4000)
    }, [success])


    //
    // If props.show changes update the presets to the existing wallet.
    //
    useEffect(() => {

        setEnabled(context.wallet.autoTopUpEnabled);
        setAmount(context.wallet.autoTopUpAmount);
        setTrigger(context.wallet.autoTopUpBalanceTrigger);

    }, [props.show])



    return (
        <React.Fragment>
            {loading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
            <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
                
                <div style={styles.wrapper}>
                    <a href="javascript:void(0)" style={styles.closeButton} onClick={close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>

                    <div style={styles.header}>
                        <img src={Images.auto_wallet_topup_icon} alt={t('AUTO_WALLET_TOP_UP')} style={styles.headingImage} />
                        <h2 style={styles.title}>{t('AUTO_WALLET_TOP_UP')}</h2>
                        <p style={styles.description}>{t('AUTO_WALLET_TOP_UP_DESC')}</p>
                    </div>

                    <div style={styles.form}>

                        <Collapse in={success}><Alert severity='success'>{t('AUTO_WALLET_TOP_UP_SUCCESS')}</Alert></Collapse>
                        <Collapse in={error}><Alert severity='error'>{t('AUTO_WALLET_TOP_UP_ERROR')}</Alert></Collapse>

                        <div style={styles.filterItem}>
                            <span style={styles.filterItemLabel}>{t('ENABLED')}{!context.wallet.card?'':' (**'+context.wallet.card+')'}</span>
                            <div style={styles.filterItemSwitch}>
                                <GlobalSwitch checked={enabled} id={'auto-topup-enabled'} onChange={toggleEnabled} />
                            </div>
                        </div>

                        <Collapse in={enabled}>
                            <PriceTicker amount={trigger} currency={context.wallet.currency} label={t('AUTO_WALLET_TOP_UP_BALANCE_TRIGGER')} onChange={setTrigger} />
                            <PriceTicker amount={amount} currency={context.wallet.currency} label={t('AUTO_WALLET_TOP_UP_AMOUNT')} onChange={setAmount} />
                        </Collapse>

                        <div style={styles.buttonGroup}>
                            <button onClick={close} style={styles.buttonGroupBtn}>{t('CLOSE')}</button>
                            <ProgressButton handleSubmit={handleSubmit} label='Save' loading={loading} />
                        </div>
                        
                    </div>
                    
                    

                </div>
            </div>
        </React.Fragment>
    )
}

const styles = {
    container: {
        position: 'absolute',
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    wrapper: {
        height: '100%',
        width: '80%',
        maxWidth: 340,
        display: 'flex',
        flexDirection: 'column'
    },
    closeButton: {
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99
    },
    loadingWrapper: {
      position: 'fixed',
      backgroundColor: 'rgba(255,255,255,.4)',
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    form: {
        paddingTop: 10,
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    caption: {
        fontSize: 11
    },
    buttonGroup: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonGroupBtn: {
        width: 120,
        height: 40,
        backgroundColor: '#555555',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        color: '#ffffff', 
        textTransform: 'uppercase',
        marginTop: 20
    },
    error: {
        backgroundColor: '#d94646',
        color: '#ffffff',
        padding: '8px 12px',
        marginBottom: 15,
        borderRadius: 4
    },
    title: {
        color: '#6CB33F',
        fontWeight: 900,
        margin: 0
    },
    description: {
        textAlign: 'center',
        fontSize: 13
    },
    header: {
        paddingTop: 102,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    headingImage: {
      height: 62,
      margin: '20px 0',
      width: 'auto'
    },
    filterItem: {
        width: '100%',
        display: 'flex',
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
    },
    filterItemLabel: {
        flex: 1,
        fontSize: 16
    }
}

export default AutoTopupScreen;