import store from "./store";
import axiosRetry from "axios-retry";
const axios = require("axios").default;

class API {
  //static ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/v1.2' : process.env.REACT_APP_ENDPOINT;
  static ENDPOINT = process.env.REACT_APP_ENDPOINT;

  /**
   *
   * @param {*} uri The path to be added to the end of your endpoint e.g. /login
   * @param {*} method GET | POST | PUT | DELETE
   * @param {*} body The params to be passed either in the body of a POST or PUT or as the params of a GET
   * @param {*} headers Any custom headers to be sent
   * @returns Promise with the response from the server
   */
  static req = (
    uri = null,
    method = null,
    body = null,
    headers = {},
    retries = 0,
  ) => {
    axiosRetry(axios, {
      retries: retries,
      retryCondition: (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) ||
          error.code === "ECONNABORTED"
        );
      },
      retryDelay: () => {
        return 3000;
      },
    });

    //
    //  Build the headers
    //
    let reqHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    };

    const state = store.getState();
    if (state.user.status === "in" && state.user.profile.token !== null)
      reqHeaders["Authorization"] = `Bearer ${state.user.profile.token}`;

    //
    //  Add in the query string or body
    //
    const params = {};
    if (method === "POST" || method === "PUT") params.data = body;
    if (method === "GET") params.params = body;

    /*
     * AXIOS
     */
    return new Promise((resolve, reject) => {
      axios
        .request({
          ...params,
          url: this.ENDPOINT + uri,
          method: method,
          headers: reqHeaders,
          timeout: 25000,
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200 && res.data.success === true)
            resolve(res.data);
          else reject(typeof res.data.msg !== "undefined" ? res.data.msg : res);
        })
        .catch((err) => {
          if (err.response) {
            reject(
              typeof err.response.data.msg !== "undefined"
                ? err.response.data.msg
                : err,
            );
          } else {
            reject("NETWORK_ERROR");
          }
        });
    });
  };

  static fileUpload = (uri = null, body = null) => {
    //
    //  Build the headers
    //
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const state = store.getState();
    if (state.user.status === "in" && state.user.profile.token !== null)
      headers["Authorization"] = `Bearer ${state.user.profile.token}`;

    let formData = new FormData();

    Object.keys(body).map((name) => {
      formData.append(name, body[name]);
    });

    return new Promise((resolve, reject) => {
      axios
        .request({
          url: this.ENDPOINT + uri,
          headers: headers,
          method: "POST",
          data: formData,
          timeout: 10000,
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200 && res.data.success === true)
            resolve(res.data);
          else reject(typeof res.data.msg !== "undefined" ? res.data.msg : res);
        })
        .catch((err) => {
          reject(typeof err.msg !== "undefined" ? err.msg : err);
        });
    });
  };

  /**
   *
   * @param {*} uri The path to be added to the end of your endpoint e.g. /login
   * @param {*} method GET | POST | PUT | DELETE
   * @param {*} body The params to be passed either in the body of a POST or PUT or as the params of a GET
   * @param {*} headers Any custom headers to be sent
   * @returns Promise with the response from the server
   */
  static fileDownload = (
    uri = null,
    method = null,
    body = null,
    headers = {},
  ) => {
    //
    //  Build the headers
    //
    let reqHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    };

    const state = store.getState();
    if (state.user.status === "in" && state.user.profile.token !== null)
      reqHeaders["Authorization"] = `Bearer ${state.user.profile.token}`;

    /*
     * AXIOS
     */
    return new Promise((resolve, reject) => {
      // We can't download nicely from inside a webview so let the native wrapper handle this
      if (
        process.env.REACT_APP_NATIVE == "true" &&
        typeof window.webViewBridge !== "undefined"
      ) {
        window.webViewBridge.send(
          "download",
          {
            uri: uri,
            method: method,
            body: body,
            headers: reqHeaders,
          },
          true,
          false,
        );
        resolve("NATIVE");
      } else {
        axios
          .request({
            url: this.ENDPOINT + uri,
            method: method,
            headers: reqHeaders,
            data: method !== "GET" ? body : null,
            params: method === "GET" ? body : null,
            timeout: 15000,
            withCredentials: true,
            responseType: "blob",
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(typeof err.msg !== "undefined" ? err.msg : err);
          });
      }
    });
  };

  static addToLocalArray = async (storage_key, val) => {
    const local_array = localStorage.getItem(storage_key);
    let new_array = local_array !== null ? JSON.parse(local_array) : [];
    new_array.push(val);
    localStorage.setItem(storage_key, JSON.stringify(new_array));
    return {
      success: true,
      result: val,
    };
  };

  static getLocal = async (storage_key) => {
    const local_array = localStorage.getItem(storage_key);
    if (local_array !== null && typeof JSON.parse(local_array) !== "undefined")
      return JSON.parse(local_array);
    else return null;
  };

  static setLocal = async (storage_key, val) => {
    localStorage.setItem(storage_key, JSON.stringify(val));
  };
}
export default API;
