import React, { useContext, useEffect, useState } from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const ProfileHeader = props => {

    const context = useContext(GlobalContext);
    const history = useHistory();
    const {t} = useTranslation();

    const d = new Date();
    const goBack = () => {
        if(history.action === 'POP'){
            history.push('/')
        } else {
            context.localHistory.map(h => history.push(h));
            history.goBack()
        }
    }

    return (
        <React.Fragment>
            <header style={{height: props.height}}>
                <div style={styles.headerContainer}>
                    <div style={styles.topBar}>
                        <button onClick={goBack} style={styles.backButton}><span style={styles.back}>{t('BACK')}</span></button>
                        <div style={styles.topCenter}>
                        <span style={styles.title}>{props.title}</span>
                        </div>
                        <button style={styles.menuButton} onClick={context.toggleMenu}><img src={Images.menubtn} style={{width:55, height:55}} alt="Menu" /></button>
                    </div>
                    {!props.fullScreenMap && <div style={styles.centerBar}>
                        <span style={styles.greeting}>{d.getHours() < 12?t('GREETING_MOR'): d.getHours() < 17?t('GREETING_AFT'):t('GREETING_EVE')}</span>
                        {context.user.profile.user.FirstName && <p style={styles.name}>{context.user.profile.user.FirstName}</p>}
                    </div>}
                </div>
            </header>
        </React.Fragment>
    );
}

const styles = {
    headerContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        background: 'url('+Images.homeheaderbg+')',
        display: 'flex',
        flexDirection: 'column'
    },
    topBar: {
        width: '100%',
        height: 55,
        alignItems: 'center',
        marginTop: 0,
        display: 'flex',
        position: 'relative'
    },
    topCenter: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuButton: {
        position: 'absolute',
        top: 0,
        right: 5,
        height: 55,
        width: 55,
        background: 'transparent',
        border: 'none'
    },
    backButton: {
        display: 'flex',
        position: 'absolute',
        height: 55,
        top: 0,
        left: 15,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        border: 'none'
    },
    centerBar: {
        height: 95,
        width: '100%',
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    greeting: {
        color: '#ffffff',
        fontSize: 28,
        fontWeight: '400',
    },
    name: {
        color: '#ffffff',
        fontSize: 28,
    },
    back: {
        color: '#ffffff',
        fontSize: 18
    },
    title: {
        color: '#ffffff',
        fontSize: 18
    }
};

export default ProfileHeader;