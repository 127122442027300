import React from 'react';
import GlobalContext from '../../context/global-context';
import Images from '../../assets/images';
import Helpers from '../../context/helpers';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation } from 'react-i18next';

//  The live socket client for real time charge data
let socket;

//  Poller to check if charge session started
let startedTimeout;

//  Poller to check if charge session is taking a while to start
let delayTimeout;

//  Clock ticker that adjusts the remaining time and animates the icons
let clockTimeout;

//  This is a timeout to return the normal screen if the 
//  user didn't press the confirm end charge button
let endTimeout;


//  Polls round after the charge has started to check for an ended session
//  this works as back up if the live socket isn't available
let endedTimeout;


// The idea is to increment the socketStateCount every time a live state 
// comes in, this will give an indication where the socket is at
let socketStateCount = 0;


class ActiveCharge extends React.Component {

    abortController = new AbortController()

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            site: this.props.site,
            point: this.props.point,
            connector: this.props.connector,
            session: null,
            liveSession: null,
            connectingSocket: false,
            endTime: {
                hours: 0,
                minutes: 0
            },
            iconState: 0,
            is: {
                delayed: false,
                started: false,
                confirmEnd: false,
                ending: false,
                ended: false,
                failed: false,
            }
        };

    }

    componentDidMount(){

        this._init();

        window.addEventListener('io', () => {
            this._connectLiveSocket();
        })

    }

    componentWillUnmount() {
        window.removeEventListener('io', null, false);
        this.abortController.abort();

        if(typeof socket !== 'undefined' && socket !== false)
            socket.disconnect();

       clearTimeout(startedTimeout);
       clearTimeout(clockTimeout);
       clearTimeout(endTimeout);
       clearTimeout(endedTimeout);
    }

    _init = () => {

        // Get the acive charge session from the users charge sessions
        // If this is a user that hasn't logged in it will pull from the local
        // storage
        if(typeof this.context.account.charges !== 'undefined'){
            
            // filter all the user sessions by the charge point ID
            const sessions = this.context.account.charges.filter(s => s.EvPointSiteID==this.state.site.id && s.EvPoint == this.state.point.id && s.Connector == this.state.connector.number);

            // Loop through and check if the end time of the charge session is ahead
            for(let i=0; i<sessions.length; i++){

                const s = sessions[i];
                const eDate = Helpers.SQLtoUTCDate(s.EndTime);
                // const bits = s.Time.split(':');
                // eDate.setHours(eDate.getHours()+parseInt(bits[0]));
                // eDate.setMinutes(eDate.getMinutes()+parseInt(bits[1]));
                // eDate.setSeconds(eDate.getSeconds()+parseInt(bits[2]));
                const now = new Date();

                if(eDate>now){
                    // Set the active charge session
                    this.setState({session: {...s, EndTime: eDate}}, () => {
                        this._pollSocketCharging();
                        this._pollSocketDelayed();
                    });
                    break;
                }
            }
        }
    }



    //
    //  Poll to see if the charge point has started charging and if so display the active charge session screen
    //

    _pollSocketCharging = () => {
        if(typeof startedTimeout !== 'undefined')
            clearTimeout(startedTimeout);
        
        // Re-fetch the site
        this.context.fetchSite(this.state.site.id, this.abortController)
        .then((site) => {

            // Get the point from the site
            let point = site.points.find(p => p.id === this.state.session.EvPoint);
            let connector = site.connectors.find(c => c.pointId === this.state.session.EvPoint && c.number === this.state.session.Connector);
            const conLen = site.connectors.filter(c => c.pointId === this.state.session.EvPoint).length;

            //
            //  If there's more than one connector per charge point id then
            //  availabilty is determined if the point status is charging and
            //  the connector is connected, otherwise it is available
            //
            //  If connector to charge point is one to one then go by the charge
            //  point status
            //
            if(point.status === 'CHARGING' && ((conLen > 1 && connector.status === 'CONNECTED') || (conLen === 1))){
                // Charging
                this._setIs({started: true}, () => {

                    // Clear the delay timeout if it is running
                    if(typeof delayTimeout !== 'undefined')
                        clearTimeout(delayTimeout);

                    this._setActiveEndTime();
                    this._connectLiveSocket();
                    this._pollSocketEnded();
                });

            } else {

                //
                // Check the session hasn't failed to start
                //
                const paygCode = typeof this.state.session.PAYGCode !== 'undefined'?this.state.session.PAYGCode:'UKEV'+this.state.point.id;
                this.context.checkChargeStatus(paygCode)
                .then(res => {
                    if(res.SessionActive === false)
                        this._setIs({ended: true, failed: true}, () => {
                            // This removes the charge from the local storage
                            this.context.unlogCharge(this.state.point.id)
                        })
                    else{
                        // Not started yet, check again in 5 seconds
                        startedTimeout = setTimeout( () => {
                            this._pollSocketCharging();
                        }, 5000)
                    }
                        
                })
                .catch(err => {
                    console.log('cannot check the charge status');
                })

                
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }



    //
    //  Poll to see if the charge point is taking a while to start
    //

    _pollSocketDelayed = () => {
        if(typeof delayTimeout !== 'undefined')
            clearTimeout(delayTimeout);

        //
        //  Run in 30 seconds. If it hasn't already been cancelled that is.
        //
        delayTimeout = setTimeout( () => {
            if(!this.state.is.started && !this.state.is.delayed && !this.state.is.ended && !this.state.is.failed)
                this._setIs({delayed: true})
        }, 15000)
    }


    //
    // Calulates the time left and runs itself again every second updating the time and the charging icon.
    //
    _setActiveEndTime = () => {

        if(this.state.session.EndTime > new Date()){
            let hrs = 0, mins = 0, secs= 0;
            var diff = (this.state.session.EndTime - new Date());

            // if(this.state.liveSession !== null){
            //     mins = this.state.liveSession.minutesRemaining;
            //     hrs = Math.floor(mins / 60);
            //     mins = mins - (hrs*60);
            // } else {
                // First get the total seconds
                secs = Math.abs(diff)/1000;
          
                // then get the total hours in those seconds and deduct from seconds
                hrs = Math.floor(secs/3600);
                secs = secs - (hrs*3600);
          
                // then get the total mins left in the seconds and deduct from the seconds
                mins = Math.floor(secs/60);
                secs = Math.floor(secs - (mins*60));
          
                //let oldTimer = ("0" + hrs).slice(-2) + ':' + ("0" + mins).slice(-2) + ':' + ("0" + secs).slice(-2);
          
                // if secs is greater than 0 add another minute to the minutes
                if(secs > 1)
                  mins++;
                
                // if the mins is now 60 change to an hour
                if(mins === 60){
                  hrs++;
                  mins = 0;
                }
            // }
      
            this.setState({
                endTime: {
                    hours: (hrs>0?("0" + hrs).slice(-2):'00'),
                    minutes: (mins>0?("0" + mins).slice(-2):'00')
                },
                iconState: this._iconState()
            }, () => {

                // Run again in one second
                clockTimeout = setTimeout(this._setActiveEndTime, 1000);
            })
          } else {
            this.setState({
                endTime: {
                    hours: '00',
                    minutes: '00'
                },
                iconState: 0
            })
          }
      }

      _iconState = () => {

        if(this.state.connectingSocket)
            return 0;

        if(this.state.liveSession !== null)
            if(!this.state.liveSession.isCharging || !this.state.liveSession.isCableConnected)
                return 0;
        
        if(this.state.is.ended)
            return 0;
            
        return this.state.iconState >=4?1:this.state.iconState+1
        
      }


      _connectLiveSocket = () => {
        this.setState({connectingSocket: true}, () => {
            if(typeof window.io !== 'undefined' && this.state.session !== null && typeof this.state.session.ChargeSessionId !== 'undefined'){

                socket = this.context.connectLiveChargeSession(this.state.session.ChargeSessionId);
    
                if(socket !== false){
    
                    socket.on('sessionState', state => {
                        socketStateCount++;
                        this.setState({liveSession: state, connectingSocket: false});
                    });
            
                    socket.on('sessionEnd', () => {
                        this._setIs({ended: true}, () => {
                            this.setState({liveSession: null, connectingSocket: false})
                        });
                    });
            
                    socket.on('sessionError', err => {
                        this.setState({liveSession: null, connectingSocket: false});
                    });
    
                }
            } else {
                this.setState({liveSession: null, connectingSocket: false});
            }
        })
      }


      _pollSocketEnded = () => {

        if(typeof endedTimeout !== 'undefined')
            clearTimeout(endedTimeout);

          // Check the status of the session
          const paygCode = typeof this.state.session.PAYGCode !== 'undefined'?this.state.session.PAYGCode:'UKEV'+this.state.point.id;
          this.context.checkChargeStatus(paygCode)
          .then(res => {

            // If it is inactive then end the session
            // and unlog any session that exists in the local
            // storage for guests.
            if(res.SessionActive === false){
                this._setIs({ended: true}, () => {
                    // This removes the charge from the local storage
                    this.context.unlogCharge(this.state.point.id)
                })
            } else {
                endedTimeout = setTimeout(this._pollSocketEnded, 5000);
            }

          })
          .catch(err => {
            endedTimeout = setTimeout(this._pollSocketEnded, 5000);
          })

      }


      endActiveCharge = () => {
  
          this._setIs({confirmEnd: true, ended: false, ending: false}, () => {
              endTimeout = setTimeout(() => {
                  this._setIs({confirmEnd: false})
              },5000)
          });
  
      }


        endActiveChargeConfirm = () => {

            this._setIs({ending: true, ended: false}, () => {

                if(typeof this.state.session.ChargeSessionId !== 'undefined' && this.state.session.ChargeSessionId !== null){
                    this.context.endCharge(this.state.session.ChargeSessionId).then(
                        res => {
                            this._setIs({ended: false, ending: true, confirmEnd: false});
                        },
                        err => {
                            this._setIs({ended: false, ending: false, confirmEnd: false});
                        }
                    );
                } else {
                    this._setIs({ended: false, ending: false, confirmEnd: false});
                }

            });

        }


    //
    //
    //  Supporting functions
    //
    //

    _setIs(newIs = {}, callback = () => {}){
        let is = {
            ...this.state.is,
            ...newIs
        };
        this.setState({is: is}, callback);
    }

    render() {

        const isVisableIcon = iconState => {
            return this.state.iconState === iconState ? styles.carImageShow : {};
        } 

        const showTime = () => {
            if(typeof this.state.session.UnitOfMeasureType !== 'undefined' && this.state.session.UnitOfMeasureType.toUpperCase() !== 'KWH')
                return true;
            
            return false;
        }
        
        return (
        <Translation>{t => 
            <div style={styles.container}>


                {/* CONNECTING WITH CHARGE POINT */}

                {(!this.state.is.started && !this.state.is.ended) && 
                    <div style={Object.assign({}, styles.wrapper, styles.middleWrapper)}>

                        <div style={styles.loading}><ActivityIndicator /></div>

                        <div style={styles.heading}>
                            <span>{t('CHARGE_CONNECTING')}</span>
                        </div>

                        {this.state.is.delayed && 
                            <div style={styles.delayed}>
                                <span>{t('CHARGE_DELAYED')}</span>
                            </div>
                        }

                        <img src={Images.cable_connect} style={styles.carConnect} alt="Connect cable" />

                        {this.state.is.delayed && <span style={styles.notice}><img src={Images.help} alt="unplug" style={styles.noticeIcon} />{t('UNPLUG_NOTICE')}</span>}
                        {!this.state.is.delayed && <span style={styles.plugin}>{t('PLUG_IN')}</span>}
                        <span style={styles.plugin_locked}>{t('PLUG_IN_LOCKED')}</span>
                    
                    </div>
                }

                {/* CHARGE SESSION ENDED */}

                {(this.state.is.ended && !this.state.is.failed) && 
                    <div style={Object.assign({}, styles.wrapper, styles.middleWrapper)}>

                        <img src={Images.bolt_white} style={{width: 40}} alt="Charge ended" />

                        <div style={styles.heading}>
                            <span>{t('CHARGE_ENDED')}</span>
                        </div>
                        
                        <a href="JavaScript:void(0);" style={styles.reloadButton} onClick={this.props.reload}>{t('RELOAD')}</a>
                    
                    </div>
                }


                {/* CHARGE SESSION FAILED */}

                {(this.state.is.ended && this.state.is.failed) && 
                    <div style={Object.assign({}, styles.wrapper, styles.middleWrapper)}>

                        <img src={Images.warning_large} style={{width: 60}} alt="Charge failed" />

                        <div style={styles.heading}>
                            <span>{t('CHARGE_FAILED')}</span>
                        </div>
                        <span style={styles.plugin_again}>{t('PLUG_IN_TRY_AGAIN')}</span>
                        
                        <a href="JavaScript:void(0);" style={styles.reloadButton} onClick={this.props.reload}>{t('RELOAD')}</a>
                    
                    </div>
                }


                {/* CONFIRMING END CHARGE SESSION */}

                {((this.state.is.confirmEnd || this.state.is.ending) && !this.state.is.ended) && 
                    <div style={Object.assign({}, styles.wrapper, styles.middleWrapper)}>

                        {(!this.state.is.ending && this.state.is.confirmEnd) &&
                            <div style={styles.endButtonConfirmWrapper}>
                                <a href="JavaScript:void(0);" style={Object.assign({}, styles.endButton, styles.endButtonConfirm)} onClick={this.endActiveChargeConfirm}>{t('CONFIRM_STOP')}</a>
                                <span style={styles.endButtonConfirmText}>{t('CONFIRM_STOP_MSG')}</span>
                            </div>
                        }

                        {(this.state.is.ending) && 
                            <div style={styles.endButtonConfirmWrapper}>
                                <div style={styles.loading}><ActivityIndicator /></div>
                                <span style={styles.endButtonConfirmText}>{t('CONFIRM_STOP_ENDING')}</span>
                            </div>
                        }
                    
                    </div>
                }





                {/* 
                *
                *   ACTIVE CHARGE SESSION
                *
                */}



                {(this.state.is.started && !this.state.is.ended && !this.state.is.ending && !this.state.is.confirmEnd) && 
                    <div style={styles.wrapper}>

                        {/* TITLE */}

                        <div style={styles.heading}>
                            <span>{t('CHARGE_ACTIVE')}</span>
                        </div>

                        {/* CAR ANIMATION */}

                        <div style={styles.car}>
                            <img src={Images.charging.connect} style={Object.assign({}, styles.carImage, isVisableIcon(0))} alt="Charging" />
                            <img src={Images.charging.connected1} style={Object.assign({}, styles.carImage, isVisableIcon(1))} alt="Charging" />
                            <img src={Images.charging.connected2} style={Object.assign({}, styles.carImage, isVisableIcon(2))} alt="Charging" />
                            <img src={Images.charging.connected3} style={Object.assign({}, styles.carImage, isVisableIcon(3))} alt="Charging" />
                            <img src={Images.charging.connected4} style={Object.assign({}, styles.carImage, isVisableIcon(4))} alt="Charging" />
                        </div>


                        {(this.state.liveSession && !this.state.connectingSocket) && 
                            <span style={styles.liveInfoMsg}>{t('CHARGE_LIVE_CONNECTING')}</span>
                        }

                        
                        {/* NO LIVE SESSION AND NOT CONNECTION */}

                        {(!this.state.liveSession && !this.state.connectingSocket) && 
                            <span style={styles.liveInfoMsg}>{t('CHARGE_CONNECTED')}</span>
                        }


                        <div style={styles.infoWrapper}>

                            {/* CHARGE TIME CLOCK */}

                            {showTime() && 
                                <div style={styles.infoPanel}>
                                    <span>{t('CHARGE_TIME')}:</span>
                                    <div style={styles.info}>
                                        <div style={styles.infoValue}>
                                            <span style={styles.digit}>{this.state.endTime.hours}</span>
                                            <span style={styles.digitLabel}>{t('HR')}</span>
                                        </div>
                                        <div style={styles.infoSeparator}>:</div>
                                        <div style={styles.infoValue}>
                                            <span style={styles.digit}>{this.state.endTime.minutes}</span>
                                            <span style={styles.digitLabel}>{t('MIN')}</span>
                                        </div>
                                    </div>
                                </div>
                            }



                            {/* LOADING SOCKET */}

                            {this.state.connectingSocket && 
                                <div style={styles.infoPanel}>
                                    <div style={styles.info}>
                                        <div style={styles.infoValue}>
                                            <span style={styles.digit}>
                                                <div style={styles.kwhLoading}><ActivityIndicator /></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }


                            {/* CURRENT PULL */}

                            {(this.state.liveSession && !this.state.connectingSocket) && 
                                <div style={styles.infoPanel}>
                                    <span>{t('RATE')}:</span>
                                    <div style={styles.info}>
                                        <div style={styles.infoValue}>
                                            <span style={styles.digit}>
                                                {(this.state.liveSession.loadKw <= 0 && socketStateCount < 2) &&
                                                    <div style={styles.kwhLoading}><ActivityIndicator /></div>
                                                }
                                                {(this.state.liveSession.loadKw > 0 || socketStateCount >= 2) && 
                                                    this.state.liveSession.loadKw
                                                }
                                            </span>
                                            <span style={styles.digitLabel}>kW</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* STATUS */}

                            {(this.state.liveSession && !this.state.connectingSocket) && 
                                <div style={styles.infoPanel}>
                                    <span>{t('STATUS')}:</span>
                                    <div style={styles.info}>
                                        <div style={styles.infoValue}>
                                            <span style={styles.digit}>
                                                {(this.state.liveSession.isCharging && this.state.liveSession.isCableConnected) && <img src={Images.charging_status.charging} style={Object.assign({}, styles.statusImage)} alt="Charging" />}
                                                {(!this.state.liveSession.isCharging && this.state.liveSession.isCableConnected) && <img src={Images.charging_status.connected} style={Object.assign({}, styles.statusImage)} alt="Connected"  />}
                                                {(!this.state.liveSession.isCableConnected) && <img src={Images.charging_status.connect} style={Object.assign({}, styles.statusImage)} alt="Connect"  />}
                                            </span>
                                            <span style={styles.digitLabel}>{!this.state.liveSession.isCableConnected?t('NOT_CONNECTED'):this.state.liveSession.isCharging?t('CHARGING'):t('CONNECTED')}</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* CHARGE POINT */}

                            {(this.state.liveSession && !this.state.connectingSocket) && 
                                <div style={styles.infoPanel}>
                                    <span>{t('AVAILABLE')}:</span>
                                    <div style={styles.info}>
                                        <div style={styles.infoValue}>
                                            <span style={styles.digit}>{this.state.liveSession.loadAmps}</span>
                                            <span style={styles.digitLabel}>Amps</span>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        

                        {/* END CHARGE SESSION BUTTON */}

                        {(this.state.session.ChargeSessionId && this.state.point.id > 999) && 
                        <div style={styles.end}>

                            {(!this.state.is.ending && !this.state.ended && !this.state.is.confirmEnd) && <a href="JavaScript:void(0);" style={styles.endButton} onClick={this.endActiveCharge}><span style={styles.endButtonText}>{t('STOP_CHARGE')}</span></a>}

                        </div>}
                
                    </div>
                }

            </div>
            }</Translation>
        )
    }

}

const styles = {
    container: {
        backgroundColor: '#6CB33F',
        background: 'linear-gradient(180deg, #6CB33 0px, rgb(9, 108, 167)',
        // display: 'flex',
        // flexDirection: 'row',
        width: '100%',
        flex: 1,
        position: 'relative',
        overflow: 'auto',
        // justifyContent: 'center',
    },
    wrapper: {
        width: '80%',
        maxWidth: 320,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto'
    },
    middleWrapper: {
        minHeight: '100%',
        justifyContent: 'center'
    },
    heading: {
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        color: '#fff',
        fontWeight: 400,
        fontSize: 26,
        display: 'flex',
        justifyContent: 'center'
    },
    infoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap:'wrap',
    },
    infoPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff',
        fontWeight: 400,
        fontSize: 16,
        padding: 15
    },
    info: {
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    infoValue: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infoSeparator: {
        color: '#fff',
        fontWeight: 300,
        fontSize: 32,
        width: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    digit: {
        paddingBottom: 10,
        color: '#fff',
        fontWeight: 400,
        fontSize: 32
    },
    digitLabel: {
        paddingBottom: 10,
        color: '#fff',
        fontWeight: 300,
        fontSize: 16
    },
    liveInfoMsg: {
        paddingBottom: 10,
        color: '#fff',
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center'
    },
    car: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        minHeight: 180,
    },
    carConnect: {
        width: 180
    },
    carImage: {
        position: 'absolute',
        left: '50%',
        top: 20,
        transform: 'translateX(-50%)',
        width: 180,
        visibility: 'hidden'
    },
    carImageShow: {
        visibility: 'visible'
    },
    loadingWrapper: {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,.4)',
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    kwhLoading: {
      backgroundColor: 'transparent',
      opacity: .6,
      borderRadius: 25,
      height: 32,
      width: 32,
      display: 'inline-block'
    },
    end: {
        marginBottom: 20
    },
    endButton: {
        border: '2px solid #fff',
        borderRadius: 8,
        height: 30,
        width: 120,
        textAlign: 'center',
        color: '#fff',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        marginTop: 15,
        marginBottom: 10
    },
    endButtonConfirm: {
        backgroundColor: '#fff',
        color: '#6CB33F',
        width: 200,
    },
    endButtonConfirmWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#fff',
    },
    statusImage: {
        height: 30,
    },
    reloadButton: {
        border: '2px solid #fff',
        borderRadius: 8,
        height: 30,
        width: 120,
        textAlign: 'center',
        color: '#fff',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        marginTop: 15,
        marginBottom: 10,
        fontSize: 16
    },
    delayed: {
        textAlign: 'center',
        paddingBottm: 10,
        color: '#ffeb3b',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center'
    },
    notice: {
        marginTop: 16,
        marginBottom: 16,
        textAlign: 'left',
        padding: 14,
        borderRadius: 10,
        backgroundColor: '#fff',
        color: '#484848',
        fontSize: 14,
        display: 'flex',
        flexDirection: 'row',
        gap: '14px',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    noticeIcon: {
        width: 28,
        height: 'auto'
    },
    plugin: {
        textAlign: 'center',
        paddingTop: 20,
        color: '#fff',
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center'
    },
    plugin_locked: {
        textAlign: 'center',
        paddingTop: 10,
        color: '#e5ffd5',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center'
    },
    plugin_again: {
        textAlign: 'center',
        color: '#fff',
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center'
    }
}

export default ActiveCharge;