import React from 'react';
import Header from '../global/Header';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import isAuth from '../../components/login/IsAuth';
import Helpers from '../../context/helpers';
import { Translation } from 'react-i18next';


class SessionsScreen extends React.Component {

    static contextType = GlobalContext;

    constructor(props){
      super(props);

      this.state = {
        location: null,
        sessions: [],
      }
    }

    asDate = (d) => {
      const nDate = new Date(d);
      let dd = nDate.getDate();
      let mm = nDate.getMonth() + 1;
      let yyyy = nDate.getFullYear();

      if (dd < 10) {
        dd = '0' + dd;
      } 
      if (mm < 10) {
        mm = '0' + mm;
      } 
      return dd + '/' + mm + '/' + yyyy;
    }

    asOldTime = (t) => {
      const bits = t.split(':');
      let hours = (parseFloat(bits[0])>0)?bits[0]+'hr':'';
      if(parseFloat(bits[0])>1)
        hours +='s';
      
      let minutes = (parseFloat(bits[1])>1)?bits[1]+'m':'';
        //if(parseFloat(bits[1])>1)
        //minutes +='s';

      let time = hours + ' ' + minutes;
      if(parseFloat(bits[0])==0 && parseFloat(bits[1])==0)
        time = parseFloat(bits[2]).toFixed(0) + 's';

      return time;
    }

    //
    // Calulates the time
    //
    asTime = (s) => {

      let hrs = 0, mins = 0, secs= 0;
      var diff = (Helpers.SQLtoUTCDate(s.EndTime) - Helpers.SQLtoUTCDate(s.StartTime));
      // Get the seconds
      secs = Math.abs(diff)/1000;
      // Find out how many hours
      hrs = Math.floor(secs/3600);
      // Reset the seconds
      secs = secs - (hrs*3600);
      // Find out how many minutes
      mins = Math.floor(secs/60);
      // Reset the seconds 
      secs = Math.floor(secs - (mins*60));

      return (hrs>0?hrs+(hrs>1?'hrs ':'hr '):'')+(mins>0?mins+(mins>1?'mins':'min'):'');
    }

    getEndDate = (s) => {
      const eDate = Helpers.SQLtoUTCDate(s.StartTime);
      const bits = s.Time.split(':');
      eDate.setHours(eDate.getHours()+parseInt(bits[0]));
      eDate.setMinutes(eDate.getMinutes()+parseInt(bits[1]));
      eDate.setSeconds(eDate.getSeconds()+parseInt(bits[2]));
      return eDate;
    }

    isActive = (s) => {
      const now = new Date();
      return Helpers.SQLtoUTCDate(s.EndTime)>now?true:false;
    }

    timeLeft = (s) => {
      const eDate = Helpers.SQLtoUTCDate(s.EndTime);
      if(eDate > new Date()){
          let hrs = 0, mins = 0, secs= 0;
          var diff = (new Date() - eDate);
          // First get the total seconds
          secs = Math.abs(diff)/1000;

          // then get the total hours in those seconds and deduct from seconds
          hrs = Math.floor(secs/3600);
          secs = secs - (hrs*3600);

          // then get the total hours left in the seconds and deduct from the seconds
          mins = Math.floor(secs/60);
          secs = Math.floor(secs - (mins*60));

          const tLeft = ("0" + hrs).slice(-2) + ':' + ("0" + mins).slice(-2) + ':' + ("0" + secs).slice(-2);

          // if secs is greater than 0 add another minute to the minutes
          if(secs > 1)
          mins++;
        
          // if the mins is now 60 change to an hour
          if(mins === 60){
            hrs++;
            mins = 0;
          }

          let newTimer = (hrs>0?("0" + hrs).slice(-2) + (hrs>1?'hrs ':'hr '):'') + (mins>0?("0" + mins).slice(-2) + 'm':'');
          return newTimer;
      } else {
        return '00:00';
      }
    }

    componentDidMount(){
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }
    componentWillUnmount() {
      clearInterval(this.interval);
    }

    sessions = () => {
      return this.context.account.charges.map((session, key) => (
      <Translation>{t => 
        <div style={styles.session} key={key} onClick={() => this.props.history.push('/point/'+session.EvPointSiteID+'/'+(session.EvPoint>999?session.EvPoint:session.EvPoint + '/' + session.Connector))}>
          <div style={styles.detail}>
              <span style={styles.title}>{t('POINT')}: {session.EvPoint>599?'UKEV':'EV-'}{(session.EvPoint<1000 && session.EvPoint > 599) && '0'}{session.EvPoint}{session.EvPoint<600&&'-'+session.Connector}</span>
              <span style={styles.date}>{t('DATE')}: {this.asDate(session.StartTime)}</span>
              <span style={styles.time}>{t('DURATION')}: {this.asTime(session)}</span>
          </div>
          <div style={styles.status}>
              {(this.isActive(session) && session.UnitOfMeasureType !== 'kwh') && <span style={Object.assign({}, styles.statusText, styles.statusTextActive)}>{this.timeLeft(session)}</span>}
              {(this.isActive(session) && session.UnitOfMeasureType === 'kwh') && <span style={Object.assign({}, styles.statusText, styles.statusTextActive)}>Charging</span>}
              {!this.isActive(session) && <span style={Object.assign({}, styles.statusText)}>{parseFloat(session.Cost).toLocaleString(navigator.language, {style: 'currency', currency: session.Currency})}</span>}
          </div>
        </div>
      }</Translation>
      ));
    }

    render() {
        return (
          <Translation>{t => 
            <div style={styles.container}>
              <Header title={t('TITLE_SESSIONS')} history={this.props.history} />
                
                <div style={styles.container}>

                  {!this.context.account.charges.length && <div style={styles.emptyContainer}><span>{t('SESSIONS_NONE')}</span></div>}
                  
                  {this.context.account.charges.length > 0 && <div style={{flex:1}}>
                    {this.sessions()}
                  </div>}
                  
                </div>
            </div> 
          }</Translation>
        );
    }
}

export default isAuth(SessionsScreen);

const styles = {
  container: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  emptyContainer: {
    flex:1, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    color: '#a2a2a2'
  },
  session: {
    width: '100%',
    height: 100,
    flex: 1,
    flexWrap:'wrap',
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: '#e8e8e8',
  },
  status: {
      height: '100%',
      width: '20%',
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
  },
  statusText: {
      color: '#aaa',
      fontSize: 20,
      fontWeight: 900,
      textAlign: 'center'
  },
  statusTextActive: {
    color: '#8ec63f'
  },
  detail: {
      height: '100%',
      width: '70%',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center'
  },
  arrow: {
      height: '100%',
      width: '10%',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center'
  },
  title: {
      color: '#484848',
      fontSize: 16,
      fontWeight: 900,
      cursor: 'pointer'
  },
  address: {
      color: '#484848',
      fontSize: 14
  },
  time: {
      color: '#484848',
      fontSize: 14
  },
  date: {
      color: '#484848',
      fontSize: 14
  }
};
