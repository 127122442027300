import React from 'react';
import Images from '../../assets/images';
import GlobalContext from '../../context/global-context';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';


const locationMarkerSelectionPopUp = props => {

    const firstPoint = props.sites[0];

    const navigate = (context) => {
        window.open('https://www.google.com/maps/dir/?api=1&parameters&origin='+context.location.lat+','+context.location.lng+'&destination='+firstPoint.location.latitude+','+firstPoint.location.longitude)
     }

    return (
        <GlobalContext.Consumer>
        {context => (
            <Translation>{t=>
            <div style={Object.assign({}, styles.container, {bottom: props.visible?60:-200})} className="animate">
                <div style={styles.summaryContainer}>
                    <div style={styles.summary}>
                        <div style={styles.left}>
                            <div style={styles.distance}>
                                <span style={styles.distanceValue}>{firstPoint.distance}</span>
                                <span style={styles.distanceLabel}>{firstPoint.distanceLabel}</span>
                            </div>
                            <a href="JavaScript:void(0);" onClick={() => {navigate(context)}} style={styles.navigate}>
                                <img src={Images.navigate} style={{width: 32, height: 32}} alt="Navigate" />
                                <span style={styles.navigateLabel}>{t('NAVIGATE')}</span>
                            </a>
                        </div>
                        <div style={styles.right}>
                            {props.sites.map((point) => (
                                <div key={point.id}  style={styles.location} onClick={() => {props.selectLocation(point)}}>
                                    <span style={styles.locationtitle}>{point.name}</span>
                                    <span style={styles.locationaddress}>{context.getSiteAddress(point.id)}</span>
                                    <span style={styles.locationSelect}><img src={Images.navArrowGrey} style={{width: 18, height: 18}} alt="Select location" /></span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }</Translation>
        )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    container: {
        width: '100%',
        position: 'absolute',
        left: 0,
        height: 'auto',
        minHeight: 180,
        maxHeight: 275,
        zIndex: 102,
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        overflow: 'auto'
    },
    summaryContainer: {
        display: 'flex',
        paddingTop: 20,
        paddingBottom: 20
    },
    summary: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    left: {
        flex: 1,
        height: '100%',
        maxWidth: 100,
        maxHeight: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    distance: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    distanceValue: {
        color: '#6CB33F',
        fontSize: 30,
        fontWeight: 'bold'
    },
    distanceLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    navigate: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    navigateLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    location: {
        marginBottom: 15,
        paddingBottom: 15,
        paddingRight: 30,
        minHeight: 50,
        borderBottom: '1px solid #f2f2f2',
        position: 'relative'
    },
    locationtitle: {
        color: '#484848',
        fontSize: 20,
        fontWeight: 900,
        display: 'block'
    },
    locationaddress: {
        color: '#888888',
        fontSize: 14
    },
    locationSelect: {
        position: 'absolute',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)'
    },
};

export default locationMarkerSelectionPopUp;