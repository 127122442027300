import React from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import { Translation } from 'react-i18next';


class GetStartedScreen extends React.Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      activePanel: 1
    };
  }

  next = () => {
    const idx = this.state.activePanel + 1;
    this.setState({ activePanel: idx });
  }

  back = () => {
    const idx = this.state.activePanel - 1;
    this.setState({ activePanel: idx });
  }

  panelClass = (idx) => {
    return this.state.activePanel === idx ? styles.panelIn : this.state.activePanel > idx ? styles.panelDone : styles.panelOut;
  }

  finish = () => {
    this.context.userAcceptOnboarding();
    this.goBack();
  }

  goBack = () => {
    console.log(this.props.history.action);

    // if(this.props.history.action === 'POP'){
    //   console.log('route');
    //   this.props.history.push('/');
    // } else {
    //   console.log('go back');
    //   this.props.history.goBack();
    // }

    this.props.history.replace('/');
  }

  render() {
    return (
      <Translation>{t =>
        <div style={styles.container}>
          <div style={styles.wrapper}>
            <div style={styles.panels}>
              <div style={Object.assign({}, styles.panel, this.panelClass(1))} className="animate">
                <div style={styles.panelBody}>
                  <div style={styles.panelHeader}>
                    <h2 style={styles.panelHeading}>{t('GS_ONE_HEADING')}</h2>
                    <span style={styles.panelDescription}>{t('GS_ONE_DESC')}</span>
                  </div>
                  <div style={Object.assign({ backgroundImage: 'url(' + Images.onboarding.home + ')' }, styles.panelImage)}></div>
                  <div style={styles.panelFooter}>
                    <div style={styles.buttonGroup}>
                      <a id="nextfirst" href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.next}><span style={{ color: '#ffffff' }}>{t('NEXT')}</span></a>
                    </div>
                  </div>
                </div>
              </div>


              <div style={Object.assign({}, styles.panel, this.panelClass(2))} className="animate">
                <div style={styles.panelBody}>
                  <div style={styles.panelHeader}>
                    <h2 id="heading1" style={{ color: '#484848' }}>{t('GS_TWO_HEADING')}</h2>
                    <span>{t('GS_TWO_DESC')}</span>
                  </div>
                  <div style={Object.assign({ backgroundImage: 'url(' + Images.onboarding.location + ')' }, styles.panelImage)}></div>
                  <div style={styles.panelFooter}>
                    <div style={styles.buttonGroup}>
                      <a href="JavaScript:void(0);" style={styles.buttonGroupBtn} onClick={this.back}><span style={{ color: '#ffffff' }}>{t('BACK')}</span></a>
                      <a id="nextSecond" href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.next}><span style={{ color: '#ffffff' }}>{t('NEXT')}</span></a>
                    </div>
                  </div>
                </div>
              </div>


              <div style={Object.assign({}, styles.panel, this.panelClass(3))} className="animate">
                <div style={styles.panelBody}>
                  <div style={styles.panelHeader}>
                    <h2 style={{ color: '#484848' }}>{t('GS_THREE_HEADING')}</h2>
                    <span>{t('GS_THREE_DESC')}</span>
                  </div>
                  <div style={Object.assign({ backgroundImage: 'url(' + Images.onboarding.point + ')' }, styles.panelImage)}></div>
                  <div style={styles.panelFooter}>
                    <div style={styles.buttonGroup}>
                      <a href="JavaScript:void(0);" style={styles.buttonGroupBtn} onClick={this.back}><span style={{ color: '#ffffff' }}>{t('BACK')}</span></a>
                      <a id="nextThird" href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.next}><span style={{ color: '#ffffff' }}>{t('NEXT')}</span></a>
                    </div>
                  </div>
                </div>
              </div>


              <div style={Object.assign({}, styles.panel, this.panelClass(4))} className="animate">
                <div style={styles.panelBody}>
                  <div style={styles.panelHeader}>
                    <h2 style={{ color: '#484848' }}>{t('GS_FOUR_HEADING')}</h2>
                    <span>{t('GS_FOUR_DESC')}</span>
                  </div>
                  <div style={Object.assign({ backgroundImage: 'url(' + Images.onboarding.charging + ')' }, styles.panelImage)}></div>
                  <div style={styles.panelFooter}>
                    <div style={styles.buttonGroup}>
                      <a href="JavaScript:void(0);" style={styles.buttonGroupBtn} onClick={this.back}><span style={{ color: '#ffffff' }}>{t('BACK')}</span></a>
                      <a id="nextFourth" href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.finish}><span style={{ color: '#ffffff' }}>{t('GOT_IT')}</span></a>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      }</Translation>
    );
  }

}


const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  wrapper: {
    backgroundColor: '#fff',
    height: '100%',
    width: '80%',
    maxWidth: 400,
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'auto'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 20
  },
  skip: {
    color: '#848484',
    marginBottom: 10
  },
  buttonGroup: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonGroupBtn: {
    width: 125,
    height: 50,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    backgroundColor: '#555555',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
    backgroundColor: '#6CB33F'
  },
  panels: {
    position: 'relative',
    width: '100%',
    flex: 1,
    maxWidth: 300,
    overflow: 'hidden',
    overflowY: 'auto'
  },
  panel: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 95,
    justifyContent: 'center',
    display: 'flex'
  },
  panelIn: {
    transform: 'translateX(0)',
  },
  panelOut: {
    transform: 'translateX(100%)',
    visibility: 'hidden'
  },
  panelDone: {
    transform: 'translateX(-100%)',
    visibility: 'hidden'
  },
  panelBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  panelHeader: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    paddingBottom: 20,
    paddingTop: 20,
  },
  panelFooter: {
    width: '100%',
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  panelHeading: {
    margin: 0,
    color: '#484848',
    marginBottom: 10,
    marginTop: 15,
  },
  panelDescription: {
    fontSize: 14,
    color: '#484848',
    marginBottom: 10
  },
  panelImage: {
    flex: 1,
    display: 'flex',
    backgroundSize: 'contain',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat'
  },
  guideImage: {
    height: '100%',
    maxHeight: 582,
    alignSelf: 'flex-end'
  },
};


export default GetStartedScreen;