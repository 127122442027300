import React from 'react';
import Images from '../../assets/images/index';
import HomeHeaderToggle from './HomeHeaderToggle';
import GlobalContext from '../../context/global-context';
import { Link } from 'react-router-dom';

import { Translation } from 'react-i18next';
import ActivityIndicator from '../global/ActivityIndicator';

const homeHeader = props => {
    const d = new Date();

    return (
        <GlobalContext.Consumer>
            {context => (
                <Translation>
                    {
                        t => <header style={{ minHeight: props.height }} className="animate">
                            <div style={styles.headerContainer}>
                                <div style={styles.topBar}>
                                    {context.getVehicleReg() && <Link to='/vehicles' style={styles.numberPlate}>{context.getVehicleReg()}</Link>}
                                    <div style={styles.topCenter}>
                                        <HomeHeaderToggle onToggleMapView={props.onToggleMapView} mapView={props.mapView} />
                                    </div>
                                    <a id="hamburger" href="javascript:void(0)" style={styles.menuButton} onClick={context.toggleMenu}><img src={Images.menubtn} style={{ width: 55, height: 55 }} alt="Menu" /></a>
                                </div>
                                {!props.fullScreenMap && <div style={styles.centerBar}>
                                    <span id="welcome" style={styles.greeting}>{d.getHours() < 12 ? t('GREETING_MOR') : d.getHours() < 17 ? t('GREETING_AFT') : t('GREETING_EVE')}</span>
                                    {context.isLoggedIn && <p style={styles.name}>{context.user.profile.user.FirstName}</p>}
                                    {(context.user.profile.user === null && context.user.verifying === true) && <p style={styles.name}><div style={{ width: 40, height: 40 }}><ActivityIndicator /></div></p>}
                                </div>}
                            </div>
                        </header>
                    }
                </Translation>
            )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    headerContainer: {
        minHeight: '100%',
        width: '100%',
        overflow: 'hidden',
        background: 'url(' + Images.homeheaderbg + ')',
        display: 'flex',
        flexDirection: 'column'
    },
    topBar: {
        width: '100%',
        minHeight: 55,
        alignItems: 'center',
        marginTop: 0,
        display: 'flex',
        position: 'relative'
    },
    topCenter: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 55,
        width: 55
    },
    numberPlate: {
        position: 'absolute',
        top: 0,
        left: 0,
        color: '#ffffff',
        fontSize: 18,
        fontWeight: 'bold',
        paddingLeft: 20,
        height: 55,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerBar: {
        height: 95,
        width: '100%',
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    greeting: {
        color: '#ffffff',
        fontSize: 28,
        fontWeight: '400',
    },
    name: {
        color: '#ffffff',
        fontSize: 28,
    }
};

export default homeHeader;