import React, { useContext, useEffect, useState } from 'react';
import Images from '../../assets/images/index';
import ActivityIndicator from '../global/ActivityIndicator';
import { useTranslation } from 'react-i18next';
import globalContext from '../../context/global-context';


const ReturnBalance = props => {

    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    

    const close = e => {
        e.preventDefault();
        if(typeof props.close === 'function')
            props.close();
    }

    const handleSubmit = () => {

        if(parseFloat(context.wallet.balance) > 0) {
            setLoading(true);
            setError(false);
            setSuccess(false);
    
            context.userWalletRefund().then(
                res => {
                    setSuccess(true);
                    setLoading(false);
                },
                err => {
                    setLoading(false);
                    setError(true);
                }
            )
        }

    }



    return (
        <React.Fragment>
            {loading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
            <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
                

                <div style={styles.wrapper}>
                    <button style={styles.closeButton} onClick={close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>

                    <div style={styles.header}>
                        <img src={Images.withdraw_icon} alt={t('RETURN_WALLET_BALANCE')} style={styles.titleImage} />
                        <h2 style={styles.title}>{t('RETURN_WALLET_BALANCE')}</h2>
                    </div>
                    
                    <div style={styles.inner}>

                        {success && 
                            <div style={styles.success}>
                                <img src={Images.check_circle} alt="Wallet balance returned" />
                                <span>{t('RETURN_WALLET_BALANCE_SUCCESS')}</span>
                            </div>
                        }

                        <div style={styles.wallet}>
                            {!context.loading.wallet && 
                                <div style={styles.balance}>
                                    <span style={styles.amount}>{parseFloat(context.wallet.balance).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                                    <span style={styles.amount_desc}>{t('CURRENT_BALANCE')}</span>
                                </div>
                            }
                            {context.loading.wallet && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
                        </div>

                        {error && <div style={styles.error}>{t('RETURN_WALLET_BALANCE_ERROR')}</div>}
                        

                        <p style={{textAlign: 'center'}}>{t('RETURN_WALLET_BALANCE_DESC')}</p>

                        <div style={styles.buttonGroup}>
                            <button style={styles.buttonGroupBtn} onClick={close}>{t('BACK')}</button>
                            <button style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={handleSubmit} disabled={parseFloat(context.wallet.balance) <= 0}>{t('CONFIRM')}</button>
                        </div>   

                    </div>                 

                </div>


            </div>
        </React.Fragment>
    )
}

const styles = {
    container: {
        position: 'absolute',
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    wrapper: {
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column'
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    closeButton: {
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99,
        backgroundColor: 'transparent',
        border: 'none'
    },
    loadingWrapper: {
      position: 'fixed',
      backgroundColor: 'rgba(255,255,255,.4)',
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    form: {
        paddingTop: 10,
        paddingBottom: 30
    },
    caption: {
        fontSize: 11
    },
    buttonGroup: {
        width: '100%',
        maxWidth: 260,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonGroupBtn: {
        width: 120,
        height: 40,
        backgroundColor: '#555555',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        color: '#ffffff', 
        textTransform: 'uppercase'
    },
    buttonGroupBtnPrimary: {
        backgroundColor: '#6CB33F'
    },
    error: {
        backgroundColor: '#d94646',
        color: '#ffffff',
        padding: '8px 12px',
        marginBottom: 15,
        borderRadius: 4
    },
    success: {
        backgroundColor: '#6cb33f',
        color: '#ffffff',
        padding: '8px 12px',
        marginTop: 15,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        gap: '10px'
    },
    header: {
        paddingTop: 82,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        color: '#6CB33F',
        fontWeight: 900,
        marginBottom: 10,
    },
    titleImage: {
        height: 82,
        width: 'auto'
    },
    wallet: {
      width: '100%',
      display: 'flex',
      minHeight: 120,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative'
    },
    balance: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    amount:{
      fontSize: 48,
      fontWeight: '600',
      color: '#484848',
    },
    amount_desc:{
      fontSize: 14,
      color: '#888'
    },
}

export default ReturnBalance;