import React, {useState} from 'react';
import { Collapse, IconButton } from "@mui/material";
import images from '../assets/images';
import { useTranslation } from 'react-i18next';

const NotificationAccordian = props => {

    const {t} = useTranslation();

    const [expand, setExpand] = useState(false);

    const handleClick = () => {
        if(typeof props.onClick === 'function')
            props.onClick();
    }

    const hasClick = typeof props.onClick !== 'undefined';

    const handleToggle = () => {
        setExpand(!expand);
    }

    return (

        <React.Fragment>
            <div style={styles.container}>
                <div style={styles.wrapper}>

                    <div style={styles.label}>
                        <img src={props.icon} alt={props.title} style={styles.icon} />
                        <span style={styles.title}>{props.title}</span>
                        <div style={{flex:1}}></div>
                        {props.description && <IconButton size="small" onClick={handleToggle} style={styles.expandButton}><img src={expand ? images.expand_less_icon : images.expand_more_icon} alt="Expand" style={styles.expandIcon}/></IconButton>}
                    </div>

                    <Collapse in={expand}>
                        <span style={styles.description}>{props.description}</span>
                        {hasClick && <button onClick={handleClick} style={styles.descriptionButton}>{t('MORE_INFO')}</button>}
                    </Collapse>
                </div>
            </div>
        </React.Fragment>

    )
}

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center'
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        color: '#9f7f09'
    },
    icon: {
        width: 22,
        height: 'auto'
    },
    expandButton: {
    },
    expandIcon: {
        height: 24,
        width: 'auto'
    },
    description: {
        fontSize: 14,
        fontWeight: 600,
        color: '#484848'
    },
    descriptionButton: {
        fontSize: 14,
        fontWeight: 600,
        color: '#9f7f09'
    }
}

export default NotificationAccordian;