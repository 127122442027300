import React from "react";
import isAuth from "../../components/login/IsAuth";
import GlobalContext from "../../context/global-context";
import GlobalSwitch from "../global/Switch";
import Header from "../global/Header";
import { Link } from "react-router-dom";
import Images from "../../assets/images";
import ChangePassword from "./ChangePassword";

class PreferencesScreen extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      showChangePassword: false,
    };
  }

  logout = () => {
    this.context
      .userLogout()
      .then((result) => {
        this.props.history.push("/");
        // if using isAuth then the previous screen should appear
      })
      .catch((err) => {
        console.log("There was an error:" + err);
      });
  };

  unitToggle = (unit) => {
    if (this.context.account.preferences.unit !== unit)
      this.context.unitToggle();

    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  render() {
    return (
      <div style={styles.container}>
        <Header title={"Preferences"} history={this.props.history} />
        <div style={styles.wrapper}>
          <div style={styles.other}>
            <div style={styles.attributeHeader}>
              <span style={styles.attributeHeaderText}>Filters</span>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Show Public</span>
              <span style={styles.attributeValue}>
                <GlobalSwitch
                  checked={this.context.account.preferences.showPublic}
                  id={"filter-public"}
                  onChange={() => {
                    this.context.preferenceToggle("showPublic");
                  }}
                />
              </span>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Show Private</span>
              <span style={styles.attributeValue}>
                <GlobalSwitch
                  checked={this.context.account.preferences.showPrivate}
                  id={"filter-private"}
                  onChange={() => {
                    this.context.preferenceToggle("showPrivate");
                  }}
                />
              </span>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Show Available Only</span>
              <span style={styles.attributeValue}>
                <GlobalSwitch
                  checked={this.context.account.preferences.availableOnly}
                  id={"filter-availableOnly"}
                  onChange={() => {
                    this.context.preferenceToggle("availableOnly");
                  }}
                />
              </span>
            </div>
            <div style={styles.attributeHeader}>
              <span style={styles.attributeHeaderText}>Other</span>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Vehicles</span>
              <Link to="/vehicles" style={styles.attributeValueLink}>
                {this.context.getVehicleReg()}
              </Link>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Unit</span>
              <span style={styles.attributeValueLink} onClick={this.openDialog}>
                {this.context.account.preferences.unit === "m" ? "Miles" : "Km"}
              </span>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Connector</span>
              <span style={styles.attributeValue}>Type 2</span>
            </div>
            <div style={styles.attribute}>
              <span style={styles.attributeLabel}>Payment Method</span>
              <span style={styles.attributeValue}>PAYG</span>
            </div>
            <div
              style={Object.assign({}, styles.attribute, { cursor: "pointer" })}
            >
              <span
                style={styles.attributeLabelLink}
                onClick={() => this.setState({ showChangePassword: true })}
              >
                Change password
              </span>
              <span style={styles.attributeValue}></span>
            </div>
            <div
              style={Object.assign({}, styles.attribute, { cursor: "pointer" })}
            >
              <span style={styles.attributeLabelLink} onClick={this.logout}>
                Sign out
              </span>
              <span style={styles.attributeValue}></span>
            </div>
          </div>
        </div>

        {this.state.dialogOpen && (
          <div style={styles.dialogBg} onClick={this.closeDialog}></div>
        )}
        <div
          style={Object.assign(
            {},
            styles.dialog,
            this.state.dialogOpen && styles.dialogIn,
          )}
          className="animate"
        >
          <div style={styles.dialogWrapper}>
            <span style={styles.dialogHeader}>Unit</span>
            <div style={styles.dialogBody}>
              <div
                style={styles.dialogUnit}
                onClick={() => {
                  this.unitToggle("m");
                }}
              >
                <div style={styles.dialogIcon}>
                  {this.context.account.preferences.unit === "m" ? (
                    <img
                      src={Images.bullet_full}
                      style={{ width: 20, height: 20 }}
                      alt="Bullet"
                    />
                  ) : (
                    <img
                      src={Images.bullet}
                      style={{ width: 20, height: 20 }}
                      alt="Bullet"
                    />
                  )}
                </div>
                <div style={styles.detail}>Miles</div>
              </div>
              <div
                style={styles.dialogUnit}
                onClick={() => {
                  this.unitToggle("k");
                }}
              >
                <div style={styles.dialogIcon}>
                  {this.context.account.preferences.unit === "k" ? (
                    <img
                      src={Images.bullet_full}
                      style={{ width: 20, height: 20 }}
                      alt="Bullet"
                    />
                  ) : (
                    <img
                      src={Images.bullet}
                      style={{ width: 20, height: 20 }}
                      alt="Bullet"
                    />
                  )}
                </div>
                <div style={styles.detail}>Km</div>
              </div>
            </div>
          </div>
        </div>

        <ChangePassword
          show={this.state.showChangePassword}
          close={() => this.setState({ showChangePassword: false })}
        />
      </div>
    );
  }
}

export default isAuth(PreferencesScreen);

const styles = {
  container: {
    backgroundColor: "#fff",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  wrapper: {
    backgroundColor: "#fff",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "auto",
  },
  vehicle: {
    width: "100%",
    display: "flex",
    height: 50,
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "row",
  },
  number_plate: {
    flex: 1,
    paddingLeft: 20,
    fontSize: 18,
    fontWeight: "400",
    color: "#484848",
  },
  model: {
    flex: 1,
    textAlign: "right",
    paddingRight: 20,
    fontSize: 18,
    fontWeight: "400",
    color: "#484848",
  },
  wallet: {
    width: "100%",
    display: "flex",
    height: 220,
    marginTop: 60,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  donut: {
    width: 240,
    height: 240,
    borderWidth: 10,
    borderStyle: "solid",
    display: "flex",
    borderColor: "#6CB33F",
    borderRadius: 140,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  bolt: {
    width: 70,
    height: 70,
    position: "absolute",
    top: -35,
    left: 85,
    backgroundColor: "#fff",
    borderRadius: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  amount: {
    fontSize: 38,
    fontWeight: "600",
    color: "#484848",
  },
  last_updated: {
    fontSize: 14,
    color: "#484848",
  },
  topup: {
    display: "flex",
    height: 40,
    marginTop: 40,
    marginBottom: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  topupButton: {
    backgroundColor: "#464646",
    height: 40,
    width: 100,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },
  topupButtonText: {
    color: "#fff",
    textAlign: "center",
  },
  attribute: {
    width: "100%",
    display: "flex",
    height: 50,
    flexWrap: "wrap",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#efefef",
    alignItems: "center",
    flexDirection: "row",
  },
  attributeLabel: {
    flex: 1,
    marginLeft: 20,
  },
  attributeLabelLink: {
    flex: 1,
    marginLeft: 20,
    fontWeight: 900,
    color: "#6CB33F",
  },
  attributeValue: {
    flex: 1,
    textAlign: "right",
    marginRight: 20,
  },
  attributeValueLink: {
    flex: 1,
    textAlign: "right",
    marginRight: 20,
    fontWeight: 900,
    color: "#6CB33F",
  },
  attributeHeader: {
    height: 40,
    backgroundColor: "#efefef",
    width: "100%",
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  attributeHeaderText: {
    fontSize: 18,
  },
  dialogBg: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0,0,0,.3)",
    zIndex: 989,
  },
  dialog: {
    display: "block",
    width: 280,
    position: "absolute",
    top: "50%",
    left: "50%",
    backgroundColor: "#fff",
    boxShadow: "0 0 6px rgba(0,0,0,.3)",
    transform: "translate(-50%, 100%)",
    visibility: "hidden",
    opacity: 0,
    zIndex: 990,
  },
  dialogIn: {
    transform: "translate(-50%, -50%)",
    visibility: "visible",
    opacity: 100,
  },
  dialogWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  dialogHeader: {
    fontSize: 18,
    fontWeight: 900,
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  dialogBody: {
    flex: 1,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  dialogUnit: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  dialogIcon: {
    marginRight: 20,
  },
};
