import React from 'react'
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation } from 'react-i18next';

class forgotPasswordForm extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                email: '',
            },
            errors: {
                email: this.errorField('panel1'),
            },
            isValid: false,
            isLoading: false,
            isSuccess: false
        };
    }


    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }


    bindField = (e) => {
        let fields = {...this.state.fields};
        if(e.target.type === 'checkbox'){
            fields[e.target.name] = e.target.checked;
        } else {
            fields[e.target.name] = e.target.value;
        }
        this.setState({fields: fields}, () => {this.validate()});
    }

    dirtyGroup = (group = null, callback = () => {}) => {
        let errors = {...this.state.errors};
        Object.values(errors).map(e => {
            if(e.group === group)
                e.dirty = true;
        })
        this.setState({errors: errors}, () => {callback()});
    }

    validate = (callback = () => {}) => {
        let errors = {...this.state.errors};

        // Validate email
        
        if(this.state.fields.email.length > 0)
            errors.email.dirty = true;

        errors.email.invalid = false;
        if(errors.email.dirty && this.state.fields.email === ''){
            errors.email.invalid = true;
        }

        if(errors.email.dirty && !this.state.fields.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)){
            errors.email.invalid = true;
        }

        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({errors: errors, isValid: isValid}, () => {callback()});
    }

    resetPassword = () => {

        this.dirtyGroup('panel1', () => {
            this.validate(() => {
                if(this.state.isValid){
                    this.setState({isLoading: true, postErrors: []});

                    this.context.userPasswordForgot(this.state.fields)
                    .then((res) => {
                        this.setState({isLoading: false, isSuccess: true});
                    })
                    .catch((err)=>{
                        this.setState({isLoading: false});
                        const errs = [];
                        if(typeof err.ModelState !== 'undefined'){
                            Object.keys(err.ModelState).map(s => {
                                err.ModelState[s].map(e => errs.push(e));
                            })
                        } else {
                            errs.push('Something went wrong please try again.')
                        }
                        this.setState({postErrors: errs});
                    });

                } else {

                    console.log('not valid')
                }
            });
        })
    }

    render() {
        const errors = this.state.errors;
        return (
        <Translation>{t => 
            <div style={Object.assign({}, styles.container, this.props.show ? styles.containerIn:styles.containerOut)} className="animate">
                <div style={styles.bgImg}>
                    <a href="javascript:void(0)" style={styles.closeButton} onClick={this.props.close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>
                    
                    <div style={styles.wrapper}>

                        <div style={styles.panel} className="animate">
                            
                            {!this.state.isSuccess && <div style={styles.panelBody}>

                                <span style={styles.header}>{t('FORGOT_PASS_HEADER')}</span>
                                <span style={styles.description}>{t('FORGOT_PASS_DESC')}</span>
                                <div style={Object.assign({}, styles.inputWrapper, errors.email.invalid && styles.inputError)}>
                                    <input name={'email'} onChange={this.bindField} placeholder={t('EMAIL')} style={styles.input} />
                                </div>
                                {errors.email.invalid && <span style={styles.inputErrorMsg}>{t('FORGOT_PASS_EMAIL_ERROR')}</span>}
                                <div style={Object.assign({}, styles.buttonGroup, { justifyContent: 'flex-end' })}>
                                    <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.resetPassword}>{this.state.isLoading ? <span style={styles.loading}><ActivityIndicator /></span>:<span style={{ color: '#ffffff' }}>{t('NEXT')}</span>}</a>
                                </div>
                            </div>}
                            {this.state.isSuccess && <div style={styles.panelBody}>
                                <span style={Object.assign({}, styles.header, styles.success)}>{t('FORGOT_PASS_SUCCESS')}</span>
                                <div style={Object.assign({}, styles.description, styles.success)}>{t('FORGOT_PASS_SUCCESS_MSG')}</div>
                            </div>}
                        </div>

                    </div>

                    
                    <div style={styles.rolecLogoWrapper}>
                        <img src={Images.rolec_logo} style={styles.rolecLogo} alt="Powered by Rolec" />
                    </div>

                </div>
            </div>
            }</Translation>
        )
    }
}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '90'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    bgImg: {
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundImage: 'url('+Images.bg+')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        display: 'flex',
        height: '100%',
        overflowY: 'auto'
    },
    vendLogo: {
        width: '80%',
        maxWidth: 250,
        maxHeight: 58,
        marginBottom: 40,
        alignSelf: 'center'
    },
    rolecLogoWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 20,
      paddingBottom: 20,
    },
    rolecLogo: {
        width: 110,
        height: 25,
        paddingRight: 20,
    },
    closeButton: {
        marginTop: 50,
        marginRight: 20,
        height: 50,
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        cursor: 'pointer',
        zIndex: 90,
        color: '#6CB33F'
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        maxWidth: 300,
        overflow: 'hidden',
        minHeight: 300
    },
    panel: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: 95,
        justifyContent: 'center',
        display: 'flex',
        transform: 'translateX(0)',
    },
    panelBody: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10
    },
    description: {
        fontSize: 14,
        marginBottom: 20,
        textAlign: 'center'
    },
    buttonGroup: {
        width: '100%',
        marginTop: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonGroupBtn: {
        width: 140,
        height: 50,
        backgroundColor: '#555555',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    buttonGroupBtnPrimary: {
        backgroundColor: '#6CB33F'
    },
    inputWrapper: {
        height: 54,
        width: '100%',
        backgroundColor: '#ebebeb',
        borderRadius: 12,
        marginBottom: 10,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    input: {
        fontSize: 16,
        flex: 1,
        paddingLeft: 20
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      width: '100%',
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      marginTop: -8
    },
    success: {
      color: '#5ab552',
    },
    loadingWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
}

export default forgotPasswordForm;