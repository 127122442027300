import React from 'react';
import GlobalContext from '../../context/global-context';


class LogoutScreen extends React.Component {

  static contextType = GlobalContext;

  componentDidMount(){
    this.context.userLogout().then(
      result=>{
        this.props.history.push('/')
      },
      err=>{

      }
    )
  }

  render() {
    return (
      <span>logout</span>
    );
  }

}


export default LogoutScreen;