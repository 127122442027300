import React from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation } from 'react-i18next';

class changePassword extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                current_password: '',
                password: '',
                confirm_password: ''
            },
            errors: {
                current_password: this.errorField('cp'),
                password: this.errorField('cp'),
                confirm_password: this.errorField('cp')
            },
            isValid: false,
            isLoading: false,
            changeSuccess: false,
            postErrors: [],
            panels: {}
        };
    }


    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }


    bindField = (e) => {
        let fields = {...this.state.fields};
        if(e.target.type === 'checkbox'){
            fields[e.target.name] = e.target.checked;
        } else {
            fields[e.target.name] = e.target.value;
        }
        this.setState({fields: fields}, () => {this.validate()});
    }

    dirtyGroup = (group = null, callback = () => {}) => {
        let errors = {...this.state.errors};
        Object.values(errors).map(e => {
            if(e.group === group)
                e.dirty = true;
        })
        console.log(errors);
        this.setState({errors: errors}, () => {callback()});
    }

    validate = (callback = () => {}) => {
        let errors = {...this.state.errors};
        const required = [
            'current_password',
            'password',
            'confirm_password'
        ]

        required.map(f => {
            if(this.state.fields[f].length > 0)
                errors[f].dirty = true;

            errors[f].invalid = false;
            if(errors[f].dirty && this.state.fields[f] === ''){
                errors[f].invalid = true;
            }
        })

        // Validate password minimum criteria
        if(errors.password.dirty && !this.state.fields.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.{8,})/)){
            errors.password.invalid = true;
        }

        // Validate passwords match
        if(errors.password.dirty && errors.confirm_password.dirty && this.state.fields.password !== this.state.fields.confirm_password){
            errors.confirm_password.invalid = true;
        }

        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({errors: errors, isValid: isValid}, () => {callback()});
    }


    onChangePassword = () => {

        this.dirtyGroup('cp', () => {
            this.validate(() => {
                if(this.state.isValid){
                    this.setState({isLoading: true, postErrors: [], changeSuccess: false});

                    this.context.userChangePassword(this.state.fields)
                    .then((response) => {
                        this.setState({isLoading: false, changeSuccess: true});
                        setTimeout(() => {
                            this.props.close();
                        }, 2000);
                    })
                    .catch((err)=>{
                        this.setState({isLoading: false});
                        const errs = [];
                        errs.push('Something went wrong please try again.')
                        this.setState({postErrors: errs});
                    });

                } else {

                    console.log('not valid')
                }
            });
        })

    }

    handleClose = e => {
        e.preventDefault();
        if(typeof this.props.close === 'function')
            this.props.close();
    }

    render() {
        const errors = this.state.errors;
        return (
            <Translation>
                {t => 
                <div style={Object.assign({}, styles.container, this.props.show ? styles.containerIn:styles.containerOut)} className="animate">
                    <button style={styles.close} onClick={this.handleClose}><img src={Images.close_btn} alt="close" style={{ width: 30, height: 30 }} /></button>
            
                    <div style={styles.wrapper}>
                        <div style={styles.header}>
                            <img src={Images.password_icon} alt={t('CHANGE_PASSWORD')} style={styles.headingImage} />
                            <span style={styles.heading}>{t('CHANGE_PASSWORD')}</span>
                        </div>

                        {this.state.changeSuccess && <span style={styles.successMsg}>{t('PASSWORD_CHANGE_SUCCESS')}</span>}

                        {this.state.postErrors.map((err, key) => (
                            <span key={key} style={styles.inputErrorMsg}>{err}</span>
                        ))}

                        <div style={Object.assign({}, styles.inputWrapper, errors.current_password.invalid && styles.inputError)}>
                            <input type={'password'} name={'current_password'} onChange={this.bindField} placeholder={t('CURRENT_PASSWORD')} style={styles.input} />
                        </div>
                        {errors.current_password.invalid && <span style={styles.inputErrorMsg}>Please enter your current password</span>}

                        <div style={Object.assign({}, styles.inputWrapper, errors.password.invalid && styles.inputError)}>
                            <input type={'password'} name={'password'} onChange={this.bindField} placeholder={t('PASSWORD')} style={styles.input} />
                        </div>
                        {errors.password.invalid && <span style={styles.inputErrorMsg}>{t('PASSWORD_REQUIREMENT')}</span>}
                        
                        <div style={Object.assign({}, styles.inputWrapper, errors.confirm_password.invalid && styles.inputError)}>
                            <input type={'password'} name={'confirm_password'} onChange={this.bindField} placeholder={t('CONFIRM_PASSWORD')} style={styles.input} />
                        </div>
                        {errors.confirm_password.invalid && <span style={styles.inputErrorMsg}>{t('PASSWORD_NOMATCH')}</span>}

                        <div style={styles.buttonGroup}>
                            <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.onChangePassword}>{this.state.isLoading ? <span style={styles.loading}><ActivityIndicator /></span>:<span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('CHANGE')}</span>}</a>
                        </div>
                    </div>
                </div>
                }
            </Translation>
            )
    }
}

const styles = {
  container: {
    position: 'absolute',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerIn: {
      transform: 'translateY(0)'
  },
  containerOut: {
    transform: 'translateY(100%)',
    visibility: 'hidden'
  }, 
  wrapper: {
    width: '80%',
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  close: {
    position: 'absolute',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 50,
    right: 20,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none'
  },
  heading: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    color: '#484848'
  },
  headingImage: {
    height: 82,
    width: 'auto'
  },
  buttonGroup: {
      width: '100%',
      marginTop: 40,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
  },
  buttonGroupBtn: {
      width: 140,
      height: 50,
      backgroundColor: '#555555',
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
      backgroundColor: '#6CB33F'
  },
  inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column'
  },
  input: {
      fontSize: 16,
      flex: 1,
      paddingLeft: 20
  },
  inputError: {
    boxShadow: 'inset 0 0 0 1px #F26A6A'
  },
  inputErrorMsg: {
    width: '100%',
    color: '#F26A6A',
    fontSize: 11,
    paddingLeft: 20,
    marginBottom: 8,
    marginTop: -8
  },
  loadingWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  successMsg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: '#00cc00',
    fontSize: 14,
    marginBottom: 15
  },
};

export default changePassword;