import React, { useContext, useEffect, useState } from 'react';
import Images from '../../assets/images/index';
import ActivityIndicator from '../global/ActivityIndicator';
import { useTranslation } from 'react-i18next';
import globalContext from '../../context/global-context';
import TextField from '../global/textField';


const UpdateDetails = props => {

    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fields, setFields] = useState([
        {
            name: 'FirstName',
            label: t('FIRST_NAME'),
            required: true,
            value: context.user.profile.user.FirstName,
            invalid: false,
            error: ''
        },
        {
            name: 'LastName',
            label: t('LAST_NAME'),
            required: true,
            value: context.user.profile.user.LastName,
            invalid: false,
            error: ''
        },
        {
            name: 'Mobile',
            label: t('MOBILE'),
            required: true,
            value: context.user.profile.user.Mobile,
            invalid: false,
            error: ''
        },
        {
            name: 'Address1',
            label: t('ADD_1'),
            required: true,
            value: context.user.profile.user.Address1,
            invalid: false,
            error: ''
        },
        {
            name: 'Address2',
            label: t('ADD_2'),
            required: false,
            value: context.user.profile.user.Address2,
            invalid: false,
            error: ''
        },
        {
            name: 'City',
            label: t('TOWN'),
            required: true,
            value: context.user.profile.user.City,
            invalid: false,
            error: ''
        },
        {
            name: 'County',
            label: t('COUNTY'),
            required: false,
            value: context.user.profile.user.County,
            invalid: false,
            error: ''
        },
        {
            name: 'PostCode',
            label: t('POSTCODE'),
            required: true,
            value: context.user.profile.user.PostCode,
            invalid: false,
            error: ''
        },
    ])
    const [isValid, setIsValid] = useState(false);

    const handleInputChange = e => {
        const fIdx = fields.findIndex(f => f.name === e.target.name);
        let newFields = JSON.parse(JSON.stringify(fields));
        newFields[fIdx].value = e.target.value;
        newFields[fIdx] = validateField(newFields[fIdx]);
        setFields(newFields);
    }

    const validateField = field => {
        field.invalid = false;
        field.error = '';

        if(field.required && (field.value === null || field.value.length === 0)){
            field.invalid = true;
            field.error = `${field.label} ${t('IS_REQUIRED')}`
        }

        return field;
    }


    const close = () => {
        if(typeof props.close === 'function')
            props.close();
    }

    const handleSubmit = () => {

        if(isValid){
            setLoading(true);
            setError(false);

            let params = {};
            fields.map(field => params[field.name] = field.value);

            context.userUpdateDetails(params).then(
                res => {
                    close();
                    setLoading(false);
                },
                err => {
                    setLoading(false);
                    setError(true);
                }
            )

        }
    }

    useEffect(() => {

        let valid = true;
        const idx = fields.findIndex(f => f.invalid === true);
        if(idx > -1)
            valid = false;

        setIsValid(valid);

    }, [fields])



    return (
        <React.Fragment>
            {loading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
            <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
                
                <div style={styles.wrapper}>
                    <a href="javascript:void(0)" style={styles.closeButton} onClick={close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>

                    <div style={styles.header}>
                        <img src={Images.edit_icon} alt={t('UPDATE_DETAILS')} style={styles.headingImage} />
                        <h2 style={styles.title}>{t('UPDATE_DETAILS')}</h2>
                    </div>

                    {error && <div style={styles.error}>{t('USER_UPDATE_ERROR')}</div>}

                    <span style={styles.caption}>* {t('REQUIRED_FIELD')}</span>
                    <div style={styles.form}>
                        {fields.map((field, idx) => (<TextField key={idx} field={field} onChange={handleInputChange} />))}

                        <div style={styles.buttonGroup}>
                            <button style={styles.buttonGroupBtn} onClick={close}>{t('BACK')}</button>
                            <button style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={handleSubmit} disabled={!isValid}>{t('SAVE')}</button>
                        </div>
                    </div>
                    
                    

                </div>
            </div>
        </React.Fragment>
    )
}

const styles = {
    container: {
        position: 'absolute',
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    wrapper: {
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column'
    },
    closeButton: {
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99
    },
    loadingWrapper: {
      position: 'fixed',
      backgroundColor: 'rgba(255,255,255,.4)',
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    form: {
        paddingTop: 10,
        paddingBottom: 30
    },
    caption: {
        fontSize: 11
    },
    buttonGroup: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonGroupBtn: {
        width: 120,
        height: 40,
        backgroundColor: '#555555',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        color: '#ffffff', 
        textTransform: 'uppercase'
    },
    buttonGroupBtnPrimary: {
        backgroundColor: '#6CB33F'
    },
    error: {
        backgroundColor: '#d94646',
        color: '#ffffff',
        padding: '8px 12px',
        marginBottom: 15,
        borderRadius: 4
    },
    title: {
        color: '#6CB33F',
        fontWeight: 900,
        margin: 0
    },
    header: {
        paddingTop: 62,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    headingImage: {
      height: 48,
      width: 'auto'
    },
}

export default UpdateDetails;