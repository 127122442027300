import React, { useEffect } from 'react';
import Header from '../global/Header';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import isAuth from '../../components/login/IsAuth';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Helpers from '../../context/helpers';
import { withTranslation } from 'react-i18next';
import ChargeSessionsDownloadDialog from './ChargeSessionsDownloadDialog';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


class StatisticsScreen extends React.Component {

    static contextType = GlobalContext;

    constructor(props){
      super(props);

      this.state = {
        location: null,
        days: 30,
        showDownload: false,
        currencies: ['GBP']
      }
    }

    getFromDate = (days = this.state.days) => {
      let dt = new Date();
      dt.setDate(dt.getDate() - days);
      dt = new Date(dt.toISOString().split('T')[0]);
      return dt;
    }

    getEndDate = (s) => {
      const eDate = Helpers.SQLtoUTCDate(s.StartTime);
      const bits = s.Time.split(':');
      eDate.setHours(eDate.getHours()+parseInt(bits[0]));
      eDate.setMinutes(eDate.getMinutes()+parseInt(bits[1]));
      eDate.setSeconds(eDate.getSeconds()+parseInt(bits[2]));
      return eDate;
    }

    isActive = (s) => {
      const now = new Date();
      return Helpers.SQLtoUTCDate(s.EndTime)>now?true:false;
    }

    durationMinutes = (s) => {
      const eDate = Helpers.SQLtoUTCDate(s.EndTime);
      const sDate = Helpers.SQLtoUTCDate(s.StartTime);

      var diff = (eDate - sDate);

      // First get the total seconds
      const secs = Math.abs(diff)/1000;

      return (secs/60);
    }

    dailyCharges = () => {

      const { t } = this.props;

      let data = {};

      for(let i = this.state.days; i >= 0; i--){
        let dt = new Date();
        dt.setDate(dt.getDate() - i);
        const m = t(Helpers.monthsShort()[dt.getMonth()]);
        const d = dt.getDate();
        const lab = d + ' ' + m ;
        data[lab] = 0;
      }

      this.context.account.charges.map(c => {
        
        const st = Helpers.SQLtoUTCDate(c.StartTime);
        const m = t(Helpers.monthsShort()[st.getMonth()]);
        const d = st.getDate();
        const lab = d + ' ' + m ;
          
        if(typeof data[lab] !== 'undefined')
          data[lab] += this.durationMinutes(c);

      })

      return {
        labels: Object.keys(data),
        datasets: [{
          label: "Charge History",
          backgroundColor: 'rgb(175 232 140)',
          borderColor: 'rgb(108, 179, 63)',
          data: Object.values(data),
        }]
      }
    }

    dailyAverageSpend = (currency) => {
      let days = [];
      let total = 0;

      const fromDate = this.getFromDate();

      if(this.context.account.charges.length > 0){
        this.context.account.charges.filter(c => c.Currency === currency).map(c => {
          const st = Helpers.SQLtoUTCDate(c.StartTime.replace(/T[\d]{2}:[\d]{2}:[\d]{2}\.[\d]*/, 'T00:00:00.000'));
          if(st >= fromDate && days.indexOf(st) === -1){
            days.push(st);
            total += c.Cost;
          }
        });
      }

      return parseFloat((total/days.length));
    }

    totalSpend = (currency) => {
      let total = 0;
      const fromDate = this.getFromDate();
      this.context.account.charges.filter(c => c.Currency === currency).map(c => {
        const st = Helpers.SQLtoUTCDate(c.StartTime.replace(/T[\d]{2}:[\d]{2}:[\d]{2}\.[\d]*/, 'T00:00:00.000'));
        if(st>=fromDate)
          total += c.Cost;
      })
      
      return parseFloat(total);
    }

    totalSessions = (days) => {
      const fromDate = this.getFromDate(days);
      let sessions = [];
      if(this.context.account.charges.length > 0)
        sessions = this.context.account.charges.filter(c => Helpers.SQLtoUTCDate(c.StartTime.replace(/T[\d]{2}:[\d]{2}:[\d]{2}\.[\d]*/, 'T00:00:00.000')) >= fromDate);
    
      return sessions.length;
    }

    handleDownload = (e) => {
      e.preventDefault();
      this.setState({showDownload: true})
    }

    handleDownloadClose = () => {
      this.setState({showDownload: false})
    }
    
    handleSwitchDays = (e) => {
      e.preventDefault();
    
      let newDays = 30;
      switch(this.state.days){
        case 30:
          newDays = 60;
          break;
        case 60:
          newDays = 90;
          break;
        case 90:
          newDays = 365;
          break;
      }
      this.setState({days: newDays});

    }

    getCurrencies = () => {
      const fromDate = this.getFromDate();
      let sessions = [];
      if(this.context.account.charges.length > 0)
        sessions = this.context.account.charges.filter(c => Helpers.SQLtoUTCDate(c.StartTime.replace(/T[\d]{2}:[\d]{2}:[\d]{2}\.[\d]*/, 'T00:00:00.000')) >= fromDate);

      let currencies = [];
      sessions.map(s => {
        if(currencies.indexOf(s.Currency) === -1)
          currencies.push(s.Currency);
      })

      return currencies;
    }

    componentDidMount(){
    }

    render() {
        const { t } = this.props;

        const lineOptions = {
          responsive: true,
          stacked: false,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              display: true,
              position: 'left',
              title: {
                display: true,
                text: t('MINS')
              },
              grid: {
                color: 'rgb(255,255,255)'
              }
            }
          }
        };

        const stats = {
          totalSessions30: this.totalSessions(30),
          totalSessions60: this.totalSessions(60),
          totalSessions90: this.totalSessions(90),
          totalSessions365: this.totalSessions(365),
          dailyCharges: this.dailyCharges()
        }

        return (
          <div style={styles.container}>
             <Header title={t('TITLE_STATS')} history={this.props.history} />

             <div style={styles.container}>
              <div style={styles.filter}>
                <div></div>
                <div style={styles.filterLabel}>
                  <button style={styles.downloadBtn} onClick={this.handleDownload}><img src={Images.download} width='28' alt='Download charge session history' /></button>
                  {this.state.days < 365 && <span>{t('LAST')} {this.state.days} {t('DAYS')}</span>}
                  {this.state.days === 365 && <span>{t('LAST')} 12 {t('MONTHS')}</span>}
                  <button style={styles.arrowBtn} onClick={this.handleSwitchDays}><img src={Images.rightArrow} width='20' alt='Switch time period' /></button>
                </div>
              </div>

              <div style={styles.total}>
                <div style={styles.donut}>
                  <div style={styles.bolt}><img src={Images.bolt} style={{width: 40, height: 62}} alt="Charges" /></div>
                  {this.state.days === 30 && <span style={styles.amount} className="pulse">{stats.totalSessions30}</span>}
                  {this.state.days === 60 && <span style={styles.amount} className="pulse">{stats.totalSessions60}</span>}
                  {this.state.days === 90 && <span style={styles.amount} className="pulse">{stats.totalSessions90}</span>}
                  {this.state.days === 365 && <span style={styles.amount} className="pulse">{stats.totalSessions365}</span>}
                  <span style={styles.caption}>{t('CHARGES')}</span>
                </div>
              </div>

              {this.context.account &&
              <div style={styles.daily}>
                <div style={Object.assign({marginBottom: 20}, styles.attributeHeader)}><span style={styles.attributeHeaderText}>{t('YOUR_CHARGE_HISTORY')}</span></div>
                {this.context.account.charges.length > 0 && <Line data={stats.dailyCharges} height={200} options={lineOptions} />}
                {this.context.loading.charges && <div style={styles.dailyLoading}>{t('LOADING')}</div>}
              </div>
              }


              <div style={styles.other}>
                {this.getCurrencies().map(c => (
                  <React.Fragment key={c} >
                    <div style={styles.attributeHeader}><span style={styles.attributeHeaderText}>{t('YOUR_SPEND')} ({c})</span></div>
                    <div style={styles.attribute}>
                      <span style={styles.attributeLabel}>{t('TOTAL')}</span>
                      <span style={styles.attributeValue}>{parseFloat(this.totalSpend(c)).toLocaleString(navigator.language, {style: 'currency', currency: c})}</span>
                    </div>
                    <div style={styles.attribute}>
                      <span style={styles.attributeLabel}>{t('DAILY_AVG')}</span>
                      <span style={styles.attributeValue}>{parseFloat(this.dailyAverageSpend(c)).toLocaleString(navigator.language, {style: 'currency', currency: c})}</span>
                    </div>
                  </React.Fragment>
                ))}
                
              </div>
          </div>

            <ChargeSessionsDownloadDialog open={this.state.showDownload} close={this.handleDownloadClose} days={this.state.days} />
          </div> 
        );
    }
}

export default isAuth(withTranslation()(StatisticsScreen));

const styles = {
  container: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  emptyContainer: {
    flex:1, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    color: '#a2a2a2'
  },
  total: {
    width: '100%',
    display: 'flex',
    minHeight: 220,
    marginTop: 40,
    marginBottom: 40,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  bolt: {
    width: 70,
    height: 70,
    position: 'absolute',
    top: -35,
    left: 55,
    backgroundColor: '#fff',
    borderRadius: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  donut: {
    width: 180,
    height: 180,
    borderWidth: 10,
    borderStyle: 'solid',
    display: 'flex',
    borderColor: '#6CB33F',
    borderRadius: 140,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  amount:{
    fontSize: 38,
    fontWeight: '600',
    color: '#484848',
  },
  caption:{
    fontSize: 14,
    color: '#484848'
  },
  attribute: {
    width: '100%',
    display: 'flex',
    height: 50,
    flexWrap:'wrap',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#efefef',
    alignItems: 'center',
    flexDirection: 'row',
  },
  attributeLabel: {
      flex: 1
  },
  attributeValue: {
      flex: 1,
      textAlign: 'right',
      fontWeight: 900
  },
  other: {
    paddingRight: 20,
    paddingLeft: 20
  },
  attributeHeader: {
      height: 40,
      backgroundColor: '#efefef',
      paddingLeft: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
  },
  filter: {
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  filterLabel: {
    display: 'flex',
    flexDirection: 'row',
    height: 28,
    fontSize: 14,
    lineHeight: 28,
    textAlign: 'right',
    fontWeight: 600,
    alignItems: 'center'
  },
  daily: {
    padding: 20
  },
  dailyLoading: {
    width: '100%',
    height: 200,
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: '#888'
  },
  downloadBtn: {
    height: 28,
    width: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
    outline: 'none',
    marginRight: 8
  },
  arrowBtn: {
    height: 28,
    width: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
    outline: 'none'
  },
};
