import React from 'react';
import Header from '../global/Header';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import { Translation } from 'react-i18next';


class NotFoundScreen extends React.Component {

    static contextType = GlobalContext;

    constructor(props){
      super(props);
    }

    componentDidMount(){
    }

    render() {
        return (
          <Translation>{t =>
          <div style={styles.container}>
            <Header title={t('TITLE_404')} history={this.props.history} />
            <div style={styles.emptyContainer}><span>{t('404')}<br />{window.location.href}</span></div>
          </div> 
          }</Translation>
        );
    }
}

export default NotFoundScreen;

const styles = {
  container: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  emptyContainer: {
    flex:1, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    color: '#a2a2a2'
  },
  favorite: {
    width: '100%',
    height: 100,
    flex: 1,
    flexWrap:'wrap',
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: '#e8e8e8',
    cursor: 'pointer'
  },
  icon: {
      height: '100%',
      width: '20%',
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
  },
  detail: {
      height: '100%',
      width: '70%',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center'
  },
  arrow: {
      height: '100%',
      width: '10%',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center'
  },
  title: {
      color: '#484848',
      fontSize: 20
  },
  address: {
      color: '#484848',
      fontSize: 14
  }
};
