const sitesReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: []
}, action) => {
    let current_data = JSON.parse(JSON.stringify(state.data));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;

    switch(action.type){
        case "SITES_UPDATE": 
            let new_data = typeof action.payload.sites === 'undefined' ? JSON.parse(JSON.stringify(current_data)) : action.payload.sites;
            new_data.map(p => {
                p.distance = _distanceInKm(action.payload.location.lat, action.payload.location.lng, p.location.latitude, p.location.longitude);
                p.distanceLabel = 'Km';
        
                if(action.payload.location.unit === 'm'){
                    p.distance =  _kmToMiles(p.distance);
                    p.distanceLabel = 'Miles';
                }
            });
            state = {
                ...state,
                status: status,
                msg: '',
                data: new_data.sort((a, b) => a.distance - b.distance)
            }
        break
        default:
    }
    return state;
}



const _degreesToRadians = (degrees) => {
    return degrees * Math.PI / 180;
}

const _distanceInKm = (lat1, lon1, lat2, lon2) => {
    const earthRadiusKm = 6371;

    const dLat = _degreesToRadians(lat2 - lat1);
    const dLon = _degreesToRadians(lon2 - lon1);

    lat1 = _degreesToRadians(lat1);
    lat2 = _degreesToRadians(lat2);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round((earthRadiusKm * c) * 10) / 10;
}

const  _kmToMiles = (distance) => {
    return Math.round((distance * 0.621371) * 10) / 10
}

export default sitesReducer;