import React, { useEffect, useState, useContext } from 'react';
import Images from '../../assets/images/index';
import GlobalState from '../../context/global-context';
import LogoBar from './LogoBar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StatusBarPadding from '../../common/StatusBarPadding';

const Header = props => {

    const context = useContext(GlobalState);
    const { t } = useTranslation();
    const history = useHistory();

    const goBack = () => {
        if (history.action === 'POP') {
            history.push('/')
        } else {
            if (typeof props.overrideHistory !== 'undefined' && props.overrideHistory === true)
                context.localHistory.map(h => history.push(h));

            history.goBack()
        }
    }

    return (
        <div className={styles.stickyTopBar}>
            {!context.isNative && <LogoBar />}
            {context.isNative && <StatusBarPadding platform={context.platform} />}

            <div style={{ height: 60 }}>
                <div style={styles.headerContainer}>
                    <div style={styles.topBar}>
                        {(props.showBack !== false) && <button onClick={goBack} style={styles.backButton}><span style={styles.back}>{t('BACK')}</span></button>}
                        <span style={styles.title}>{props.title}</span>
                        <button style={styles.menuButton} onClick={context.toggleMenu}><img src={Images.menubtn} style={{ width: 55, height: 55 }} alt="Menu" /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;

const styles = {
    headerContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        background: 'url(' + Images.homeheaderbg + ')',
    },
    topBar: {
        display: 'flex',
        width: '100%',
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
        position: 'relative',
    },
    menuButton: {
        height: 55,
        width: 55,
        position: 'absolute',
        top: 0,
        right: 5,
        background: 'transparent',
        border: 'none'
    },
    backButton: {
        display: 'flex',
        position: 'absolute',
        height: 55,
        top: 0,
        left: 15,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        border: 'none'
    },
    back: {
        color: '#ffffff',
        fontSize: 18
    },
    title: {
        color: '#ffffff',
        fontSize: 18
    }
};