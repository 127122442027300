import React from 'react'
import Images from '../../assets/images/index';
import { Translation } from 'react-i18next';

const SelectTime = props => {

    const bgColor = hours => {
        let delay = '0s';
        
        if(props.time > 0) {
            // Forwards
            delay = '0'+(0.25-((props.time-hours)/40))+'s';
            // Backwards
            if(props.time < hours)
                delay = '0'+(0.25-((hours-props.time)/40))+'s';
        }

        return {
            fill: (hours<=props.time?'#6CB33F':'#D3D3D3'),
            transition: 'all .2s ease ' + delay,
        }
    }

    return (
        <Translation>{t=> 
        <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
            <div style={styles.content}>
                <a href="JavaScript:void(0);" style={styles.close} onClick={props.onClickClose}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>
                <div style={styles.header}>
                    <span style={styles.heading}>{t('SELECT_TIME')}</span>
                    <svg x="0px" y="0px" width="300px" height="230px" viewBox="0 0 300 230">
                        <g onClick={() => {props.selectChargeTime(1)}}>
                            <path style={bgColor(1)} d="M53.992,211.624c-1.489,0.869-23.18,13.526-23.18,13.526s1.082-0.594-1.688,0.984
                                c-2.77,1.578-5.346,1.386-7.923-3.267c-11.578-20.601-18.355-44.26-18.819-69.462h39.24c0.454,18.252,5.426,35.389,13.833,50.334
                                C57.816,207.828,55.481,210.756,53.992,211.624z"/>
                            <g>
                                <path fill="#FFFFFF" d="M23.24,188.991v1.225h-5.295v-1.225h1.939v-5.58c0-0.221,0.006-0.448,0.02-0.682l-1.376,1.151
                                    c-0.08,0.063-0.158,0.101-0.235,0.116s-0.15,0.017-0.218,0.003c-0.068-0.013-0.129-0.036-0.182-0.069
                                    c-0.053-0.033-0.092-0.069-0.119-0.109l-0.516-0.708l2.919-2.482h1.344v8.36H23.24z"/>
                                <path fill="#FFFFFF" d="M26.509,184.152c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.119-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.718,0.53c0.192,0.227,0.337,0.498,0.437,0.813
                                    c0.1,0.315,0.149,0.663,0.149,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.285-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.547,0.063-0.794,0.191s-0.481,0.303-0.702,0.523v4.911h-1.635v-9.836h1.635V184.152z"/>
                                <path fill="#FFFFFF" d="M33.884,184.603c0.211-0.406,0.463-0.725,0.754-0.956c0.291-0.232,0.635-0.348,1.033-0.348
                                    c0.313,0,0.565,0.068,0.754,0.205l-0.106,1.225c-0.022,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.165-0.012-0.294-0.033c-0.13-0.022-0.257-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    c-0.142,0.053-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.167,0.303-0.242,0.483v4.184h-1.635
                                    v-6.791h0.96c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L33.884,184.603z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(2)}}>
                            <path style={bgColor(2)} d="M54.703,98.891c-7.948,14.621-12.637,31.273-13.08,48.978H2.383c0.453-24.895,7.071-48.276,18.388-68.704
                                L54.703,98.891z"/>
                            <g>
                                <path fill="#FFFFFF" d="M22.882,121.018c0.176,0,0.316,0.05,0.42,0.149c0.104,0.099,0.155,0.23,0.155,0.394v0.979h-6.566v-0.543
                                    c0-0.11,0.023-0.226,0.07-0.348c0.046-0.121,0.12-0.23,0.222-0.327l2.906-2.913c0.243-0.247,0.46-0.482,0.652-0.708
                                    c0.192-0.225,0.352-0.447,0.48-0.668s0.226-0.445,0.294-0.672c0.068-0.228,0.103-0.467,0.103-0.719c0-0.229-0.033-0.432-0.1-0.605
                                    c-0.066-0.175-0.16-0.321-0.281-0.44s-0.267-0.208-0.437-0.268s-0.36-0.09-0.572-0.09c-0.194,0-0.374,0.027-0.54,0.083
                                    c-0.166,0.055-0.313,0.132-0.44,0.231s-0.236,0.215-0.324,0.348s-0.154,0.278-0.199,0.437c-0.075,0.203-0.172,0.338-0.291,0.404
                                    s-0.292,0.081-0.516,0.046l-0.861-0.152c0.066-0.459,0.194-0.861,0.384-1.208c0.19-0.346,0.427-0.635,0.712-0.867
                                    c0.285-0.231,0.611-0.405,0.979-0.522s0.765-0.176,1.188-0.176c0.441,0,0.845,0.065,1.211,0.195
                                    c0.366,0.131,0.68,0.314,0.94,0.553s0.463,0.525,0.609,0.86c0.146,0.336,0.218,0.709,0.218,1.119c0,0.353-0.052,0.68-0.156,0.979
                                    c-0.104,0.3-0.243,0.587-0.417,0.86s-0.378,0.539-0.612,0.795s-0.479,0.516-0.734,0.78l-2.158,2.205
                                    c0.208-0.063,0.414-0.109,0.619-0.143c0.205-0.033,0.4-0.05,0.585-0.05H22.882z"/>
                                <path fill="#FFFFFF" d="M26.509,116.477c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.119-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.718,0.53c0.192,0.227,0.337,0.498,0.437,0.813
                                    c0.1,0.315,0.149,0.663,0.149,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.285-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.547,0.063-0.794,0.191s-0.481,0.303-0.702,0.523v4.911h-1.635v-9.836h1.635V116.477z"/>
                                <path fill="#FFFFFF" d="M33.884,116.927c0.211-0.406,0.463-0.725,0.754-0.956c0.291-0.232,0.635-0.348,1.033-0.348
                                    c0.313,0,0.565,0.068,0.754,0.205l-0.106,1.225c-0.022,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.165-0.012-0.294-0.033c-0.13-0.022-0.257-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    c-0.142,0.053-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.167,0.303-0.242,0.483v4.184h-1.635
                                    v-6.791h0.96c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L33.884,116.927z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(3)}}>
                            <path style={bgColor(3)} d="M93.342,58.174C78.739,67.137,66.426,79.478,57.481,94.1L23.549,74.364
                                C35.918,53.9,53.097,36.685,73.542,24.279L93.342,58.174z"/>
                            <g>
                                <path fill="#FFFFFF" d="M49.634,58.311c0.066-0.459,0.194-0.861,0.384-1.208c0.19-0.346,0.427-0.635,0.712-0.867
                                    c0.285-0.231,0.611-0.405,0.979-0.522s0.765-0.176,1.188-0.176c0.441,0,0.84,0.063,1.195,0.188
                                    c0.355,0.126,0.659,0.301,0.91,0.523s0.445,0.484,0.58,0.784c0.134,0.3,0.202,0.625,0.202,0.974c0,0.304-0.034,0.572-0.103,0.804
                                    s-0.167,0.434-0.294,0.605c-0.128,0.173-0.287,0.318-0.477,0.438c-0.19,0.119-0.404,0.218-0.642,0.298
                                    c0.573,0.181,1.001,0.456,1.284,0.827c0.282,0.371,0.423,0.836,0.423,1.396c0,0.477-0.088,0.898-0.265,1.265
                                    c-0.177,0.366-0.415,0.674-0.715,0.923c-0.3,0.25-0.648,0.438-1.042,0.566c-0.395,0.128-0.813,0.191-1.254,0.191
                                    c-0.481,0-0.9-0.055-1.258-0.165s-0.668-0.273-0.933-0.49c-0.265-0.216-0.49-0.48-0.675-0.794
                                    c-0.186-0.313-0.344-0.675-0.477-1.086l0.721-0.298c0.19-0.079,0.368-0.1,0.533-0.063s0.286,0.127,0.36,0.269
                                    c0.08,0.154,0.167,0.307,0.262,0.456c0.095,0.15,0.208,0.284,0.341,0.4c0.132,0.117,0.288,0.212,0.467,0.285
                                    c0.179,0.072,0.392,0.109,0.639,0.109c0.278,0,0.521-0.046,0.728-0.136c0.208-0.091,0.381-0.209,0.52-0.354
                                    c0.139-0.146,0.243-0.308,0.312-0.486c0.068-0.179,0.103-0.358,0.103-0.54c0-0.229-0.024-0.438-0.073-0.625
                                    c-0.049-0.188-0.15-0.348-0.305-0.48c-0.154-0.132-0.376-0.235-0.665-0.311c-0.289-0.075-0.677-0.112-1.162-0.112v-1.165
                                    c0.402-0.005,0.735-0.042,1-0.113c0.265-0.07,0.476-0.168,0.632-0.294s0.266-0.277,0.328-0.454
                                    c0.062-0.176,0.093-0.37,0.093-0.582c0-0.45-0.126-0.792-0.377-1.026c-0.251-0.233-0.587-0.351-1.006-0.351
                                    c-0.194,0-0.373,0.027-0.536,0.083c-0.164,0.055-0.31,0.132-0.44,0.231s-0.239,0.215-0.328,0.348s-0.154,0.278-0.199,0.437
                                    c-0.075,0.203-0.173,0.338-0.294,0.404s-0.292,0.081-0.513,0.046L49.634,58.311z"/>
                                <path fill="#FFFFFF" d="M59.04,59.152c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.119-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.718,0.53c0.192,0.227,0.337,0.498,0.437,0.813
                                    c0.1,0.315,0.149,0.663,0.149,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.285-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.547,0.063-0.794,0.191s-0.481,0.303-0.702,0.523v4.911h-1.635v-9.836h1.635V59.152z"/>
                                <path fill="#FFFFFF" d="M66.414,59.602c0.211-0.406,0.463-0.725,0.754-0.956c0.291-0.232,0.635-0.348,1.033-0.348
                                    c0.313,0,0.565,0.068,0.754,0.205l-0.106,1.225c-0.022,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.165-0.012-0.294-0.033c-0.13-0.022-0.257-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    c-0.142,0.053-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.167,0.303-0.242,0.483v4.184h-1.635
                                    v-6.791h0.96c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L66.414,59.602z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(4)}}>
                            <path style={bgColor(4)} d="M147.351,42.214c-17.797,0.434-34.541,5.151-49.218,13.182L78.333,21.491
                                c20.464-11.381,43.91-18.045,68.87-18.516L147.351,42.214z"/>
                            <g>
                                <path fill="#FFFFFF" d="M115.597,28.776v0.946c0,0.089-0.029,0.165-0.086,0.229c-0.058,0.064-0.139,0.096-0.245,0.096h-0.874
                                    v2.337h-1.423v-2.337h-4.038c-0.11,0-0.208-0.034-0.292-0.103s-0.137-0.153-0.159-0.255l-0.172-0.827l4.535-6.057h1.549v5.971
                                    H115.597z M112.969,28.776v-3.131c0-0.296,0.02-0.622,0.06-0.979l-2.972,4.11H112.969z"/>
                                <path fill="#FFFFFF" d="M118.377,26.32c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.119-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.718,0.53c0.192,0.227,0.337,0.498,0.437,0.813
                                    c0.1,0.315,0.149,0.663,0.149,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.285-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.547,0.063-0.794,0.191s-0.481,0.303-0.702,0.523v4.911h-1.635v-9.836h1.635V26.32z"/>
                                <path fill="#FFFFFF" d="M125.751,26.771c0.211-0.406,0.463-0.725,0.754-0.956c0.291-0.232,0.635-0.348,1.033-0.348
                                    c0.313,0,0.565,0.068,0.754,0.205l-0.106,1.225c-0.022,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.165-0.012-0.294-0.033c-0.13-0.022-0.257-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    c-0.142,0.053-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.167,0.303-0.242,0.483v4.184h-1.635
                                    v-6.791h0.96c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L125.751,26.771z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(5)}}>
                            <path style={bgColor(5)} d="M153.1,42.214c17.798,0.434,34.542,5.151,49.219,13.182l19.8-33.905
                                c-20.464-11.381-43.909-18.045-68.87-18.516L153.1,42.214z"/>
                            <g>
                                <path fill="#FFFFFF" d="M176.51,24.781c0.212-0.044,0.415-0.076,0.609-0.096s0.382-0.03,0.563-0.03
                                    c0.503,0,0.946,0.075,1.33,0.226c0.385,0.15,0.706,0.357,0.967,0.622s0.457,0.575,0.589,0.93c0.133,0.355,0.199,0.738,0.199,1.148
                                    c0,0.508-0.09,0.972-0.269,1.391s-0.427,0.777-0.744,1.075c-0.318,0.298-0.694,0.529-1.129,0.692s-0.908,0.244-1.42,0.244
                                    c-0.3,0-0.585-0.03-0.854-0.092c-0.27-0.063-0.522-0.145-0.759-0.249c-0.235-0.104-0.454-0.223-0.655-0.357
                                    c-0.2-0.134-0.38-0.276-0.539-0.427l0.503-0.695c0.106-0.149,0.245-0.225,0.417-0.225c0.11,0,0.223,0.035,0.338,0.105
                                    c0.114,0.071,0.246,0.148,0.394,0.232s0.322,0.161,0.523,0.231c0.2,0.07,0.442,0.105,0.725,0.105c0.3,0,0.564-0.048,0.794-0.146
                                    c0.229-0.097,0.421-0.232,0.573-0.407c0.151-0.174,0.267-0.381,0.344-0.622c0.077-0.24,0.116-0.502,0.116-0.784
                                    c0-0.521-0.151-0.928-0.454-1.222c-0.302-0.293-0.749-0.439-1.34-0.439c-0.455,0-0.92,0.084-1.397,0.251l-1.019-0.291l0.794-4.646
                                    h4.727V22c0,0.234-0.073,0.424-0.219,0.569s-0.393,0.219-0.741,0.219h-2.628L176.51,24.781z"/>
                                <path fill="#FFFFFF" d="M183.943,24.814c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.118-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.719,0.53c0.191,0.227,0.337,0.498,0.437,0.813
                                    s0.148,0.663,0.148,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.284-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.548,0.063-0.795,0.191s-0.48,0.303-0.701,0.523v4.911h-1.635v-9.836h1.635V24.814z"/>
                                <path fill="#FFFFFF" d="M191.317,25.264c0.212-0.406,0.463-0.725,0.755-0.956c0.291-0.232,0.635-0.348,1.032-0.348
                                    c0.313,0,0.565,0.068,0.755,0.205l-0.106,1.225c-0.021,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.164-0.012-0.294-0.033c-0.131-0.022-0.258-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    s-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.166,0.303-0.241,0.483v4.184h-1.635v-6.791h0.96
                                    c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L191.317,25.264z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(6)}}>
                            <path style={bgColor(6)} d="M206.659,57.724c14.603,8.963,26.916,21.304,35.861,35.925l33.932-19.735
                                c-12.369-20.464-29.547-37.68-49.993-50.085L206.659,57.724z"/>
                            <g>
                                <path fill="#FFFFFF" d="M233.698,59.119l-0.33,0.411c0.185-0.093,0.385-0.165,0.599-0.216s0.444-0.076,0.691-0.076
                                    c0.362,0,0.714,0.06,1.056,0.179c0.343,0.119,0.644,0.299,0.904,0.54c0.26,0.24,0.47,0.539,0.629,0.896
                                    c0.158,0.357,0.238,0.774,0.238,1.251c0,0.446-0.082,0.864-0.245,1.255s-0.393,0.731-0.688,1.022s-0.652,0.521-1.069,0.688
                                    s-0.877,0.251-1.38,0.251c-0.512,0-0.971-0.081-1.377-0.244s-0.752-0.392-1.039-0.686c-0.287-0.293-0.507-0.646-0.659-1.059
                                    c-0.151-0.413-0.228-0.871-0.228-1.374c0-0.45,0.091-0.912,0.274-1.387c0.183-0.475,0.467-0.968,0.851-1.479l2.283-3.064
                                    c0.08-0.106,0.195-0.196,0.348-0.271s0.328-0.112,0.526-0.112h1.456L233.698,59.119z M234.069,63.984
                                    c0.261,0,0.5-0.044,0.718-0.132c0.219-0.089,0.406-0.212,0.563-0.371c0.156-0.159,0.278-0.345,0.367-0.56
                                    c0.088-0.214,0.132-0.446,0.132-0.698c0-0.273-0.042-0.52-0.125-0.738c-0.084-0.218-0.203-0.403-0.357-0.556
                                    c-0.155-0.152-0.34-0.268-0.557-0.348c-0.216-0.079-0.454-0.119-0.715-0.119s-0.496,0.044-0.708,0.133
                                    c-0.212,0.088-0.393,0.211-0.543,0.367s-0.267,0.342-0.351,0.556c-0.084,0.215-0.126,0.445-0.126,0.692
                                    c0,0.265,0.036,0.506,0.109,0.725c0.072,0.219,0.181,0.405,0.324,0.56s0.321,0.274,0.533,0.36S233.791,63.984,234.069,63.984z"/>
                                <path fill="#FFFFFF" d="M240.364,59.152c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.118-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.719,0.53c0.191,0.227,0.337,0.498,0.437,0.813
                                    s0.148,0.663,0.148,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.284-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.548,0.063-0.795,0.191s-0.48,0.303-0.701,0.523v4.911h-1.635v-9.836h1.635V59.152z"/>
                                <path fill="#FFFFFF" d="M247.738,59.602c0.212-0.406,0.463-0.725,0.755-0.956c0.291-0.232,0.635-0.348,1.032-0.348
                                    c0.313,0,0.565,0.068,0.755,0.205l-0.106,1.225c-0.021,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.164-0.012-0.294-0.033c-0.131-0.022-0.258-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    s-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.166,0.303-0.241,0.483v4.184h-1.635v-6.791h0.96
                                    c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L247.738,59.602z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(7)}}>
                            <path style={bgColor(7)} d="M245.299,98.441c7.947,14.621,12.637,31.273,13.079,48.977h39.24c-0.452-24.895-7.07-48.276-18.388-68.703
                                L245.299,98.441z"/>
                            <g>
                                <path fill="#FFFFFF" d="M270.304,112.969v0.708c0,0.212-0.022,0.383-0.066,0.513c-0.044,0.131-0.088,0.239-0.132,0.328
                                    l-3.628,7.467c-0.075,0.154-0.181,0.285-0.317,0.394s-0.32,0.162-0.55,0.162h-1.185l3.7-7.314
                                    c0.084-0.149,0.167-0.288,0.248-0.414c0.082-0.125,0.173-0.245,0.275-0.36h-4.581c-0.102,0-0.189-0.037-0.265-0.112
                                    s-0.112-0.163-0.112-0.265v-1.105H270.304z"/>
                                <path fill="#FFFFFF" d="M273.196,116.477c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.118-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.719,0.53c0.191,0.227,0.337,0.498,0.437,0.813
                                    s0.148,0.663,0.148,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.284-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.548,0.063-0.795,0.191s-0.48,0.303-0.701,0.523v4.911h-1.635v-9.836h1.635V116.477z"/>
                                <path fill="#FFFFFF" d="M280.57,116.927c0.212-0.406,0.463-0.725,0.755-0.956c0.291-0.232,0.635-0.348,1.032-0.348
                                    c0.313,0,0.565,0.068,0.755,0.205l-0.106,1.225c-0.021,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.164-0.012-0.294-0.033c-0.131-0.022-0.258-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    s-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.166,0.303-0.241,0.483v4.184h-1.635v-6.791h0.96
                                    c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L280.57,116.927z"/>
                            </g>
                        </g>
                        <g onClick={() => {props.selectChargeTime(8)}}>
                            <path style={bgColor(8)} d="M246.01,211.175c1.489,0.869,23.18,13.525,23.18,13.525s-1.082-0.594,1.688,0.984
                                c2.769,1.579,5.346,1.386,7.923-3.267c11.577-20.601,18.355-44.259,18.818-69.461h-39.24c-0.453,18.252-5.426,35.388-13.832,50.334
                                C242.185,207.379,244.521,210.306,246.01,211.175z"/>
                            <g>
                                <path fill="#FFFFFF" d="M267.722,185.225c0.583,0.19,1.014,0.48,1.291,0.871c0.278,0.39,0.417,0.868,0.417,1.433
                                    c0,0.424-0.08,0.808-0.241,1.151c-0.161,0.345-0.388,0.638-0.679,0.881s-0.641,0.431-1.049,0.563
                                    c-0.408,0.133-0.859,0.198-1.354,0.198c-0.495,0-0.946-0.065-1.354-0.198c-0.408-0.132-0.758-0.319-1.049-0.563
                                    s-0.518-0.536-0.679-0.881c-0.161-0.344-0.241-0.728-0.241-1.151c0-0.564,0.139-1.043,0.417-1.433
                                    c0.278-0.391,0.708-0.681,1.291-0.871c-0.464-0.194-0.812-0.47-1.043-0.827s-0.348-0.788-0.348-1.291
                                    c0-0.361,0.074-0.699,0.222-1.013c0.148-0.313,0.354-0.586,0.619-0.817s0.581-0.412,0.95-0.543
                                    c0.368-0.13,0.773-0.195,1.215-0.195c0.44,0,0.846,0.065,1.214,0.195c0.369,0.131,0.686,0.312,0.95,0.543s0.471,0.504,0.619,0.817
                                    c0.147,0.313,0.222,0.651,0.222,1.013c0,0.503-0.116,0.934-0.348,1.291S268.186,185.03,267.722,185.225z M266.107,189.044
                                    c0.269,0,0.505-0.038,0.708-0.115s0.374-0.185,0.513-0.321c0.14-0.137,0.244-0.3,0.314-0.49c0.07-0.189,0.106-0.395,0.106-0.615
                                    c0-0.525-0.146-0.922-0.438-1.191s-0.692-0.404-1.204-0.404c-0.513,0-0.914,0.135-1.205,0.404s-0.437,0.666-0.437,1.191
                                    c0,0.221,0.035,0.426,0.105,0.615c0.07,0.19,0.176,0.354,0.314,0.49s0.31,0.244,0.513,0.321S265.838,189.044,266.107,189.044z
                                    M266.107,181.77c-0.243,0-0.453,0.037-0.629,0.112c-0.177,0.075-0.323,0.176-0.44,0.302c-0.117,0.125-0.203,0.27-0.258,0.434
                                    c-0.056,0.163-0.083,0.335-0.083,0.516c0,0.19,0.023,0.374,0.069,0.553s0.125,0.338,0.235,0.477
                                    c0.11,0.14,0.256,0.251,0.437,0.335s0.403,0.125,0.669,0.125c0.265,0,0.487-0.041,0.668-0.125s0.327-0.195,0.438-0.335
                                    c0.109-0.139,0.188-0.298,0.234-0.477c0.047-0.179,0.069-0.362,0.069-0.553c0-0.181-0.028-0.353-0.086-0.516
                                    c-0.058-0.164-0.144-0.309-0.258-0.434c-0.115-0.126-0.261-0.227-0.437-0.302C266.56,181.807,266.35,181.77,266.107,181.77z"/>
                                <path fill="#FFFFFF" d="M272.389,184.152c0.265-0.247,0.556-0.447,0.874-0.603c0.317-0.154,0.69-0.231,1.118-0.231
                                    c0.371,0,0.699,0.063,0.986,0.188c0.287,0.126,0.526,0.303,0.719,0.53c0.191,0.227,0.337,0.498,0.437,0.813
                                    s0.148,0.663,0.148,1.043v4.322h-1.635v-4.322c0-0.415-0.095-0.736-0.284-0.963c-0.19-0.228-0.479-0.341-0.867-0.341
                                    c-0.282,0-0.548,0.063-0.795,0.191s-0.48,0.303-0.701,0.523v4.911h-1.635v-9.836h1.635V184.152z"/>
                                <path fill="#FFFFFF" d="M279.763,184.603c0.212-0.406,0.463-0.725,0.755-0.956c0.291-0.232,0.635-0.348,1.032-0.348
                                    c0.313,0,0.565,0.068,0.755,0.205l-0.106,1.225c-0.021,0.079-0.054,0.136-0.096,0.169s-0.099,0.05-0.169,0.05
                                    c-0.066,0-0.164-0.012-0.294-0.033c-0.131-0.022-0.258-0.033-0.381-0.033c-0.181,0-0.342,0.026-0.483,0.079
                                    s-0.268,0.129-0.381,0.229c-0.112,0.1-0.212,0.22-0.298,0.36c-0.086,0.142-0.166,0.303-0.241,0.483v4.184h-1.635v-6.791h0.96
                                    c0.167,0,0.284,0.029,0.351,0.089s0.11,0.167,0.132,0.321L279.763,184.603z"/>
                            </g>
                        </g>
                        <g>
                            <g id="Layer_2">
                                <g>
                                    <path fill="#D2D2D2" d="M200.862,147.387c-0.231-5.905-6.048-12.494-16.44-13.085l-2.385-0.048
                                        c-0.739-0.016-1.455-0.252-2.07-0.691c-3.652-2.598-16.767-11.075-32.185-11.075c-13.662,0-22.648,3.05-24.323,3.669h-6.495
                                        c-0.434,0-0.847,0.189-1.13,0.522c-0.282,0.331-0.402,0.771-0.326,1.197l0.295,1.759c-1.594,1.9-6.621,8.295-6.621,13.585
                                        c0,8.069,0.194,10.943,0.86,12.808c0.76,2.133,2.655,3.382,3.446,3.822c1.36,4.654,5.622,8.083,10.712,8.083
                                        c4.969,0,9.142-3.271,10.61-7.757h36.723c1.47,4.486,5.643,7.757,10.612,7.757c4.969,0,9.142-3.271,10.611-7.757h1.864
                                        c3.45,0,6.261-2.813,6.261-6.244C200.862,153.924,200.926,149.018,200.862,147.387z M124.192,164.975
                                        c-4.549,0-8.259-3.702-8.259-8.256c0-4.557,3.702-8.259,8.259-8.259c4.549,0,8.258,3.702,8.258,8.259
                                        C132.451,161.272,128.741,164.975,124.192,164.975z M182.131,164.975c-4.549,0-8.258-3.702-8.258-8.256
                                        c0-4.557,3.702-8.259,8.258-8.259c4.549,0,8.259,3.702,8.259,8.259C190.39,161.272,186.68,164.975,182.131,164.975z
                                        M197.914,153.913c0,1.827-1.482,3.313-3.314,3.313h-1.311c0.006-0.171,0.051-0.334,0.051-0.503
                                        c0-6.181-5.027-11.208-11.209-11.208c-6.181,0-11.206,5.027-11.206,11.208c0,0.169,0.043,0.332,0.05,0.503h-35.624
                                        c0.005-0.171,0.05-0.334,0.05-0.503c0-6.181-5.027-11.208-11.208-11.208c-5.779,0-10.492,4.412-11.088,10.028
                                        c-0.1-0.169-0.221-0.326-0.289-0.508c-0.577-1.62-0.689-5.403-0.689-11.822c0-4.161,4.573-10.19,6.337-12.162
                                        c0.302-0.333,0.434-0.784,0.357-1.23l-0.118-0.716h5.027c0.182,0,0.369-0.037,0.539-0.107c0.087-0.037,9.243-3.563,23.514-3.563
                                        c14.538,0,27,8.059,30.471,10.53c1.099,0.784,2.391,1.212,3.721,1.235l2.329,0.051c8.598,0.483,13.423,5.642,13.604,10.246
                                        C197.97,149.094,197.914,153.887,197.914,153.913z"/>
                                </g>
                                <g>
                                    <path fill="#FFFFFF" d="M158.197,106.127v34.13c0,1.709-1.392,3.102-3.101,3.102h-13.509c-1.707,0-3.1-1.393-3.1-3.102v-34.13
                                        c0-1.708,1.394-3.1,3.1-3.1h13.509C156.798,103.021,158.197,104.419,158.197,106.127z"/>
                                    <path fill="#FFFFFF" d="M155.092,143.893h-13.51c-2.003,0-3.634-1.635-3.634-3.636v-34.13c0-2.002,1.63-3.633,3.634-3.633h13.51
                                        c2.001,0,3.633,1.631,3.633,3.633v34.13C158.725,142.258,157.093,143.893,155.092,143.893z M141.582,103.554
                                        c-1.419,0-2.574,1.154-2.574,2.573v34.13c0,1.42,1.154,2.574,2.574,2.574h13.51c1.417,0,2.573-1.154,2.573-2.574v-34.13
                                        c0-1.418-1.156-2.573-2.573-2.573H141.582z"/>
                                </g>
                                <g>
                                    <path fill="#33ABDF" d="M141.582,142.14c-1.036,0-1.883-0.847-1.883-1.883v-34.13c0-1.036,0.847-1.882,1.883-1.882h13.51
                                        c1.035,0,1.882,0.847,1.882,1.882v34.13c0,1.036-0.847,1.883-1.882,1.883H141.582z"/>
                                </g>
                                <g>
                                    <polygon fill="#FFFFFF" points="151.71,112.829 143.023,124.273 149.194,123.251 147.448,132.182 153.648,120.753 
                                        148.484,121.302 			"/>
                                </g>
                                <g>
                                    <path fill="#33ABDF" d="M151.91,99.701v2.516h-7.141v-2.516C147.148,98.628,149.525,98.628,151.91,99.701z"/>
                                </g>
                            </g>
                            <g id="Layer_3">
                            </g>
                        </g>

                    </svg>                  
                    <div style={styles.confirm}>
                    <a href="JavaScript:void(0);" style={Object.assign({}, styles.confirmButton)} onClick={props.onClickClose}>{t('CONFIRM')}</a>
                    </div>
                </div>
            </div>
            <div style={styles.notice}>
                <span>{t('CHARGE_TIME_NOTE')}</span>
            </div>
        </div>
        }</Translation>
    )
}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        display: 'block',
        overflow: 'auto'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    content: {
      paddingTop: 120,
      paddingBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center'
    },
    close: {
        position: 'absolute',
        height: 50,
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 50,
        right: 0,
        cursor: 'pointer'
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    heading: {
      fontSize: 22,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 20,
      color: '#484848',
      paddingBottom: 30
    },
    confirm: {
        paddingTop: 40,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingBottom: 20
    },
    confirmButton: {
      backgroundColor: '#464646',
      width: 140,
      height: 32,
      borderRadius: 6,
      justifyContent: 'center',
      flexDirection: 'column',
      display: 'flex',
      color: '#fff',
      textAlign: 'center',
      lineHeight: 1,
      fontSize: 15
    },  
    clock: {
        width: 120,
        height: 120,
        marginBottom: 20
    },
    notice: {
        color: '#A2A2A2',
        fontSize: 12,
        padding: '0 10% 10% 10%',
        textAlign: 'center'
    }
}

export default SelectTime;