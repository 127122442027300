import React, { useState, useContext } from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation, Trans } from 'react-i18next';
import Api from '../../context/api';
import VerifyCode from './VerifyCode';

function RegisterForm(props) {
  const [fields, setFields] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    address1: '',
    address2: '',
    city: '',
    county: '',
    postcode: '',
    country: 'UK',
    password: '',
    confirm_password: '',
    terms: false,
    marketing: false,
  });

  const [showEmailVerifyDialog, setShowEmailVerifyDialog] = useState(false);
  const [errors, setErrors] = useState({
    firstname: errorField('panel1'),
    lastname: errorField('panel1'),
    email: errorField('panel1'),
    mobile: errorField('panel1'),
    address1: errorField('panel2'),
    address2: errorField('panel2'),
    city: errorField('panel2'),
    county: errorField('panel2'),
    postcode: errorField('panel2'),
    country: errorField('panel2'),
    password: errorField('panel3'),
    confirm_password: errorField('panel3'),
    terms: errorField('panel3'),
    marketing: errorField('panel3'),
  });
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [activePanel, setActivePanel] = useState(1);

  const context = useContext(GlobalContext);

  function errorField(group = null) {
    return {
      dirty: false,
      invalid: false,
      group: group
    };
  };

  const bindField = (e) => {
    let updatedFields = { ...fields };
    if (e.target.type === 'checkbox') {
      updatedFields[e.target.name] = e.target.checked;
    } else {
      updatedFields[e.target.name] = e.target.value;
    }
    setFields(updatedFields);
    validate();
  };

  const dirtyGroup = (group = null, callback = () => { }) => {
    let updatedErrors = { ...errors };
    Object.values(updatedErrors).forEach(e => {
      if (e.group === group) e.dirty = true;
    });
    setErrors(updatedErrors);
    callback();
  };

  const validate = (callback = () => { }) => {
    let updatedErrors = { ...errors };
    const required = [
      'firstname',
      'lastname',
      'email',
      'mobile',
      'address1',
      'city',
      'county',
      'postcode',
      'password',
      'confirm_password'
    ];
    required.forEach(f => {
      if (fields[f].length > 0) updatedErrors[f].dirty = true;

      updatedErrors[f].invalid = false;
      if (updatedErrors[f].dirty && fields[f] === '') {
        updatedErrors[f].invalid = true;
      }
    });

    // Validate password minimum criteria
    if (
      updatedErrors.password.dirty &&
      !fields.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.{8,})/)
    ) {
      updatedErrors.password.invalid = true;
    }

    // Validate passwords match
    if (
      updatedErrors.password.dirty &&
      updatedErrors.confirm_password.dirty &&
      fields.password !== fields.confirm_password
    ) {
      updatedErrors.confirm_password.invalid = true;
    }

    // Validate email
    if (
      updatedErrors.email.dirty &&
      !fields.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      updatedErrors.email.invalid = true;
    }

    updatedErrors.terms.invalid = false;
    if (updatedErrors.terms.dirty && !fields.terms) updatedErrors.terms.invalid = true;

    const isValid = Object.values(updatedErrors).findIndex(e => e.invalid === true) === -1;
    setErrors(updatedErrors);
    setIsValid(isValid);
    callback();
  };

  const panel1Next = () => {
    dirtyGroup('panel1', () => {
      validate(async () => {
        if (
          !errors.firstname.invalid &&
          !errors.lastname.invalid &&
          !errors.email.invalid &&
          !errors.mobile.invalid
        ) {
          // Email validation starts here
          const emailVerification = await Api.req('/user/validateEmail', 'POST', {
            email: fields.email
          });
          switch (emailVerification.result) {
            case 'VERIFIED':
              registerMovePanel(2);
              break;
            case 'UNVERIFIED':
              setShowEmailVerifyDialog(true);
              break;
            default:
              console.log('ERROR_EMAIL_VERIFICATION');
          }
        }
      });
    });
  };

  const panel2Next = () => {
    dirtyGroup('panel2', () => {
      validate(() => {
        if (
          !errors.address1.invalid &&
          !errors.city.invalid &&
          !errors.county.invalid &&
          !errors.postcode.invalid
        ) {
          registerMovePanel(3);
        }
      });
    });
  };

  const panel2Back = () => {
    registerMovePanel(1);
  };

  const panel3Next = () => {
    dirtyGroup('panel3', () => {
      validate(() => {
        if (isValid) {
          setIsLoading(true);
          setRegisterError(null);
          setTimeout(() => {
            context
              .userRegister(fields)
              .then(user => {
                setIsLoading(false);
                props.redirect();
              })
              .catch(err => {
                setIsLoading(false);
                if (typeof err.msg === 'string') {
                  setRegisterError(err.msg);
                } else {
                  setRegisterError('ERR');
                }
              });
          }, 1000);
        } else {
          console.log('not valid');
        }
      });
    });
  };

  const panel3Back = () => {
    registerMovePanel(2);
  };

  const registerMovePanel = idx => {
    setActivePanel(idx);
  };

  const onCallEmailVerification = () => {
    registerMovePanel(2);
  };

  return (
    <Translation>
      {t => (
        <div
          style={Object.assign({}, styles.container, props.show ? styles.containerIn : styles.containerOut)}
          className="animate"
        >
          <div style={styles.bgImg}>
            <a href="javascript:void(0)" style={styles.closeButton} onClick={props.close}>
              <img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" />
            </a>

            <div style={styles.registerWrapper}>
              {showEmailVerifyDialog && (
                <VerifyCode onClose={() => setShowEmailVerifyDialog(false)} email={fields.email} callEmailVerification={onCallEmailVerification} />
              )}

              {/* Panel 1 */}
              <div
                style={Object.assign({}, styles.panel, activePanel === 1 ? styles.panelIn : styles.panelOut)}
                className="animate"
              >
                <div style={styles.panelBody}>
                  <span style={styles.header}>{t('CREATE_ACCOUNT')}</span>
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.firstname.invalid && styles.inputError)}
                  >
                    <input
                      name={'firstname'}
                      onChange={bindField}
                      placeholder={t('FIRST_NAME')}
                      style={styles.input}
                    />
                  </div>
                  {errors.firstname.invalid && (
                    <span style={styles.inputErrorMsg}>{t('FIRST_NAME_ERR')}</span>
                  )}
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.lastname.invalid && styles.inputError)}
                  >
                    <input
                      name={'lastname'}
                      onChange={bindField}
                      placeholder={t('LAST_NAME')}
                      style={styles.input}
                    />
                  </div>
                  {errors.lastname.invalid && (
                    <span style={styles.inputErrorMsg}>{t('LAST_NAME_ERR')}</span>
                  )}
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.email.invalid && styles.inputError)}
                  >
                    <input
                      type={'email'}
                      name={'email'}
                      onChange={bindField}
                      placeholder={t('EMAIL')}
                      style={styles.input}
                    />
                  </div>
                  {errors.email.invalid && (
                    <span style={styles.inputErrorMsg}>{t('EMAIL_ERR')}</span>
                  )}
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.mobile.invalid && styles.inputError)}
                  >
                    <input
                      type={'number'}
                      name={'mobile'}
                      onChange={bindField}
                      placeholder={t('MOBILE')}
                      style={styles.input}
                    />
                  </div>
                  {errors.mobile.invalid && (
                    <span style={styles.inputErrorMsg}>{t('MOBILE_ERR')}</span>
                  )}
                  <div style={Object.assign({}, styles.buttonGroup, { justifyContent: 'flex-end' })}>
                    <a
                      href="JavaScript:void(0);"
                      style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)}
                      onClick={panel1Next}
                    >
                      <span style={{ color: '#ffffff', textTransform: 'uppercase' }}>
                        {t('NEXT')}
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Panel 2 */}
              <div
                style={Object.assign({}, styles.panel, activePanel === 2 ? styles.panelIn : styles.panelOut)}
                className="animate"
              >
                <div style={styles.panelBody}>
                  <span style={styles.header}>{t('YOUR_ADDRESS')}</span>
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.address1.invalid && styles.inputError)}
                  >
                    <input
                      name={'address1'}
                      onChange={bindField}
                      placeholder={t('ADD_1')}
                      style={styles.input}
                    />
                  </div>
                  {errors.address1.invalid && (
                    <span style={styles.inputErrorMsg}>{t('ADD_1_ERR')}</span>
                  )}
                  <div style={Object.assign({}, styles.inputWrapper)}>
                    <input
                      name={'address2'}
                      onChange={bindField}
                      placeholder={t('ADD_2')}
                      style={styles.input}
                    />
                  </div>
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.city.invalid && styles.inputError)}
                  >
                    <input
                      name={'city'}
                      onChange={bindField}
                      placeholder={t('TOWN')}
                      style={styles.input}
                    />
                  </div>
                  {errors.city.invalid && (
                    <span style={styles.inputErrorMsg}>{t('TOWN_ERR')}</span>
                  )}
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.county.invalid && styles.inputError)}
                  >
                    <input
                      name={'county'}
                      onChange={bindField}
                      placeholder={t('COUNTY')}
                      style={styles.input}
                    />
                  </div>
                  {errors.county.invalid && (
                    <span style={styles.inputErrorMsg}>{t('COUNTY_ERR')}</span>
                  )}
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.postcode.invalid && styles.inputError)}
                  >
                    <input
                      name={'postcode'}
                      onChange={bindField}
                      placeholder={t('POSTCODE')}
                      style={styles.input}
                    />
                  </div>
                  {errors.postcode.invalid && (
                    <span style={styles.inputErrorMsg}>{t('POSTCODE_ERR')}</span>
                  )}

                  <div style={styles.buttonGroup}>
                    <a href="JavaScript:void(0);" style={styles.buttonGroupBtn}>
                      <span style={{ color: '#ffffff', textTransform: 'uppercase' }} onClick={panel2Back}>
                        {t('BACK')}
                      </span>
                    </a>
                    <a
                      href="JavaScript:void(0);"
                      style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)}
                      onClick={panel2Next}
                    >
                      <span style={{ color: '#ffffff', textTransform: 'uppercase' }}>
                        {t('NEXT')}
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Panel 3 */}
              <div
                style={Object.assign({}, styles.panel, activePanel === 3 ? styles.panelIn : styles.panelOut)}
                className="animate"
              >
                <div style={styles.panelBody}>
                  <span style={styles.header}>{t('SET_PASSWORD')}</span>

                  {registerError && <span style={styles.inputErrorMsg}>{t(registerError)}</span>}

                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.password.invalid && styles.inputError)}
                  >
                    <input
                      type={'password'}
                      name={'password'}
                      onChange={bindField}
                      placeholder={t('PASSWORD')}
                      style={styles.input}
                    />
                  </div>
                  {errors.password.invalid && (
                    <span style={styles.inputErrorMsg}>{t('PASSWORD_REQUIREMENT')}</span>
                  )}
                  <div
                    style={Object.assign({}, styles.inputWrapper, errors.confirm_password.invalid && styles.inputError)}
                  >
                    <input
                      type={'password'}
                      name={'confirm_password'}
                      onChange={bindField}
                      placeholder={t('CONFIRM_PASSWORD')}
                      style={styles.input}
                    />
                  </div>
                  {errors.confirm_password.invalid && (
                    <span style={styles.inputErrorMsg}>{t('PASSWORD_NOMATCH')}</span>
                  )}
                  <div>
                    <div style={styles.checkbox}>
                      <input
                        type={'checkbox'}
                        name={'marketing'}
                        id={'marketing'}
                        onChange={bindField}
                        style={styles.input}
                      />
                      <label htmlFor={'marketing'}>{t('REGISTER_MARKETING')}</label>
                    </div>
                  </div>
                  <div>
                    <div style={styles.checkbox}>
                      <input
                        type={'checkbox'}
                        name={'terms'}
                        id={'terms'}
                        onChange={bindField}
                        style={styles.input}
                      />
                      <label htmlFor={'terms'}>
                        <Trans
                          i18nKey="I_AGREE"
                          components={[
                            <a
                              href="https://www.rolecserv.com/rolec-smart-solutions/app-end-user-licence-agreement---vendelectric"
                              style={{ color: '#222', fontWeight: 'bold', textDecoration: 'underline' }}
                              target="_blank"
                            />,
                            <a
                              href="https://www.rolecserv.com/rolec-smart-solutions/app-privacy-policy---vendelectric"
                              style={{ color: '#222', fontWeight: 'bold', textDecoration: 'underline' }}
                              target="_blank"
                            />,
                          ]}
                        />
                      </label>
                    </div>
                    {errors.terms.invalid && (
                      <span
                        style={Object.assign({}, styles.inputErrorMsg, { display: 'block', marginTop: 4 })}
                      >
                        {t('TERMS_AGREE_ERR')}
                      </span>
                    )}
                  </div>
                  <div style={styles.buttonGroup}>
                    <a href="JavaScript:void(0);" style={styles.buttonGroupBtn}>
                      <span style={{ color: '#ffffff', textTransform: 'uppercase' }} onClick={panel3Back}>
                        {t('BACK')}
                      </span>
                    </a>
                    <a
                      href="JavaScript:void(0);"
                      style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)}
                      onClick={panel3Next}
                    >
                      {isLoading ? (
                        <span style={styles.loading}>
                          <ActivityIndicator />
                        </span>
                      ) : (
                        <span style={{ color: '#ffffff', textTransform: 'uppercase' }}>
                          {t('REGISTER')}
                        </span>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div style={styles.rolecLogoWrapper}>
              <img src={Images.rolec_logo} style={styles.rolecLogo} alt="Powered by Rolec" />
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
}

const styles = {
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '90',
  },
  containerIn: {
    transform: 'translateY(0)',
  },
  containerOut: {
    transform: 'translateY(100%)',
    visibility: 'hidden',
  },
  bgImg: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `url(${Images.bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    overflowY: 'auto',
  },
  vendLogo: {
    width: '80%',
    maxWidth: 250,
    maxHeight: 58,
    marginBottom: 40,
    alignSelf: 'center',
  },
  rolecLogoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingBottom: 20,
  },
  rolecLogo: {
    width: 110,
    height: 25,
    paddingRight: 20,
  },
  closeButton: {
    marginTop: 50,
    marginRight: 20,
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    zIndex: 90,
    color: '#6CB33F',
  },
  registerWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: 1,
    maxWidth: 300,
    overflow: 'hidden',
    minHeight: 500,
  },
  panel: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 95,
    justifyContent: 'center',
    display: 'flex',
  },
  panelIn: {
    transform: 'translateX(0)',
  },
  panelOut: {
    transform: 'translateX(100%)',
    visibility: 'hidden',
  },
  panelBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  buttonGroup: {
    width: '100%',
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonGroupBtn: {
    width: 140,
    height: 50,
    backgroundColor: '#555555',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  buttonGroupBtnPrimary: {
    backgroundColor: '#6CB33F',
  },
  inputWrapper: {
    height: 54,
    width: '100%',
    backgroundColor: '#ebebeb',
    borderRadius: 12,
    marginBottom: 10,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    fontSize: 16,
    flex: 1,
    paddingLeft: 20,
  },
  inputError: {
    boxShadow: 'inset 0 0 0 1px #F26A6A',
  },
  inputErrorMsg: {
    width: '100%',
    color: '#F26A6A',
    fontSize: 11,
    paddingLeft: 20,
    marginBottom: 8,
    marginTop: -8,
  },
  loadingWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: 0.7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    fontSize: 13,
  },
};

export default RegisterForm;
