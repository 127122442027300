import React from 'react';
import GlobalContext from '../../context/global-context';
import Images from '../../assets/images';
import { withTranslation } from 'react-i18next';


class NumberPlateInput extends React.Component {

  static contextType = GlobalContext

  constructor(props){
    super(props);

    this.state = {
      reg: props.reg
    };

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.setState({reg: e.target.value.toUpperCase()}, () => {
      this.props.onSetReg(this.state.reg);
    });
  }

  myVehicles = () => {
    const { t } = this.props;

    if(typeof this.context.account.vehicles !== 'undefined')
      return (
        <div>
          <div style={styles.header}>{t('MY_VEHICLES')}</div>
          {this.context.account.vehicles.map(vehicle => (
            <a href='javascript:void(0)' style={styles.vehicle} key={vehicle.reg} onClick={() => {this.selectReg(vehicle.reg)}}>
              <span style={styles.reg}>{vehicle.reg}</span>
              <span style={styles.model}>{vehicle.model} ({vehicle.make})</span>
            </a>
          ))}
        </div>
      )
  }

  selectReg = (reg) => {
    this.setState({reg: reg}, () => {
      this.props.onSetReg(this.state.reg);
    })
  }

  render(){
    const { t } = this.props;
    return (
      <div style={Object.assign({}, styles.dialogBg, this.props.onShow && styles.dialogIn)}>
        <div style={styles.dialog} className="animate">
          <div style={styles.dialogWrapper}>
              <div style={styles.dialogBody}>
                <div style={styles.inputWrapper}>
                  <span style={styles.regCountry}>GB</span>
                  <input type={'text'} name={'reg'} onChange={this.handleChange} placeholder={t('YOUR_REG')} style={styles.input} maxLength={8} value={this.state.reg} />
                </div>

                {this.myVehicles()}

                <button style={styles.close} onClick={this.props.onClose}>{t('OK')}</button>
              </div>
          </div>
        </div>
      </div>
    );
  }

}

const styles = {
  dialogBg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '50%',
    left: '50%',
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: 989,
    transform: 'translate(-50%, 100%)',
    visibility: 'hidden',
    opacity: 0,
  },
  dialogIn: {
    transform: 'translate(-50%, -50%)',
    visibility: 'visible',
    opacity: 100,
  },
  dialog: {
    display: 'block',
    width: 280,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    boxShadow: '0 0 6px rgba(0,0,0,.3)',
    zIndex: 990,
    borderRadius: 12
  },
  dialogWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },
  header: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 15,
    marginBottom: 10,
    color: '#848484',
  },
  dialogBody: {
    flex: 1,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogUnit: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  dialogIcon: {
    marginRight: 20
  },
  inputWrapper: {
    height: 54,
    width: '100%',
    borderRadius: 4,
    justifyContent: 'center',
    display: 'flex',
    border: '1px solid #484848'
  },
  regCountry: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    width: 40,
    height: '100%',
    backgroundColor: '#0935d4',
    backgroundColor: '#6CB33F',
    color: '#fff',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  input: {
      backgroundColor: '#fdb414',
      backgroundColor: '#fff',
      textAlign: 'center',
      fontSize: 27, 
      flex: 1,
      color: '#484848',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      fontWeight: 900,
      minWidth: 100,
      textTransform: 'uppercase'
  },
  vehicleButton: {
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#6CB33F',
    fontWeight: 900,
    cursor: 'pointer'
  },
  vehicle: {
    padding: 8,
    marginBottom: 8,
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'row',
    color: '#6CB33F',
    fontWeight: 900,
    cursor: 'pointer',
    borderRadius: 6,
    flex: 1
  },
  reg: {
    flex: 1
  },
  model: {
    maxWidth: '50%',
    alignSelf: 'flex-end',
    color: '#848484',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  close: {
    width: 90,
    height: 30,
    backgroundColor: '#6CB33F',
    border: 'none',
    color: '#fff',
    borderRadius: 6,
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
};


export default withTranslation()(NumberPlateInput);