import React, { useRef } from 'react';

export default function FormPanel({ header, subHeader, formFields, actions }) {

    const formContainerRef = useRef(null);

    const handleInputFocus = () => {
        if (formContainerRef.current) {
            formContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div style={styles.panel}>
            <span style={styles.header}>{header}</span>
            <span style={styles.subheader}>{subHeader}</span>
            <div style={styles.formContainer} ref={formContainerRef}>
                {formFields.map((field, index) => (
                    <div key={index} onFocus={handleInputFocus}>
                        {field}
                    </div>
                ))}
                {actions}
            </div>
        </div>
    )
}

const styles = {
    panel: {
        marginTop: 13,
        padding: '5px 20px',
        backgroundColor: 'rgba(255,255,255,.6)',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 16,
        paddingRight: 16,
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        // marginTop: 20,
        marginBottom: 10,
        display: 'block',
        color: '#484848',
        textAlign: 'center'
    },
    subheader: {
        fontSize: 16,
        marginBottom: 20,
        display: 'block',
        color: '#484848',
        textAlign: 'center'
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    }
}
