import React, { useEffect, useState, useRef } from "react";
import config from "../config";
import GlobalContext from "./global-context";
import { LoadScript } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import helpers from "./helpers";
import { changeLanguage } from "i18next";
import {
  logout,
  userValidate,
  userUpdateProfile,
  login,
  userClearAlert,
} from "../actions/userActions";
import { sitesUpdate } from "../actions/sitesActions";
import { historyUpdate } from "../actions/historyActions";
import { accountUpdate } from "../actions/accountActions";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import WarningDiaog from "../components/global/WarningDialog";
import { useTranslation } from "react-i18next";

// Capacitor specific imports
import { PushNotifications } from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";
import { Geolocation } from "@capacitor/geolocation";
import { StatusBar, Style } from "@capacitor/status-bar";
import SplashScreen from "../components/global/SplashScreen";
import { App } from "@capacitor/app";

const VERSION = process.env.REACT_APP_VERSION;

const TEST = false;
const BO_ENDPOINT = TEST
  ? "https://backoffice-test.vendelectric.com/"
  : "https://backoffice.vendelectric.com/";
//const BO_ENDPOINT = 'http://localhost:80/';
const DRIVER_ENDPOINT = TEST
  ? "https://pay.api-test.vendelectric.com/v1.2/"
  : "https://pay.api.vendelectric.com/v1.2/";
const SITE_ENDPOINT = BO_ENDPOINT + "api/v1/";

//
//  Default location for the map is London
//
const DEFAULT_LOCATION = {
  lat: 51.5007,
  lng: 0.1246,
};

//
//  Default camera position for the map
//  matches the default location
//
const DEFAULT_CAMERA = {
  lat: 51.5007,
  lng: 0.1246,
};

//
//  Blank guest object for charge as guest
//
const GUEST = {
  firstname: "",
  lastname: "",
  address1: "",
  address2: "",
  city: "",
  postcode: "",
  county: "",
};

//
//  Keys for local storage items
//
const STORAGE_KEY = {
  token: "t",
  expiry: "e",
  location: "l",
  version: "v",
  onboarded: "o",
  charges: "c",
  language: "cc",
  nativePrompt: "np",
  deviceId: "d",
};

//
//  three interval timers will be used to periodically update data
//
var LOCATION_INT = null;
var SITES_INT = null;
var SESSIONS_INT = null;

//
//  Used for the silent refresh of the token
//
var silent_refresh;

const GlobalState = (props) => {
  let history = useHistory();

  useEffect(() => {
    props.historyUpdate(history.location.pathname);
  }, [history.location]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Init states
  const [splash, setSplash] = useState(true);
  const [localLoaded, setLocalLoaded] = useState(false);
  const [mapsLoaded, setMapsLoaded] = useState(false);

  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [camera, setCamera] = useState(DEFAULT_CAMERA);

  const [device, setDevice] = useState({
    deviceId: null,
    deviceOS: null,
    deviceMake: null,
    deviceModel: null,
    pushToken: null,
    appVersion: VERSION,
    appType: config.NATIVE ? "NATIVE" : "WEB",
    push: false,
  });
  const [pushToken, setPushToken] = useState(null);
  const [pushKick, setPushKick] = useState(0);

  const [isNative, setIsNative] = useState(config.NATIVE);
  const [platform, setPlatform] = useState("web");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [site, setSite] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState({
    sites: false,
    user: false,
    location: false,
    charges: true,
    userVerify: false,
    search: false,
    wallet: false,
  });
  const [errors, setErrors] = useState({ location: false });
  const [guest, setGuest] = useState(GUEST);
  const [isGuestCheckout, setIsGuestCheckout] = useState(false);
  const [wallet, setWallet] = useState({
    exists: false,
    balance: "0.00",
    error: false,
    card: false,
    currency: "GBP",
    autoTopUpEnabled: false,
    autoTopUpAmount: 0,
    autoTopUpBalanceTrigger: 0,
  });
  const [currencies, setCurrencies] = useState([
    {
      Code: "GBP",
      Symbol: "£",
    },
  ]);

  const sessionsQuickMode = useRef(false);
  useEffect(() => {
    console.log(
      `Sessions quick mode ${sessionsQuickMode.current ? "On" : "Off"}`,
    );
  }, [sessionsQuickMode]);

  const [warnings, setWarnings] = useState([]);

  const addWarning = (msg) => {
    let newWarnings = [...warnings];
    newWarnings.push(msg);
    setWarnings(newWarnings);
  };

  const removeWarning = (idx) => {
    let newWarnings = [...warnings];
    newWarnings.splice(idx, 1);
    setWarnings(newWarnings);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const appVersion = () => {
    return VERSION;
  };

  const userLogout = () => {
    return new Promise((resolve, reject) => {
      props.logout();
      //localStorage.removeItem(STORAGE_KEY.charges);
      //localStorage.removeItem(STORAGE_KEY.language);
      //changeLanguage('en');
      resolve(true);
    });
  };

  const userRegister = (user) => {
    return new Promise((resolve, reject) => {
      let params = {
        Email: user.email,
        FirstName: user.firstname,
        LastName: user.lastname,
        Address1: user.address1,
        Address2: user.address2,
        City: user.city,
        County: user.county,
        PostCode: user.postcode,
        Country: user.country,
        Mobile: user.mobile,
        PhoneNumber: user.mobile,
        TermsAndConditions: user.terms,
        Password: user.password,
        ConfirmPassword: user.confirm_password,
        Marketing: user.marketing,
      };

      fetch(DRIVER_ENDPOINT + "user/register", {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((resJson) => {
          // Registration success
          if (resJson.success === true) {
            props.login({
              username: user.email,
              password: user.password,
            });

            resolve(resJson);
          } else {
            // Error
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        });
    });
  };

  const userAddGuest = (guest) => {
    return new Promise((resolve, reject) => {
      setGuest(guest);
      setIsGuestCheckout(true);
      resolve(true);
    });
  };

  const userPasswordForgot = (dataObj) => {
    return new Promise((resolve, reject) => {
      let params = {
        Email: dataObj.email,
      };

      fetch(DRIVER_ENDPOINT + "user/password/forgot", {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            resolve(true);
          } else {
            res.json().then((error) => {
              reject(error);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const userPasswordReset = (dataObj) => {
    return new Promise((resolve, reject) => {
      let params = {
        Email: dataObj.email,
        Code: dataObj.token,
        Password: dataObj.password,
        ConfirmPassword: dataObj.confirm_password,
      };

      fetch(DRIVER_ENDPOINT + "user/password/reset", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            res.json().then((resJson) => {
              if (resJson.success) resolve(resJson);
              else reject(resJson);
            });
          } else {
            res.json().then((error) => {
              reject(error);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getFullName = () => {
    return (
      props.user.profile.user.FirstName + " " + props.user.profile.user.LastName
    );
  };

  const userChangePassword = (dataObj) => {
    return new Promise((resolve, reject) => {
      let params = {
        OldPassword: dataObj.current_password,
        NewPassword: dataObj.password,
        ConfirmPassword: dataObj.confirm_password,
      };

      fetch(DRIVER_ENDPOINT + "user/password", {
        method: "POST",
        body: JSON.stringify(params),
        headers: _withBearer({
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) resolve(resJson);
          else reject(resJson.result);
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        });
    });
  };

  const userUpdateDetails = (fields) => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/profile", {
        method: "PUT",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(fields),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success) {
            props.userUpdateProfile(resJson.result);
            resolve(resJson);
          } else reject(resJson);
        })
        .catch((err) => {
          // Do nothing
          reject(false);
        });
    });
  };

  const userIsOnboarded = () => {
    const onboarded = localStorage.getItem(STORAGE_KEY.onboarded);
    return onboarded !== null ? true : false;
  };

  const userNativePrompted = () => {
    const promted = localStorage.getItem(STORAGE_KEY.nativePrompt);
    return promted !== null ? true : false;
  };

  const userAcceptOnboarding = () => {
    localStorage.setItem(STORAGE_KEY.onboarded, new Date());
  };

  const userDismissNativePrompt = () => {
    localStorage.setItem(STORAGE_KEY.nativePrompt, new Date());
  };

  const userWalletGet = () => {
    return new Promise((resolve, reject) => {
      _setLoading("wallet", true);

      fetch(DRIVER_ENDPOINT + "user/wallet", {
        method: "GET",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          _setLoading("wallet", false);

          let newWallet = {
            ...wallet,
          };

          if (resJson.success === true)
            newWallet = {
              ...newWallet,
              ...resJson.result,
            };

          setWallet(newWallet);
          window.dispatchEvent(new Event("wallet"));
          resolve(newWallet);
        })
        .catch((error) => {
          _setLoading("wallet", false);

          const newWallet = {
            ...wallet,
            balance: "0.00",
            error: true,
          };

          setWallet(newWallet);
          window.dispatchEvent(new Event("wallet"));
          resolve(newWallet);
        });
    });
  };

  const userWalletTopup = (Amount, UseSavedCard) => {
    return new Promise((resolve, reject) => {
      let data = {
        Amount: parseFloat(Amount).toFixed(2),
        UseSavedCard: wallet.exists ? UseSavedCard : false,
        Currency: "GBP",
      };

      fetch(DRIVER_ENDPOINT + "user/wallet", {
        method: "POST",
        body: JSON.stringify(data),
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (response.ok && response.status === 200) {
            return response.json();
          } else {
            throw new Error("There was an error adding funds");
          }
        })
        .then((resJson) => {
          if (resJson.success === true) resolve(resJson.result);
          else reject(resJson.result);
        })
        .catch((error) => {
          userWalletGet();
          reject(error);
          console.error(error);
        });
    });
  };

  const userWalletRefund = () => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/wallet/refund", {
        method: "POST",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (response.ok && response.status === 200) {
            return response.json();
          } else {
            throw new Error("There was an error returning your balance");
          }
        })
        .then((resJson) => {
          userWalletGet();
          if (resJson.success === true) resolve(resJson.result);
          else reject(resJson.result);
        })
        .catch((error) => {
          userWalletGet();
          reject(error);
          console.error(error);
        });
    });
  };

  const userWalletRemoveCard = () => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/wallet/card", {
        method: "DELETE",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((res) => {
          //console.log('card removed')
          setWallet({
            ...wallet,
            card: false,
          });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          console.log("card not removed");
          // Do nothing
          reject(false);
        });
    });
  };

  const userWalletSwitchCurrency = (currency) => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/wallet/currency", {
        method: "PUT",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          Currency: currency,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success) {
            userWalletGet();
            resolve(resJson);
          }
          reject(resJson);
        })
        .catch((err) => {
          // Do nothing
          reject(false);
        });
    });
  };

  const userPushDeviceInfo = () => {
    fetch(`${DRIVER_ENDPOINT}user/device`, {
      method: "POST",
      headers: _withBearer({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(device),
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.success) {
          // Store the device ID
          setDevice({
            ...device,
            deviceId: resJson.result,
            push: false,
          });
          localStorage.setItem(STORAGE_KEY.deviceId, resJson.result);
        }
      })
      .catch((error) => {
        // This is a background task, don't do anything
      });
  };

  const userRFIDEligible = () => {
    fetch(`${DRIVER_ENDPOINT}rfid/eligible${_getGroupQueryString()}`, {
      method: "GET",
      headers: _withBearer({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((resJson) => {
        let newAccount = {
          rfidEligible: false,
        };

        if (resJson.success === true) newAccount.rfidEligible = resJson.result;

        props.accountUpdate(newAccount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*
   *
   *   CURRENCIES
   *
   */

  const fetchCurrencies = () => {
    fetch(DRIVER_ENDPOINT + "currencies")
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) setCurrencies(responseJson.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*
   *
   *   SITES
   *
   */

  const fetchSites = () => {
    _setLoading("sites", true);

    fetch(SITE_ENDPOINT + "sites" + _getGroupQueryString())
      .then((response) => response.json())
      .then((responseJson) => {
        _setLoading("sites", false);

        props.sitesUpdate({
          sites: responseJson,
          location: {
            ...location,
            unit:
              props.user.status === "in"
                ? props.account.data.preferences.unit
                : "m",
          },
        });

        if (SITES_INT !== null) clearTimeout(SITES_INT);

        SITES_INT = setTimeout(fetchSites, 30000);
      })
      .catch((error) => {
        _setLoading("sites", false);
        console.error(error);
      });
  };

  const fetchSite = (id, abort = new AbortController()) => {
    return new Promise((resolve, reject) => {
      const opts = {
        signal: abort.signal,
      };

      fetch(SITE_ENDPOINT + "sites/" + id + _getGroupQueryString(), opts)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((resJson) => {
          setSite(resJson);
          resolve(resJson);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //
  //
  //  Favourites
  //
  //

  const getFavorites = () => {
    return favorites;
  };

  const favoriteToggle = (point) => {
    if (props.user.status === "in") {
      let account = JSON.parse(JSON.stringify(props.account.data));
      if (!isFavorite(point.id)) {
        account.favorites.push(point.id);
      } else {
        let idx = account.favorites.indexOf(point.id);
        account.favorites.splice(idx, 1);
      }

      props.accountUpdate({ favorites: account.favorites });
    }
  };

  const favoritesMatchup = () => {
    fetch(
      SITE_ENDPOINT +
        "sites" +
        _getGroupQueryString() +
        "&ids=" +
        props.account.data.favorites.join(),
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setFavorites(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isFavorite = (id) => {
    if (!props.account.data.favorites) return false;

    return props.account.data.favorites.indexOf(id) > -1 ? true : false;
  };

  const getSite = (id) => {
    let idx = props.sites.data.findIndex((p) => p.id === id);
    return idx > -1 ? props.sites.data[idx] : {};
  };

  const getSiteAddress = (id) => {
    let address = null;

    let idx = props.sites.data.findIndex((p) => p.id === id);
    if (typeof idx !== "undefined")
      address = idx > -1 ? props.sites.data[idx].address : null;

    if (address === null && typeof site !== "undefined" && site !== null)
      if (site.id === id) address = site.address;

    if (address === null) {
      idx = favorites.findIndex((p) => p.id === id);
      if (typeof idx !== "undefined")
        address = idx > -1 ? favorites[idx].address : null;
    }

    return address === null
      ? ""
      : _isStrNull(address.line1, ", ") +
          _isStrNull(address.line2, ", ") +
          _isStrNull(address.line3, ", ") +
          _isStrNull(address.city, ", ") +
          _isStrNull(address.county, ", ") +
          _isStrNull(address.postcode);
  };

  const getUserLocation = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const position = await Geolocation.getCurrentPosition();
        let newLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        handleSetLocation(newLocation);

        if (LOCATION_INT !== null) {
          clearTimeout(LOCATION_INT);
        }
        LOCATION_INT = setTimeout(getUserLocation, 10000);

        resolve(newLocation);
      } catch (error) {
        _setError("location", true);
        reject(location);
      }
    });
  };

  const focusOnUserLocation = () => {
    getUserLocation().then(
      (location) => {
        setCamera(location);
      },
      (err) => {
        // default to the last known location
        setCamera({ ...location });
      },
    );
  };

  const handleSetLocation = (location) => {
    _setError("location", false);
    setLocation(location);
    //calculateDistanceToSites();
    props.sitesUpdate({
      location: {
        ...location,
        unit:
          props.user.status === "in"
            ? props.account.data.preferences.unit
            : "m",
      },
    });
    localStorage.setItem(STORAGE_KEY.location, JSON.stringify(location));
  };

  const requiresPushNotifications = async () => {
    try {
      const info = await Device.getInfo();

      if (["android", "ios"].indexOf(info.platform) > -1) {
        const permStatus = await PushNotifications.checkPermissions();
        console.log(permStatus);

        if (permStatus.receive !== "granted") return true;
        else return false;
      } else return false;
    } catch (error) {
      return false;
    }
  };

  const requestPushNotifications = async () => {
    try {
      const permStatus = await PushNotifications.requestPermissions();

      if (permStatus.receive !== "granted")
        throw new Error("User denied permissions!");

      await PushNotifications.register();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handlePushNotifications = async () => {
    try {
      const info = await Device.getInfo();

      if (["android", "ios"].indexOf(info.platform) > -1) {
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") PushNotifications.register();
        });

        await PushNotifications.addListener("registration", (token) => {
          setPushToken(token.value);
          //console.info('Registration token: ', token.value);
        });

        await PushNotifications.addListener("registrationError", (err) => {
          //console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            const action = (key) => (
              <React.Fragment>
                <button
                  onClick={() => closeSnackbar(key)}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "transparent",
                    outline: "none",
                    fontSize: 24,
                  }}
                >
                  &times;
                </button>
              </React.Fragment>
            );

            const code = notification.data?.code;
            const cpid = notification.data?.cpid;

            switch (code) {
              case "AUTO_TOPUP_SUCCESS":
              case "AUTO_TOPUP_FAILED":
                setPushKick(new Date().getTime());
                break;
              default:
              // Do nothing
            }

            enqueueSnackbar(
              typeof code !== "undefined" && t(code) !== code
                ? t(code, {
                    CPID:
                      typeof cpid !== "undefined"
                        ? cpid
                        : t("THIS_CHARGE_POINT"),
                  })
                : notification.body,
              {
                autoHideDuration: 7000,
                action,
              },
            );
          },
        );

        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (action) => {
            console.log(
              "Push notification action performed",
              action.actionId,
              action.inputValue,
            );

            if (
              action.actionId === "tap" &&
              typeof action.notification?.data?.code !== "undefined" &&
              typeof action.notification?.data?.cpid !== "undefined"
            ) {
              let cpid = action.notification.data.cpid;
              if (cpid.length > 9 && cpid.charAt(9) !== "-")
                cpid = `${cpid.substring(0, 9)}-${cpid.substring(9)}`;
              history.push(`/qr/${cpid}`);
            } else {
              switch (action.notification?.data?.code) {
                case "AUTO_TOPUP_SUCCESS":
                case "AUTO_TOPUP_FAILED":
                  history.push(`/account`);
                  break;
                default:
                // Do nothing
              }
            }
          },
        );
      }
    } catch (error) {}
  };

  const handleSetDevice = async () => {
    try {
      const info = await Device.getInfo();
      const id = await Device.getId();

      //
      // If nulls are also passed in just retain whatever we have already to save loosing anything
      //
      const newDevice = {
        ...device,
        deviceId: id.uuid,
        deviceOS: `${info.operatingSystem} (${info.osVersion})`,
        deviceMake: info.manufacturer,
        deviceModel: info.model,
        userId:
          typeof props.user?.profile?.user?.id !== "undefined"
            ? props.user.profile.user.id
            : null,
        pushToken: pushToken,
        push:
          typeof props.user?.profile?.user?.id !== "undefined" ? true : false,
      };

      setDevice(newDevice);
      setPlatform(info.platform);
    } catch (error) {}
  };

  //
  //
  //  Preferences
  //
  //

  const userPreferencesLoad = () => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/preferences" + _getGroupQueryString(), {
        method: "GET",
        headers: _withBearer({}),
      })
        .then((response) => {
          return response.ok && response.status === 200
            ? response.json()
            : false;
        })
        .then((responseJson) => {
          if (responseJson.success) {
            let preferences = responseJson.result.preferences;
            let vehicles =
              typeof responseJson.result.vehicles !== "undefined"
                ? responseJson.result.vehicles
                : [];
            let favorites =
              typeof responseJson.result.favorites === "string"
                ? responseJson.result.favorites.split(",")
                : responseJson.result.favorites;
            let rfid_tags =
              typeof responseJson.result.rfid_tags !== "undefined"
                ? responseJson.result.rfid_tags
                : [];

            //
            //  Ensure the favourites are parsed as integers
            //
            favorites.map((f, k) => {
              favorites[k] = parseInt(f);
            });

            //
            //  Switch any preference boolean strings to boolean
            //
            Object.keys(preferences).map((p) => {
              if (preferences[p] === "true" || preferences[p] === "false")
                preferences[p] = preferences[p] === "true";
            });

            //
            //  Update the state with the new values
            //
            let newAccount = {
              preferences: {
                ...props.account.data.preferences,
                ...preferences,
              },
              vehicles,
              favorites: favorites.filter((v, i, a) => a.indexOf(v) === i),
              rfid_tags,
            };

            props.accountUpdate(newAccount);

            ////console.log('Preferences');
            window.dispatchEvent(new Event("user"));

            if (typeof newAccount.preferences.lang !== "undefined") {
              changeLanguage(newAccount.preferences.lang);
              localStorage.setItem(
                STORAGE_KEY.language,
                JSON.stringify(newAccount.preferences.lang),
              );
            }
          }
        })
        .catch((error) => {
          //console.log('there was an error')
          //console.log(error);
          reject(error);
        });
    });
  };

  const preferenceToggle = (type) => {
    let account = JSON.parse(JSON.stringify(props.account.data));
    Object.keys(account.preferences).map((k) => {
      if (k === type)
        account.preferences[k] = account.preferences[k] ? false : true;
    });
    props.accountUpdate({ preferences: account.preferences });
  };

  const preferencesPush = () => {
    return new Promise((resolve, reject) => {
      let params = {
        favorites: props.account.data.favorites,
        preferences: props.account.data.preferences,
        vehicles: props.account.data.vehicles,
      };

      fetch(DRIVER_ENDPOINT + "user/preferences", {
        method: "PUT",
        headers: _withBearer({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(params),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            ////console.log('posted favourites');
          }
        })
        .catch((error) => {
          //console.log('there was an error posting favourites')
          reject(error);
          console.error(error);
        });
    });
  };

  const marketingPreferenceToggle = (preference) => {
    let user = JSON.parse(JSON.stringify(props.user.profile.user));
    switch (preference) {
      case "email":
        user.MarketingEmail = user.MarketingEmail !== null ? null : new Date();
        break;
      case "sms":
        user.MarketingSMS = user.MarketingSMS !== null ? null : new Date();
        break;
      case "push":
        user.MarketingPush = user.MarketingPush !== null ? null : new Date();
        break;
      default:
      // Nothing
    }

    props.userUpdateProfile(user);
  };

  const marketingPreferencesPush = () => {
    return new Promise((resolve, reject) => {
      let params = {
        MarketingEmail: props.user.profile.user.MarketingEmail,
        MarketingSMS: props.user.profile.user.MarketingSMS,
        MarketingPush: props.user.profile.user.MarketingPush,
      };

      fetch(DRIVER_ENDPOINT + "user/marketing_preferences", {
        method: "PUT",
        headers: _withBearer({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(params),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            ////console.log('posted favourites');
          }
        })
        .catch((error) => {
          //console.log('there was an error posting favourites')
          reject(error);
          console.error(error);
        });
    });
  };

  const unitToggle = () => {
    let account = JSON.parse(JSON.stringify(props.account.data));
    account.preferences.unit = account.preferences.unit === "m" ? "k" : "m";
    props.accountUpdate({ preferences: account.preferences });
  };

  const paymentMethodToggle = () => {
    let account = JSON.parse(JSON.stringify(props.account.data));
    account.preferences.paymentMethod =
      account.preferences.paymentMethod === "PAYG" ? "WALLET" : "PAYG";
    props.accountUpdate({ preferences: account.preferences });
  };

  const languageToggle = (lang) => {
    let account = JSON.parse(JSON.stringify(props.account.data));
    account.preferences.lang = lang;
    props.accountUpdate({ preferences: account.preferences });
    changeLanguage(account.preferences.lang);
    localStorage.setItem(
      STORAGE_KEY.language,
      JSON.stringify(account.preferences.lang),
    );
  };

  //
  //
  //  Vehicles
  //
  //

  const vehicleAdd = (vehicle) => {
    return new Promise((resolve, reject) => {
      if (props.user.status === "in") {
        let account = JSON.parse(JSON.stringify(props.account.data));
        vehicle.use = account.vehicles.length === 0;
        account.vehicles.push(vehicle);

        props.accountUpdate({ vehicles: account.vehicles });
        resolve(true);
      } else {
        reject("You are not logged in");
      }
    });
  };

  const vehicleRemove = (vehicle) => {
    //console.log('removing vehicle');
    return new Promise((resolve, reject) => {
      if (props.user.status === "in") {
        let account = JSON.parse(JSON.stringify(props.account.data));
        const idx = account.vehicles.findIndex((v) => v.reg === vehicle.reg);
        if (idx > -1) account.vehicles.splice(idx, 1);

        //console.log('vehicle removed');

        props.accountUpdate({ vehicles: account.vehicles });
        resolve(true);
      } else {
        reject("You are not logged in");
      }
    });
  };

  const getVehicleName = () => {
    if (props.account.data.vehicles.length === 0) return "";

    const vehicle = props.account.data.vehicles.find((v) => {
      return v.use;
    });
    return vehicle.make + " " + vehicle.model;
  };

  const getVehicleReg = () => {
    if (
      !props.account.data.vehicles ||
      props.account.data.vehicles.length === 0
    )
      return false;

    const vehicle = props.account.data.vehicles.find((v) => {
      return v.use;
    });
    return typeof vehicle !== "undefined" ? vehicle.reg : "";
  };

  const switchVehicle = (reg) => {
    let account = JSON.parse(JSON.stringify(props.account.data));

    let newVehicles = account.vehicles.map((vehicle) =>
      vehicle.reg === reg
        ? { ...vehicle, use: true }
        : { ...vehicle, use: false },
    );

    account.vehicles = newVehicles;
    props.accountUpdate({ vehicles: account.vehicles });
  };

  //
  //
  //  Sessions
  //
  //

  const sessionsLoad = () => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/sessions", {
        method: "GET",
        headers: _withBearer({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          return response.ok && response.status === 200
            ? response.json()
            : false;
        })
        .then((responseJson) => {
          let charges = [];
          if (responseJson.success === true) {
            if (responseJson.result.length > 0) {
              charges = responseJson.result.sort((a, b) => {
                return new Date(a.StartTime) < new Date(b.StartTime) ? 1 : -1;
              });
            }

            props.accountUpdate({ charges: charges });
            if (SESSIONS_INT !== null) {
              clearTimeout(SESSIONS_INT);
            }
            SESSIONS_INT = setTimeout(
              sessionsLoad,
              sessionsQuickMode.current ? 10000 : 60000,
            );

            window.dispatchEvent(new Event("sessions"));
            resolve(charges);
          } else reject(charges);

          _setLoading("charges", false);
        })
        .catch((error) => {
          //console.log('there was an error')
          //console.log(error);
          reject(error);
        });
    });
  };

  const downloadSessions = (days) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${DRIVER_ENDPOINT}user/sessions/download${_getGroupQueryString()}&days=${days}&type=xlsx`,
        {
          method: "GET",
          headers: _withBearer({
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        },
      )
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const sitesFilter = (value) => {
    if (props.user.status === "in") {
      if (
        !props.account.data.preferences.showPublic &&
        value.privacy.public === true
      )
        return false;

      if (
        !props.account.data.preferences.showPrivate &&
        value.privacy.public === false
      )
        return false;

      if (
        props.account.data.preferences.availableOnly &&
        value.connectors_stats.available_count === 0
      )
        return false;
    }

    return true;
  };

  const sitesFilterByLocation = (lat, lon) => {
    return props.sites.data.filter((p) => {
      if (sitesFilter(p)) {
        if (
          parseFloat(p.location.latitude).toFixed(4) === lat.toFixed(4) &&
          parseFloat(p.location.longitude).toFixed(4) === lon.toFixed(4)
        ) {
          return true;
        }
      }
      return false;
    });
  };

  const isChargePointWaiting = (pointId, payg = null) => {
    let code = "UKEV" + (pointId < 1000 ? `0${pointId}` : pointId);

    // Check if passing in paygcode instead
    if (
      typeof payg !== "undefined" &&
      payg !== null &&
      payg.match(/^[a-zA-Z]{2}\d{2}$/)
    )
      code = payg;

    return new Promise((resolve, reject) => {
      fetch(
        `${DRIVER_ENDPOINT}socket/session/available/${code}${_getGroupQueryString()}`,
        {
          method: "GET",
          headers: _withBearer({
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        },
      )
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const selectCharge = (charge) => {
    return new Promise((resolve, reject) => {
      //
      //  Set the connector with the number of hours to return a price
      //
      const params = {
        hours: charge.Hours,
        vrm: charge.VRM.replace(/ /g, "").toUpperCase(),
        payMethod: props.user.status === "in" ? charge.paymentMethod : "PAYG",
      };

      fetch(`${DRIVER_ENDPOINT}socket/session/setendtime/${charge.PAYGCode}`, {
        method: "PUT",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson.result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const logCharge = (charge) => {
    if (props.user.status === "out") {
      //console.log(charge);

      const newCharge = {
        Connector: charge.Connector,
        Cost: charge.Price,
        Energy: 0,
        EvPoint: charge.EvPoint,
        EvPointSiteID: charge.EvPointSiteID,
        Id: charge.Id,
        SerialNumber: "PAYG",
        StartTime: charge.StartTime,
        EndTime: charge.EndTime,
        VRM: charge.VRM,
        UnitOfMeasureType: charge.UnitOfMeasureType,
        PAYGCode: charge.PAYGCode,
      };

      let newAccount = JSON.parse(JSON.stringify(props.account.data));

      if (
        typeof newAccount === "undefined" ||
        typeof newAccount.charges === "undefined"
      )
        newAccount = { charges: [] };

      newAccount.charges.push(newCharge);
      props.accountUpdate({ charges: newAccount.charges });
      // add to local storage
      localStorage.setItem(
        STORAGE_KEY.charges,
        JSON.stringify(newAccount.charges),
      );
      window.dispatchEvent(new Event("sessions"));
    }
  };

  const unlogCharge = (EvPoint) => {
    if (props.user.status === "out") {
      let newAccount = JSON.parse(JSON.stringify(props.account.data));
      if (newAccount.charges !== null) {
        const idx = newAccount.charges.findIndex((c) => c.EvPoint === EvPoint);
        if (idx > -1) newAccount.charges.splice(idx, 1);

        props.accountUpdate({ charges: newAccount.charges });
        // add to local storage
        localStorage.setItem(
          STORAGE_KEY.charges,
          JSON.stringify(newAccount.charges),
        );
        window.dispatchEvent(new Event("sessions"));
      }
    }
  };

  const limitCharge = (code, limit) => {
    return new Promise((resolve, reject) => {
      fetch(`${DRIVER_ENDPOINT}socket/session/limit/${code}`, {
        method: "PUT",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ limit }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const confirmCharge = (charge) => {
    return new Promise((resolve, reject) => {
      //
      //  Get the payment url
      //
      const params = {
        PAYGCode: charge.PAYGCode,
        FirstName:
          props.user.status === "in"
            ? props.user.profile.user.FirstName
            : guest.firstname,
        LastName:
          props.user.status === "in"
            ? props.user.profile.user.LastName
            : guest.lastname,
        Address1:
          props.user.status === "in"
            ? props.user.profile.user.Address1
            : guest.address1,
        Address2:
          props.user.status === "in"
            ? props.user.profile.user.Address2
            : guest.address2,
        City:
          props.user.status === "in"
            ? props.user.profile.user.City
            : guest.city,
        Postcode:
          props.user.status === "in"
            ? props.user.profile.user.PostCode
            : guest.postcode,
        County:
          props.user.status === "in"
            ? props.user.profile.user.County
            : guest.county,
        Email:
          props.user.status === "in"
            ? props.user.profile.user.Email
            : guest.email,
        VRM: charge.VRM,
        payMethod: props.user.status === "in" ? charge.paymentMethod : "PAYG",
      };

      fetch(`${DRIVER_ENDPOINT}socket/session/start/${charge.PAYGCode}`, {
        method: "POST",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (response.ok && response.status === 200) {
            if (props.user.status === "in") {
              userWalletGet();
            }

            return response.json();
          } else {
            throw new Error("There was an error confirming the charge");
          }
        })
        .then((responseJson) => {
          if (responseJson.success === true) {
            // Prompt the re-loading of sessions
            sessionsLoad();
            resolve(responseJson.result);
          } else reject(responseJson.result);
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        });
    });
  };

  const authoriseCharge = (code) => {
    return new Promise((resolve, reject) => {
      fetch(`${DRIVER_ENDPOINT}socket/session/authorised/${code}`, {
        method: "GET",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const checkChargeStatus = (PAYGCode) => {
    return new Promise((resolve, reject) => {
      // Check if passing in GM paygcode instead
      if (PAYGCode.match(/^[a-zA-Z]{2}\d{2}$/))
        PAYGCode =
          PAYGCode.substring(0, 2).toUpperCase() +
          "-" +
          PAYGCode.substring(2, 4).toUpperCase();

      fetch(DRIVER_ENDPOINT + `socket/session/status/${PAYGCode}`, {
        method: "GET",
        headers: _withBearer({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.success === true) {
            if (props.user.status === "in") sessionsLoad();
            resolve(responseJson.result);
          } else reject(responseJson.result);
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        });
    });
  };

  const endCharge = (ChargeSessionId) => {
    return new Promise((resolve, reject) => {
      fetch(SITE_ENDPOINT + "chargesessions/" + ChargeSessionId + "/stop", {
        method: "POST",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        });
    });
  };

  const connectLiveChargeSession = (ChargeSessionId) => {
    if (typeof window.io !== "undefined" && ChargeSessionId !== null)
      return window.io.connect(
        BO_ENDPOINT + "api/v1/charge-session?transactionId=" + ChargeSessionId,
      );

    return false;
  };

  const reportFault = (data) => {
    return new Promise((resolve, reject) => {
      if (props.user.status === "in") {
        fetch(SITE_ENDPOINT + "fault-report", {
          method: "POST",
          // headers: {
          //   'Content-Type': 'multipart/form-data'
          // },
          body: data,
        })
          .then((res) => {
            if (res.ok && res.status === 201) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => {
            reject(error);
            console.error(error);
          });
      } else {
        reject("You are not logged in");
      }
    });
  };

  const sendFeedback = (data) => {
    return new Promise((resolve, reject) => {
      if (props.user.status === "in") {
        fetch(SITE_ENDPOINT + "feedback", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (res.ok && res.status === 201) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => {
            reject(error);
            console.error(error);
          });
      } else {
        reject("You are not logged in");
      }
    });
  };

  const searchLocations = (str, attempt = 1) => {
    _setLoading("search", true);

    return new Promise(async (resolve, reject) => {
      if (attempt > 20) reject(null);

      //
      //  First off check if this is a PAYG code being entered
      //
      let isChargePointWaiting = null;

      // This will be the PAYG response
      let payg = null;

      if (str.match(/^(U|u)(K|k)(E|e)(V|v)[a-zA-Z]{2}\d{2}$/)) {
        // It matches PAYG prep the result
        payg = {
          session: null,
          point: null,
          site: null,
        };

        const PAYGCode = str.substring(4, 8).toUpperCase();

        // See if the PAYG code entered is waiting
        await isChargePointWaiting(null, PAYGCode).then(
          (res) => {
            isChargePointWaiting = res;
          },
          (err) => {
            // Cannot match that PAYG code
          },
        );
      }

      // create the initial query string
      // includes the user id to get private locations
      let q = _getGroupQueryString();

      // If a charge session is waiting on that PAYG code search get more info for the EvPoint.
      if (isChargePointWaiting !== null) {
        q =
          q +
          (q.length === 0 ? "?" : "&") +
          "q=EV-" +
          isChargePointWaiting.EvPoint;
      } else {
        q = q + (q.length === 0 ? "?" : "&") + "q=" + str;
      }

      // Execute the search
      fetch(SITE_ENDPOINT + "search" + q, {
        method: "GET",
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          // response from the search
          let results = responseJson;

          // Send back the attempt
          results.attempt = attempt;

          // Add a default PAYG result to null
          results.payg = payg;

          // Calculate the distance to the charge points by matching it to a site
          results.points.map((p) => {
            p.id = p.siteId;
            const site = props.sites.data.find((s) => s.id === p.siteId);
            if (typeof site !== "undefined" && site.id) {
              p.location = site.location;
              p.distance = _distanceInKm(
                p.location.latitude,
                p.location.longitude,
              );
              p.distanceLabel = "Km";

              if (
                (props.user.status === "in" &&
                  props.account.data.preferences.unit === "m") ||
                props.user.status === "out"
              ) {
                p.distance = _kmToMiles(p.distance);
                p.distanceLabel = "Miles";
              }

              results.points.sort((a, b) => a.distance - b.distance);
            }
          });

          // calculate the distance to the sites
          results.sites.map((s) => {
            s.id = s.siteId;
            s.distance = _distanceInKm(
              s.location.latitude,
              s.location.longitude,
            );
            s.distanceLabel = "Km";

            if (
              (props.user.status === "in" &&
                props.account.data.preferences.unit === "m") ||
              props.user.status === "out"
            ) {
              s.distance = _kmToMiles(s.distance);
              s.distanceLabel = "Miles";
            }
            results.sites.sort((a, b) => a.distance - b.distance);
          });

          if (
            isChargePointWaiting !== null &&
            typeof isChargePointWaiting.EvPoint !== "undefined"
          ) {
            payg.session = isChargePointWaiting;
            payg.point = results.points.find(
              (p) => p.pointId === isChargePointWaiting.EvPoint,
            );

            if (typeof payg.point.siteId !== "undefined") {
              let site = await fetchSite(payg.point.siteId);

              if (typeof site !== "undefined" && site.id) {
                site.distance = _distanceInKm(
                  site.location.latitude,
                  site.location.longitude,
                );
                site.distanceLabel = "Km";

                if (
                  (props.user.status === "in" &&
                    props.account.data.preferences.unit === "m") ||
                  props.user.status === "out"
                ) {
                  site.distance = _kmToMiles(site.distance);
                  site.distanceLabel = "Miles";
                }
              }

              payg.site = site;
            }
            results.payg = payg;
          }

          _setLoading("search", false);
          resolve(results);
        })
        .catch((error) => {
          _setLoading("search", false);
          reject(error);
          console.error(error);
        });
    });
  };

  /*
   *
   * RFID
   *
   */

  const RFIDPairingStart = (chargePoint) => {
    return new Promise((resolve, reject) => {
      fetch(`${DRIVER_ENDPOINT}rfid/pairing/start`, {
        method: "POST",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          cp: chargePoint,
          uid: props.user.profile.user.id,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const RFIDPairingTag = (chargePoint, abort = new AbortController()) => {
    return new Promise((resolve, reject) => {
      fetch(`${DRIVER_ENDPOINT}rfid/pairing/tag`, {
        method: "POST",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        signal: abort.signal,
        body: JSON.stringify({
          cp: chargePoint,
          uid: props.user.profile.user.id,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const RFIDPairingFinish = (
    chargePoint,
    label,
    abort = new AbortController(),
  ) => {
    return new Promise((resolve, reject) => {
      fetch(`${DRIVER_ENDPOINT}rfid/pairing/finish`, {
        method: "POST",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        signal: abort.signal,
        body: JSON.stringify({
          cp: chargePoint,
          label: label,
          uid: props.user.profile.user.id,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            userPreferencesLoad();
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const RFIDDeleteTag = (id, abort = new AbortController()) => {
    return new Promise((resolve, reject) => {
      fetch(`${DRIVER_ENDPOINT}rfid/tag`, {
        method: "DELETE",
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        signal: abort.signal,
        body: JSON.stringify({
          tagId: id,
          uid: props.user.profile.user.id,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.success === true) {
            userPreferencesLoad();
            resolve(resJson.result);
          } else {
            reject(resJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /*
   *
   *
   * INVITES
   *
   */

  const inviteVerify = (token) => {
    return new Promise((resolve, reject) => {
      fetch(SITE_ENDPOINT + "veos/invitations/" + token, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            res.json().then((resJson) => {
              resolve(resJson);
            });
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const inviteAccept = (token) => {
    return new Promise((resolve, reject) => {
      fetch(DRIVER_ENDPOINT + "user/invite/accept", {
        method: "PUT",
        body: JSON.stringify({ token: token }),
        headers: _withBearer({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const inviteDecline = (token) => {
    return new Promise((resolve, reject) => {
      fetch(SITE_ENDPOINT + "veos/invitations/" + token, {
        method: "DELETE",
      })
        .then((res) => {
          if (res.ok && res.status === 200) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /*
   *
   *
   * TOPUP
   *
   *
   */

  const topupRequest = (amount) => {
    return new Promise((resolve, reject) => {
      if (props.user.status === "in") {
        //
        //  Get the payment url
        //
        const params = {
          Amount: amount,
          FirstName: props.user.profile.user.FirstName,
          LastName: props.user.profile.user.LastName,
          Address1: props.user.profile.user.Address1,
          Address2: props.user.profile.user.Address2,
          City: props.user.profile.user.City,
          Postcode: props.user.profile.user.PostCode,
          County: props.user.profile.user.County,
        };

        resolve("https://vendelectric.com");
      } else {
        reject("You must be logged in to topup");
      }
    });
  };

  /*
   *
   * Helpful functions
   *
   */

  const _setLoading = (idx, val) => {
    let newLoading = { ...loading };
    newLoading[idx] = val;
    setLoading(newLoading);
  };

  const _setError = (idx, val) => {
    let newErrors = { ...errors };
    newErrors[idx] = val;
    setErrors(newErrors);
  };

  const _degreesToRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  };

  const _distanceInKm = (lat2, lon2) => {
    let lat1 = location.lat;
    let lon1 = location.lng;

    const earthRadiusKm = 6371;

    const dLat = _degreesToRadians(lat2 - lat1);
    const dLon = _degreesToRadians(lon2 - lon1);

    lat1 = _degreesToRadians(lat1);
    lat2 = _degreesToRadians(lat2);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round(earthRadiusKm * c * 10) / 10;
  };

  const _kmToMiles = (distance) => {
    return Math.round(distance * 0.621371 * 10) / 10;
  };

  const _isStrNull = (str, app = "") => {
    return typeof str !== "undefined" &&
      str !== null &&
      str.length > 0 &&
      str !== "null"
      ? str + app
      : "";
  };

  const _setDimensions = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  };

  const _getGroupQueryString = () => {
    let qStr = "";
    if (props.user.status === "in" && props.user.profile.user.id !== "")
      qStr = "?uid=" + props.user.profile.user.id;
    return qStr;
  };

  const _createQueryString = (params) => {
    let qStr = "";
    Object.keys(params).map((k) => {
      qStr += k + "=" + encodeURIComponent(params[k]) + "&";
    });
    return qStr.slice(0, -1);
  };

  const _storeLocalTestUser = () => {
    localStorage.setItem("db_user", JSON.stringify(props.user.profile.user));
  };

  const _withBearer = (headers = {}) => {
    if (props.user.profile !== null && props.user.profile.token !== null)
      headers.Authorization = "Bearer " + props.user.profile.token;
    return headers;
  };

  const _parseJSON = (response) => {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        }),
      ),
    );
  };

  //
  // Preferences changed
  //
  useEffect(() => {
    if (props.user.status === "in" && props.account.prevData !== null) {
      const prevData = props.account.prevData;
      const newData = props.account.data;

      // Check preferences for changes
      if (
        JSON.stringify(newData.favorites) !==
          JSON.stringify(prevData.favorites) ||
        JSON.stringify(newData.preferences) !==
          JSON.stringify(prevData.preferences) ||
        JSON.stringify(newData.vehicles) !== JSON.stringify(prevData.vehicles)
      )
        preferencesPush();

      if (
        JSON.stringify(newData.favorites) !== JSON.stringify(prevData.favorites)
      )
        favoritesMatchup();
    }
  }, [props.account.data]);

  //
  //  Silent user refresh
  //
  useEffect(() => {
    if (typeof silent_refresh != "undefined") clearTimeout(silent_refresh);

    if (props.user.status === "in")
      silent_refresh = setTimeout(
        props.userValidate,
        Math.floor(props.user.profile.expires * 0.9 * 1000),
      );

    return () => {
      if (typeof silent_refresh != "undefined") clearTimeout(silent_refresh);
    };
  }, [props.user.profile.token]);

  useEffect(() => {
    if (props.user.status === "in" && props.user.profile.user !== null) {
      if (isGuestCheckout) setIsGuestCheckout(false);

      if (
        props.user.prevProfile === null ||
        JSON.stringify(props.user.prevProfile) !==
          JSON.stringify(props.user.profile)
      ) {
        // Assign the device to this user
        handleSetDevice();

        //re-fetch the sites and calculate the distance to the user's location
        fetchSites();
        userPreferencesLoad();
        sessionsLoad();

        userWalletGet();
        userRFIDEligible();

        window.dispatchEvent(new Event("user"));
      }

      //
      //  Check for marketing changes
      //
      if (props.user.prevProfile !== null) {
        const prevProfile = props.user.prevProfile;
        const newProfile = props.user.profile;

        // Check marketing preferences for changes
        if (
          newProfile.user.MarketingEmail !== prevProfile.user.MarketingEmail ||
          newProfile.user.MarketingPush !== prevProfile.user.MarketingPush ||
          newProfile.user.MarketingSMS !== prevProfile.user.MarketingSMS
        )
          marketingPreferencesPush();
      }
    }
  }, [props.user.profile.user]);

  useEffect(() => {
    if (props.user.alert !== null) {
      addWarning(t(props.user.alert));
    }
  }, [props.user.alert]);

  useEffect(handleSetDevice, [pushToken]);
  useEffect(userWalletGet, [pushKick]);

  /*
   *
   *   ON MOUNT
   *
   */
  useEffect(() => {
    if (isNative) {
      StatusBar.setStyle({ style: Style.Dark, color: "#484848" });
      StatusBar.setOverlaysWebView({ overlay: true });

      // Handle the hardware back button
      App.addListener("backButton", (e) => {
        if (window.location.pathname === "/") {
          App.exitApp();
        } else {
          if (history.action === "POP") {
            history.push("/");
          } else {
            history.goBack();
          }
        }
      });

      //
      // Check if the app is in the foreground or background
      //
      App.addListener("appStateChange", ({ isActive }) => {
        window.dispatchEvent(new Event("awake"));

        if (isActive) {
          props.userValidate();
        } else {
          if (typeof LOCATION_INT !== "undefined") clearTimeout(LOCATION_INT);

          if (typeof SITES_INT !== "undefined") clearTimeout(SITES_INT);

          if (typeof SESSIONS_INT !== "undefined") clearTimeout(SESSIONS_INT);

          if (typeof silent_refresh !== "undefined")
            clearTimeout(silent_refresh);
        }
      });
    }

    const v = localStorage.getItem(STORAGE_KEY.version);
    const d = localStorage.getItem(STORAGE_KEY.deviceId);
    const location = localStorage.getItem(STORAGE_KEY.location);
    const charges = localStorage.getItem(STORAGE_KEY.charges);
    const lang = localStorage.getItem(STORAGE_KEY.language);

    // Set the device information
    handleSetDevice();
    handlePushNotifications();

    if (v !== VERSION) {
      // If you need to do anything when the version changes
    }
    localStorage.setItem(STORAGE_KEY.version, VERSION);

    //  Pull out some potential account defaults
    let newAccount = {};

    // If you have been using as a guest your charges might be stored locally
    if (charges !== null) newAccount.charges = JSON.parse(charges);

    if (lang !== null) {
      if (typeof newAccount.preferences === "undefined")
        newAccount.preferences = {};

      newAccount.preferences.lang = JSON.parse(lang);
      changeLanguage(newAccount.preferences.lang);
    }

    props.accountUpdate(newAccount);

    if (location !== null) setLocation(JSON.parse(location));

    setLocalLoaded(true);

    setTimeout(() => setSplash(false), 1500);
  }, []);

  useEffect(() => {
    if (localLoaded) {
      // On initial load just check if there is a logged in user
      props.userValidate();

      //inject the socket script for realtime charge session
      helpers.injectSocketScript(BO_ENDPOINT + "socket.io/socket.io.js").then(
        (res) => {
          window.dispatchEvent(new Event("io"));
        },
        (err) => {
          window.dispatchEvent(new Event("io"));
        },
      );

      focusOnUserLocation();
      fetchSites();
      fetchCurrencies();

      window.addEventListener("resize", _setDimensions.bind(this));
      _setDimensions();

      //nativeMessageHandler();
    }

    return () => {
      if (LOCATION_INT != null) clearTimeout(LOCATION_INT);

      if (SITES_INT != null) clearTimeout(SITES_INT);

      if (SESSIONS_INT != null) clearTimeout(SESSIONS_INT);
    };
  }, [localLoaded]);

  useEffect(() => {
    //  If the user is logged in and the device needs pushing up to the server
    if (
      props.user.status === "in" &&
      device.push === true &&
      props.user.profile.user !== null
    )
      userPushDeviceInfo();
  }, [device]);

  /*
   *
   * Native Message Handler
   *
   */
  // const nativeMessageHandler = () => {

  //   /*
  //   *
  //   * Event listner for messages coming in
  //   *
  //   */
  //   window.addEventListener('message', (e) => {

  //     var message;
  //     try {
  //       message = typeof e.data === 'object' ? e.data : JSON.parse(e.data);

  //       if (typeof message.targetFunc !== 'undefined') {
  //         switch (message.targetFunc.toUpperCase()) {
  //           case "CONNECT":
  //             // If the message was to connect
  //             // return a response of connected
  //             //if(typeof window.webViewBridge !== 'undefined')
  //               //window.webViewBridge.send('connected', {msg: 'connected'}, true, false);
  //             break;
  //           case "LOCATION":
  //             //if(message.success){
  //               //console.log('got location')
  //               //console.log(message.data);
  //               //handleSetLocation(message.data)
  //             //}
  //             break;
  //           case "DEVICE":
  //             //if(message.success){
  //               //console.log(message.data);
  //               //handleSetDevice(message.data)
  //             //}
  //             break;
  //           case "PUSH":
  //             //if(message.success){
  //               //showPush(message.data)
  //             //}
  //             break;
  //           case "QR":
  //             // if(message.success){
  //             //   if (message.data && message.data.indexOf('https://app.vendelectric.com/') > -1){
  //             //     const path = message.data.replace('https://app.vendelectric.com', '');
  //             //     history.push(path);
  //             //   }
  //             // }
  //             break;
  //           case "WAKE":
  //             // if(message.success){
  //             //   props.userValidate();
  //             // }
  //             break;
  //           case "SLEEP":
  //             // if(message.success){
  //             //   if(typeof LOCATION_INT !== 'undefined')
  //             //     clearTimeout(LOCATION_INT);

  //             //   if(typeof SITES_INT !== 'undefined')
  //             //     clearTimeout(SITES_INT);

  //             //   if(typeof SESSIONS_INT !== 'undefined')
  //             //     clearTimeout(SESSIONS_INT);

  //             //   if(typeof silent_refresh !== 'undefined')
  //             //     clearTimeout(silent_refresh);
  //             // }
  //         }
  //       }
  //     }
  //     catch(err) {
  //       console.error("failed to parse message from react-native " + err);
  //       return;
  //     }
  //   });

  // }

  return (
    <GlobalContext.Provider
      value={{
        localHistory: props.localHistory,
        isLoggedIn: Boolean(props.user.status === "in"),
        user: props.user,
        userLogin: props.login,
        isNative,
        platform,
        isTest: TEST,
        account: props.account.data,
        dimensions,
        isMenuOpen,
        sites: props.sites.data,
        site,
        favorites,
        location,
        loading,
        errors,
        camera,
        guest,
        isGuestCheckout,
        userLogout: userLogout,
        userRegister: userRegister,
        userAddGuest: userAddGuest,
        userPasswordForgot: userPasswordForgot,
        userPasswordReset: userPasswordReset,
        userChangePassword: userChangePassword,
        userUpdateDetails: userUpdateDetails,
        userAcceptOnboarding: userAcceptOnboarding,
        userIsOnboarded: userIsOnboarded,
        userNativePrompted: userNativePrompted,
        userDismissNativePrompt: userDismissNativePrompt,
        getFullName: getFullName,
        getVehicleName: getVehicleName,
        getVehicleReg: getVehicleReg,
        favoriteToggle: favoriteToggle,
        isFavorite: isFavorite,
        getFavorites: getFavorites,
        fetchSites: fetchSites,
        fetchSite: fetchSite,
        getSite: getSite,
        getSiteAddress: getSiteAddress,
        switchVehicle: switchVehicle,
        getUserLocation: getUserLocation,
        focusOnUserLocation: focusOnUserLocation,
        toggleMenu: toggleMenu,
        closeMenu: closeMenu,
        appVersion: appVersion,
        confirmCharge: confirmCharge,
        limitCharge: limitCharge,
        authoriseCharge: authoriseCharge,
        logCharge: logCharge,
        unlogCharge: unlogCharge,
        selectCharge: selectCharge,
        endCharge: endCharge,
        connectLiveChargeSession: connectLiveChargeSession,
        isChargePointWaiting: isChargePointWaiting,
        checkChargeStatus: checkChargeStatus,
        sessionsLoad: sessionsLoad,
        preferenceToggle: preferenceToggle,
        marketingPreferenceToggle: marketingPreferenceToggle,
        unitToggle: unitToggle,
        paymentMethodToggle: paymentMethodToggle,
        languageToggle: languageToggle,
        sitesFilter: sitesFilter,
        sitesFilterByLocation: sitesFilterByLocation,
        mapsLoaded,
        reportFault: reportFault,
        sendFeedback: sendFeedback,
        searchLocations: searchLocations,
        vehicleAdd: vehicleAdd,
        vehicleRemove: vehicleRemove,
        inviteVerify: inviteVerify,
        inviteAccept: inviteAccept,
        inviteDecline: inviteDecline,
        distanceInKm: _distanceInKm,
        kmToMiles: _kmToMiles,
        topupRequest: topupRequest,
        wallet,
        userWalletGet: userWalletGet,
        userWalletTopup: userWalletTopup,
        userWalletRefund: userWalletRefund,
        userWalletRemoveCard: userWalletRemoveCard,
        userWalletSwitchCurrency: userWalletSwitchCurrency,
        RFIDPairingStart: RFIDPairingStart,
        RFIDPairingTag: RFIDPairingTag,
        RFIDPairingFinish: RFIDPairingFinish,
        RFIDDeleteTag: RFIDDeleteTag,
        downloadSessions: downloadSessions,
        currencies,
        requiresPushNotifications: requiresPushNotifications,
        requestPushNotifications: requestPushNotifications,
        setSessionsQuickMode: (mode) => {
          sessionsQuickMode.current = mode;
        },
      }}
    >
      <LoadScript
        googleMapsApiKey={config.MAPSKEY}
        id="map-script"
        version="weekly"
        onLoad={() => setMapsLoaded(true)}
      />
      {warnings.map((msg, idx) => (
        <WarningDiaog
          key={idx}
          open={true}
          close={() => removeWarning(idx)}
          msg={msg}
        />
      ))}
      {splash && <SplashScreen />}
      {!splash && props.children}
    </GlobalContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    sites: state.sites,
    account: state.account,
    localHistory: state.history,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
    logout: () => {
      dispatch(logout());
    },
    userValidate: () => {
      dispatch(userValidate());
    },
    userUpdateProfile: (payload) => {
      dispatch(userUpdateProfile(payload));
    },
    userClearAlert: () => {
      dispatch(userClearAlert());
    },
    sitesUpdate: (payload) => {
      dispatch(sitesUpdate(payload));
    },
    accountUpdate: (payload) => {
      dispatch(accountUpdate(payload));
    },
    historyUpdate: (payload) => {
      dispatch(historyUpdate(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalState);
