import Images from "../assets/images";

/*
*
*
*   This helper class just contains various none 
*   data or state modifying functions or properties
*
*/

class Helpers {

    
    constructor(){}
  
    //
    //  Return a Locale Date Object from a UTC SQL datetime string
    //
    static SQLtoUTCDate = (str) => {
        if(str.indexOf('+') > -1)
            return new Date(Date.parse(str));

        const d = str.split(/-|T|:|\./);
        return new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5]));
    }



    //
    // Array of month names in short form
    //
    static monthsShort = () => {
        return [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC'
        ]
    }



    //
    //  Object of marker types
    //  used in various places 
    //around the app
    //
    static markerTypes = {
        user: {
            image: Images.pins.user,
            label: 'YOU',
            color: '#484848'
        },
        open: {
            image: Images.pins.open,
            label: 'AVAILABLE',
            color: '#00adef'
        },
        multi: {
            image: Images.pins.multi,
            label: 'MULTIPLE',
            color: '#484848'
        },
        out: {
            image: Images.pins.out,
            label: 'PRIVATE',
            color: '#484848'
        },
        inuse: {
            image: Images.pins.inuse,
            label: 'IN_USE',
            color: '#526810'
        },
        fault: {
            image: Images.pins.fault,
            label: 'UNAVILABLE',
            color: '#ff0000'
        }
    }

    static injectSocketScript = async url => {
        return new Promise(async (resolve, reject) => {
            
            const id = 'backoffice-socket';
            const existingScript = document.getElementById(id);
            
            // Remove the existing script
            if(typeof existingScript != 'undefined' && existingScript !== null)
                existingScript.remove();
            
            
            const script = document.createElement('script')
    
            script.type = 'text/javascript'
            script.src = url
            script.id = id
            script.async = true
            script.onerror = (err) => {
                script.setAttribute('data-state', 'error')
                reject(err)
            }

            script.onload = () => {
                script.setAttribute('data-state', 'ready')
                resolve(id)
            }
        
            document.head.appendChild(script);

        }).catch(err => {
            console.error('inject socket script error: ', err)
            throw err
        })
    }

    static localeCurrency = (value, currency = 'GBP') => {
        return parseFloat(value).toLocaleString(navigator.language, {style: 'currency', currency: currency})
    }

}
export default Helpers;