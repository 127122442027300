import React from 'react'

export default function ElementWrapper({ label, isError, children }) {
    return (
        <div style={styles.formElementContainer}>
            {/* <label htmlFor="ukevNumber" style={styles.inputLabel}>{label}*</label> */}
            {isError && <span style={styles.inputErrorMsg}>{label} is required</span>}
            {children}
        </div>
    )
}

const styles = {
    inputWrapper: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: 8,
        justifyContent: 'center',
        display: 'flex',
        height: 48,
        border: 0,
    },
    inputLabel: {
        paddingRight: 4,
        paddingLeft: 4,
        fontWeight: 600,
        width: '100%',
    },
    input: {
        fontSize: 14,
        flex: 1,
        backgroundColor: 'transparent',
        border: 'none',
        border: 0,
        padding: 8,
    },
    inputError: {
        borderColor: '#bb0d0d',
        borderWidth: 1,
        boxShadow: 'inset 0 0 0 1px #bb0d0d'
    },
    inputErrorMsg: {
        width: '100%',
        color: '#bb0d0d',
        fontSize: 14,
        paddingLeft: 3,
        marginBottom: 4,
        marginTop: -8
    },
    formElementContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        width: '100%',
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    }
}