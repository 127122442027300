import React from 'react';
import Images from '../../assets/images/index';
import GlobalState from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import LoginScreen from '../login/LoginScreen';
import { Translation } from 'react-i18next';
import Helpers from '../../context/helpers';


const PaymentWindow = props => {

    return (
      <GlobalState.Consumer>
        {context => (
          <Translation>{t => 
          <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">

            <a href="JavaScript:void(0);" style={styles.close} onClick={props.closeConfirm}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>

            <div style={styles.header}>
              <span style={styles.headerTitle}>{t('SECURE_PAYMENT')}</span>
              {props.UnitOfMeasureType !== 'kwh' && <span style={styles.headerAmount}>{Helpers.localeCurrency(props.amount, props.currency)}</span>}
              {props.UnitOfMeasureType === 'kwh' && <span style={styles.authNotice}>{t('CHARGE_PAYMENT_KWH_NOTE', {amount: Helpers.localeCurrency(100, props.currency)})}</span>}
            </div>
            
            <div style={styles.iframeWrapper}>
              {props.paymentUrl && <iframe frameBorder="0" marginHeight="0" marginWidth="0" src={props.paymentUrl} width="100%" height="100%" onLoad={props.onChange}></iframe>}
            </div>
          </div>
          }</Translation>
        )}
      </GlobalState.Consumer>
    );

}

const styles = {
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerIn: {
      transform: 'translateY(0)'
  },
  containerOut: {
      transform: 'translateY(100%)',
      visibility: 'hidden'
  },
  close: {
    position: 'absolute',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 50,
    right: 20,
    cursor: 'pointer',
    zIndex: 90
  },
  iframeWrapper: {
    flex: 1,
    width: '90%',
    maxWidth: 400
  },
  header: {
    paddingTop: 70,
    width: '90%',
    maxWidth: 400
  },
  headerTitle: {
    display: 'block',
    color: '#484848',
    fontSize: 24,
    fontWeight: '600'
  },
  headerAmount: {
    display: 'block',
    color: '#484848',
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10
  },
  surcharge: {
    fontSize: 12,
    display: 'block',
    color: '#484848'
  },
  authNotice: {
    marginTop: 10,
    fontSize: 12,
    display: 'block',
    color: '#484848'
  },
  perkwh: {
    display: 'inline',
    fontSize: 12,
    color: '#484848',
    fontWeight: 600
  },

};


export default PaymentWindow;