import React, { useContext, useState } from 'react';
import GlobalContext from '../../context/global-context';
import Images from '../../assets/images';
import { withTranslation } from 'react-i18next';


const SelectKwhLimit = props => {

  const context = useContext(GlobalContext);
  const [ error, setError ] = useState(null);
  const { t } = props;
  const { code } = props;


  const handleChange = e => {
    if(e.target.value === '0'){
      props.onSetKwh('');
    }
    else {
      props.onSetKwh(e.target.value.toUpperCase());
    }
    setError(null);
  }


  const handleConfirm = () => {
    if(parseInt(props.kwh) === 0)
      handleClear();
    else{
      setError(null);
      context.limitCharge(code, props.kwh).then(
        res => {
          props.onClose();
        },
        err => {
          console.log(err);
          setError('Error setting the limit on your charge session, please try again');
          props.onSetKwh(null);
        }
      );
    }
  }

  const handleClear = () => {
    setError(null);
    context.limitCharge(code, 0).then(
      res => {
        props.onSetKwh(null);
        props.onClose();
      },
      err => {
        console.log(err);
        setError('Error clearing the limit on your charge session, please try again');
        props.onSetKwh(null);
      }
    );
  }


  return (
    <React.Fragment>
      <div style={Object.assign({}, styles.dialogBg, props.onShow && styles.dialogIn)} onClick={handleConfirm}></div>
      <div style={Object.assign({}, styles.dialog, props.onShow && styles.dialogIn)} className="animate">
        <div style={styles.dialogWrapper}>            

            <span style={styles.dialogHeader}>{t('ENTER_KWH_REQUIRED')}</span>

            <div style={styles.dialogBody}>

              <div style={styles.inputWrapper}>
                <input type={'number'} name={'kwh'} onChange={handleChange} style={styles.input} value={props.kwh===null?'':props.kwh} autoFocus />
                <span style={styles.kwhAdorn}>{t('KWH')}</span>
              </div>

              {error && <span style={styles.error}>{error}</span>}

              <div style={styles.dialogFooter}>
                <button style={styles.clear} onClick={handleClear}>{t('NO_LIMIT')}</button>
                <button style={styles.ok} onClick={handleConfirm}>{t('OK')}</button>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );

}

const styles = {
  dialogBg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '50%',
    left: '50%',
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: 989,
    transform: 'translate(-50%, 100%)',
    visibility: 'hidden',
    opacity: 0,
  },
  dialogIn: {
    transform: 'translate(-50%, -50%)',
    visibility: 'visible',
    opacity: 100,
  },
  dialog: {
    display: 'block',
    width: 280,
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: '#fff',
    boxShadow: '0 0 6px rgba(0,0,0,.3)',
    zIndex: 990,
    borderRadius: 12,
    transform: 'translate(-50%, 100%)',
    visibility: 'hidden',
    opacity: 0,
  },
  dialogWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },
  dialogHeader: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 20,
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    color: '#a9a9a9'
  },
  dialogBody: {
    flex: 1,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogUnit: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  dialogIcon: {
    marginRight: 20
  },
  inputWrapper: {
    height: 54,
    width: '100%',
    maxWidth: 140,
    borderRadius: 4,
    justifyContent: 'center',
    display: 'flex',
    border: '1px solid #929292'
  },
  kwhAdorn: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    width: 60,
    height: '100%',
    backgroundColor: '#484848',
    color: '#fff',
  },
  input: {
      backgroundColor: '#e0e0e0',
      textAlign: 'center',
      fontSize: 18, 
      flex: 1,
      color: '#484848',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      fontWeight: 900,
      minWidth: 60,
      textTransform: 'uppercase'
  },
  vehicleButton: {
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#6CB33F',
    fontWeight: 900,
    cursor: 'pointer'
  },
  vehicle: {
    padding: 8,
    marginBottom: 8,
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'row',
    color: '#6CB33F',
    fontWeight: 900,
    cursor: 'pointer',
    borderRadius: 6,
    flex: 1
  },
  reg: {
    flex: 1
  },
  model: {
    maxWidth: '50%',
    alignSelf: 'flex-end',
    color: '#848484',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ok: {
    width: 90,
    height: 30,
    backgroundColor: '#6CB33F',
    border: 'none',
    color: '#fff',
    borderRadius: 6,
    marginTop: 15,
    marginLeft: 5,
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  clear: {
    width: 100,
    height: 30,
    backgroundColor: '#484848',
    border: 'none',
    color: '#fff',
    borderRadius: 6,
    marginTop: 15,
    marginLeft: 5,
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  dialogFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  error: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    color: '#ff0000'
  },
};


export default withTranslation()(SelectKwhLimit);