import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../context/global-context';
import Images from '../../assets/images'
import QrReader from 'react-qr-reader'
import './styles.css';
import { useHistory } from 'react-router-dom';

import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Device } from '@capacitor/device';


const QrReaderScreen = props => {

  const context = useContext(GlobalContext);
  const history = useHistory();

  const [mode, setMode] = useState(null);
  const [result, setResult] = useState(null);

  const handleScan = data => {
    if (data) {
      if(data.indexOf('https://app.vendelectric.com/') > -1)
        setResult(data)
    }
  }

  const handleError = err => {
    console.error(err)
  }

  const handleClose = e => {
    e.preventDefault();
    if(typeof props.onPressClose === 'function')
      props.onPressClose();
  }
  
  const startNativeScan = async () => {
    const status = await BarcodeScanner.checkPermission({ force: true });
    if(status.granted === true){
    
      BarcodeScanner.hideBackground(); // make background of WebView transparent
    
      const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
    
      // if the result has content
      if (result.hasContent) {
        console.log(result.content); // log the raw scanned content
        stopNativeScan();
        handleScan(result.content);
      }

    } else {

      console.log('Camera permissions not granted');

    }
  };

  const stopNativeScan = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  }


  useEffect(() => {

    console.log(result);
    if(result !== null && typeof result === 'string'){
      const path = result.replace('https://app.vendelectric.com', '');
      history.push(path);
    }

  }, [result])

  useEffect(() => {

    const checkMode = async () => {
      const info = await Device.getInfo();
      if(['android', 'ios'].indexOf(info.platform) > -1){
        setMode('native');
        startNativeScan();
      } else
        setMode('web')
    }
    checkMode();

    return () => {
      if(mode === 'native')
        stopNativeScan();
    }

  }, [])


  return (
    <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">

      <button style={styles.close} onClick={handleClose}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>

      {(props.show && mode === 'native') &&
        <img src={Images.scan_bg} style={styles.scanSurround} />
      }

      <div className={'qrHeader'}>
        <h2 className={'qrHeading'}>Scan QR Code</h2>
        <p className={'qrSubheading'}>Place the QR code within the white frames</p>
      </div>

      {(props.show && mode === 'web') && 
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
          facingMode={'environment'}
          className={'qrScanOverride'}
        />
      }

    </div>
  );

}


const styles ={
  container: {
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: 'transparent',
      height: '100%',
      width: '100%',
      display: 'block',
      overflow: 'auto',
      zIndex: '102'
  },
  containerIn: {
      transform: 'translateY(0)'
  },
  containerOut: {
      transform: 'translateY(100%)',
      visibility: 'hidden'
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  close: {
    position: 'absolute',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 50,
    right: 20,
    cursor: 'pointer',
    zIndex: 999,
    backgroundColor: 'transparent'
  },
  scanSurround: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    cursor: 'pointer',
    zIndex: 999,
    backgroundColor: 'transparent',
    transform: 'translate(-50%, -85%)',
  }
};


export default QrReaderScreen;