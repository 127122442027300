import React from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api'
import GlobalState from '../../context/global-context';
import CustomStyle from './MapStyle';
import Images from '../../assets/images';
import LocationSummaryPopUp from './LocationSummaryPopUp';
import LocationMarkerSelectionPopUp from './LocationMarkerSelectionPopUp';
import Helpers from '../../context/helpers';


export class MapContainer extends React.Component {
  static contextType = GlobalState;
  
  
  constructor(props){
    super(props);

    this.state = {
      mapLoaded: false,
      activeMarker: null,
      selectedPlace: null,
      showingInfoWindow: false,
      showingLocationSummary: false,
      selectablePlaces: null,
      showingSelectionWindow: false,
    }
  }

  onMarkerPressHandler = (props, marker, e, point) => {

    this.setState({
      activeMarker: marker,
      selectedPlace: this.context.sites.find(p => p.id === point.id),
      showingInfoWindow: true,
      selectablePlaces: null,
      showingSelectionWindow: false
    }, () => {
      this.context.fetchSite(point.id);
    });

    if(!this.props.fullScreen)
      this.props.onPressMap();
  }

  selectLocation = (point) => {
    this.setState({
      activeMarker: null,
      selectedPlace: this.context.sites.find(p => p.id === point.id),
      showingInfoWindow: true,
      selectablePlaces: null,
      showingSelectionWindow: false
    });

    if(!this.props.fullScreen)
      this.props.onPressMap();
  }

  onCloseInfoWindowHandler = () => {
    this.setState({
      activeMarker: null,
      selectedPlace: null,
      showingInfoWindow: false,
      selectablePlaces: null,
      showingSelectionWindow: false
    });
  }

  onPressMapHandler = () => {
    this.props.onPressMap();
    this.onCloseInfoWindowHandler();
  }
  
  componentDidMount(){
    this.context.focusOnUserLocation();
  }

  clusterStyle = [{
      url: Helpers.markerTypes.multi.image,
      height: 50,
      width: 34,
      textColor: '#fff',
      textSize: 18,
      anchor: [17, 50], 
      anchorText: [-5, 0]
    }]

  getMarkerType = (site) => {

    if(site.privacy.public === false && site.connectors_stats.available_count > 0)
      return Helpers.markerTypes.out.image

    if(site.connectors_stats.available_count > 0)
      return Helpers.markerTypes.open.image;

    return Helpers.markerTypes.inuse.image;
  }

  clusterClickHandler = (e) => {
    if(!this.props.fullScreen)
      this.props.onPressMap();

    const lat = e.center.lat();
    const lng = e.center.lng();
    const clusters = e.markerClusterer.clusters;
    if(clusters.length === 1 && clusters[0].markers.length > 1){
      this.onCloseInfoWindowHandler();

      let select = true;
      clusters[0].markers.map(m => {
        if(lat !== m.position.lat())
          select = false;
        if(lng !== m.position.lng())
          select = false;
      })

      if(select){
        this.setState({
          selectablePlaces: this.context.sitesFilterByLocation(lat, lng),
          showingSelectionWindow: true
        });
      }
    }
  }

  renderMap = () => {
    return (
      <GoogleMap
        id='map'
        zoom={10}
        center={this.context.camera}
        mapContainerStyle={styles.map}
        onClick={this.onPressMapHandler}
        options={{
          styles: CustomStyle,
          disableDefaultUI: true,
          maxZoom: 18,
          gestureHandling: 'greedy'
        }}
        google={this.props.google}
      >
        
        <MarkerClusterer options={{imagePath:"../../assets/images/m/m", styles: this.clusterStyle}} onClick={this.clusterClickHandler} gridSize={2}>
        {
          (clusterer) => this.context.sites.filter(this.context.sitesFilter).map((site) => (<Marker key={site.id} position={{lat: parseFloat(site.location.latitude), lng: parseFloat(site.location.longitude)}} icon={{url: this.getMarkerType(site), anchor: new window.google.maps.Point(17, 50), scaledSize: new window.google.maps.Size(34,50)}} onClick={(props, marker, e) => {this.onMarkerPressHandler(props, marker, e, site)}} clusterer={clusterer} site={site} />))
        }
        </MarkerClusterer>
        
        <Marker 
          position={this.context.location} 
          icon={{
            url: Images.pins.user, 
            anchor: new window.google.maps.Point(16,16), 
            scaledSize: new window.google.maps.Size(32,32)}}
        />
        
      </GoogleMap>)
  }

  render() {
    return (
      <div style={styles.map}>
        {this.context.mapsLoaded && this.renderMap()}

      {this.state.selectedPlace && <LocationSummaryPopUp site={this.state.selectedPlace} visible={this.state.showingInfoWindow} goToLocation={this.props.goToLocation} history={this.props.history}/>}
      
      {this.state.selectablePlaces && <LocationMarkerSelectionPopUp sites={this.state.selectablePlaces} visible={this.state.showingSelectionWindow} selectLocation={this.selectLocation} history={this.props.history}/>}
      </div>
    );
  }
}

export default MapContainer;



const styles = {
  map: {
    width: '100%',
    height: '100%'
  },
  markerCallout: {
      borderWidth: 2,
      borderColor: '#acd448',
      borderRadius: 12,
      backgroundColor: '#000000'
  },
};