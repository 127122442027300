import React from 'react';
import images from '../../assets/images';
import './SplashScreen.css';
import ActivityIndicator from './ActivityIndicator';

const SplashScreen = props => {

    return (
        <div style={styles.container}>
            <div style={styles.veLogo}>
                <img src={images.logo_icon} style={styles.veIcon} className="logo-icon"/>
                <img src={images.logo_text} style={styles.veText} className="logo-text" />
            </div>
            <div className='splash-loading'>
                <ActivityIndicator />
            </div>
            <div style={styles.powered}>
                <img src={images.rolec_powered} style={styles.poweredLogo} className='logo-powered'/>
            </div>
        </div>
    )
}

const styles = {
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  veLogo: {
    display:'flex',
      height: 60,
      width: 257,
      marginBottom: 20,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
  },
  veIcon: {
      height: 60,
      width: 32,
      position: 'absolute',
  },
  veText: {
      height: 60,
      width: 225,
      position: 'absolute',
  },
  powered: {
    position: 'absolute',
    bottom: 40,
    right: 30,
    width: 115,
    height: 26
  },
  poweredLogo: {
      width: 115,
      height: 26
  }
};

export default SplashScreen;
