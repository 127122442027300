import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { userValidate } from "./actions/userActions";
import GlobalState from "./context/GlobalState";
import GlobalContext from "./context/global-context";
import HomeScreen from "./components/home/HomeScreen";
import FavoritesScreen from "./components/favorites/FavoritesScreen";
import SessionsScreen from "./components/sessions/SessionsScreen";
import StatisticsScreen from "./components/statistics/StatisticsScreen";
import LocationScreen from "./components/location/LocationScreen";
import FaultScreen from "./components/fault/FaultScreen";
import NavDrawerComponent from "./components/nav/NavDrawerComponent";
import QrRedirectScreen from "./components/qr/QrRedirectScreen";
import QrReaderScreen from "./components/qr/QrReaderScreen";
import ProfileScreen from "./components/profile/ProfileScreen";
import PreferencesScreen from "./components/profile/PreferencesScreen";
import LogoutScreen from "./components/login/LogoutScreen";
import LoginScreen from "./components/login/LoginScreen";
import ResetPasswordScreen from "./components/login/ResetPasswordScreen";
import ConfirmAccountScreen from "./components/login/ConfirmAccountScreen";
import VehiclesScreen from "./components/profile/VehiclesScreen";
import FeedbackScreen from "./components/feedback/FeedbackScreen";
import InviteScreen from "./components/invite/InviteScreen";
import GetStartedScreen from "./components/getstarted/GetStartedScreen";
import NotFoundScreen from "./components/notfound/NotFoundScreen";
import SocketScreen from "./components/socket/SocketScreen";
import AppStoreScreen from "./components/appstore/AppStoreScreen";

const App = (props) => {
  return (
    <BrowserRouter>
      <SnackbarProvider
        dense={true}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        preventDuplicate
      >
        <GlobalState>
          <GlobalContext.Consumer>
            {(context) => (
              <React.Fragment>
                <main className={"app " + (context.isMenuOpen ? "isOpen" : "")}>
                  <div className="navbg" onClick={context.closeMenu}></div>
                  <Switch>
                    <Route path={"/"} exact component={HomeScreen} />
                    <Route path={"/location/:id"} component={LocationScreen} />
                    <Route
                      path={"/point/:site/:point/:connector/:paygcode"}
                      component={SocketScreen}
                    />
                    <Route
                      path={"/point/:site/:point/:connector"}
                      component={SocketScreen}
                    />
                    <Route
                      path={"/point/:site/:point"}
                      component={SocketScreen}
                    />
                    <Route path={"/favorites"} component={FavoritesScreen} />
                    <Route path={"/sessions"} component={SessionsScreen} />
                    <Route path={"/statistics"} component={StatisticsScreen} />
                    <Route path={"/login"} component={LoginScreen} />
                    <Route path={"/account"} component={ProfileScreen} />
                    <Route
                      path={"/preferences"}
                      component={PreferencesScreen}
                    />
                    <Route path={"/logout"} component={LogoutScreen} />
                    <Route path={"/vehicles"} component={VehiclesScreen} />
                    <Route path={"/feedback"} component={FeedbackScreen} />
                    <Route path={"/fault/:id"} component={FaultScreen} />
                    <Route path={"/invite/:token"} component={InviteScreen} />
                    <Route path={"/reset"} component={ResetPasswordScreen} />
                    <Route path={"/verify"} component={ConfirmAccountScreen} />
                    <Route path={"/getstarted"} component={GetStartedScreen} />
                    <Route path={"/app-store"} component={AppStoreScreen} />
                    <Route path={"/qr/scan"} component={QrReaderScreen} />
                    <Route
                      path={"/qr/:point/:connector"}
                      component={QrRedirectScreen}
                    />
                    <Route path={"/qr/:point"} component={QrRedirectScreen} />
                    <Route path="*" component={NotFoundScreen} />
                  </Switch>
                </main>
                <NavDrawerComponent />
              </React.Fragment>
            )}
          </GlobalContext.Consumer>
        </GlobalState>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userValidate: () => {
      dispatch(userValidate());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
