import React from 'react'
import Header from '../../global/Header'
import images from '../../../assets/images'
export default function PageLayout({ children, title, history }) {
    return (
        <div style={styles.container}>
            <Header title={title} history={history} />
            <div style={styles.wrapper}>
                <div style={styles.formWrapper}>
                    {children}
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: '#fff',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    wrapper: {
        backgroundColor: '#222',
        minHeight: '100%',
        width: '100%',
        justifyContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        position: 'relative',
        backgroundImage: 'url(' + images.dark_bg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        overflowY: 'auto',
    },
    formWrapper: {
        width: '90%',
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
    },
}
