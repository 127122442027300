import React from 'react';
import Images from '../../assets/images';
import GlobalContext from '../../context/global-context';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';

const LocationSummary = props => {

    const navigate = (context) => {

        if(typeof window.webViewBridge !== 'undefined'){
            const navParams = {
                origin: {
                    latitude: context.location.lat,
                    longitude: context.location.lng
                },
                destination: {
                    latitude: props.site.location.latitude,
                    longitude: props.site.location.longitude
                }
            }
            window.webViewBridge.send('navigateTo', navParams, true, false);
        } else {
            window.open('https://www.google.com/maps/dir/?api=1&parameters&origin='+context.location.lat+','+context.location.lng+'&destination='+props.site.location.latitude+','+props.site.location.longitude)
        }
   
        
    }

    const favoriteToggle = (e, context) => {
        e.stopPropagation(); 
        if(context.isLoggedIn){
            props.site.isFavorite = !props.site.isFavorite;
            context.favoriteToggle(props.site);
        } else {
            props.history.push('/Login');
        }
    }
    
    const paidAvailable = typeof props.site.connectors_stats !== 'undefined'?props.site.connectors_stats.paid_vend_available:false;
    const freeAvailable = typeof props.site.connectors_stats !== 'undefined'?props.site.connectors_stats.free_vend_available:false;
    const availableCount = typeof props.site.connectors_stats !== 'undefined'?props.site.connectors_stats.available_count:0;

    return (

        <GlobalContext.Consumer>
        {context => (
            <Translation>{t => 
            <div style={styles.container}>
                <div style={styles.summary}>
                <div style={styles.left}>
                    <div style={styles.distance}>
                        <span style={styles.distanceValue}>{props.site.distance}</span>
                        <span style={styles.distanceLabel}>{t(props.site.distanceLabel.toUpperCase())}</span>
                    </div>
                    <a href="JavaScript:void(0);" onClick={() => {navigate(context)}} style={styles.navigate}>
                        <img src={Images.navigate} style={{width: 32, height: 32}} alt="Navigate" />
                        <span style={styles.navigateLabel}>{t('NAVIGATE')}</span>
                    </a>
                </div>
                <div style={styles.right}>
                    <div style={styles.location}>
                        <span style={styles.locationtitle}>{props.site.name}</span>
                        <span style={styles.locationaddress}>{context.getSiteAddress(props.site.id)}</span>
                    </div>
                    <div style={styles.attributes}>
                        {paidAvailable && <div style={styles.attribute}><img src={Images.paid} style={{width: 24, height: 16, marginRight: 8}} alt="Paid" /><span style={styles.attributetitle}>{t('PAID')}</span></div>}
                        {freeAvailable && <div style={styles.attribute}><img src={Images.free} style={{width: 16, height: 16, marginRight: 8}} alt="Free" /><span style={styles.attributetitle}>{t('FREE')}</span></div>}
                        <div style={styles.attribute}>
                            {(props.lowestPrice && props.lowestPrice > 0.01) && <span style={styles.attributetitle}>{t('FROM')} {parseFloat(props.lowestPrice).toLocaleString(navigator.language, {style: 'currency', currency: props.lowestPriceCurrency})}/{t(props.lowestPriceTypeDesc.toUpperCase())}</span>}
                        </div>
                    </div>
                    <div style={styles.socketinfo}>
                    <div style={styles.info}><span style={styles.infotitle}>{availableCount} {t('SOCKETS_AVAILABLE')}</span></div>
                    </div>
                    <div style={styles.actions}>
                        <a href="JavaScript:void(0);" style={styles.action} onClick={(e) => {favoriteToggle(e, context)}}>{context.isFavorite(props.site.id) ? <img src={Images.starBlue} style={{width: 16, height: 16, marginRight: 8}} alt="is favorite" />:<img src={Images.star} style={{width: 16, height: 16, marginRight: 8}} alt="is favorite" />}<span style={Object.assign({}, styles.actiontitle, props.site.isFavorite ? styles.actionActiveTitle: {})}>{t('ADD_TO_FAVOURITES')}</span></a>
                        <Link to={'/fault/'+props.site.id} onClick={e => e.stopPropagation()} style={styles.action}><img src={Images.warning} style={{width: 16, height: 16, marginRight: 8}} alt="Report a fault" /><span style={styles.actiontitle}>{t('REPORT_FAULT')}</span></Link>
                    </div>
                </div>
                </div>
            </div>
            }</Translation>
        )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    container: {
      display: 'flex',
      paddingTop: 20,
      paddingBottom: 20,
    },
    summary: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    left: {
        flex: 1,
        height: '100%',
        maxWidth: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    distance: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    distanceValue: {
        color: '#6CB33F',
        fontSize: 30,
        fontWeight: 'bold'
    },
    distanceLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    navigate: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    navigateLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    location: {
        marginBottom: 15,
        paddingRight: 15
    },
    locationtitle: {
        color: '#484848',
        fontSize: 20,
        fontWeight: 900,
        display: 'block'
    },
    locationaddress: {
        color: '#888888',
        fontSize: 14
    },
    attributes: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        display: 'flex',
        marginBottom: 15
    },
    attribute: {
        marginRight: 15,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    attributetitle: {
        color: '#484848',
        fontSize: 16,
        fontWeight: 'bold'
    },
    socketinfo: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        display: 'flex',
        marginBottom: 15
    },
    info: {
        marginRight: 15
    },
    infotitle: {
        color: '#484848',
        fontSize: 16,
        fontWeight: 'bold'
    },
    actions: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    action: {
        display: 'flex',
        marginRight: 15,
        flexDirection: 'row',
        alignItems: 'center'
    },
    actiontitle: {
        color: '#a2a2a2',
        fontSize: 14
    },
    actionActiveTitle: {
        color: '#6CB33F',
        fontSize: 14
    }
  };

export default LocationSummary;

