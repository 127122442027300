import React from 'react';
import Images from '../../assets/images/index';
import { Link } from 'react-router-dom';

import { Translation } from 'react-i18next';

const homeFooter = props => {

    return (
        <Translation>
        {
        t => 
        <div style={{bottom: props.bottom, left: 0, position: 'absolute', height: 156, width: '100%', zIndex: 98, display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}} className="animate">
            <div style={styles.footerContainer}>
                <div style={styles.footerBlock}>
                <Link to="/favorites" style={styles.footerButton}>
                    <span style={styles.footerButtonContent}>
                        <img style={styles.footerButtonIcon} src={Images.homefooter.favorites} alt="Favorites" />
                        <span style={styles.footerButtonText}>{t('HOME_BTN_FAV')}</span>
                    </span>
                </Link>
                <Link to="/account" style={styles.footerButton}>
                    <span style={styles.footerButtonContent}>
                        <img style={styles.footerButtonIcon} src={Images.homefooter.profile} alt="Profile" />
                        <span style={styles.footerButtonText}>{t('HOME_BTN_ACC')}</span>
                    </span>
                </Link>
                </div>
                <div style={styles.footerBlock}>
                <Link to="/sessions" style={styles.footerButton}>
                    <span style={styles.footerButtonContent}>
                        <img style={styles.footerButtonIcon} src={Images.homefooter.sessions} alt="Charge sessions" />
                        <span style={styles.footerButtonText}>{t('HOME_BTN_CHA')}</span>
                    </span>
                </Link>
                <Link to="/statistics" style={styles.footerButton}>
                    <span style={styles.footerButtonContent}>
                        <img style={styles.footerButtonIcon} src={Images.homefooter.stats} alt="Statistics" />
                        <span style={styles.footerButtonText}>{t('HOME_BTN_STA')}</span>
                    </span>
                </Link>
                </div>
            </div>
        </div>
        }
        </Translation>
    );
}

const styles = {
    footerContainer: {
        maxHeight: 136,
        width: '100%',
        flexDirection: 'row',
        flexWrap:'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    footerBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap:'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerButton: {
        width: 125,
        height: 55,
        margin: 4,
        backgroundColor: '#484848',
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    footerButtonContent: {
        flex: 1,
        flexDirection: 'row',
        flexWrap:'wrap',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    footerButtonText: {
        color: '#ffffff',
        fontSize: 16
    },
    footerButtonIcon: {
        height: 16,
        width: 40
    }
};

export default homeFooter;