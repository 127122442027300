import React from 'react';
import Images from '../../assets/images/index';
import Header from '../global/Header';
import GlobalContext from '../../context/global-context';
import isAuth from '../login/IsAuth';
import ActivityIndicator from '../global/ActivityIndicator';

import AddVehicleScreen from './AddVehicleScreen';
import { Translation } from 'react-i18next';


class VehiclesScreen extends React.Component {

  static contextType = GlobalContext

  constructor(props){
    super(props);

    this.state = {
      showAdd: false,
      removing: false
    }
  }

  onShowAddVehicleHandler = () => {
    this.setState({showAdd: true})
  }

  onCloseddVehicleHandler = () => {
    this.setState({showAdd: false})
  }

  removeVehicle = (vehicle) => {
    this.setState({removing: vehicle.reg}, () => {
      this.context.vehicleRemove(vehicle).then(
        res => {
          this.setState({removing: false});
        },
        err => {
          this.setState({removing: false});
        }
      )
    })
  }

  componentDidMount(){
  }

  vehicles = () => {
    return this.context.account.vehicles.map(vehicle => (
      <div style={styles.vehicle} key={vehicle.reg}>
        <div style={styles.icon}>
          <a href="JavaScript:void(0);" onClick={() => {this.context.switchVehicle(vehicle.reg)}}>{vehicle.use?<img src={Images.bullet_full} style={{width: 20, height: 20}} alt="Bullet" />:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}</a>
        </div>
        <div style={styles.detail}>
            <span style={styles.model}>{vehicle.make + ' ' + vehicle.model}</span>
            <span style={styles.reg}>{vehicle.reg}</span>
        </div>
        {!this.state.removing && <a href="JavaScript:void(0);" style={styles.icon} onClick={() => {this.removeVehicle(vehicle)}}><img src={Images.remove_btn} style={{ width: 20, height: 20 }} alt="Remove" /></a>}
        {this.state.removing === vehicle.reg && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
      </div>
    ));
  }

  render() {
    const { navigation } = this.props;
    return (
        <Translation>{t => 
          <div style={styles.container}>
            <Header title={t('TITLE_VEHICLES')} history={this.props.history} />
            <div style={styles.wrapper}>
              {this.vehicles()}
              {this.context.account.vehicles.length === 0 && 
                <div style={styles.emptyMsg}><span style={{maxWidth: '80%'}}>{t('NO_VEHICLES')}</span></div>
              }

              <div style={styles.addVehicle}>
                <a href="JavaScript:void(0);" style={styles.addVehicleButton}>
                <span style={styles.addVehicleButtonText} onClick={this.onShowAddVehicleHandler}>{t('ADD_VEHICLE')}</span>
                </a>
              </div>
            </div>

            <AddVehicleScreen show={this.state.showAdd} close={this.onCloseddVehicleHandler} />
          </div>
      }</Translation>
    );
  }

}


const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'block',
    overflow: 'auto',
  },
  vehicle: {
    height: 100,
    flex: 1,
    flexWrap:'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: '#e8e8e8',
    display: 'flex',
    cursor: 'pointer'
  },
  icon: {
      width: '20%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
  },
  detail: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      justifyContent: 'center',
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 20
  },
  model: {
      flex: 1,
      color: '#484848',
      fontSize: 20
  },
  reg: {
      flex: 1,
      color: '#484848',
      fontSize: 14
  },
  emptyMsg: {
    display: 'flex',
    height: 40,
    marginTop: 40,
    marginBottom: 40,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#888'
  },
  addVehicle: {
    display: 'flex',
    height: 40,
    marginTop: 40,
    marginBottom: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  addVehicleButton: {
    backgroundColor: '#464646',
    height: 40,
    minWidth: 80,
    padding: '0 10px',
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  addVehicleButtonText: {
    color: '#fff',
    textAlign: 'center'
  },
  loadingWrapper: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#d4d4d4',
    opacity: .7,
    borderRadius: 25,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};


export default isAuth(VehiclesScreen);