import React, { useContext, useEffect, useState } from "react";
import Images from "../../assets/images/index";
import Header from "../global/Header";
import GlobalContext from "../../context/global-context";
import ActivityIndicator from "../global/ActivityIndicator";
import Helpers from "../../context/helpers";
import globalContext from "../../context/global-context";
import { Link, useHistory } from "react-router-dom";
import images from "../../assets/images";
import { useTranslation } from "react-i18next";

//let paygTicker;

const QrRedirectScreen = (props) => {
  const context = useContext(globalContext);
  const history = useHistory();
  const { t } = useTranslation();

  const pointId = props.match.params.point;
  const connectorId =
    typeof props.match.params.connector !== "undefined"
      ? props.match.params.connector
      : 1;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    //
    //
    //  If the point id is an integer and less than 600 then we assume Group Manager
    //
    //
    if (!isNaN(parseInt(pointId)) && parseInt(pointId) < 600)
      setError("GM_NOT_SUPPORTED");
    else findAndRedirect();
  }, []);

  const findAndRedirect = () => {
    setLoading(true);

    //
    //
    //
    context.searchLocations(pointId).then(
      (res) => {
        // Loop though the results and find the matching point
        if (res.points !== "undefined" && res.points.length > 0) {
          res.points.map((p, idx) => {
            if (
              p.pointId === pointId ||
              p.pointName === pointId ||
              p.pointName === `${pointId}-${connectorId}` ||
              p.pointName === sanitizePointFormat(pointId)
            )
              history.replace("/point/" + p.siteId + "/" + p.pointId);

            if (idx === res.points.length - 1) {
              setLoading(false);
              setError("QR_CHARGE_POINT_NOT_FOUND");
            }
          });
        } else setError("QR_CHARGE_POINT_NOT_FOUND");

        //throw error
      },
      (err) => {
        //throw error
        setError("QR_CHARGE_POINT_NOT_FOUND");
      },
    );
  };

  const sanitizePointFormat = (pointId) => {
    if (
      pointId.toUpperCase().indexOf("UKEV") !== 0 ||
      pointId.indexOf("-") === -1
    )
      return pointId;

    let bits = pointId.split("-");
    return `${bits[0]}-${
      typeof bits[1] !== "undefined" ? parseInt(bits[1]) : "1"
    }`;
  };

  const onClickCloseHandler = () => {
    if (history.action === "POP") {
      history.push("/");
    } else {
      history.goBack();
    }
  };

  return (
    <div style={styles.container}>
      <a
        href="JavaScript:void(0);"
        style={styles.close}
        onClick={onClickCloseHandler}
      >
        <img
          src={Images.close_btn}
          style={{ width: 30, height: 30 }}
          alt="Close"
        />
      </a>

      <div style={styles.wrapper}>
        {loading && (
          <div style={styles.loading}>
            <ActivityIndicator />
          </div>
        )}
        {error && (
          <React.Fragment>
            <img
              src={images.fault}
              alt="charge point not found"
              style={{ height: 160, width: "auto" }}
            />
            <span
              style={{
                fontSize: 20,
                textAlign: "center",
                marginBottom: 4,
                fontWeight: 600,
              }}
            >
              {t("QR_CHARGE_POINT_NOT_FOUND")}
            </span>
            <span
              style={{ fontSize: 14, textAlign: "center", marginBottom: 14 }}
            >
              {t("QR_CHARGE_POINT_NOT_FOUND_DESC")}
            </span>
            {!context.isLoggedIn && (
              <React.Fragment>
                <span
                  style={{
                    fontSize: 14,
                    marginBottom: 4,
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  {t("QR_LOGIN")}
                </span>
                <span style={{ fontSize: 11, textAlign: "center" }}>
                  {t("QR_LOGIN_DESC")}
                </span>
                <Link to="/login" style={styles.buttonPrimary}>
                  Login
                </Link>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    alignItems: "center",
  },
  wrapper: {
    display: "flex",
    height: "100%",
    width: "80%",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  loading: {
    backgroundColor: "#6CB33F",
    opacity: 0.7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  close: {
    position: "absolute",
    height: 50,
    width: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 50,
    right: 20,
    cursor: "pointer",
    zIndex: 90,
    color: "#6CB33F",
  },
  payg: {
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  buttonPrimary: {
    backgroundColor: "#6CB33F",
    height: 36,
    minWidth: 80,
    padding: "0 10px",
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    lineHeight: 1,
    color: "#fff",
    textAlign: "center",
    marginTop: 10,
    border: "none",
    fontSize: 16,
    marginLeft: 5,
    marginRight: 5,
  },
};

export default QrRedirectScreen;
