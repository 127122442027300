import React from 'react';

const TextField = props => {

  const {field, onChange} = props;

  return (
    <div style={styles.inputContainer}>

        <label htmlFor={field.name} style={styles.inputLabel}>{field.label}{field.required && '*'}</label>
        <div style={Object.assign({}, styles.inputWrapper, field.invalid && styles.inputError)}>
            <input name={field.name} onChange={onChange} style={styles.input} value={field.value} />
        </div>
        {field.invalid && <span style={styles.inputErrorMsg}>{field.error}</span>}

    </div>
  )
}

const styles = {
  inputContainer: {
    marginBottom: 14
  },
  inputWrapper: {
      height: 48,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 8,
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column'
  },
  input: {
      fontSize: 16,
      flex: 1,
      paddingLeft: 20
  },
  inputError: {
    boxShadow: 'inset 0 0 0 1px #F26A6A'
  },
  inputErrorMsg: {
    width: '100%',
    color: '#F26A6A',
    fontSize: 11,
    paddingLeft: 20,
    marginBottom: 8,
    marginTop: -8
  },
  inputLabel: {
    display: 'block',
    fontSize: 11,
    paddingBottom: 4
  }
}

export default TextField