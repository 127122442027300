import React from 'react';
import './Switch.css';


const globalSwitch = props => {

    return (
        <label className="switch" htmlFor={props.id}>
            <input type="checkbox" checked={props.checked} onChange={props.onChange} name={props.id} id={props.id} />
            <span className="slider round"></span>
        </label>
    );
}

export default globalSwitch;