import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Images from "../../../assets/images";
import globalContext from "../../../context/global-context";

const RFIDPushNotice = props => {

    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [showNotice, setShowNotice] = useState(false);
    const [showHelp, setShowHelp] = useState(false);

    const requestPushNotifications = async () => {
        const hasGranted = await context.requestPushNotifications();
        setShowHelp(!hasGranted);
        if(hasGranted)
            setShowNotice(false);
    }

    const handleIgnore = () => {
        setShowNotice(false);
    }

    const handleClose = () => {
        setShowHelp(false);
    }

    const checkPushNotifications = async () => {
        const needsNotice = await context.requiresPushNotifications();
        setShowNotice(needsNotice);
    }

    useState(() => {

        window.addEventListener('awake', () => checkPushNotifications());
        checkPushNotifications();

        return () => {
            window.removeEventListener('awake', null, false);
        }

    }, [])

    return (
        <React.Fragment>
            {showNotice && 
                <div style={styles.notice}>
                    <div style={styles.notice_body}>
                        <img src={Images.warning_icon} alt="Push Notice" style={styles.notice_icon} />
                        <div>
                            <span>{t('PUSH_NOTIFICATIONS_NOTICE')}</span>
                            <div style={styles.notice_buttons}>
                                <button onClick={handleIgnore} style={styles.notice_button}>{t('NO_THANKS')}</button>
                                <button onClick={requestPushNotifications} style={styles.notice_button_primary}>{t('ACCEPT')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showHelp === true && <div style={styles.dialogBg} onClick={handleClose}></div>}
            {showHelp && 
                <div style={styles.dialog} className="animate">
                    <div style={styles.dialogWrapper}>
                        <span style={styles.dialogHeader}><img src={Images.help} alt="Help" style={styles.dialogIcon}/>{t('PUSH_NOTIFICATIONS_HELP_HEADER')}</span>
                        <div style={styles.dialogBody}>
                            <p style={styles.subtle}>{t('PUSH_NOTIFICATIONS_HELP_DESC')}</p>
                            <button onClick={handleClose} style={styles.notice_button_primary}>{t('OK')}</button>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

const styles = {
    notice: {
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #cca200',
      backgroundColor: '#f6f2e3',
      borderRadius: 3,
      marginTop: 5,
      marginBottom: 10
    },
    notice_body: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      padding: 12,
      color: '#484848',
      alignItems: 'flex-start',
      gap: '15px'
    },
    notice_icon: {
        width: 32,
        height: 'auto'
    },
    notice_buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '10px'
    },
    notice_button: {
        backgroundColor: '#464646',
        height: 36,
        minWidth: 60,
        padding: '0 14px',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        lineHeight: 1,
        color: '#fff',
        textAlign: 'center',
        marginTop: 10,
        border: 'none',
        fontSize: 14
    },
    notice_button_primary: {
      backgroundColor: '#6CB33F',
      height: 36,
      minWidth: 60,
      padding: '0 14px',
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 14,
    },
    dialogBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 989
    },
    dialog: {
      display: 'block',
      width: 280,
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: '#fff',
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      transform: 'translate(-50%, -50%)',
      visibility: 'visible',
      opacity: 100,
      zIndex: 990,
      borderRadius: 6
    },
    dialogWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column'
    },
    dialogHeader: {
      fontSize: 18,
      fontWeight: 900,
      marginTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
      color: '#404040'
    },
    dialogBody: {
      flex: 1,
      padding: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    dialogUnit: {
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    },
    dialogIcon: {
      marginRight: 10,
      width: 30,
      height: 30
    },
    para: {
        color: '#101010',
        lineHeight: 1.3,
        fontSize: 14,
        margin: 0,
        marginBottom: 10
    },
}


export default RFIDPushNotice;