import React from 'react';
import Images from '../../assets/images/index';
import Header from '../global/Header';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import Helpers from '../../context/helpers';
import isAuth from '../../components/login/IsAuth';
import { Translation } from 'react-i18next';


class ConfirmAccountScreen extends React.Component {

  static contextType = GlobalContext

  constructor(props){
    super(props);

    this.state = {
      token: this.props.location.search.replace('?token=', ''),
      loading: true,
      isVerified: false,
      isError: false
    }
  }

  componentDidMount(){
  }

  onHome = () => {
    this.props.history.push('/');
  }

  isVerified = () => {
    if(this.state.isVerified){
      return true;
    }
    
    if(!this.state.isError) {

      console.log('trying to verify');
      if(this.state.token.length > 0){
        this.context.userVerifyAccount(this.state.token).then(
          res => {
            if(res === 'Error'){
              this.setState({loading: false, isVerified: false, isError: true});
            } else {
              this.setState({loading: false, isVerified: true, isError: false});
            }
          },
          err => {
            this.setState({loading: false, isVerified: false, isError: true});
          }
        )
      } else {
        this.setState({loading: false, isVerified: false, isError: true});
      }
    
    }

    return false;
  }


  render() {
    this.context.isLoggedIn && this.isVerified()
    return (
      <Translation>{t =>
        <div style={styles.container}>
          <Header title={'Confirm Account'} history={this.props.history} showBack={false} />
          {this.state.loading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

          {(!this.state.loading && this.context.isLoggedIn) && 
            <div style={styles.wrapper}>

              {this.state.isVerified &&
                <div style={styles.content}>
                  <img src={Images.check} style={{height: 48}} alt="Confirmed" />
                  <h2 style={styles.success}>{t('CONFIRM_ACC_HEADER')}</h2>
                  <p style={styles.success}>{t('CONFIRM_ACC_MSG')}</p>

                  <div style={styles.buttonGroup}>
                    <a href="JavaScript:void(0);" style={styles.buttonGroupBtn} onClick={this.onHome}><span style={{color: '#ffffff', textDecoration: 'uppercase'}}>{t('HOME')}</span></a>
                  </div>
                </div>
              }

              {this.state.isError &&
                <div style={styles.content}>
                  <h2 style={styles.error}>{t('ERROR')}</h2>
                  <p style={styles.error}>{t('CONFIRM_ACC_ERROR')}</p>

                  <div style={styles.buttonGroup}>
                    <a href="JavaScript:void(0);" style={styles.buttonGroupBtn} onClick={this.onHome}><span style={{color: '#ffffff', textDecoration: 'uppercase'}}>{t('HOME')}</span></a>
                  </div>
                </div>
              }

            </div>
          }
        </div>
      }</Translation>
    );
  }

}


const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'block',
    overflow: 'auto',
  },
  content: {
    height: '100%',
    width: '90%',
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  success: {
    color: '#00aedf',
  },
  error: {
    color: '#F26A6A',
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonGroupBtn: {
    width: 125,
    height: 50,
    backgroundColor: '#555555',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  loadingWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
};


export default isAuth(ConfirmAccountScreen);