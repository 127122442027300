import React from 'react';

import MapSearch from './MapSearch';
import GetUserLocationButton from './GetUserLocationButton';
import LocationSummary from './LocationSummary';
import MapFilter from './MapFilter';
import QrReaderScreen from '../qr/QrReaderScreen';

import ActivityIndicator from '../global/ActivityIndicator';

import Map from './Map';

import HomeHeader from './HomeHeader';
import HomeFooter from './HomeFooter';
import HomeFooterTabs from './HomeFooterTabs';
import LogoBar from '../global/LogoBar';

import GlobalContext from '../../context/global-context';
import { Translation } from 'react-i18next';

import {isMobile, isTablet, isAndroid, isIOS} from "react-device-detect";
import StatusBarPadding from '../../common/StatusBarPadding';

var searchTimeout;

class HomeScreen extends React.Component {

    static contextType = GlobalContext;


    onPressMapHandler = () => {
      let full = this.state.fullScreenMap?false:true;
      this.setState({
        fullScreenMap: full
      });

      let animationValues = {
        headerHeight: 150,
        buttonsBottom: '10px',
        locationBottom: 200,
        tabsBottom: -60
      }

      if(full)
        animationValues = {
          headerHeight: 60,
          buttonsBottom: '-200px',
          locationBottom: 80,
          tabsBottom: 0
        };

      this.setState({searchResults: false});
      this.setState({headerHeight: animationValues.headerHeight});
      this.setState({buttonsBottom: animationValues.buttonsBottom});
      this.setState({tabsBottom: animationValues.tabsBottom});
      this.setState({locationBottom: animationValues.locationBottom});
    }

    onToggleMapViewHandler = () => {

      if(!this.state.fullScreenMap)
        this.onPressMapHandler();
      
      let mapView = this.state.mapView?false:true;
      let listViewPos = 0;

      if(mapView)
        listViewPos = this.context.dimensions.width;

      this.setState({
        mapView: mapView
      });

      // Animated.timing(this.state.listViewPos, { toValue: listViewPos, duration: 200,}).start(); 
      // Animated.timing(this.state.calloutBottom, { toValue: -280, duration: 400,}).start(); 
    }

    onPressLocationSummaryPopUpHandler = (point) => {
      this.setState({
        calloutPoint: point.id,
        calloutBottom: 70
      })

      if(!this.state.fullScreenMap)
        this.onPressMapHandler();
    }

    onPressLocationSummaryPopUpCloseHandler = () => {
      this.setState({
        calloutBottom: -280
      })
    }

    onPressFilterHandler = () => {

      //if(!this.state.fullScreenMap)
        //this.onPressMapHandler();

      this.setState({showFilter: true}); 
    }

    onPressFilterCloseHandler = () => {
      this.setState({showFilter: false}); 
    }

    onToggleFilterItemHandler = (type, key) => {
      let filters = this.state.filters;
      filters[type][key].value = filters[type][key].value?false:true;

      this.setState({
        filters: filters
      });
    }

    onPressScanHandler = () => {
      if(this.context.isNative && typeof window.webViewBridge !== 'undefined')
        window.webViewBridge.send('qr', {showScan: true}, true, false);
      else
        this.setState({showScan: true}); 
    }

    onPressScanCloseHandler = () => {
      this.setState({showScan: false}); 
    }

    constructor(props){
      super(props);

      this.state = {
        points: [],
        fullScreenMap: false, 
        headerHeight: 150,
        buttonsBottom: '10px',
        tabsBottom: -60,
        locationBottom: 200,
        showFilter: false,
        showScan: false,
        mapView: true,
        searchResults: false,
        searchText: '',
        // listViewPos: new Animated.Value(width),
        filters: {
          types: [
            {
              label: 'Wall (BS1363)',
              value: 0
            },
            {
                label: 'Type 2',
                value: 0
            },
            {
                label: 'J-1772',
                value: 0
            },
            {
                label: 'Tesla',
                value: 0
            }
          ],
          speeds: [
            {
                label: 'All',
                value: true
            },
            {
                label: '7kW',
                value: false
            },
            {
                label: '11kW',
                value: false
            },
            {
                label: '22kW',
                value: false
            }
          ]
        }
      }
    }

    onToggleFavoriteHandler = (point) => {
      this.context.favoriteToggle(point);
      let newPoints = this.state.points.map(point => (
            this.context.isFavorite(point.id)? {...point, isFavorite: true}: {...point, isFavorite: false}
      ))

      this.setState({points: newPoints});
    }



    componentDidMount(){
      if(!this.context.userIsOnboarded())
        this.props.history.push('/getstarted');

      if(isMobile && this.context.isNative === false && !this.context.userNativePrompted())
        this.props.history.push('/app-store');

    }

    onOpenDrawerHandler = () => {
      this.props.navigation.toggleDrawer();
    }


    onMarkerPressHandler = async (point) => {
      const camera = await this.map.getCamera();
      camera.center.latitude = point.location.latitude;
      camera.center.longitude = point.location.longitude;
      camera.zoom = 10;
      this.map.animateCamera(camera, { duration: 1000 });
    }

    getMarkerType = (point) => {
      if(point.connectors_stats.available_count > 0)
        return 'open';
      
      return 'inuse';
    }

    goToLocation = point => {
      this.props.history.push('/location/'+point.id);
    }

    goToLocationPAYG = payg => {
      this.props.history.push('/point/'+payg.site.id+ '/' + payg.session.EvPoint + '/' + payg.session.Connector + '/' + payg.session.PAYGCode.replace('-', ''));
    }

    goToPoint = (siteId, connectorId) => {
      this.props.history.push('/point/'+siteId+'/'+connectorId);
    }

    listSites = () => {
      return this.context.sites.filter(this.context.sitesFilter).map((site) => <div onClick={()=>{this.goToLocation(site)}} key={site.id} style={styles.locationContainer}><LocationSummary site={site} toggleFavorite={this.onToggleFavoriteHandler} history={this.props.history} /></div>);
    }

    searchLocations = (e, attempt = 1) => {
      let str = typeof e !== 'undefined' && e !== null?e.target.value:this.state.searchText;
      if(str.match(/^(U|u)(K|k)(E|e)(V|v)[a-zA-Z0-9]{2}\d{2}$/))
        str = str.toUpperCase();

      this.setState({searchText: str}, () => {

        if(searchTimeout > 0)
          clearTimeout(searchTimeout);

        if(this.state.searchText.length > 0){

          if(!this.state.fullScreenMap)
            this.onPressMapHandler();
          
            searchTimeout = setTimeout(() => { 
              this.setState({searchResults: false}, () => {
                this.context.searchLocations(this.state.searchText, attempt).then(
                  res => {
                    this.setState({searchResults: res}, () => {

                      if(res.payg !== null && res.payg.session === null && attempt < 20){
                        attempt = attempt+1;
                        setTimeout(() => {
                          this.searchLocations(null, attempt);
                        }, 4000);
                      }

                    });
                  },
                  err => {
                    this.setState({searchResults: false});
                  }
                )
              });
            }, 500);

        } else {
          this.setState({searchResults: false});
        }
      })
    }

    searchClear = () => {
      this.setState({searchText: '', searchResults: false});
    }

    render() {
        return (
        <Translation>{t=>
          <React.Fragment>
            {!this.state.showScan && 
              <div style={styles.container}>
                {!this.context.isNative && <LogoBar />}
                {this.context.isNative && <StatusBarPadding platform={this.context.platform} />}

                <HomeHeader height={this.state.headerHeight} onOpenDrawer={this.onOpenDrawerHandler} onToggleMapView={this.onToggleMapViewHandler} mapView={this.state.mapView} fullScreenMap={this.state.fullScreenMap} />
                {(this.context.isLoggedIn) && <MapFilter show={this.state.showFilter} onPressFilterClose={this.onPressFilterCloseHandler} onToggleFilterItem={this.onToggleFilterItemHandler} filters={this.state.filters}/>}
                
                {this.state.mapView && <GetUserLocationButton bottom={this.state.locationBottom} />}
                {this.context.loading.locations && 
                <div style={Object.assign({}, styles.loading, {bottom: this.state.locationBottom})}><ActivityIndicator /></div>}
                
                <div style={Object.assign({}, styles.mapWrapper, !this.state.mapView && styles.mapWrapperHide)}>
                  <MapSearch mapView={this.state.mapView} onPressFilter={this.onPressFilterHandler} onPressScan={this.onPressScanHandler} searchResults={this.state.searchResults} searchLocations={this.searchLocations} searchText={this.state.searchText} searchClear={this.searchClear} goToLocation={this.goToLocation} goToLocationPAYG={this.goToLocationPAYG} goToPoint={this.goToPoint} />
                  <Map onPressMap={this.onPressMapHandler} fullScreen={this.state.fullScreenMap} goToLocation={this.goToLocation} history={this.props.history} />
                </div>

                
                <HomeFooter bottom={this.state.buttonsBottom} />
                <HomeFooterTabs bottom={this.state.tabsBottom} />
                {this.context.errors.location && <div style={styles.locationError}>{t('LOCATION_ERR')}</div>}

                
                {!this.state.mapView && 
                <div style={styles.listWrapper}>
                  <div style={styles.listScroll}>
                    <div style={{minHeight: 70}}></div>
                    {this.listSites()}
                    <div style={{minHeight: 70}}></div>
                  </div>
                </div>}
              </div>
            }

            {this.state.showScan && <QrReaderScreen show={true} onPressClose={this.onPressScanCloseHandler} history={this.props.history}/>}
          </React.Fragment>
        }</Translation>
        );
    }
}

export default HomeScreen;

const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  loading: {
    left: 50, 
    backgroundColor: '#aaa',
    opacity: .3,
    borderRadius: 25,
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 98
  },
  mapWrapper: {
    flex: 1,
    position: 'relative'
  },
  mapWrapperHide: {
    flex: 0
  },
  listWrapper: {
    position: 'relative',
    backgroundColor: '#ffffff',
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  locationContainer: {
    flex: 1,
    borderBottom: '1px solid #e2e2e2',
    cursor: 'pointer'
  },
  scrollView: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  locationError: {
    position: 'absolute',
    bottom: 0,
    display:'block',
    padding: 10,
    fontSize: 12,
    color: '#fff',
    backgroundColor: 'rgba(0,0,0,.8)',
    zIndex: 103
  }
};
