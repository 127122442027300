  
const BLANK_PROFILE = {
    expires: null,
    rt: null,
    token: null,
    user: null
}

const userReducer = (state = {
    profile: BLANK_PROFILE,
    prevProfile: null,
    status: 'out',
    msg: '',
    verifying: false,
    alert: null
}, action) => {
    let current_profile = JSON.parse(JSON.stringify(state.profile));

    switch(action.type){
        case "USER_LOGIN":
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'error'?action.payload:'',
                profile: action.status === 'in'?action.payload:BLANK_PROFILE,
                prevProfile: null,
                verifying: false,
                alert: null
            }
            break;
        case "USER_LOGOUT":
            state = {
                ...state,
                status: 'out',
                msg: '',
                profile: BLANK_PROFILE,
                verifying: false,
                alert: (state.status === 'in' || action.payload === true) ? 'USER_LOGOUT' : null
            }
            break;
        case "USER_UPDATE":
            state = {
                ...state,
                prevProfile: current_profile,
                profile: {
                    ...state.profile,
                    user: action.payload
                }
            }
            break;
        case "USER_VERIFYING":
            state = {
                ...state,
                verifying: action.payload
            }
            break;
        case "USER_CLEARALERT":
            state = {
                ...state,
                alert: null
            }
            break;
        default:
    }
    return state;
}

export default userReducer;