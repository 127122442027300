import React from "react";
import Images from "../../assets/images/index";
import GlobalContext from "../../context/global-context";
import ActivityIndicator from "../global/ActivityIndicator";
import { Translation } from "react-i18next";

class ResetPasswordScreen extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: "",
        token: this.props.location.search.replace("?token=", ""),
        password: "",
        confirm_password: "",
      },
      errors: {
        email: this.errorField(),
        password: this.errorField(),
        confirm_password: this.errorField(),
      },
      isSuccess: false,
      isError: false,
      isValid: false,
      isLoading: false,
      resetError: false,
    };
  }

  errorField = (group = null) => {
    return {
      dirty: false,
      invalid: false,
      group: group,
    };
  };

  bindField = (e) => {
    let fields = { ...this.state.fields };
    fields[e.target.name] = e.target.value;
    this.setState({ fields: fields }, () => {
      this.validate();
    });
  };

  dirtyGroup = (group = null, callback = () => {}) => {
    let errors = { ...this.state.errors };
    Object.values(errors).map((e) => {
      if (e.group === group) e.dirty = true;
    });
    this.setState({ errors: errors }, () => {
      callback();
    });
  };

  validate = (callback = () => {}) => {
    let errors = { ...this.state.errors };
    const required = ["email", "password", "confirm_password"];

    required.map((f) => {
      if (this.state.fields[f].length > 0) errors[f].dirty = true;

      errors[f].invalid = false;
      if (errors[f].dirty && this.state.fields[f] === "") {
        errors[f].invalid = true;
      }
    });

    // Validate password minimum criteria
    if (
      errors.password.dirty &&
      !this.state.fields.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.{8,})/,
      )
    ) {
      errors.password.invalid = true;
    }

    // Validate passwords match
    if (
      errors.password.dirty &&
      errors.confirm_password.dirty &&
      this.state.fields.password !== this.state.fields.confirm_password
    ) {
      errors.confirm_password.invalid = true;
    }

    const isValid =
      Object.values(errors).findIndex((e) => e.invalid === true) === -1;
    this.setState({ errors: errors, isValid: isValid }, () => {
      callback();
    });
  };

  resetPassword = () => {
    this.dirtyGroup(null, () => {
      this.validate(() => {
        if (this.state.isValid) {
          this.setState({ isLoading: true, isError: false, isSuccess: false });

          this.context
            .userPasswordReset(this.state.fields)
            .then((result) => {
              if (result.success) {
                this.setState({ isLoading: false, isSuccess: true });

                // if using isAuth then the previous screen should appear
                this.context.userLogin({
                  username: this.state.fields.email,
                  password: this.state.fields.password,
                });

                setTimeout(() => this.props.history.push("/"), 3000);
              } else this.setState({ isLoading: false, isError: true });
            })
            .catch((err) => {
              this.setState({ isLoading: false, isError: true });
            });
        } else {
          console.log("you are not valid");
        }
      });
    });
  };

  login = () => {
    this.props.history.push("/login");
  };

  home = () => {
    this.props.history.push("/");
  };

  render() {
    const errors = this.state.errors;
    return (
      <Translation>
        {(t) => (
          <div style={styles.container}>
            <div style={styles.bgImg}>
              <div style={styles.wrapper}>
                {this.state.isLoading && (
                  <div style={styles.loadingWrapper}>
                    <div style={styles.loading}>
                      <ActivityIndicator />
                    </div>
                  </div>
                )}
                {!this.state.isLoading && !this.state.isSuccess && (
                  <div style={styles.form}>
                    <div style={styles.header}>{t("RESET_PASSWORD")}</div>
                    <div style={styles.description}>
                      {t("RESET_PASSWORD_DESC")}
                    </div>
                    {this.state.isError && (
                      <span
                        style={Object.assign(
                          {},
                          styles.description,
                          styles.error,
                        )}
                      >
                        {t("RESET_PASSWORD_ERR")}
                      </span>
                    )}
                    <div
                      style={Object.assign(
                        {},
                        styles.inputWrapper,
                        errors.email.invalid && styles.inputError,
                      )}
                    >
                      <input
                        type={"text"}
                        name={"email"}
                        onChange={this.bindField}
                        placeholder={t("EMAIL")}
                        style={styles.input}
                      />
                    </div>
                    {errors.email.invalid && (
                      <span style={styles.inputErrorMsg}>{t("EMAIL_ERR")}</span>
                    )}
                    <div
                      style={Object.assign(
                        {},
                        styles.inputWrapper,
                        errors.password.invalid && styles.inputError,
                      )}
                    >
                      <input
                        type={"password"}
                        name={"password"}
                        onChange={this.bindField}
                        placeholder={t("PASSWORD")}
                        style={styles.input}
                      />
                    </div>
                    {errors.password.invalid && (
                      <span style={styles.inputErrorMsg}>
                        {t("PASSWORD_REQUIREMENT")}
                      </span>
                    )}
                    <div
                      style={Object.assign(
                        {},
                        styles.inputWrapper,
                        errors.confirm_password.invalid && styles.inputError,
                      )}
                    >
                      <input
                        type={"password"}
                        name={"confirm_password"}
                        onChange={this.bindField}
                        placeholder={t("CONFIRM_PASSWORD")}
                        style={styles.input}
                      />
                    </div>
                    {errors.confirm_password.invalid && (
                      <span style={styles.inputErrorMsg}>
                        {t("PASSWORD_NOMATCH")}
                      </span>
                    )}

                    <div style={styles.buttonGroup}>
                      <button
                        style={Object.assign(
                          {},
                          styles.buttonGroupBtn,
                          styles.buttonGroupBtnPrimary,
                        )}
                        onClick={this.resetPassword}
                      >
                        <span style={{ color: "#ffffff" }}>{t("RESET")}</span>
                      </button>
                    </div>
                  </div>
                )}
                {!this.state.isLoading && this.state.isSuccess && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Images.complete}
                      alt={t("SUCCESS")}
                      style={styles.successImage}
                    />
                    <span
                      style={Object.assign({}, styles.header, styles.success)}
                    >
                      {t("SUCCESS")}
                    </span>
                    <div
                      style={Object.assign(
                        {},
                        styles.description,
                        styles.success,
                      )}
                    >
                      {t("RESET_PASSWORD_SUCCESS")}
                    </div>
                    <div style={styles.buttonGroup}>
                      <button
                        style={Object.assign({}, styles.buttonGroupBtn)}
                        onClick={this.home}
                      >
                        <span
                          style={{
                            color: "#ffffff",
                            textTransform: "uppercase",
                          }}
                        >
                          {t("HOME")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div style={styles.rolecLogoWrapper}>
                <img
                  src={Images.rolec_logo}
                  style={styles.rolecLogo}
                  alt="Powered by Rolec"
                />
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

const styles = {
  container: {
    backgroundColor: "#fff",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  bgImg: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundImage: "url(" + Images.bg + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    display: "flex",
    height: "100%",
    overflowY: "auto",
  },
  vendLogo: {
    width: "80%",
    maxWidth: 250,
    maxHeight: 58,
    marginBottom: 40,
    alignSelf: "center",
  },
  rolecLogoWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingBottom: 20,
  },
  rolecLogo: {
    width: 110,
    height: 25,
    paddingRight: 20,
  },
  wrapper: {
    position: "relative",
    width: "100%",
    maxWidth: 300,
    overflow: "hidden",
    minHeight: 400,
    marginTop: 60,
  },
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginError: {
    color: "#F26A6A",
    fontSize: 14,
    marginBottom: 15,
    textAlign: "center",
    display: "block",
  },
  inputWrapper: {
    height: 54,
    width: "100%",
    backgroundColor: "#ebebeb",
    borderRadius: 12,
    marginBottom: 10,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    fontSize: 16,
    flex: 1,
    paddingLeft: 20,
  },
  inputError: {
    boxShadow: "inset 0 0 0 1px #F26A6A",
  },
  inputErrorMsg: {
    width: "100%",
    color: "#F26A6A",
    fontSize: 11,
    paddingBottom: 20,
  },
  forgotPassword: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonGroup: {
    width: "100%",
    marginTop: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "20px",
  },
  buttonGroupBtn: {
    width: 125,
    height: 50,
    backgroundColor: "#555555",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },
  buttonGroupBtnPrimary: {
    backgroundColor: "#6CB33F",
  },
  footerLinks: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  appSupport: {
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    marginTop: 10,
    color: "#484848",
  },
  guestCheckout: {
    flex: 1,
    marginTop: 15,
    color: "#6CB33F",
    fontWeight: "900",
  },
  loadingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    backgroundColor: "#6CB33F",
    opacity: 0.7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  description: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: "center",
  },
  success: {
    color: "#5ab552",
  },
  error: {
    color: "#F26A6A",
  },
  successImage: {
    height: 90,
    width: "auto",
  },
};

export default ResetPasswordScreen;
