import React, { useContext, useEffect, useState } from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { useTranslation } from 'react-i18next';



const errorField = (group = null) => {
    return {
        dirty: false,
        invalid: false,
        group: group
    };
}

var _topupwalletinview = false;
var _checkWalletChangeTimeout;

const TopupWallet = props => {

    const context = useContext(GlobalContext);
    const {t} = useTranslation();

    const [fields, setFields] = useState({
        amount: '',
    });
    const [errors, setErrors] = useState({
        amount: errorField()
    });
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [topupError, setTopupError] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(false);
    const [paymentUrlLoads, setPaymentUrlLoads] = useState(0);
    const [existingBalance, setExistingBalance] = useState(false);
    const [useExistingCard, setUseExistingCard] = useState('0.00');
    const [confirmCard, setConfirmCard] = useState(false);
  
  
    const bindField = (e) => {
        let newFields = {...fields};
        newFields[e.target.name] = e.target.value;
        setFields(newFields);
    }

    useEffect(() => {
        validate();
    }, [fields])

    const selectAmount = (amount) => {
        let newFields = {...fields};
        if(amount > 5){
            newFields.amount = amount.toString();
            setFields(newFields);
        }
    }

    const validate = () => {
        let newErrors = {...errors};
        const required = ['amount'];
  
        required.map(f => {
            if(fields[f].length > 0)
                newErrors[f].dirty = true;
  
            newErrors[f].invalid = false;
            if(newErrors[f].dirty && fields[f] === ''){
                newErrors[f].invalid = true;
            }
        })

        if(parseFloat(fields.amount) < 5)
            newErrors.amount.invalid = true;
  
        const isValid = Object.values(newErrors).findIndex(e => e.invalid === true) === -1;
        setErrors(newErrors)
        setIsValid(isValid);
    }

    
    const dirtyGroup = (group = null) => {
        let newErrors = {...errors};
        Object.values(newErrors).map(e => {
            if(e.group === group)
                e.dirty = true;
        })
        setErrors(newErrors);
    }


    const topup = () => {
        dirtyGroup()

        if(isValid){
            setIsLoading(true)
            setTopupError(false);

            //
            // Double check the ability to use the existing card
            //
            let useExisting = useExistingCard;
            if(useExisting && (context.wallet.card === false || context.wallet.card.length !== 4))
            useExisting = false;
        
            context.userWalletTopup(fields.amount, useExisting)
            .then(result => {

                if(result !== 'SUCCESS'){
                    setIsLoading(false);
                    setPaymentUrl(result.paymentUrl);
                } else {
                    onPaymentWindowChange();
                }

            })
            .catch(err => {
                let errorMsg = 'An error occured topping up your wallet. Please select \'New Card\' and re-enter your card details.';
                setIsLoading(false);
                setTopupError(errorMsg);
            })
        } else {
            console.log('you are not valid');
        }
    }

    const onPaymentWindowChange = () => {

        setPaymentUrlLoads(paymentUrlLoads+1);

        // Clear the timeout
        if(typeof _checkWalletChangeTimeout !== 'undefined')
            clearTimeout(_checkWalletChangeTimeout);


        context.userWalletGet().then(
            result => {
                if(parseFloat(result.balance) > parseFloat(existingBalance)){

                    
                    //Switch the preference to wallet if it is currently PAYG  
                    if(context.account.preferences.paymentMethod === 'PAYG')
                        context.paymentMethodToggle();

                    if(useExistingCard)
                        close();
                    else {
                        setConfirmCard(true);
                        setIsLoading(false);
                        setPaymentUrl(false);
                    }

                    
                } else {
                    if(_topupwalletinview)
                        _checkWalletChangeTimeout = setTimeout(onPaymentWindowChange, 30000);
                }
            },
            err => {
                if(_topupwalletinview)
                    _checkWalletChangeTimeout = setTimeout(onPaymentWindowChange, 30000);
            }
        )
        
    }

    const newBalance = () => {
        let nb  = 0;
        if(!isNaN(parseFloat(context.wallet.balance)))
            nb += parseFloat(context.wallet.balance);

        if(!isNaN(parseFloat(fields.amount)))
            nb += parseFloat(fields.amount);

        return nb.toFixed(2);
    }

    const removeCard = () => {
        context.userWalletRemoveCard().then(
            res => {
                close();
            },
            err => {
                console.log(err);
                close();
            }
        )
    }

    const payWithExistingCard = val => {
        setUseExistingCard(val);
    }

    const close = () => {
        setIsLoading(false);
        setPaymentUrl(false);
        setConfirmCard(false);
        setFields({
            amount: ''
        });
        setExistingBalance(context.wallet.balance);

        props.close();
    }

    //
    //
    //  Mount / Unmount
    //
    //
    useEffect(() => {

        context.userWalletGet().then(
            wallet => {
                setExistingBalance(wallet.balance);
                setUseExistingCard(context.wallet.card !== false?true:false);
            },
            err => {
                setExistingBalance('0.00')
            }
        )

        return () => {
            _topupwalletinview = false;

            if(typeof _checkWalletChangeTimeout !== 'undefined')
                clearTimeout(_checkWalletChangeTimeout);
        }

    }, [])

    useEffect(() => {

        _topupwalletinview = props.show;

        if(typeof _checkWalletChangeTimeout !== 'undefined')
            clearTimeout(_checkWalletChangeTimeout);

    }, [props.show])

    return (
        <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
            <div style={styles.wrapper}>
                <button style={styles.closeButton} onClick={close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>

                
                {isLoading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

                
                {(!isLoading && !paymentUrl && !confirmCard) && 
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingTop: 60, justifyContent: 'center', alignItems: 'center'}}>

                    <div style={{display: 'flex', flex: 1, width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

                        <span style={{fontSize: 18, marginBottom: 15, fontWeight: 600}}>{t('SELECT_TOPUP')}</span>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={Object.assign({}, {display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, margin: 5, borderColor: '#6CB33F', borderRadius: 6, borderStyle: 'solid', borderWidth: 1, fontSize: 18}, fields.amount === '10' && {backgroundColor: '#6CB33F', color: '#fff'})} onClick={() => {selectAmount(10)}}>{parseFloat(10).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                            <span style={Object.assign({}, {display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, margin: 5, borderColor: '#6CB33F', borderRadius: 6, borderStyle: 'solid', borderWidth: 1, fontSize: 18}, fields.amount === '20' && {backgroundColor: '#6CB33F', color: '#fff'})} onClick={() => {selectAmount(20)}}>{parseFloat(20).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                            <span style={Object.assign({}, {display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, margin: 5, borderColor: '#6CB33F', borderRadius: 6, borderStyle: 'solid', borderWidth: 1, fontSize: 18}, fields.amount === '30' && {backgroundColor: '#6CB33F', color: '#fff'})} onClick={() => {selectAmount(30)}}>{parseFloat(30).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                        </div>
                        <span style={{fontSize: 18, marginBottom: 15, marginTop: 15}}>{t('ENTER_AMOUNT')}</span>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top'}}>
                            <div style={styles.inputWrapper}>
                                <input type={'number'} name={'amount'} onChange={bindField} placeholder={'25'} style={Object.assign({}, styles.input, errors.amount.invalid && styles.inputError)} />
                                <span style={{fontSize: 12, color: '#a2a2a2'}}>{t('MIN_SPEND')} {parseFloat(5).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                            </div>
                        </div>

                        {context.wallet.card !== false && <span style={{fontSize: 18, marginBottom: 15, marginTop: 15}}></span>}
                        {context.wallet.card !== false && 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={Object.assign({}, {display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, margin: 5, borderColor: '#6CB33F', borderRadius: 6, borderStyle: 'solid', borderWidth: 1, fontSize: 18}, useExistingCard && {backgroundColor: '#6CB33F', color: '#fff'})} onClick={() => {payWithExistingCard(true)}}>{t('CARD_ENDING')} **{context.wallet.card}</span>
                            <span style={Object.assign({}, {display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, margin: 5, borderColor: '#6CB33F', borderRadius: 6, borderStyle: 'solid', borderWidth: 1, fontSize: 18}, !useExistingCard && {backgroundColor: '#6CB33F', color: '#fff'})} onClick={() => {payWithExistingCard(false)}}>{t('NEW_CARD')}</span>
                        </div>
                        }

                        
                        {topupError && <span style={{color: '#F26A6A', fontSize: 14, marginBottom: 15, fontWeight: 400, textAlign:'center', width: '80%', maxWidth: 400, marginTop: 5}}>{topupError}</span>}
                    </div>

                    <div style={{display: 'flex', flex: 1, width: '100%', flexDirection: 'column', justifyContent: 'flex-end'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{maxWidth: '80%', width: '100%', display: 'flex', flexDirection: 'row', margin: '10px 0'}}>
                                <span style={{flex: 1}}>{t('YOUR_CURRENT_BALANCE')}</span>
                                <span style={{alignSelf: 'flex-end'}}>{parseFloat(context.wallet.balance).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                            </div>
                            <div style={{maxWidth: '80%', width: '100%', display: 'flex', flexDirection: 'row', margin: '10px 0'}}>
                                <span style={{flex: 1}}>{t('YOUR_NEW_BALANCE')}</span>
                                <span style={{alignSelf: 'flex-end'}}>{parseFloat(newBalance()).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                            </div>
                            <div style={{display: 'flex', maxWidth: '80%', marginBottom: 20, marginTop: 30, width: '100%'}}>
                                <span style={{display: 'block', flex: 1, backgroundColor: '#6CB33F', padding: '7px 10px', color: '#fff', borderRadius: 4, textAlign: 'center'}} onClick={topup}>{t("CONFIRM")}</span>
                            </div>
                        </div>
                    </div>

                </div>
                }


                {(!isLoading && !paymentUrl && confirmCard) && 
                    <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingTop: 60, justifyContent: 'center', alignItems: 'center'}}>
                        

                        <div style={{display: 'flex', flex: 1, width: '90%', maxWidth: 400, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontSize: 24, marginBottom: 5, textAlign: 'center', fontWeight: 600}}>{t('STORING_CARD')}</span>
                            <span style={{fontSize: 16, marginBottom: 15, marginTop: 15, textAlign: 'center'}}>{t('STORING_CARD_DESC')}</span>
                            
                            <div style={{display: 'flex', maxWidth: '80%', marginBottom: 20, marginTop: 30, width: '100%'}}>
                                <span style={{display: 'block', flex: 1, backgroundColor: '#464646', padding: '7px 10px', color: '#fff', borderRadius: 4, textAlign: 'center', margin: '0px 5px'}} onClick={removeCard}>No thanks</span>
                                <span style={{display: 'block', flex: 1, backgroundColor: '#6CB33F', padding: '7px 10px', color: '#fff', borderRadius: 4, textAlign: 'center', margin: '0px 5px'}} onClick={close}>OK</span>
                            </div>
                        </div>

                    </div>
                }

                {(!isLoading && paymentUrl) && 
                    <div style={{flex:1, display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{paddingTop: 20, width: '90%', maxWidth: 400}}>
                            <span style={{display: 'block', color: '#484848', fontSize: 24, fontWeight: '600'}}>{t('SECURE_TOPUP')}</span>
                            <span style={{ display: 'block', color: '#484848', fontSize: 18, fontWeight: '600', marginBottom: 10}}>{parseFloat(fields.amount).toLocaleString(navigator.language, {style: 'currency', currency: context.wallet.currency})}</span>
                        </div>
                            
                        <div style={{flex: 1, width: '90%', maxWidth: 400}}>
                            <iframe frameBorder="0" marginHeight="0" marginWidth="0" src={paymentUrl} width="100%" height="100%" onLoad={onPaymentWindowChange}></iframe>
                        </div>
                    </div>
                }

            </div>
        </div>
    )

}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        display: 'block',
        overflow: 'auto'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    closeButton: {
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99,
        backgroundColor: 'transparent',
        border: 'none'
    },
    inputWrapper: {
        display: 'flex',
        maxWidth: 80,
        flexDirection: 'column',
        marginBottom: 10,
        justifyContent: 'center',
        marginLeft: 5
    },
    inputErrorMsg: {
        color: '#F26A6A',
        fontSize: 11,
        paddingLeft: 20,
        marginBottom: 8,
        marginTop: -8
    },
    input: {
        fontSize: 16, 
        flex: 1,
        paddingLeft: 10,
        borderRadius: 6,
        lineHeight: '32px',
        border: '1px solid #6CB33F'
    },
    inputError: {
        border: '1px solid #F26A6A'
    },
    loadingWrapper: {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,.4)',
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
}

export default TopupWallet;