import React from 'react';
import Header from '../global/Header';
import ActivityIndicator from '../global/ActivityIndicator';
import isAuth from '../../components/login/IsAuth';
import GlobalContext from '../../context/global-context';
import Images from '../../assets/images';
import { Translation } from 'react-i18next';

//
//  Sending a fault report via email
//

class FaultScreen extends React.Component {

  static contextType = GlobalContext;

  constructor(props){
    super(props);

    this.state = {
      id: this.props.match.params.id,
      site: null,
      pointId: null,
      loading: true,
      success: false,
      feedback: '',
      feedbackError: false
    };

    this.file = React.createRef();
  }

  sendFault = () => {
    if(this.state.feedback.length > 0){
      this.setState({loading: true, feedbackError: false});

      const data = new FormData();
      data.append('photo', this.file.current.files[0]);
      data.append('pointId', this.state.pointId);
      data.append('location', this.context.location.lat+', '+this.context.location.lng);
      data.append('comments', this.state.feedback);
      data.append('userId', this.context.user.profile.user.id);
      data.append('siteId', this.state.id);

      this.context.reportFault(data).then(
        res => {
          console.log(res);
          this.setState({loading: false, success: true});
        },
        err => {
          console.log(err);
          this.setState({loading: false, success: false});
        }
      )
    } else {
      this.setState({loading: false, feedbackError: true});
    }
  }


  //
  //  On change event for the input elements
  //  Sets the state that matches the name of the input
  //
  onInputChange = (e) => {
    let val = {};
    val[e.target.name] = e.target.value;
    this.setState(val);
  }

  componentDidMount = () => {
    this.context.fetchSite(this.state.id)
      .then((responseJson) => {
        this.setState({
          site: responseJson,
          loading: false
        })
      })
      .catch((error) => {
        console.error(error);
    });
  }

  render() {

    //const site = this.context.sites.find(p => p.id === this.state.id);
    const points =  () => {return this.state.site.points.map(pod => (
      <option key={pod.id} value={pod.id}>{pod.name}</option>
    ))}
    return (
      <Translation>{t =>
      <div style={styles.container}>
        <Header title={t('TITLE_FAULT')} history={this.props.history} />
        
        <div style={styles.wrapper}>

        {this.state.loading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

        {!this.state.loading && 
            <div style={styles.formWrapper}>

              {!this.state.success && 
              <div style={styles.panel}>
                <div style={styles.location}>
                    <span style={styles.locationtitle}>{this.state.site.name}</span>
                    <span style={styles.locationaddress}>{this.context.getSiteAddress(this.state.site.id)}</span>
                </div>

                <div style={styles.inputWrapper}>
                  <select style={styles.select_input} name="pointId" onChange={this.onInputChange}>
                    <option>--{t('SELECT_POINT')}--</option>
                    {points()}
                  </select>
                </div>

                <div style={styles.inputWrapper}>
                  <textarea name="feedback" value={this.state.feedback} onChange={this.onInputChange} placeholder={t('FAULT_LABEL')} style={styles.input}></textarea>
              {this.state.feedbackError && <span style={styles.inputErrorMsg}>{t('FAULT_REQUIREMENT')}</span>}
                </div>

                <input type={'file'} ref={this.file} />

                <div style={styles.buttonGroup}>
              <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.sendFault}><span style={{color: '#ffffff', textTransform: 'uppercase'}}>{t('SEND')}</span></a>
                </div>
              </div>
              }

              {this.state.success && 
                <div style={{textAlign: 'center'}}>
                  <img style={styles.successImg} src={Images.check_circle} className={'pulse'} alt="Fault report received" />
              <span style={styles.successHeader}>{t('THANK_YOU')}</span>
                  <span style={styles.successText}>{t('FAULT_SUCCESS')}</span>
                </div>
              }
            </div>
        }

        </div>

      </div> 
      }</Translation>
    );
  }

}


const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  wrapper: {
    backgroundColor: '#222',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    backgroundImage: 'url('+Images.dark_bg+')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    overflow: 'hidden'
  },
  formWrapper: {
    width: '90%',
    maxWidth: 380,
    display: 'flex',
    flexDirection: 'column'
  },
  panel: {
    padding: 20,
    backgroundColor: 'rgba(255,255,255,.8)',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  summary: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '90%',
    marginLeft: '5%',
    marginTop: '5%'
  },
  header: {
      fontSize: 24, 
      fontWeight: 'bold', 
      marginTop: 20,
      marginBottom: 10,
      display: 'block',
      color: '#484848',
      textAlign: 'center'
  },
  location: {
      marginBottom: 15,
      paddingRight: 15
  },
  locationtitle: {
      color: '#484848',
      fontSize: 20,
      fontWeight: 900,
      display: 'block'
  },
  locationaddress: {
      color: '#888888',
      fontSize: 14
  },
  info: {
      marginRight: 15
  },
  infotitle: {
      color: '#484848',
      fontSize: 16,
      fontWeight: 'bold'
  },
  actions: {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row'
  },
  action: {
      display: 'flex',
      marginRight: 15,
      flexDirection: 'row',
      alignItems: 'center'
  },
  actiontitle: {
      color: '#a2a2a2',
      fontSize: 14
  },
  actionActiveTitle: {
      color: '#6CB33F',
      fontSize: 14
  },
  loadingWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  successImg: {
    width: 36, 
    height: 36,
    marginBottom: 20
  },
  successHeader: {
      fontSize: 38,  
      marginBottom: 10,
      display: 'block',
      color: '#fff'
  },
  successText: {
      fontSize: 18,  
      display: 'block',
      color: '#fff'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputWrapper: {
    width: '100%',
    backgroundColor: '#ebebeb',
    borderRadius: 12,
    marginBottom: 10,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  inputError: {
    borderColor: '#bb0d0d',
    borderWidth: 1
  },
  buttonGroup: {
    width: '100%',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonGroupBtn: {
    width: 100,
    height: 40,
    backgroundColor: '#555555',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
    backgroundColor: '#6CB33F'
  },
  input: {
      fontSize: 16, 
      minHeight: 100,
      flex: 1,
      paddingLeft: 20,
      paddingTop: 10,
      backgroundColor: 'transparent',
      border: 'none'
  },
  select_input: {
      fontSize: 16, 
      minHeight: 50,
      flex: 1,
      paddingLeft: 20,
      paddingTop: 10,
      backgroundColor: 'transparent',
      border: 'none'
  },
  inputError: {
    boxShadow: 'inset 0 0 0 1px #bb0d0d'
  },
  inputErrorMsg: {
    color: '#bb0d0d',
    fontSize: 11,
    paddingLeft: 20,
    marginBottom: 8,
    marginTop: -8
  },
};


export default isAuth(FaultScreen);