import ActivityIndicator from "../components/global/ActivityIndicator";

const ProgressButton = props => {

    const handleSubmit = () => {
        if(typeof props.handleSubmit === 'function')
            props.handleSubmit();
    }

    return (
        <button onClick={handleSubmit} style={styles.button}>{props.loading ? <div style={styles.loading}><ActivityIndicator /></div> : props.label || 'Save'}</button>
    )
}

const styles = {
    loading: {
        display: 'flex', 
        flexDirection: 'column', 
        height: 32, 
        width: 32
    },
    button: {
        width: 120,
        height: 40,
        backgroundColor: '#6CB33F',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        color: '#ffffff', 
        textTransform: 'uppercase',
        marginTop: 20
    }
}

export default ProgressButton;