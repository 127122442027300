import React from 'react';
import GlobalState from "../../context/global-context";
import LoginScreen from './LoginScreen';

// This function takes a component...
const isAuth = (WrappedComponent) => {
    // ...and returns another component...
    return class extends React.Component {

      static contextType = GlobalState

      constructor(props) {
        super(props);
      }
  
      render() {
        return this.context.isLoggedIn?<WrappedComponent {...this.props}></WrappedComponent>:<LoginScreen isAuth={true} history={this.props.history}></LoginScreen>
      }
    };
  }

  export default isAuth;