export const feedbackSchema = [
    {
        element: "input",
        label: 'Name',
        type: "text",
        id: 'name',
        placeholder: 'Enter your name',
        name: 'name',
        required: true,
    },
    {
        element: "input",
        label: 'UKEV Number',
        type: "text",
        id: 'ukevNumber',
        placeholder: 'Enter the charge point UKEV Number',
        name: 'ukevNumber',
        required: true,
    },
    {
        element: "select",
        dropdownOptions: [
            {
                value: "Payment / Billing",
                label: "Payment / Billing"
            },
            {
                value: "Hardware / Physical",
                label: "Hardware / Physical"
            },
            {
                value: "Other",
                label: "Other"
            }
        ],
        label: 'Category',
        id: 'category',
        name: 'category',
    },
    {
        element: "textArea",
        label: 'Feedback',
        id: 'feedback',
        placeholder: 'Enter your feedback',
        name: 'feedback',
        required: true,
    },
]
