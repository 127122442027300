import React from 'react';
import Images from '../../assets/images/index';
import { Link } from 'react-router-dom'

const LogoBar = props => {

    return (
        <div style={styles.container}>
            <div style={styles.headerContainer}>
                <Link style={styles.headerTextView} to="/"><img src={Images.vend_logo_inverse} style={{width: 130, height: 20}} alt="vendelectric" /></Link>
            </div>
        </div>
    );
}

export default LogoBar;

const styles = {
    container: {
        minHeight: 38, 
        width: '100%', 
        backgroundColor: '#282828',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    headerContainer: {
        minHeight: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
};