import React, { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Dialog, DialogContent, TextField, Typography, Button, Stack, createTheme, ThemeProvider, CircularProgress, Box } from '@mui/material';
import Api from '../../context/api';

const VerifyCode = props => {

    const { t } = useTranslation();

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [codeText, setCodeText] = useState('');
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(true);
    const [processing, setProcessing] = useState(false);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#6cb33f' // This is the Rolec Green
            },
            secondary: {
                main: '#444444'
            },
            warning: {
                main: '#d32f2f'
            }
        }
    })

    const handleConfirm = async (e) => {
        /*
             only take action if there is something filled in the code TextField
             send the code to the server and get the return code, if the result is 'VERIFIED', proceed to the next step,
             otherwise, print the error message.
        */
        setProcessing(true);
        setLoading(true);

        try {
            setError(false);
            setSuccess(false);

            const update = await Api.req('/user/validateCode', 'GET', { code: codeText, email: props.email });
            if (update.success) {
                setSuccess(true);  // correct code 
                props.callEmailVerification();
                props.onClose();
            }

        } catch (error) {
            setError(true);
        } finally {
            setProcessing(false);
            setLoading(false);
        }

    }

    const handleCodeText = (event) => {
        setCodeText(event.target.value);
    }

    return (
        <Translation>{t =>
            <ThemeProvider theme={theme}>
                <Dialog open={showConfirm} fullWidth maxWidth="xs">
                    <DialogContent>
                        <Typography variant='h6'>
                            Enter Verfication Code
                        </Typography>
                        <Typography>
                            You should have received an email with a code. Enter the code in the box below to continue.
                        </Typography>
                        <TextField label='code' fullWidth value={codeText} disabled={processing} onChange={handleCodeText} />
                        <Box sx={{ color: 'warning.main' }}>
                            {error && <>{t('INVALID_EMAIL_VERIFICATION_CODE')}</>}
                        </Box>
                        <Box sx={{ pt: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Button id="cancel" variant="outlined" sx={{ mr: 3 }} onClick={props.onClose} color="secondary">Cancel</Button>
                            <Button variant="contained" onClick={handleConfirm} disabled={processing || codeText.length !== 4}>Confirm {loading && <CircularProgress size={20} className="ml" color="inherit" sx={{ ml: 2 }} />}</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </ThemeProvider>
        }</Translation>
    )

}

export default VerifyCode;