const historyReducer = (state = ['/'], action) => {

    let current_data = [...state];

    switch(action.type){
        case "HISTORY_UPDATE": 
            if(current_data[(current_data.length)-1] !== action.payload && action.payload.indexOf('/qr/') === -1){
                let new_state = [...current_data, action.payload];
                if(new_state.length >= 5){
                    new_state = new_state.slice(Math.max(new_state.length - 4, 0));
                    new_state.unshift('/');
                }
                state = new_state;
            }
        break
        default:
    }
    return state;
}

export default historyReducer;