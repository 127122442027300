import React from 'react';
import Images from '../../../assets/images';
import { Translation } from 'react-i18next';


const RFIDHelp = props => {

    return (
        <Translation>{t => 
            <React.Fragment>
                {props.open === true && <div style={styles.dialogBg} onClick={props.close}></div>}
                <div style={Object.assign({}, styles.dialog, props.open === true && styles.dialogIn)} className="animate">
                    <div style={styles.dialogWrapper}>
                        <span style={styles.dialogHeader}><img src={Images.help} alt="Help" style={styles.dialogIcon}/>{t('HELP')}</span>
                        {props.step === 1 &&
                        <div style={styles.dialogBody}>
                            <h5 style={styles.para}>{t('RFID_HELP_DESC')}</h5>
                            <p style={styles.subtle}>{t('RFID_HELP_SUB_DESC')}</p>
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        }</Translation>
    )

}

const styles = {
    dialogBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 989
    },
    dialog: {
      display: 'block',
      width: 280,
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: '#fff',
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      transform: 'translate(-50%, 100%)',
      visibility: 'hidden',
      opacity: 0,
      zIndex: 990,
      borderRadius: 6
    },
    dialogIn: {
      transform: 'translate(-50%, -50%)',
      visibility: 'visible',
      opacity: 100,
    },
    dialogWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column'
    },
    dialogHeader: {
      fontSize: 18,
      fontWeight: 900,
      marginTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
      color: '#404040'
    },
    dialogBody: {
      flex: 1,
      padding: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    dialogUnit: {
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    },
    dialogIcon: {
      marginRight: 10,
      width: 30,
      height: 30
    },
    para: {
        color: '#101010',
        lineHeight: 1.3,
        fontSize: 14,
        margin: 0,
        marginBottom: 10
    },
}

export default RFIDHelp;