import React from 'react';
import { Translation } from 'react-i18next';
import images from '../../assets/images';


const WarningDiaog = props => {

  const handleClose = () => {
    if(typeof props.close === 'function')
      props.close();
  }

    return (
        <Translation>{t => 
            <React.Fragment>
                {props.open === true && <div style={styles.dialogBg} onClick={handleClose}></div>}
                <div style={Object.assign({}, styles.dialog, props.open === true && styles.dialogIn)} className="animate">
                    <div style={styles.dialogWrapper}>
                        <div style={styles.dialogHeader}>
                          <img src={images.fault} style={styles.dialogIcon} />
                        </div>
                        <div style={styles.dialogBody}>
                            <h5 style={styles.para}>{props.msg}</h5>
                            <button onClick={handleClose} style={styles.button}>{t('OK')}</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }</Translation>
    )

}

const styles = {
    dialogBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 989
    },
    dialog: {
      display: 'block',
      width: 280,
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: '#fff',
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      transform: 'translate(-50%, 100%)',
      visibility: 'hidden',
      opacity: 0,
      zIndex: 990,
      borderRadius: 6
    },
    dialogIn: {
      transform: 'translate(-50%, -50%)',
      visibility: 'visible',
      opacity: 100,
    },
    dialogWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column'
    },
    dialogHeader: {
      marginTop: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogBody: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    dialogUnit: {
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    },
    dialogIcon: {
      width: 80,
      height: 80
    },
    para: {
        color: '#101010',
        lineHeight: 1.3,
        fontSize: 14,
        margin: 0,
        marginBottom: 8,
        textAlign: 'center'
    },
    button: {
      backgroundColor: '#464646',
      height: 36,
      minWidth: 80,
      padding: '0 10px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    }
}

export default WarningDiaog;