import React from 'react'
import ElementWrapper from '../elementWrapper/elementWrapper'

export default function TextArea({ label, isError, ...props }) {
    return (
        <ElementWrapper label={label} isError={isError}>
            <div>
                <textarea
                    {...props}
                    style={styles.inputTextArea}
                />
            </div>
        </ElementWrapper>
    )
}

const styles = {
    inputTextArea: {
        fontSize: 16,
        flex: 1,
        backgroundColor: 'transparent',
        border: 'none',
        border: 0,
        padding: 8,
        width: '96%',
        backgroundColor: '#fff',
        borderRadius: 8,
        justifyContent: 'center',
        display: 'flex',
        height: 60,
        resize: 'vertical',
    },
}