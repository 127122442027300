import edit_icon from './icons_edit.svg';
import logout_icon from './icons_logout.svg';
import withdraw_icon from './icons_withdraw.svg';
import password_icon from './icons_password.svg';
import notifications_icon from './icons_notifications.svg';
import auto_wallet_topup_icon from './icons_auto_wallet_topup.svg';
import plus_icon from './icons_plus.svg';
import minus_icon from './icons_minus.svg';
import warning_icon from './icons_warning.svg';
import schedule_warning_icon from './icons_timer_warning.svg';
import timer_warning_icon from './icons_schedule_warning.svg';
import expand_more_icon from './icons_expand_more.svg';
import expand_less_icon from './icons_expand_less.svg';
import help from './help.svg';

const images = {
    pins: {
        user: require('./pin_user_location.png'),
        open: require('./pin_point_open.png'),
        multi: require('./pin_point_multi.png'),
        out: require('./pin_point_oou.png'),
        inuse: require('./pin_point_used.png'),
        fault: require('./pin_point_fault.png'),
    },
    homefooter: {
        stats: require('./home_footer_stats_icon.png'),
        profile: require('./home_footer_profile_icon.png'),
        favorites: require('./home_footer_favorites_icon.png'),
        journey: require('./home_footer_journey_icon.png'),
        sessions: require('./home_footer_sessions_icon.png'),
    },
    homeheaderbg: require('./home_header_bg.jpg'),
    homesearch: require('./home_search.png'),
    homefilter: require('./home_filter.png'),
    homeQr: require('./home_qr.png'),
    homelocation: require('./home_location.png'),
    menubtn: require('./menu_btn.png'),
    navArrow: require('./nav_arrow.png'),
    navArrowGrey: require('./nav_arrow_grey.png'),
    star: require('./star.png'),
    starBlue: require('./star_blue.png'),
    warning: require('./warning.png'),
    warning_large: require('./warning_large.png'),
    fault: require('./fault_icon.png'),
    paid: require('./paid.png'),
    free: require('./free.png'),
    navigate: require('./navigate.png'),
    bolt: require('./bolt.png'),
    bolt_white: require('./bolt_white.png'),
    bg: require('./bg.jpg'),
    dark_bg: require('./dark_bg.jpg'),
    vend_logo: require('./vend_logo.png'),
    vend_logo_inverse: require('./vend_logo_inverse.png'),
    rolec_logo: require('./rolec_logo.png'),
    close_btn: require('./close.png'),
    remove_btn: require('./remove.png'),
    remove_btn_blue: require('./remove_blue.png'),
    check: require('./check.png'),
    check_circle: require('./check_circle.png'),
    bullet: require('./bullet.png'),
    bullet_full: require('./bullet_full.png'),
    battery_charging: require('./battery_charging.gif'),
    onboarding: {
        home: require('./get_started/vend-app-home.jpg'),
        location: require('./get_started/vend-app-location.jpg'),
        point: require('./get_started/vend-app-point.jpg'),
        charging: require('./get_started/vend-app-charging.jpg'),
    },
    load_managed: require('./load_managed.png'),
    charging: {
        connect: require('./charging/charging-connect.png'),
        connected1: require('./charging/charging-connected-1.png'),
        connected2: require('./charging/charging-connected-2.png'),
        connected3: require('./charging/charging-connected-3.png'),
        connected4: require('./charging/charging-connected-4.png'),
    },
    charging_status: {
        charging: require('./charging/status-charging.png'),
        connected: require('./charging/status-connected.png'),
        connect: require('./charging/status-connect.png')
    },
    cable_connect: require('./charging/cable-connect.png'),
    rfid_present: require('./rfid_present.svg'),
    rfid_grey: require('./rfid_grey.svg'),
    rfid_icon: require('./rfid_icon.png'),
    rfid_icon_grey: require('./rfid_icon_grey.png'),
    rfid_icon_white: require('./rfid_icon_white.png'),
    help,
    app_store: {
        ios: require('./app_store_ios.png'),
        google: require('./app_store_google_play.png')
    },
    download: require('./download.png'),
    complete: require('./complete.png'),
    rightArrow: require('./right-arrow.png'),
    edit_icon,
    logout_icon,
    withdraw_icon,
    password_icon,
    notifications_icon,
    scan_bg: require('./scan-bg.png'),
    rolec_powered: require('./rolec-powered.png'),
    logo_icon: require('./logo-icon.png'),
    logo_text: require('./logo-text.png'),
    auto_wallet_topup_icon,
    plus_icon,
    minus_icon,
    warning_icon,
    schedule_warning_icon,
    timer_warning_icon,
    expand_less_icon,
    expand_more_icon
};

export default images;