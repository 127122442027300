import React from "react";
import Images from "../../assets/images";
import { Translation } from "react-i18next";

const PaymentMethodSelect = (props) => {
  return (
    <Translation>
      {(t) => (
        <div
          style={Object.assign(
            {},
            styles.dialogBg,
            props.show && styles.dialogIn,
          )}
        >
          <div style={styles.dialog} className="animate">
            <div style={styles.dialogWrapper}>
              <span style={styles.dialogHeader}>{t("PAYMENT_METHOD")}</span>

              <div style={styles.dialogBody}>
                <div
                  style={styles.dialogUnit}
                  onClick={() => {
                    props.onSelect("WALLET");
                  }}
                >
                  <div style={styles.dialogIcon}>
                    {props.paymentMethod === "WALLET" ? (
                      <img
                        src={Images.bullet_full}
                        style={{ width: 20, height: 20 }}
                        alt="Bullet"
                      />
                    ) : (
                      <img
                        src={Images.bullet}
                        style={{ width: 20, height: 20 }}
                        alt="Bullet"
                      />
                    )}
                  </div>
                  <div style={styles.detail}>{t("WALLET")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const styles = {
  dialogBg: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "50%",
    left: "50%",
    backgroundColor: "rgba(0,0,0,.3)",
    zIndex: 989,
    transform: "translate(-50%, 100%)",
    visibility: "hidden",
    opacity: 0,
  },
  dialogIn: {
    transform: "translate(-50%, -50%)",
    visibility: "visible",
    opacity: 100,
  },
  dialog: {
    display: "block",
    width: 280,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    boxShadow: "0 0 6px rgba(0,0,0,.3)",
    zIndex: 990,
    borderRadius: 12,
  },
  dialogWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  dialogHeader: {
    fontSize: 18,
    fontWeight: 900,
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  header: {
    fontSize: 18,
    marginTop: 15,
    marginBottom: 10,
    color: "#484848",
    fontWeight: 600,
  },
  content: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  dialogBody: {
    flex: 1,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  dialogUnit: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  dialogIcon: {
    marginRight: 20,
  },
  close: {
    width: 90,
    height: 30,
    backgroundColor: "#6CB33F",
    border: "none",
    color: "#fff",
    borderRadius: 6,
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },
};

export default PaymentMethodSelect;
