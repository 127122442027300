import React from 'react';
import { Translation } from 'react-i18next';

const homeHeaderToggle = props => {

    return (
        <Translation>
            {t => 
            <div style={styles.container} onClick={props.onToggleMapView}>
                <div style={Object.assign({}, styles.toggle, props.mapView?styles.toggleMap:styles.toggleList)}></div>
                <div style={styles.outline}>
                    <span style={styles.label}><span style={props.mapView?styles.textActive:styles.text}>{t('MAP')}</span></span>
                    <span style={styles.label}><span style={props.mapView?styles.text:styles.textActive}>{t('LIST')}</span></span>
                </div>
            </div>
            }
        </Translation>
    
    )

}

const styles = {
    container: {
        width: 120,
        height: 30,
        position: 'relative',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ffffff',
        borderRadius: 25,
    },
    toggle: {
        position: 'absolute',
        top: 0,
        width: '50%',
        height: '100%',
        backgroundColor: '#ffffff',
        borderRadius: 25,
        zIndex: 70
    },
    toggleMap: {
        left: '0'
    },
    toggleList: {
        right: '0'
    },
    outline: {
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        zIndex: 80,
        cursor: 'pointer'
    },
    label: {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px'
    },
    text: {
        color: '#ffffff'
    },
    textActive: {
        color: '#484848'
    }
};

export default homeHeaderToggle;