import React from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import { Translation } from 'react-i18next';


class AppStoreScreen extends React.Component {

  static contextType = GlobalContext;

  constructor(props){
    super(props);
  }

  finish = () => {
    this.context.userDismissNativePrompt();
    this.goBack();
  }

  goBack = () => {
    console.log(this.props.history.action);

    // if(this.props.history.action === 'POP'){
    //   console.log('route');
    //   this.props.history.push('/');
    // } else {
    //   console.log('go back');
    //   this.props.history.goBack();
    // }

    this.props.history.replace('/');
  }

  render() {
    return (
    <Translation>{t => 
      <div style={styles.container}>   
        <div style={styles.wrapper}>   
          <div style={styles.panel}>
            <h2 style={styles.panelHeading}>{t('APP_STORE_HEADING')}</h2>
            <span style={styles.panelDescription}>{t('APP_STORE_DESC')}</span>
            <a href="https://apps.apple.com/us/app/vendelectric/id1492980500?ls=1" rel="nofollow" target="_blank"><img src={Images.app_store.ios} style={{width: 200, marginBottom: 10}} alt="Download from the App Store" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.vendelectric.app" rel="nofollow" target="_blank"><img src={Images.app_store.google} style={{width: 200, marginBottom: 10}} alt="Download from Google Play" /></a>
            
            <span style={styles.noThanks}>{t('APP_STORE_DECLINE')}</span>
            <div style={styles.buttonGroup}>
              <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.finish}><span style={{color: '#ffffff'}}>{t('CONTINUE')}</span></a>
            </div>
            {/* <img src={Images.vend_logo} style={{width: 200}}/> */}
          </div>
        </div>
      </div>
      }</Translation>
    );
  }

}


const styles = {
  container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
  },
  wrapper: {
    backgroundColor: '#fff',
    height: '100%',
    width: '80%',
    maxWidth: 400,
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'auto'
  },
  wrapper: {
    backgroundColor: '#222',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    backgroundImage: 'url('+Images.dark_bg+')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    overflow: 'hidden'
  },
  skip: {
    color: '#848484',
    marginBottom: 10
  },
  buttonGroup: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonGroupBtn: {
    width: 125,
    height: 50,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    backgroundColor: '#555555',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
    backgroundColor: '#6CB33F'
  },
  panels: {
    position: 'relative',
    width: '100%',
    flex: 1,
    maxWidth: 300,
    overflow: 'hidden',
    overflowY: 'auto'
  },
  panel: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '20%',
    flexDirection: 'column'
  },
  panelIn: {
    transform: 'translateX(0)',
  },
  panelOut: {
    transform: 'translateX(100%)',
    visibility: 'hidden'
  },
  panelDone: {
    transform: 'translateX(-100%)',
    visibility: 'hidden'
  },
  panelBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  panelHeader: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    paddingBottom: 20,
    paddingTop: 20
  },
  panelFooter: {
    width: '100%',
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  panelHeading: {
    margin: 0,
    color: '#ffffff',
    marginBottom: 10,
    marginTop: 15,
    textAlign: 'center'
  },
  panelDescription: {
    fontSize: 14,
    color: '#ffffff',
    marginBottom: 20,
    textAlign: 'center'
  },
  noThanks: {
    fontSize: 14,
    color: '#ffffff',
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center'
  },
  panelImage: {
    flex: 1,
    display: 'flex',
    backgroundSize: 'contain',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat'
  },
  guideImage: {
    height: '100%',
    maxHeight: 582,
    alignSelf: 'flex-end'
  },
};


export default AppStoreScreen;