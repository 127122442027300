import React, { useContext, useState } from 'react';
import Images from '../../assets/images';
import { Translation } from 'react-i18next';
import globalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';


const ChargeSessionsDownloadDialog = props => {

    const [ downloading, setDownloading ] = useState(false);
    const [ complete, setComplete ] = useState(false);
    const [ error, setError ] = useState(false);
    const context = useContext(globalContext);

    const handleDownload = () => {
        setDownloading(true);
        context.downloadSessions(props.days).then(
        res => {
            setDownloading(false);
            setComplete(true);
        },
        err => {
            setDownloading(false);
            setError(true);
        }
      );
    }

    const handleClose = () => {
      setDownloading(false);
      setComplete(false);
      setError(false);
      props.close();
    }

    return (
        <Translation>{t => 
            <React.Fragment>
                {props.open === true && <div style={styles.dialogBg} onClick={handleClose}></div>}
                <div style={Object.assign({}, styles.dialog, props.open === true && styles.dialogIn)} className="animate">
                    <div style={styles.dialogWrapper}>
                        {(!complete && !error) && <div style={styles.dialogBody}>
                            <img src={Images.download} alt="Download" style={styles.dialogIcon}/>
                            <span style={styles.dialogHeader}>{t('DOWNLOAD')}</span>
                            <h5 style={styles.para}>{t('CHARGE_SESSION_DOWNLOAD')}</h5>
                            <button onClick={handleDownload} style={styles.button} disabled={downloading}>
                              {!downloading && t('OK')}
                              {downloading && <div style={styles.downloading}><ActivityIndicator /></div>}
                            </button>
                        </div>}
                        {complete && <div style={styles.dialogBody}>
                            <img src={Images.complete} alt="Complete" style={styles.dialogIcon}/>
                            <h5 style={styles.para}>{t('CHARGE_SESSION_DOWNLOAD_SUCCESS')}</h5>
                            <button onClick={handleClose} style={styles.button}>{t('CLOSE')}</button>
                        </div>}
                        {error && <div style={styles.dialogBody}>
                            <img src={Images.fault} alt="Error" style={styles.dialogIcon}/>
                            <h5 style={styles.para}>{t('CHARGE_SESSION_DOWNLOAD_ERROR')}</h5>
                            <button onClick={handleClose} style={styles.button}>{t('CLOSE')}</button>
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        }</Translation>
    )

}

const styles = {
    dialogBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 989
    },
    dialog: {
      display: 'block',
      width: 280,
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: '#fff',
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      transform: 'translate(-50%, 100%)',
      visibility: 'hidden',
      opacity: 0,
      zIndex: 990,
      borderRadius: 6
    },
    dialogIn: {
      transform: 'translate(-50%, -50%)',
      visibility: 'visible',
      opacity: 100,
    },
    dialogWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column'
    },
    dialogHeader: {
      fontSize: 18,
      fontWeight: 900,
      marginTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
      color: '#404040'
    },
    dialogBody: {
      flex: 1,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    dialogUnit: {
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    },
    // dialogIcon: {
    //   marginRight: 10,
    //   width: 30,
    //   height: 30
    // },
    para: {
        color: '#101010',
        lineHeight: 1.3,
        fontSize: 14,
        margin: 0,
        marginBottom: 10,
        textAlign: 'center'
    },
    button: {
      backgroundColor: '#464646',
      height: 36,
      minWidth: 80,
      padding: '0 10px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    downloading: {
      width: 28,
      height: 28,
      backgroundColor: '#464646',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      color: '#888',
      borderRadius: 14
    },
    dialogIcon: {
      width: 80,
      height: 80
    },
}

export default ChargeSessionsDownloadDialog;