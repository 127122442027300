import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Button(props) {
    const { t } = useTranslation();
    return (
        <div style={styles.buttonGroup}>
            <button id ="sendButton" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={props.onClick}>
                <span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('SEND')}</span>
            </button>
        </div>
    )
}

const styles = {
    buttonGroup: {
        width: '100%',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    buttonGroupBtn: {
        width: 100,
        height: 40,
        backgroundColor: '#555555',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    buttonGroupBtnPrimary: {
        backgroundColor: '#6CB33F'
    },
}
