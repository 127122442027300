import React, { useContext } from 'react';
import Images from '../../assets/images/index';
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import globalContext from '../../context/global-context';

const NavDrawerComponent = props => {

  const { t } = useTranslation();
  const context = useContext(globalContext);

  return (
    <nav style={Object.assign({}, styles.drawerNav, context.isMenuOpen && styles.drawerNavOpen)} className="animate">
      {!context.isLoggedIn
        ? <div style={styles.header}>
          <Link id="login" style={styles.headerTextView} to="/Login" onClick={context.closeMenu}><span style={styles.headerTextBold}>{t('MENU_LOGIN')}</span></Link>
        </div>
        : <div style={styles.header}>
          <div style={styles.headerTextView}>
            <span style={styles.headerTextBold}>{context.getFullName()}</span>
          </div>
          <div style={styles.headerTextView}>
            <span style={styles.headerText}>{(context.account.vehicles.length ? context.getVehicleReg() : t('MENU_NOVEHICLE'))}</span>
            <Link style={styles.headerTextButton} to="/Vehicles" onClick={context.closeMenu}><span style={styles.headerTextButtonText}>{(context.account.vehicles.length ? t('CHANGE') : t('ADD'))}</span></Link>
          </div>
          <div style={styles.headerTextView}>
            <span style={styles.headerText}>{parseFloat(context.wallet.balance).toLocaleString(navigator.language, { style: 'currency', currency: context.wallet.currency })}</span>
            <Link style={styles.headerTextButton} to="/account/topup" onClick={context.closeMenu}><span style={styles.headerTextButtonText}>{t('TOPUP')}</span></Link>
          </div>
          <Link style={styles.menuItem} to="/account" onClick={context.closeMenu}>
            <span style={styles.menuItemText}>{t('MENU_ACC')}</span>
            <img src={Images.navArrow} style={styles.menuItemIcon} alt="Account" />
          </Link>
        </div>
      }

      <div style={styles.footer}>
        <a href="https://www.rolecserv.com/vendelectric" target="_blank" style={styles.menuItem} onClick={context.closeMenu}>
          <span style={styles.menuItemText}>{t('MENU_ABOUT')}</span>
          <img src={Images.navArrow} style={styles.menuItemIcon} alt="About" />
        </a>

        <Link style={styles.menuItem} to="/feedback" onClick={context.closeMenu}>
          <span id="feedback" style={styles.menuItemText}>{t('MENU_FEED')}</span>
          <img src={Images.navArrow} style={styles.menuItemIcon} alt="Feedback" />
        </Link>

        <a href="https://www.rolecserv.com/rolec-smart-solutions/app-privacy-policy---vendelectric" target="_blank" style={styles.smallMenuItem} onClick={context.closeMenu}>
          <span style={styles.smallMenuItemText}>{t('MENU_PRIVACY')}</span>
        </a>

        <a href="https://www.rolecserv.com/rolec-smart-solutions/app-end-user-licence-agreement---vendelectric" target="_blank" style={styles.smallMenuItem} onClick={context.closeMenu}>
          <span style={styles.smallMenuItemText}>{t('MENU_TERMS')}</span>
        </a>

        <span style={styles.version}>v{context.appVersion()}</span>
      </div>
    </nav>
  );
}

const styles = {
  drawerNav: {
    position: 'absolute',
    width: '300px',
    height: '100%',
    top: 0,
    right: 0,
    backgroundColor: '#444',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    overflow: 'auto',
    transform: 'translateX(300px)'
  },
  drawerNavOpen: {
    transform: 'translateX(0)'
  },
  header: {
    flex: 1,
    paddingTop: 60,
    paddingBottom: 30
  },
  footer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    justifyContent: 'flex-end'
  },
  headerTextView: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 30,
    paddingLeft: 30
  },
  headerText: {
    flex: 1,
    fontSize: 20,
    color: '#6CB33F',
    marginBottom: 15,
    fontWeight: '400'
  },
  headerTextBold: {
    flex: 1,
    fontSize: 18,
    color: '#6CB33F',
    marginBottom: 15,
    fontWeight: '900'
  },
  headerTextButton: {
    flex: 1
  },
  headerTextButtonText: {
    textAlign: 'right',
    fontSize: 12,
    color: '#6CB33F',
    marginBottom: 15,
    fontWeight: '300'
  },
  menuItem: {
    display: 'flex',
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30
  },
  menuItemText: {
    fontSize: 17,
    fontWeight: 'normal',
    color: '#ffffff'
  },
  menuItemIcon: {
    height: 10,
    width: 10
  },
  smallMenuItem: {
    display: 'flex',
    height: 26,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30
  },
  smallMenuItemText: {
    fontSize: 13,
    fontWeight: 'normal',
    color: '#d2d2d2'
  },
  smallMenuItemIcon: {
    height: 10,
    width: 10
  },
  version: {
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 11,
    color: '#d2d2d2'
  }
};

export default NavDrawerComponent;