import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/userReducer";
import sites from "../reducers/sitesReducer";
import account from "../reducers/accountReducer";
import history from "../reducers/historyReducer";

export default createStore(
    combineReducers({
        user,
        sites,
        account,
        history
    }),
    {},
    applyMiddleware(thunk)
)