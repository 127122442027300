import React from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';

const chargePointsMapGetLocation = props => {

    return (  
        <GlobalContext.Consumer>
            {context => (
                <div style={{bottom: props.bottom, right: 30, position: 'absolute', height: 50, width: 50, zIndex: 98}}>
                    <a href="JavaScript:void(0);" style={styles.locationButton} onClick={context.focusOnUserLocation}>
                        <img src={Images.homelocation} style={styles.locationIcon} alt="Your location" />
                    </a>
                </div>
            )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    locationButton: {
        width: 50,
        height: 50,
        backgroundColor: '#ffffff',
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    locationIcon: {
        width: 20,
        height: 20,
        margin: 14
    }
};

export default chargePointsMapGetLocation;