import React from 'react';
import GlobalSwitch from '../global/Switch';
import GlobalContext from '../../context/global-context';
import Images from '../../assets/images';
import { Translation } from 'react-i18next';


const mapFilter = props => {

    //const speedFilters = props.filters.speeds.map((val, key) => (<div key={key} style={styles.filterItem}><span style={styles.filterItemLabel}>{val.label}</span><div style={styles.filterItemSwitch}><Switch value={val.value} onValueChange={(value) => {props.onToggleFilterItem('speeds', key, value)}} /></div></div>));
    //const connectorTypeFilters = props.filters.types.map((val, key) => (<div key={key} style={styles.filterItem}><span style={styles.filterItemLabel}>{val.label}</span><div style={styles.filterItemSwitch}><GlobalSwitch checked={val.value} id={'filter-'+key} onChange={() => {props.onToggleFilterItem('types', key)}} /></div></div>));

    return (
        <GlobalContext.Consumer>
            {context => (
                <Translation>
                    {t => 
                    <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
                    
                        <div style={styles.header}>
                            <span style={styles.heading}>{t('FILTER')}</span>
                            <a href="javascript:void(0)" style={styles.closeButton} onClick={props.onPressFilterClose}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>
                        </div>
                        <div style={styles.filters}>
                            <div style={styles.filterItem}>
                                <span style={styles.filterItemLabel}>{t('FILTER_PUBLIC')}</span>
                                <div style={styles.filterItemSwitch}>
                                    <GlobalSwitch checked={context.account.preferences.showPublic} id={'filter-public'} onChange={() => {context.preferenceToggle('showPublic')}} />
                                </div>
                            </div>
                            <div style={styles.filterItem}>
                                <span style={styles.filterItemLabel}>{t('FILTER_PRIVATE')}</span>
                                <div style={styles.filterItemSwitch}>
                                    <GlobalSwitch checked={context.account.preferences.showPrivate} id={'filter-private'} onChange={() => {context.preferenceToggle('showPrivate')}} />
                                </div>
                            </div>
                            <div style={styles.filterItem}>
                                <span style={styles.filterItemLabel}>{t('FILTER_AVAILABLE')}</span>
                                <div style={styles.filterItemSwitch}>
                                    <GlobalSwitch checked={context.account.preferences.availableOnly} id={'filter-availableOnly'} onChange={() => {context.preferenceToggle('availableOnly')}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </Translation>
            )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '102'
    },
    containerIn: {
        transform: 'translateX(0)'
    },
    containerOut: {
        transform: 'translateX(100%)'
    },
    header: {
        marginTop: 30,
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        width: '100%',
        justifyContent: 'center'
    },
    heading: {
        fontSize: 24,
        fontWeight: 900,
        marginLeft: 20
    },
    closeButton: {
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99
    },
    closeText: {
        color: '#fff',
    },
    filters: {
        flex: 1,
        width: '100%'
    },
    filterHeader: {
        height: 60,
        backgroundColor: '#efefef',
        width: '100%',
        paddingLeft: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    filterHeaderText: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    filterItem: {
        width: '100%',
        display: 'flex',
        height: 50,
        borderBottomWidth: 1,
        borderBottomColor: '#efefef',
        borderBottomStyle: 'solid',
        alignItems: 'center',
        flexDirection: 'row',
    },
    filterItemLabel: {
        paddingLeft: 20,
        flex: 1,
        fontSize: 16
    },
    filterItemSwitch: {
        paddingRight: 20
    },
};

export default mapFilter;