import React from 'react'
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation, Trans } from 'react-i18next';

const height = window.innerHeight;
const width = window.innerWidth;

let mounted = false;

class guestCheckoutForm extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                firstname: '',
                lastname: '',
                email: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                county: '',
                postcode: '',
                country: 'UK',
                save: false,
                password: '',
                confirm_password: '',
                terms: false,
                marketing: false
            },
            errors: {
                firstname: this.errorField('panel1'),
                lastname: this.errorField('panel1'),
                email: this.errorField('panel1'),
                address1: this.errorField('panel2'),
                address2: this.errorField('panel2'),
                city: this.errorField('panel2'),
                county: this.errorField('panel2'),
                postcode: this.errorField('panel2'),
                country: this.errorField('panel2'),
                mobile: this.errorField('panel3'),
                password: this.errorField('panel3'),
                confirm_password: this.errorField('panel3'),
                terms: this.errorField('panel3'),
                marketing: this.errorField('panel3')
            },
            isValid: false,
            isLoading: false,
            showRegister: false,
            activePanel: 1
        };
    }

    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }


    bindField = (e) => {
        let fields = {...this.state.fields};
        if(e.target.type === 'checkbox'){
            fields[e.target.name] = e.target.checked;
        } else {
            fields[e.target.name] = e.target.value;
        }

        this.setState({fields: fields}, () => {this.validate()});
    }

    dirtyGroup = (group = null, callback = () => {}) => {
        let errors = {...this.state.errors};
        Object.values(errors).map(e => {
            if(e.group === group)
                e.dirty = true;
        })
        this.setState({errors: errors}, () => {callback()});
    }

    validate = (callback = () => {}) => {
        let errors = {...this.state.errors};
        
        let required = [
            'firstname',
            'lastname',
            'email',
            'address1',
            'city',
            'postcode'
        ];

        if(this.state.fields.save){
            required.push('password');
            required.push('confirm_password');
        }

        required.map(f => {
            if(this.state.fields[f].length > 0)
                errors[f].dirty = true;

            errors[f].invalid = false;
            if(errors[f].dirty && this.state.fields[f] === ''){
                errors[f].invalid = true;
            }
        })

    
        errors.terms.invalid = false;
        errors.password.invalid = false;
        errors.confirm_password.invalid = false;
        if(this.state.fields.save){
            if(errors.terms.dirty && this.state.fields.terms !== true)
                errors.terms.invalid = true;

            // Validate password minimum criteria
            if(errors.password.dirty && !this.state.fields.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)){
                errors.password.invalid = true;
            }

            // Validate passwords match
            if(errors.password.dirty && errors.confirm_password.dirty && this.state.fields.password !== this.state.fields.confirm_password){
                errors.confirm_password.invalid = true;
            }

        }

        // Validate email
        if(errors.email.dirty && !this.state.fields.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)){
            errors.email.invalid = true;
        }

        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({errors: errors, isValid: isValid}, () => {callback()});
    }


    panel1Next = () => {
        this.dirtyGroup('panel1', () => {
            this.validate(() => {
                if(!this.state.errors.firstname.invalid && !this.state.errors.lastname.invalid && !this.state.errors.email.invalid && !this.state.errors.mobile.invalid)
                this.registerMovePanel(2);
            });
        })
    }

    panel2Back = () => {
        this.registerMovePanel(1);
    }

    panel2Next = () => {

        this.dirtyGroup('panel2', () => {
            
            if(this.state.fields.save){
                if(!this.state.errors.address1.invalid && !this.state.errors.city.invalid && !this.state.errors.postcode.invalid)
                this.registerMovePanel(3);
            }
            else {
                this.validate(() => {
                    if(this.state.isValid){
                            this.setState({isLoading: true}, () => {

                                this.context.userAddGuest(this.state.fields)
                                .then((user) => {

                                    if(mounted){
                                        this.setState({isLoading: false}, () => {
                                            this.props.onSuccess();
                                        });
                                    }
                                    
                                })
                                .catch((err)=>{console.log(err)});

                            });
                    } else {

                        console.log('not valid')
                    }
                });
            }
        })
    }

    panel3Next = () => {

        this.dirtyGroup('panel3', () => {
            this.validate(() => {
                if(this.state.isValid){
                    this.setState({isLoading: true});

                    setTimeout(() => {

                        this.context.userRegister(this.state.fields)
                        .then((user) => {
                            this.setState({isLoading: false});
                        })
                        .catch((err)=>{console.log(err)});

                    }, 1000)
                } else {

                    console.log('not valid')
                }
            });
        })
    }

    panel3Back = () => {
        this.registerMovePanel(2);
    }

    registerMovePanel = (idx) => {

        // let panels = {
        // ...this.state.panels,
        // p1: this.registerPanelPos(idx, 0),
        // p2: this.registerPanelPos(idx, 1),
        // p3: this.registerPanelPos(idx, 2)
        // };
        this.setState({activePanel: idx});
    }

    // registerPanelPos = (idx, pnl) => {
    //     return idx === pnl?0:idx>pnl?-width:width;
    // }

    componentDidMount() {
        mounted = true;
    }

    componentWillUnmount() {
        mounted = false;
    }


    render() {
        const errors = this.state.errors;
        return (
        <Translation>{t => 
            <div style={Object.assign({}, styles.container, this.props.show ? styles.containerIn:styles.containerOut)} className="animate">
                <div style={styles.bgImg}>
                    <a href="javascript:void(0)" style={styles.closeButton} onClick={this.props.close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></a>
                    
                    
                    {this.state.isLoading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
                    
                    {!this.state.isLoading && <div style={styles.registerWrapper}>


                        <div style={Object.assign({}, styles.panel, this.state.activePanel === 1?styles.panelIn:styles.panelOut)} className="animate">
                            <div style={styles.panelBody}>
                                <span style={styles.header}>{t('CHECKOUT_GUEST')}</span>
                                <span style={styles.requiredFields}>{'*' + t('REQUIRED_FIELDS')}</span>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.firstname.invalid && styles.inputError)}>
                                    <input name={'firstname'} onChange={this.bindField} placeholder={t('FIRST_NAME')+'*'} style={styles.input} />
                                    {errors.firstname.invalid && <span style={styles.inputErrorMsg}>{t('FIRST_NAME_ERR')}</span>}
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.lastname.invalid && styles.inputError)}>
                                    <input name={'lastname'} onChange={this.bindField} placeholder={t('LAST_NAME')+'*'} style={styles.input} />
                                    {errors.lastname.invalid && <span style={styles.inputErrorMsg}>{t('LAST_NAME_ERR')}</span>}
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.email.invalid && styles.inputError)}>
                                    <input type={'email'} name={'email'} onChange={this.bindField} placeholder={t('EMAIL')+'*'} style={styles.input} />
                                    {errors.email.invalid && <span style={styles.inputErrorMsg}>{t('EMAIL_ERR')}</span>}
                                </div>
                                <div style={Object.assign({}, styles.buttonGroup, { justifyContent: 'flex-end' })}>
                                    <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.panel1Next}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('NEXT')}</span></a>
                                </div>
                            </div>
                        </div>

                        <div style={Object.assign({}, styles.panel, this.state.activePanel === 2?styles.panelIn:styles.panelOut)} className="animate">
                            <div style={styles.panelBody}>
                                <span style={styles.header}>{t('YOUR_ADDRESS')}</span>
                                <span style={styles.requiredFields}>{'*' + t('REQUIRED_FIELDS')}</span>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.address1.invalid && styles.inputError)}>
                                    <input name={'address1'} onChange={this.bindField} placeholder={t('ADD_1')+'*'} style={styles.input} />
                                    {errors.address1.invalid && <span style={styles.inputErrorMsg}>{t('ADD_1_ERR')}</span>}
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper)}>
                                    <input name={'address2'} onChange={this.bindField} placeholder={t('ADD_2')} style={styles.input} />
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.city.invalid && styles.inputError)}>
                                    <input name={'city'} onChange={this.bindField} placeholder={t('TOWN')+'*'} style={styles.input} />
                                    {errors.city.invalid && <span style={styles.inputErrorMsg}>{t('TOWN_ERR')}</span>}
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper)}>
                                    <input name={'county'} onChange={this.bindField} placeholder={t('COUNTY')} style={styles.input} />
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.postcode.invalid && styles.inputError)}>
                                    <input name={'postcode'} onChange={this.bindField} placeholder={t('POSTCODE')+'*'} style={styles.input} />
                                    {errors.postcode.invalid && <span style={styles.inputErrorMsg}>{t('POSTCODE_ERR')}</span>}
                                </div>
                                <div>
                                    <input type={'checkbox'} name={'save'} id={'save'} onChange={this.bindField} style={styles.input} />
                                    <label htmlFor={'save'}>{t('SAVE_DETAILS')}</label>
                                </div>

                                <div style={styles.buttonGroup}>
                                    <a href="JavaScript:void(0);" style={styles.buttonGroupBtn}><span style={{ color: '#ffffff', textTransform: 'uppercase' }} onClick={this.panel2Back}>{t('BACK')}</span></a>
                                    <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.panel2Next}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('NEXT')}</span></a>
                                </div>
                            </div>
                        </div>

                        <div style={Object.assign({}, styles.panel, this.state.activePanel === 3?styles.panelIn:styles.panelOut)} className="animate">
                            <div style={styles.panelBody}>
                                <span style={styles.header}>{t('SET_PASSWORD')}</span>
                                <span style={styles.requiredFields}>{'*' + t('REQUIRED_FIELDS')}</span>
                                <div style={Object.assign({}, styles.inputWrapper, errors.mobile.invalid && styles.inputError)}>
                                    <input type={'number'} name={'mobile'} onChange={this.bindField} placeholder={t('MOBILE')} style={styles.input} />
                                    {errors.mobile.invalid && <span style={styles.inputErrorMsg}>{t('MOBILE_ERR')}</span>}
                                </div>
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.password.invalid && styles.inputError)}>
                                    <input type={'password'} name={'password'} onChange={this.bindField} placeholder={t('PASSWORD')+'*'} style={styles.input} />
                                </div>
                                {errors.password.invalid && <span style={styles.inputErrorMsg}>{t('PASSWORD_REQUIREMENT')}</span>}
                                <div style={Object.assign({}, styles.inputWrapper, styles.inputRequired, errors.confirm_password.invalid && styles.inputError)}>
                                    <input type={'password'} name={'confirm_password'} onChange={this.bindField} placeholder={t('CONFIRM_PASSWORD')+'*'} style={styles.input} />
                                </div>
                                {errors.confirm_password.invalid && <span style={styles.inputErrorMsg}>{t('PASSWORD_NOMATCH')}</span>}
                                <div>
                                    <div style={styles.checkbox}>
                                        <input type={'checkbox'} name={'marketing'} id={'marketing'} onChange={this.bindField} style={styles.input} />
                                        <label htmlFor={'marketing'}>{t('REGISTER_MARKETING')}</label>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.checkbox}>
                                        <input type={'checkbox'} name={'terms'} id={'terms'} onChange={this.bindField} style={styles.input} />
                                        <label htmlFor={'terms'}>
                                            <Trans
                                                i18nKey="I_AGREE"
                                                components={[
                                                    <a href="https://www.rolecserv.com/rolec-smart-solutions/app-end-user-licence-agreement---vendelectric" style={{color: '#222', fontWeight: 'bold', textDecoration: 'underline'}} target="_blank" />,
                                                    <a href="https://www.rolecserv.com/rolec-smart-solutions/app-privacy-policy---vendelectric" style={{color: '#222', fontWeight: 'bold', textDecoration: 'underline'}} target="_blank" />
                                                ]}
                                            /></label>
                                    </div>
                                    {errors.terms.invalid && <span style={Object.assign({}, styles.inputErrorMsg, {display: 'block', marginTop: 4})}>{t('TERMS_AGREE_ERR')}</span>}
                                </div>
                                <div style={styles.buttonGroup}>
                                    <a href="JavaScript:void(0);" style={styles.buttonGroupBtn}><span style={{ color: '#ffffff', textTransform: 'uppercase' }} onClick={this.panel3Back}>{t('BACK')}</span></a>
                                    <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.panel3Next}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('REGISTER')}</span></a>
                                </div>
                            </div>
                        </div>

                    </div>}

                    
                    <div style={styles.rolecLogoWrapper}>
                        <img src={Images.rolec_logo} style={styles.rolecLogo} alt="Powered by Rolec" />
                    </div>
                    
                </div>
            </div>
        }</Translation>
        )
    }
}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '90'
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    bgImg: {
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(' + Images.bg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        display: 'flex'
    },
    vendLogo: {
        width: 250,
        height: 58,
        marginBottom: 40,
        alignSelf: 'center'
    },
    closeButton: {
        width: 40,
        height: 40,
        top: 70,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99
    },
    registerWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        maxWidth: 300,
        overflow: 'hidden'
    },
    panel: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: 95,
        justifyContent: 'center',
        display: 'flex'
    },
    panelIn: {
        transform: 'translateX(0)',
    },
    panelOut: {
        transform: 'translateX(100%)',
        visibility: 'hidden'
    },
    panelBody: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20
    },
    requiredFields: {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 20,
        marginTop: -16,
        color: '#555555',
        alignSelf: 'flex-start'
    },
    rolecLogo: {
        position: 'absolute',
        bottom: 30,
        right: 30,
        width: 110,
        height: 25,
        zIndex: 98
    },
    buttonGroup: {
        width: '100%',
        marginTop: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonGroupBtn: {
        width: 140,
        height: 50,
        backgroundColor: '#555555',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    buttonGroupBtnPrimary: {
        backgroundColor: '#6CB33F'
    },
    inputWrapper: {
        height: 54,
        width: '100%',
        backgroundColor: '#ebebeb',
        borderRadius: 12,
        marginBottom: 10,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    input: {
        fontSize: 16,
        flex: 1,
        paddingLeft: 20
    },
    inputRequired: {
      boxShadow: 'inset 0 0 0 1px #A7A7A7'
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      marginTop: -8,
      alignSelf: 'flex-start'
    },
    loadingWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loading: {
      backgroundColor: '#6CB33F',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        fontSize: 13
    }
}

export default guestCheckoutForm;