import React from 'react';
import Images from '../../assets/images/index';
import { Link } from 'react-router-dom';

const homeFooterTabs = props => {

    return (
        <div style={Object.assign({bottom: props.bottom}, styles.footerWrapper)} className="animate">
            <div style={styles.footerContainer}>
                <Link style={styles.footerButton} to="/favorites">
                    <img style={styles.footerButtonIcon} src={Images.homefooter.favorites} alt="favorites" />
                </Link>
                <Link style={styles.footerButton} to="/account">
                    <img style={styles.footerButtonIcon} src={Images.homefooter.profile} alt="account" />
                </Link>
                <Link style={styles.footerButton} to="/sessions">
                    <img style={styles.footerButtonIcon} src={Images.homefooter.sessions} alt="sessions" />
                </Link>
                <Link style={styles.footerButton} to="/statistics">
                    <img style={styles.footerButtonIcon} src={Images.homefooter.stats} alt="statistics" />
                </Link>
            </div>
        </div>
    );
}

const styles = {
    // footerContainer: {
    //     width: '100%',
    //     flex: 1,
    //     flexDirection: 'row',
    //     flexWrap:'wrap',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     display: 'flex',
    //     backgroundColor: '#484848'
    // },
    // footerButton: {
    //     height: 60,
    //     width: '25%',
    //     backgroundColor: '#484848',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     display: 'flex'
    // },
    // footerButtonIcon: {
    //     height: 20,
    //     width: 50
    // },
    footerWrapper: {
        left: 0, 
        position: 'absolute', 
        height: 60, 
        width: '100%', 
        zIndex: 100
    },
    // footerWrapper: {
    //     left: 0, 
    //     position: 'absolute', 
    //     width: 60, 
    //     height: '100%', 
    //     zIndex: 100
    // },
    footerContainer: {
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        flexWrap:'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: '#484848'
    },
    footerButton: {
        height: 60,
        width: '25%',
        backgroundColor: '#484848',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    footerButtonIcon: {
        height: 20,
        width: 50
    }
    
};

export default homeFooterTabs;