import React from 'react';

const StatusBarPadding = props => {

    return (
        <div style={{...styles.container, ...(props.platform === 'android' && styles.android)}}></div>
    );
}

export default StatusBarPadding;

const styles = {
    container: {
        minHeight: 42, 
        width: '100%', 
        backgroundColor: '#619f3a',
        display: 'block',
        zIndex: 2
    },
    android: {
        minHeight: 48
    }
};