import React from 'react';
import Images from '../../assets/images/index';
import GlobalState from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import LoginScreen from '../login/LoginScreen';


const SignInOrGuest = props => {

    return (
      <GlobalState.Consumer>
        {context => (
          <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
            {(!context.isLoggedIn && !context.isGuestCheckout) && <LoginScreen allowGuest={true} onClose={props.closeConfirm}></LoginScreen>}
          </div>
        )}
      </GlobalState.Consumer>
    );

}

const styles = {
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerIn: {
      transform: 'translateY(0)'
  },
  containerOut: {
      transform: 'translateY(100%)',
      visibility: 'hidden'
  }
};


export default SignInOrGuest;