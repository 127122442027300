import React, { useState, useContext } from 'react';
import GlobalContext from '../../context/global-context';
import isAuth from '../login/IsAuth';
import { useTranslation } from 'react-i18next';
import { feedbackSchema } from './FeedbackSchema';
import FormBuilder from '../common/formBuilder/formBuilder';
import Button from '../common/button/button';
import Success from '../common/success/success';
import FormPanel from '../common/formPanel/formPanel';
import LoadingSpinner from '../common/loadingSpinner/loadingSpinner';
import PageLayout from '../common/pageLayout/pageLayout';

const initialFormValues = {
  name: {
    value: '',
    error: false,
  },
  ukevNumber: {
    value: '',
    error: false,
  },
  feedback: {
    value: '',
    error: false,
  },
  category: {
    value: 'Payment / Billing',
    error: false,
  },
}

const FeedbackScreen = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const [status, setStatus] = useState("pending")
  const [formValues, setFormValues] = useState(initialFormValues)

  function validateForm() {
    let _formValues = { ...formValues }
    feedbackSchema.forEach((element) => {
      const fieldValue = _formValues[element.name]
      if (!fieldValue.value || fieldValue.value.length === 0) {
        _formValues[element.name] = { ..._formValues[element.name], error: true }
      }
    })
    setFormValues(_formValues)
    return _formValues
  }

  function sendFeedback() {
    const _formValues = validateForm()
    setStatus("loading")

    const errors = Object.keys(_formValues).filter((key) => _formValues[key].error)
    if (errors.length > 0) return setStatus("pending")

    const { feedback, name, ukevNumber, category } = _formValues
    const data = {
      location: globalContext.location.lat + ', ' + globalContext.location.lng,
      feedback: feedback.value,
      email: globalContext.user.profile.user.Email,
      name: name.value,
      ukevNumber: ukevNumber.value,
      category: category.value,
    };

    globalContext.sendFeedback(data).then(
      (res) => {
        console.log(res);
        setStatus("submitted")
      },
      (err) => {
        console.log(err);
        setStatus("pending")
      }
    );
  }

  function handleChange(e) {
    setFormValues({
      ...formValues, [e.target.name]: {
        value: e.target.value,
        error: false
      }
    });
  }

  return (
    <PageLayout >
      {status === "loading" && <LoadingSpinner />}

      {status === "pending" && (
        <FormPanel
          header={t('FEEDBACK_HEADER')}
          subHeader={t('FEEDBACK_SUBHEADER')}
          formFields={feedbackSchema.map((element, idx) => <FormBuilder key={idx} {...element} onChange={handleChange} value={formValues[element.name].value} isError={formValues[element.name].error} />)}
          actions={<Button onClick={sendFeedback} />}
        />
      )}

      {status === "submitted" && <Success />}
    </PageLayout>

  );
};

export default isAuth(FeedbackScreen);

