import { useState } from "react";
import Helpers from "../context/helpers";
import Images from '../assets/images/index';


const PriceTicker = props => {

    const [amount, setAmount] = useState(props.amount);

    const increase = () => {
        if(+(amount) < 100){
            const newAmount = +(amount)+1;
            setAmount(newAmount);

            if(typeof props.onChange === 'function')
                props.onChange(newAmount);
        }
    }

    const decrease = () => {
        if(+(amount) > 0){
            const newAmount = +(amount)-1;
            setAmount(newAmount);

            if(typeof props.onChange === 'function')
                props.onChange(newAmount);
        }
    }


    return (
        <div style={styles.container}>

            <span>{props.label}</span>

            <div style={styles.ticker}>

                <button onClick={decrease} style={styles.button}><img src={Images.minus_icon} alt="Minus" style={styles.buttonIcon} /></button>
                <h1 style={styles.amount}>{Helpers.localeCurrency(amount, props.currency)}</h1>
                <button onClick={increase} style={styles.button}><img src={Images.plus_icon} alt="Add" style={styles.buttonIcon} /></button>

            </div>

        </div>
    )
}

const styles = {
    container: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: '20px', 
        padding: '30px 0', 
        width: 300, 
        alignItems: 'center'
    },
    ticker: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        width: '100%', 
        alignItems: 'center', 
        gap: '30px'
    },
    button: {
        display: 'flex', 
        backgroundColor: '#6CB33F', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: 40, 
        width: 40, 
        borderRadius: 20
    },
    buttonIcon: {
        height: 16,
        width: 16
    },
    amount: {
        fontSize: 40, 
        margin: 0
    }
}

export default PriceTicker;   