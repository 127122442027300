import React from 'react';
import Images from '../../assets/images/index';
import Header from '../global/Header';
import LocationSummary from '../home/LocationSummary';
import ActivityIndicator from '../global/ActivityIndicator';
import Helpers from '../../context/helpers';

import GlobalContext from '../../context/global-context';
import { Translation } from 'react-i18next';

class LocationScreen extends React.Component {

  static contextType = GlobalContext;

  abortController  = new AbortController(); 

  constructor(props){
    super(props);

    this.state = {
      id: this.props.match.params.id,
      site: null,
      loading: true,
      errors: {
        loading: false
      }
    };
  }

  getMarkerType = (connector) => {
    const point = this.state.site.points.find(p => p.id === connector.pointId);
    if(this.state.site.privacy.public === false && (connector.status === 'CONNECTED' || point.status === 'CHARGING')){
      return Helpers.markerTypes.inuse;
    } else if(this.state.site.privacy.public === false){
      return Helpers.markerTypes.out;
    } else if(connector.status === 'CONNECTED' || point.status === 'CHARGING'){
      return Helpers.markerTypes.inuse;
    } else if(point.status === 'IDLE') {
      return Helpers.markerTypes.open;
    } else {
      return Helpers.markerTypes.fault;
    }
  }

  getPointName = (id) => {
    return this.state.site.points.find(p => p.id === id).name;
  }

  getPointAmps = (id) => {
    return this.state.site.points.find(p => p.id === id).provision.amps;
  }

  getPointPower = (id) => {
    return this.state.site.points.find(p => p.id === id).provision.kW;
  }

  selectPointHandler = (c) => {
    if(c.pointId < 600){
      this.props.history.push('/qr/'+c.pointId+'/'+c.number)
    } else {
      this.props.history.push('/point/'+this.state.site.id+'/'+c.pointId)
    }
  }

  points = () => {
    if(typeof this.state.site.connectors !== 'undefined')
      return this.state.site.connectors.map(c => (
        <Translation key={c.id}>{t =>
          <div style={styles.point}>
              <div style={styles.icon}>
                <img src={this.getMarkerType(c).image} style={{width: 27, height: 40}} alt="Status" />
                <span style={Object.assign({}, styles.iconText, {color: this.getMarkerType(c).color})}>{t(this.getMarkerType(c).label)}</span>
              </div>
              <div style={styles.pointInfo}>
                  <span style={styles.title}>{this.getPointName(c.pointId)}{c.pointId<600&&'-'+c.number}</span>
                  <div style={styles.detail}>
                    <span style={styles.attribute}>{c.socketType}</span>
                    <span style={styles.attribute}>{this.getPointAmps(c.pointId)}A ({this.getPointPower(c.pointId)}kW)</span>
                  </div>
              </div>
              <div style={styles.more}>
              <a href="JavaScript:void(0);" style={styles.moreButton} onClick={() => {this.selectPointHandler(c)}}><span style={styles.moreButtonText}>{t('SELECT')}</span></a>
              </div>
          </div>
        }</Translation>
      ));

    return null;
  }

  resetErrors = () => {
    let errors = {...this.state.errors};
    Object.values(errors).map(v => v = false);
    this.setState({errors: errors});
  }

  setSite = site => {
    
    // Add some additional defaults
    site.connectors_stats = {
      total_count: 0,
      available_count: 0,
      paid_vend_available: false,
      free_vend_available: false
    }
    site.lowestPrice = null;
    site.lowestPriceTypeDesc = null;
    site.lowestPriceCurrency = 'GBP';
    site.isFavorite = this.context.isFavorite(site.id);

    site.connectors.map(c => {
      site.connectors_stats.total_count = site.connectors_stats.total_count+1;

      if(typeof c.privateTariff !== 'undefined' && c.privateTariff !== null){
        if(parseFloat(c.privateTariff.price) > 0)
          site.connectors_stats.paid_vend_available = true;

        if(parseFloat(c.privateTariff.price) === 0)
          site.connectors_stats.free_vend_available = true;

        if(parseFloat(c.privateTariff.price) < site.lowestPrice || site.lowestPrice === null){
          site.lowestPrice = parseFloat(c.privateTariff.price).toFixed(2);
          site.lowestPriceTypeDesc = c.privateTariff.typeDesc.replace('/', '');
          site.lowestPriceCurrency = typeof c.privateTariff.currency !== 'undefined' ? c.privateTariff.currency : site.lowestPriceCurrency;
        }

      } else if(typeof c.paygTariff !== 'undefined'){
        if(parseFloat(c.paygTariff.price) > 0)
          site.connectors_stats.paid_vend_available = true;

        if(parseFloat(c.paygTariff.price) === 0)
          site.connectors_stats.free_vend_available = true;

        if(parseFloat(c.paygTariff.price) < site.lowestPrice || site.lowestPrice === null)
          site.lowestPrice = parseFloat(c.paygTariff.price).toFixed(2);
          site.lowestPriceTypeDesc = c.paygTariff.typeDesc.replace('/', '');
          site.lowestPriceCurrency = typeof c.paygTariff.currency !== 'undefined' ? c.paygTariff.currency : site.lowestPriceCurrency;
      }

    });

    site.points.map(p => {
      if(p.status === 'IDLE')
        site.connectors_stats.available_count = site.connectors_stats.available_count+1;
    })

    site.distance = this.context.distanceInKm(site.location.latitude, site.location.longitude);
    site.distanceLabel = 'Km'

    if((this.context.isLoggedIn && this.context.account.preferences.unit === 'm') || !this.context.isLoggedIn){
      site.distance =  this.context.kmToMiles(site.distance);
      site.distanceLabel = 'Miles';
    }

    this.setState({
      site: site,
      loading: false
    })
  }

  componentDidMount = () => {

    this.resetErrors();

    if(this.context.site !== null && this.context.site.id === this.state.id)
      this.setSite(this.context.site);
    
    this.loadSite();

    window.addEventListener('user', () => this.loadSite());
    
  }

  componentWillUnmount = () => {
    this.abortController.abort();
    window.removeEventListener('user', null, false);
  }

  loadSite = () => {
    this.context.fetchSite(this.state.id, this.abortController)
      .then((site) => {
        if(site !== null)
          this.setSite(site);
      })
      .catch((error) => {
        console.log(error);
        let errors = {...this.state.errors};
        errors.loading = true;
        this.setState({errors: errors});
    });
  }

  render() {

    return (
      <Translation>{t =>
      <div style={styles.container}>
        <Header title={t('TITLE_LOCATION')} history={this.props.history} />

        <div style={styles.content}>

          {this.state.loading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

          {!this.state.errors.loading &&
          <div style={styles.summaryContainer}>
            {this.state.site && <LocationSummary site={this.state.site} lowestPrice={this.state.site.lowestPrice} lowestPriceTypeDesc={this.state.site.lowestPriceTypeDesc} lowestPriceCurrency={this.state.site.lowestPriceCurrency} history={this.props.history} />}
          </div>}

          

          {(this.state.site && !this.state.errors.loading) &&  
          <div style={styles.points}>
            <div style={{height: 30}}></div>
            {this.points()}
          </div>}

          {this.state.errors.loading && <span style={styles.error}>{t('LOCATION_ERROR')}</span>}

        </div>

      </div>
      }</Translation>
    );
  }

}


//const styles = StyleSheet.create({
const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: 20
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    alignItems: 'center'
  },
  summaryContainer: {
    height: 220,
    width: '100%',
    maxWidth: 500
  },
  points: {
    position: 'relative',
    flexGrow: 1,
    minHeight: '0px',
    width: '100%',
    maxWidth: 500
  },
  point: {
    width: '90%',
    backgroundColor: '#efefef',
    display: 'flex',
    flexWrap:'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: '5%',
    marginBottom: 4,
    borderRadius: 12,
    height: 100
  },
  icon: {
      height: '100%',
      width: '20%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
  },
  iconText: {
    fontSize: 10,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  pointInfo: {
      height: '100%',
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
  },
  more: {
      height: '100%',
      width: '20%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
  },
  moreButton: {
    backgroundColor: '#464646',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 6,
    width: '100%',
    height: 32,
    cursor: 'pointer'
  },
  moreButtonText: {
    color: '#fff',
    textAlign: 'center',
    margin: 0,
    lineHeight: 1,
    textTransform: 'lowercase'
  },
  title: {
      color: '#484848',
      fontSize: 16,
      fontWeight: 900
  },
  detail: {
    flexDirection: 'row',
    display: 'flex'
  },
  attribute: {
    flex: 1,
    color: '#484848',
    fontSize: 14,
  },
  address: {
      color: '#484848',
      fontSize: 14
  },
  loadingWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  error: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#cc0000'
  },
};


export default LocationScreen;