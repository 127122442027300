import React from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import isAuth from '../../components/login/IsAuth';
import Header from '../global/Header';
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next';

class inviteScreen extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            token: this.props.match.params.token,
            invite: null,
            error: '',
            isTokenVerified: false,
            isAccepted: false, 
            isDeclined: false,
            isLoading: false,
            isError: false,
        };
    }

    componentDidMount = () => {
        this.onVerifyToken();
    }

    onVerifyToken = () => {
        this.setState({isLoading: true, isError: false, error: ''}, () => {
            this.context.inviteVerify(this.state.token)
            .then((res) => {
                this.setState({
                    isLoading: false, 
                    isTokenVerified: true, 
                    invite: res
                });
            })
            .catch((error) => {
                this.setState({
                    isError: true, 
                    isLoading: false, 
                    isTokenVerified: false, 
                    invite: null,
                    error: 'INVITE_ERROR_VERIFY'})
            });
        })
    }

    onAcceptInvite = () => {
        this.setState({isLoading: true, isError: false, error: ''}, () => {
            this.context.inviteAccept(this.state.token)
            .then((res) => {
                this.setState({
                    isLoading: false,
                    isAccepted: true
                });
            })
            .catch((err) => {
                this.setState({
                    isError: true, 
                    isLoading: false,
                    isAccepted: false,
                    error: 'INVITE_ERROR_ACCEPT'})
            });
        })
    }

    onDeclineInvite = () => {
        this.setState({isLoading: true, isError: false, error: ''}, () => {
            this.context.inviteDecline(this.state.token)
            .then((res) => {
                this.setState({
                    isLoading: false,
                    isDeclined: true
                });
            })
            .catch((err) => {
                this.setState({
                    isError: true, 
                    isLoading: false,
                    isDeclined: false,
                    error: 'INVITE_ERROR_DECLINE'})
            });
        })
    }

    showForm = () => {
        return this.state.isTokenVerified && !this.state.isAccepted && !this.state.isDeclined && !this.state.isLoading;
    }

    render() {
        const errors = this.state.errors;
        return (
        <Translation>{t => 
            <div style={styles.container}>
                <Header title={t('TITLE_INVITE')} history={this.props.history} />

                {this.state.isLoading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

                {!this.state.isLoading && <div style={Object.assign({alignItems: 'center'}, styles.container)}>
                    <div style={styles.wrapper}>
                        <div style={styles.header}>
                            {this.state.invite !== null && <span style={styles.heading}>{t('HI')} {this.state.invite.name}</span>}
                        </div>

                        {this.state.isAccepted && <span style={styles.successMsg}>{t('INVITE_ACCEPTED')}</span>}
                        {this.state.isDeclined && <span style={styles.declinedMsg}>{t('INVITE_DECLINED')}</span>}
                        {this.state.isError && <span style={styles.errorMsg}>{t(this.state.error)}</span>}

                        {(this.state.isAccepted || this.state.isDeclined) && <div style={Object.assign({}, styles.buttonGroup, {justifyContent: 'center'})}>
                        <Link to="/" style={Object.assign({}, styles.buttonGroupBtn)}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('CLOSE')}</span></Link>
                        </div>}

                        {this.showForm() && <div>
                            <span style={styles.intro}>{t('INVITE_INTRO')} {this.state.invite.organisation}.</span>

                            <div style={styles.buttonGroup}>
                                <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn)} onClick={this.onDeclineInvite}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('DECLINE')}</span></a>
                                <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.onAcceptInvite}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('ACCEPT')}</span></a>
                            </div>
                        
                            <span style={styles.text}>{t('INVITE_DESC')} {this.state.invite.organisation}.</span>
                        </div>}
                    </div>
                </div>}
            </div>
            }</Translation>
            )
    }
}

const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  containerIn: {
      transform: 'translateY(0)'
  },
  containerOut: {
      transform: 'translateY(100%)',
      visability: 'hidden'
  }, 
  wrapper: {
    width: '80%',
    maxWidth: 400,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  heading: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    color: '#484848'
  },
  intro: {
    display: 'block',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 10,
    color: '#484848'
  },
  text: {
    display: 'block',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 10,
    color: '#484848'
  },
  buttonGroup: {
      width: '100%',
      marginTop: 30,
      marginBottom: 30,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
  },
  buttonGroupBtn: {
      width: 140,
      height: 50,
      backgroundColor: '#555555',
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
      backgroundColor: '#6CB33F'
  },
  loadingWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  successMsg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: '#00cc00',
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'center'
  },
  declinedMsg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: '#cccc00',
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'center'
  },
  errorMsg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: '#cc0000',
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'center'
  },
};

export default isAuth(inviteScreen);