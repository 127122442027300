import React from 'react';
import LocationSummary from './LocationSummary';


const locationSummaryPopUp = props => {

    return (
        <div style={Object.assign({}, styles.container, {bottom: props.visible?60:-200})} className="animate">
            <div onClick={() => props.goToLocation(props.site)}>
                {props.site && <LocationSummary site={props.site} toggleFavorite={props.toggleFavorite} history={props.history} />}
            </div>
        </div>
    );
}

const styles = {
    container: {
        width: '100%',
        position: 'absolute',
        left: 0,
        height: 220,
        zIndex: 99,
        backgroundColor: '#ffffff',
        cursor: 'pointer'
    }
};

export default locationSummaryPopUp;