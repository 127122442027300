import React from 'react';

const RFIDProgress = props => {

    const maxSteps=5;

    const getDelay = q => {
        let delay = '0s';
        
        if(props.step >= q) {
            // Forwards
            delay = '0'+(0.25-((props.step-q)/40))+'s';
            // Backwards
            if(props.step < q)
                delay = '0'+(0.25-((q-props.step)/40))+'s';
        }

        return delay;
    }

    const styleCurve = q => {
        return {
            fill: (q<=props.step?props.step>=maxSteps?'#6CB33F':'#6CB33F':'#E6E7E8'),
            transition: 'all .2s ease ' + getDelay(q),
        }
    }

    const stylePoint = q => {
        return {
            fill: (q<=props.step?props.step>=maxSteps?'#6CB33F':'#6CB33F':'#E6E7E8'),
            transition: 'all .2s ease ' + getDelay(q),
        }
    }

    const styleTick = q => {
        return {
            opacity: (q<=props.step?'1':'0'),
            fill: '#ffffff',
            transition: 'all .2s ease ' + getDelay(q),
        }
    }

    const styleBody = () => {
        return {
            fill: (props.step>=maxSteps?'#6CB33F':'#D1D3D4'),
            transition: 'all .2s ease ' + getDelay(maxSteps),
        }
    }

    const styleIcon = () => {
        return {
            fill: (props.step>=maxSteps?'#ffffff':'#010101'),
            transition: 'all .2s ease ' + getDelay(maxSteps),
        }
    }

    const stylePulse = () => {
        return {
            fill: '#D1D3D4',
            opacity: .4
        }
    }

    return (
        <div style={{width:300, height: 300, position: 'relative'}}>
            
            
            <svg x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" style={{zIndex: 49}}>

                {/* Curve Q1 */}
                <path style={styleCurve(2)} d="M150,7.19v7.23c74.88,0,135.58,60.7,135.58,135.58c0,0.36,0,0.72-0.01,1.07h7.229
                    c0.01-0.36,0.01-0.71,0.01-1.07C292.81,71.13,228.87,7.19,150,7.19z"/>
                {/* Curve Q2 */}
                <path style={styleCurve(3)} d="M292.81,150c0,78.87-63.939,142.81-142.81,142.81v-7.229c74.88,0,135.58-60.7,135.58-135.58H292.81z"/>
                {/* Curve Q3 */}
                <path style={styleCurve(4)} d="M150,285.58v7.229C71.49,292.81,7.77,229.45,7.2,151.07h7.23C15,225.46,75.48,285.58,150,285.58z"/>
                {/* Curve Q4 */}
                <path style={styleCurve(5)} d="M7.19,150c0,0.36,0,0.71,0.01,1.07h7.23c-0.01-0.35-0.01-0.71-0.01-1.07c0-74.88,60.7-135.58,135.58-135.58
                    V7.19C71.13,7.19,7.19,71.13,7.19,150z"/>

                {/* Point Q1 */}
                <circle style={stylePoint(1)} cx="150" cy="11.091" r="10.09"/>
                {/* Point Q2 */}
                <circle style={stylePoint(2)} cx="288.91" cy="150" r="10.09"/>
                {/* Point Q3 */}
                <circle style={stylePoint(3)} cx="150" cy="288.91" r="10.09"/>
                {/* Point Q4 */}
                <circle style={stylePoint(4)} cx="11.09" cy="150" r="10.09"/>

                {/* Circle Center */}
                <circle style={styleBody()} cx="150" cy="150" r="92.808"/>


                {/* RFID ICON */}
                <path style={styleIcon()} d="M188.572,180.338c-0.366,0.001-0.724-0.11-1.024-0.32c-0.806-0.563-1.003-1.674-0.439-2.48
                    c0.001-0.001,0.002-0.003,0.003-0.004c12.718-18.013,10.608-42.567-4.995-58.147c-0.662-0.728-0.608-1.854,0.119-2.516
                    c0.678-0.616,1.712-0.618,2.392-0.004c16.837,16.799,19.116,43.287,5.396,62.715C189.691,180.055,189.149,180.336,188.572,180.338z"
                    />
                <path style={styleIcon()} d="M190.389,201.495c-0.983-0.004-1.778-0.805-1.773-1.787c0.002-0.523,0.233-1.019,0.634-1.356
                    c25.912-21.657,29.362-60.219,7.704-86.132c-4.588-5.49-10.104-10.132-16.298-13.715c-0.887-0.425-1.261-1.489-0.835-2.376
                    c0.425-0.887,1.489-1.261,2.376-0.835c0.083,0.04,0.164,0.086,0.24,0.139c30.929,17.887,41.502,57.46,23.614,88.389
                    c-3.795,6.562-8.714,12.405-14.531,17.265C191.201,201.35,190.802,201.494,190.389,201.495z"/>
                <path style={styleIcon()} d="M118.412,188.174c-0.44,0.002-0.865-0.16-1.193-0.454c-19.087-17.193-21.586-46.235-5.717-66.437
                    c0.607-0.774,1.728-0.91,2.502-0.302c0.774,0.607,0.91,1.728,0.303,2.502c-14.709,18.729-12.393,45.651,5.299,61.593
                    c0.728,0.661,0.781,1.788,0.12,2.516C119.389,187.961,118.912,188.173,118.412,188.174L118.412,188.174z"/>
                <path style={styleIcon()} d="M120.166,208.628c-0.311,0.008-0.617-0.065-0.891-0.214c-31.459-16.956-43.217-56.205-26.261-87.664
                    c4.225-7.839,10.021-14.721,17.027-20.215c0.774-0.607,1.895-0.472,2.502,0.303c0.607,0.774,0.472,1.895-0.303,2.502
                    c-26.563,20.861-31.186,59.306-10.324,85.87c5.192,6.612,11.693,12.083,19.095,16.07c0.866,0.467,1.19,1.546,0.724,2.412
                    c-0.318,0.591-0.942,0.953-1.614,0.936H120.166z"/>
                <path style={styleIcon()} d="M175.775,145.575l-9.188-50.285c-0.619-3.323-3.793-5.533-7.124-4.96l-30.214,5.521
                    c-3.361,0.591-5.607,3.795-5.017,7.157c0.004,0.021,0.008,0.043,0.012,0.065l7.507,41.051l-6.973,8.397
                    c-2.238,2.738-1.84,6.771,0.891,9.02c0.16,0.125,0.339,0.232,0.499,0.338c-0.714,1.186-1.026,2.569-0.891,3.945
                    c0.152,1.695,0.98,3.258,2.298,4.337c0.37,0.307,0.776,0.567,1.211,0.774c-1.436,2.688-0.784,6.014,1.559,7.961
                    c0.775,0.634,1.69,1.073,2.671,1.282l5.619,28.834c0.106,0.52,0.564,0.892,1.095,0.89h0.214c0.604-0.111,1.005-0.691,0.894-1.296
                    c-0.002-0.005-0.002-0.009-0.003-0.013l-0.543-2.227l-5.085-26.072l-0.214-1.096l-0.214-1.096l-0.115-0.614c0-0.044,0-0.089,0-0.134
                    c-0.593-2.058-0.748-4.218-0.454-6.339c0.071-0.473,0.169-0.944,0.294-1.435c0.228-0.894,0.554-1.76,0.971-2.582
                    c0,0,0-0.08,0.063-0.115l1.78-3.073l1.781-3.179c0.059-0.103,0.1-0.214,0.124-0.329l0.143-0.739l0.241-1.202l0.133-0.641
                    l0.099-0.481l0.222-1.148l0.526-2.617l0.454-2.298l0.222-1.095l0.224-1.095v-0.099l0.195-0.997l0.73-3.633
                    c0-0.187,0.08-0.356,0.125-0.525c0.114-0.475,0.261-0.942,0.436-1.397c0.145-0.375,0.323-0.736,0.534-1.078
                    c0.28-0.483,0.671-0.892,1.141-1.193c0.411-0.232,0.872-0.366,1.345-0.392c0.785-0.053,1.573,0.04,2.323,0.276h0.107
                    c1.128,0.273,2.062,1.062,2.521,2.128c0.554,1.382,0.681,2.899,0.364,4.354l-0.837,7.746l-0.125,1.149l-0.089,0.837v0.107v0.213
                    l-0.106,0.891l-0.143,1.326l-0.214,2.03l-0.08,0.739l-0.445,4.105l-0.659,6.082c-0.057,0.536,0.288,1.032,0.811,1.166
                    c0.054,0.026,0.11,0.044,0.17,0.054c0.609,0.068,1.158-0.37,1.229-0.979l0.89-8.299c0.375,0.472,0.73,0.89,1.061,1.264
                    c0.54,0.599,1.111,1.17,1.709,1.71c0.471,0.398,1.174,0.341,1.572-0.129s0.34-1.174-0.129-1.572
                    c-1.49-1.364-2.785-2.929-3.848-4.647l0.455-4.203l8.904-1.63c3.375,4.907,7.248,10.41,7.293,10.472
                    c2.627,3.901,1.709,6.108,1.683,6.18c-0.035,0.072-0.064,0.146-0.089,0.224c-0.578,2.395-4.845,25.983-6.233,33.472
                    c-0.223,1.229-0.374,2.04-0.4,2.235c-0.108,0.602,0.289,1.179,0.891,1.292h0.195c0.529-0.002,0.986-0.373,1.096-0.891
                    c0.054-0.33,5.806-32.289,6.545-35.494c0.275-0.704,1.14-3.749-1.905-8.264c-0.054-0.08-3.518-5.005-6.724-9.652l6.981-1.273
                    c3.348-0.618,5.565-3.827,4.96-7.177L175.775,145.575z M170.433,150.562l-7.978,1.486l-1.202,0.224l-1.193,0.213l-7.284,1.336
                    l0.766-7.034c0.142-0.835,0.195-1.683,0.16-2.529c-0.024-0.567-0.104-1.131-0.24-1.683c-0.162-0.719-0.445-1.406-0.837-2.03
                    c-0.854-1.318-2.168-2.271-3.687-2.672c-0.867-0.258-1.768-0.387-2.672-0.382c-0.73,0.003-1.453,0.149-2.127,0.427
                    c-0.213,0.086-0.418,0.188-0.615,0.303c-0.721,0.451-1.33,1.061-1.781,1.781c-0.208,0.307-0.393,0.628-0.552,0.962
                    c-0.161,0.339-0.305,0.687-0.428,1.042c-0.213,0.624-0.389,1.26-0.525,1.906l-0.828,4.132l-0.24,1.193l-0.249,1.282l-0.419,2.075
                    l-0.454,2.297l-0.196,0.989c-0.147-0.067-0.29-0.145-0.427-0.232l-0.179-0.098c-0.25-0.179-0.48-0.385-0.686-0.614
                    c-0.336-0.375-0.599-0.81-0.774-1.283c-0.077-0.204-0.137-0.417-0.179-0.632l-0.133-0.729l-0.259-1.424l-1.112-6.056l-0.259-1.434
                    l-0.258-1.425l-7.124-39.18c-0.23-1.072-0.005-2.191,0.623-3.09c0.588-0.865,1.5-1.456,2.529-1.639l30.214-5.521
                    c0.231-0.043,0.467-0.064,0.703-0.063c1.899,0.01,3.523,1.366,3.874,3.232l9.189,50.285c0.384,2.14-1.032,4.19-3.17,4.586H170.433z
                    M133.604,165.814c-0.561,1.103-0.994,2.264-1.292,3.464c-0.343,0.065-0.693,0.086-1.041,0.063c-0.861-0.079-1.682-0.407-2.36-0.944
                    c-0.858-0.7-1.397-1.718-1.495-2.822c-0.086-1.002,0.192-2.001,0.783-2.814c0.491,0.131,0.996,0.2,1.505,0.205
                    c0.205,0,0.4,0,0.605,0c1.69-0.152,3.25-0.977,4.328-2.289l2.351-2.831l0.437,0.169l-0.339,1.701l-1.62,2.85L133.604,165.814z
                    M132.428,177.604c-0.253-0.132-0.491-0.29-0.712-0.472c-1.662-1.389-1.983-3.819-0.739-5.592c0.214,0,0.419,0.053,0.632,0.053
                    h0.348C131.764,173.609,131.923,175.644,132.428,177.604z M133.407,152.992l0.063,0.32c0.149,0.744,0.423,1.458,0.811,2.11
                    c0.251,0.41,0.551,0.791,0.891,1.131l-2.218,2.671c-0.707,0.85-1.722,1.385-2.822,1.487c-0.083,0.005-0.167,0.005-0.25,0
                    c-0.444,0.022-0.89-0.028-1.317-0.151c-0.368-0.115-0.719-0.28-1.042-0.489c-0.157-0.094-0.309-0.194-0.454-0.303
                    c-1.771-1.474-2.015-4.104-0.543-5.877l5.77-6.964L133.407,152.992z"/>


                    {/* TICK 1 */}
                    <polygon style={styleTick(2)} points="154.843,8.262 147.82,15.285 144.308,11.773 145.673,10.408 147.82,12.555 153.479,6.896"/>
                    {/* TICK 2 */}
                    <polygon style={styleTick(3)} points="294.178,147.241 287.154,154.264 283.643,150.752 285.008,149.387 287.154,151.534 292.813,145.875"/>
                    {/* TICK 3 */}
                    <polygon style={styleTick(4)} points="155.268,286.15 148.245,293.174 144.733,289.662 146.098,288.297 148.245,290.444 153.902,284.785"/>
                    {/* TICK 4 */}
                    <polygon style={styleTick(5)} points="16.357,147.24 9.334,154.264 5.823,150.752 7.188,149.387 9.334,151.534 14.992,145.875 "/>

                    {/* TICK CENTER  */}
                    <polygon style={styleTick(5)} points="162.391,113.224 145.304,130.313 136.76,121.768 140.081,118.446 145.304,123.671 159.069,109.902"/>
                </svg>
            
                {props.scanning && <svg width="300px" height="300px" viewBox="-100 -100 300 300" style={{position: 'absolute', left: 0, top: 0, zIndex: 48}}>
                    {/* Circle pulse */}
                    <circle style={stylePulse()} cx="0" cy="0" r="92.808" transform="translate(50 50)">
                        <animateTransform attributeName="transform" type="scale" additive="sum" from="1 1" to="1.3 1.3" begin="0s" dur="1.2s" repeatCount="indefinite"></animateTransform>
                        <animate attributeName="opacity" additive="sum" from=".4" to="0" begin="0s" dur="1.2s" repeatCount="indefinite"></animate>
                    </circle>
                </svg>}
        </div>
        
    )

}

export default RFIDProgress;