import React from 'react'
import { useTranslation } from 'react-i18next'
import images from '../../../assets/images'

export default function Success() {

    const { t } = useTranslation()

    return (
        <div style={{ textAlign: 'center' }}>
            <img style={styles.successImg} src={images.check_circle} className={'pulse'} alt="Feedback received" />
            <span id="thankYou" style={styles.successHeader}>{t('THANK_YOU')}</span>
            <span style={styles.successText}>{t('FEEDBACK_SUCCESS')}</span>
        </div>
    )
}

const styles = {
    successImg: {
        width: 36,
        height: 36,
        marginBottom: 20
    },
    successHeader: {
        fontSize: 38,
        marginBottom: 10,
        display: 'block',
        color: '#fff'
    },
    successText: {
        fontSize: 18,
        display: 'block',
        color: '#fff'
    },
}
