export function accountUpdate (payload) {
    return async dispatch => {

        dispatch({
            type: 'ACCOUNT_UPDATE',
            status: 'OK',
            payload: payload
        })

    }
}