import React from 'react';

export default React.createContext({
    localHistory: [],
    isLoggedIn: false,
    isMenuOpen: false,
    dimensions: {},
    isNative: false,
    user: {},
    sites: [],
    site: null,
    favorites: [],
    location: {},
    loading: {},
    errors: {},
    camera: {},
    guest: {},
    isGuestCheckout: false,
    userLogin: user => {},
    userLogout: () => {},
    userRegister: () => {},
    userAddGuest: () => {},
    userPasswordForgot: () => {},
    userPasswordReset: () => {},
    userChangePassword: () => {},
    userVerifyAccount: () => {},
    userAcceptOnboarding: () => {},
    userIsOnboarded: () => {},
    userNativePrompted: () => {},
    userDismissNativePrompt: () => {},
    getFullName: () => {},
    getVehicleName: () => {},
    getVehicleReg: () => {},
    favoriteToggle: () => {},
    isFavorite: () => {},
    getFavorites: () => {},
    getFakePoints: position => {},
    fetchSites: position => {},
    fetchSite: position => {},
    getSite: id => {},
    getSiteAddress: id => {},
    switchVehicle: () => {},
    getUserLocation: () => {},
    focusOnUserLocation: () => {},
    toggleMenu: () => {},
    closeMenu: () => {},
    appVersion: () => {},
    confirmCharge: () => {},
    limitCharge: (code, limit) => {},
    authoriseCharge: () => {},
    logCharge: () => {},
    unlogCharge: () => {},
    selectCharge: () => {},
    endCharge: () => {},
    connectLiveChargeSession: () => {},
    isChargePointWaiting: () => {},
    checkChargeStatus: () => {},
    sessionsLoad: () => {},
    preferenceToggle: () => {},
    marketingPreferenceToggle: () => {},
    unitToggle: () => {},
    paymentMethodToggle: () => {},
    languageToggle: () => {},
    pointsFilter: () => {},
    pointsFilterByLocation: () => {},
    mapsLoaded: false,
    reportFault: () => {},
    sendFeedback: () => {},
    searchLocations: () => {},
    vehicleAdd: () => {},
    vehicleRemove: () => {},
    inviteVerify: () => {},
    inviteAccept: () => {},
    inviteDecline: () => {},
    distanceInKm: () => {},
    kmToMiles: () => {},
    topupRequest: () => {},
    wallet: false,
    userWalletGet: () => {},
    userWalletTopup: () => {},
    userWalletRemoveCard: () => {},
    userWalletSwitchCurrency: () => {},
    RFIDPairingStart: () => {},
    RFIDPairingTag: () => {},
    RFIDPairingFinish: () => {},
    RFIDDeleteTag: () => {},
    downloadSessions: () => {},
    currencies: [],
    requiresPushNotifications: () => {},
    requestPushNotifications: () => {},
    setSessionsQuickMode: (mode) => {}
})