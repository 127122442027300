import React, { useContext, useEffect, useRef, useState } from 'react';
import Images from '../../../assets/images/index';
import { Translation } from 'react-i18next';
import images from '../../../assets/images/index';
import globalContext from '../../../context/global-context';
import RFIDPushNotice from './RFIDPushNotice';





    

const RFIDDelete = props => {

    const context = useContext(globalContext);

    const handleClose = () => {
        props.close();
    }

    const handleConfirm = () => {
        context.RFIDDeleteTag(props.id);
        handleClose();
    }

    return (
        <Translation>{t => 
            <React.Fragment>
                <div style={styles.dialogBg} onClick={handleClose}></div>
                <div style={Object.assign({}, styles.dialog, styles.dialogIn)} className="animate">
                    <div style={styles.dialogWrapper}>
                        <span style={styles.dialogHeader}>{t('RFID_REMOVE_TITLE')}</span>
                        <div style={styles.dialogBody}>
                            <p style={styles.para}>{t('RFID_REMOVE_CONFIRM')}</p>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <button onClick={handleClose} style={styles.button}>{t('NO')}</button>
                                <button onClick={handleConfirm} style={styles.buttonPrimary}>{t('YES')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }</Translation>
    )

}

const RFIDScreen = props => {

    const [removeRFID, setRemoveRFID] = useState(null);

    const context = useContext(globalContext);

    //
    //  Cancel and close the whole thing
    //
    const handleClose = () => {
        props.close();
    }


    return (
        <Translation>{t => 
            <div style={Object.assign({}, styles.container, props.show ? styles.containerIn:styles.containerOut)} className="animate">
                <div style={styles.wrapper}>
                    <button style={styles.closeButton} onClick={handleClose}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>

                    <div style={styles.header}>
                        <img src={images.rfid_icon_grey} style={{width: 120, height: 120}} alt="RFID Cards" />
                        <h2 style={styles.title}>{t('RFID_TAGS')}</h2>
                    </div>

                    <div style={styles.main}>

                        <RFIDPushNotice />

                        {context.account.rfid_tags &&
                            <div style={styles.list}>

                                {context.account.rfid_tags.length === 0 &&  
                                    <div style={styles.listItem}>
                                        <div style={styles.listItemDescription}>
                                            <span style={styles.listItemSecondary}>{t('RFID_NO_TAGS')}</span>
                                        </div>
                                    </div>
                                }

                                {context.account.rfid_tags.map((tag, idx) => (
                                    <div key={idx} style={styles.listItem}>
                                        <div style={styles.listItemDescription}>
                                            <span style={styles.listItemPrimary}>{tag.Name}</span>
                                            <span style={styles.listItemSecondary}>{tag.Enabled?t('ENABLED'):t('DISABLED')}</span>
                                        </div>
                                        <span style={{marginRight: 15, fontSize: 30, color: '#a2a2a2'}} onClick={() => {setRemoveRFID(tag.Id)}}><img src={Images.remove_btn_blue} alt='remove' style={{width: 24}} /></span>
                                    </div>
                                ))}

                            </div>
                        }
                        
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <button onClick={handleClose} style={styles.button}>{t('CLOSE')}</button>
                            <button onClick={props.addRFIDCard} style={styles.buttonPrimary}>{t('ADD_TAG')}</button>
                        </div>
                        
                    </div>
                </div>

                {removeRFID !== null && <RFIDDelete id={removeRFID} close={() => {setRemoveRFID(null)}} />}
            </div>
        }</Translation>
    )

}

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: '#fbfbfb',
        height: '100%',
        width: '100%',
        display: 'block',
        overflow: 'auto',
        zIndex: 87
    },
    containerIn: {
        transform: 'translateY(0)'
    },
    containerOut: {
        transform: 'translateY(100%)',
        visibility: 'hidden'
    },
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    main: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        scrollBehavior: 'smooth'
    },
    closeButton: {
        border: 'none',
        backgroundColor: 'transparent',
        width: 40,
        height: 40,
        top: 50,
        right: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 99
    },
    header: {
        paddingTop: 70,
        paddingBottom: 40,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: '#6CB33F',
        fontWeight: 900,
        margin: 0
    },
    list: {
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        alignItems: 'center', 
        marginBottom: 20
    },
    listItem: {
        backgroundColor: '#f4f5f7',
        border: '1px solid #e2e2e2', 
        width: '90%', 
        height: 65, 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        borderRadius: 4,
        marginBottom: 10
    },
    listItemDescription: {
        flex: 1,
        display: 'flex', 
        flexDirection: 'column', 
        justifyItems: 'center', 
        paddingLeft: 15
    },
    listItemPrimary: {
        fontSize: 16,
        color: '#464646',
        fontWeight: 600
    },
    listItemSecondary: {
        fontSize: 12,
        color: '#999999',
        fontWeight: 600
    },
    buttonPrimary: {
      backgroundColor: '#6CB33F',
      height: 42,
      minWidth: 80,
      padding: '0 14px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    buttonSuccess: {
      backgroundColor: '#6CB33F',
      height: 42,
      minWidth: 80,
      padding: '0 14px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    button: {
      backgroundColor: '#464646',
      height: 42,
      minWidth: 80,
      padding: '0 14px',
      borderRadius: 6,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
      fontWeight: 300
    },
    buttonLink: {
      backgroundColor: 'transparent',
      border: 'none',
      height: 40,
      padding: 0,
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      lineHeight: 1,
      color: '#101010',
      textDecoration: 'underline',
    },
    dialogBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 989
    },
    dialog: {
      display: 'block',
      width: 280,
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: '#fff',
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      transform: 'translate(-50%, 100%)',
      visibility: 'hidden',
      opacity: 0,
      zIndex: 990,
      borderRadius: 6
    },
    dialogIn: {
      transform: 'translate(-50%, -50%)',
      visibility: 'visible',
      opacity: 100,
    },
    dialogWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column'
    },
    dialogHeader: {
      fontSize: 18,
      fontWeight: 900,
      marginTop: 15,
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
    },
    dialogBody: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    dialogUnit: {
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    },
    dialogIcon: {
      marginRight: 20,
      width: 30,
      height: 30
    },
    para: {
        color: '#101010',
        lineHeight: 1.3,
        fontSize: 14
    }
}

export default RFIDScreen;