import React from 'react'
import ActivityIndicator from '../../global/ActivityIndicator'

export default function LoadingSpinner() {
    return (
        <div style={styles.loadingWrapper}>
            <div style={styles.loading}>
                <ActivityIndicator />
            </div>
        </div>
    )
}

const styles = {
    loadingWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loading: {
        backgroundColor: '#6CB33F',
        opacity: .7,
        borderRadius: 25,
        height: 30,
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}
