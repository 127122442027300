import React from 'react';
import Images from '../../assets/images/index';
import Header from '../global/Header';
import GlobalContext from '../../context/global-context';
import isAuth from '../login/IsAuth';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation } from 'react-i18next';

class AddVehicleScreen extends React.Component {

  static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                make: '',
                model: '',
                reg: ''
            },
            errors: {
                make: this.errorField('panel1'),
                model: this.errorField('panel1'),
                reg: this.errorField('panel1')
            },
            isValid: false,
            isLoading: false
        };
  }

  errorField = (group = null) => {
    return {
        dirty: false,
        invalid: false,
        group: group
    };
  }

  bindField = (e) => {
    let fields = {...this.state.fields};
    if(e.target.type === 'checkbox'){
        fields[e.target.name] = e.target.checked;
    } else {
        fields[e.target.name] = e.target.value;
    }
    this.setState({fields: fields}, () => {this.validate()});
  }

  validate = (callback = () => {}) => {
    let errors = {...this.state.errors};
    const required = [
        'make',
        'model',
        'reg'
    ]

    required.map(f => {
        if(this.state.fields[f].length > 0)
            errors[f].dirty = true;

        errors[f].invalid = false;
        if(errors[f].dirty && this.state.fields[f] === ''){
            errors[f].invalid = true;
        }
    })

    const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
    this.setState({errors: errors, isValid: isValid}, () => {callback()});
  }

  addVehicle = () => {
    this.validate(() => {
        if(this.state.isValid){
            this.setState({isLoading: true});

            this.context.vehicleAdd(this.state.fields)
              .then((user) => {
                this.setState({isLoading: false});
                this.props.close();
              })
              .catch((err)=>{console.log(err)});

        } else 
            console.log('not valid');
    });
  }

  componentDidMount(){
  }

  render() {
    const errors = this.state.errors;
    return (
      <Translation>{t => 
      <div style={Object.assign({}, styles.container, this.props.show ? styles.containerIn:styles.containerOut)} className="animate">
        <div style={styles.mainWrapper}>
            <button style={styles.closeButton} onClick={this.props.close}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>
            
            
            {this.state.isLoading && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
            
            {!this.state.isLoading && 
            
            <div style={styles.addVehicleWrapper}>

              <span style={styles.header}>{t('ADD_VEHICLE')}</span>

              <div style={Object.assign({marginTop: 15}, styles.inputWrapper, errors.make.invalid && styles.inputError)}>
                  <input name={'make'} onChange={this.bindField} placeholder={t('MAKE')} style={styles.input} />
                  {errors.make.invalid && <span style={styles.inputErrorMsg}>{t('VEHICLE_MAKE_REQUIREMENT')}</span>}
              </div>
              <div style={Object.assign({}, styles.inputWrapper, errors.model.invalid && styles.inputError)}>
                  <input name={'model'} onChange={this.bindField} placeholder={t('MODEL')} style={styles.input} />
                  {errors.model.invalid && <span style={styles.inputErrorMsg}>{t('VEHICLE_MODEL_REQUIREMENT')}</span>}
              </div>
              <div style={Object.assign({}, styles.inputWrapper, errors.reg.invalid && styles.inputError)}>
                  <input name={'reg'} onChange={this.bindField} placeholder={t('REG')} style={styles.input} />
                  {errors.reg.invalid && <span style={styles.inputErrorMsg}>{t('VEHICLE_REG_REQUIREMENT')}</span>}
              </div>
              <div style={Object.assign({}, styles.buttonGroup, { justifyContent: 'flex-end' })}>
                  <a href="JavaScript:void(0);" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={this.addVehicle}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('ADD')}</span></a>
              </div>


            </div>}

        </div>
      </div>
      }</Translation>
    );
  }

}


const styles = {
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '90'
  },
  containerIn: {
      transform: 'translateY(0)'
  },
  containerOut: {
      transform: 'translateY(100%)'
  },
  mainWrapper: {
    width: '80%',
    maxWidth: 400
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    width: 40,
    height: 40,
    top: 50,
    right: 20,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 99
  },
  header: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 20
  },
  buttonGroup: {
      width: '100%',
      marginTop: 40,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
  },
  buttonGroupBtn: {
      width: 140,
      height: 50,
      backgroundColor: '#555555',
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
      backgroundColor: '#6CB33F'
  },
  inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column'
  },
  input: {
      fontSize: 16,
      flex: 1,
      paddingLeft: 20
  },
  inputError: {
    boxShadow: 'inset 0 0 0 1px #F26A6A'
  },
  inputErrorMsg: {
    color: '#F26A6A',
    fontSize: 11,
    paddingLeft: 20,
    marginBottom: 8,
    marginTop: -8
  },
  loadingWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};


export default isAuth(AddVehicleScreen);