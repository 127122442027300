import React from 'react';
import Images from '../../assets/images/index';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation } from 'react-i18next';

const mapSearch = props => {

    const points = () => {
        return props.searchResults.points.map(p => (
            <a href="JavaScript:void(0);" style={styles.searchResultsItem} key={p.pointId} onClick={() => {props.goToLocation(p)}}>
                <div style={styles.searchResultItemTitle}>{p.pointName}</div>
                <div style={styles.searchResultItemDistance}>{p.distance} {p.distanceLabel}</div>
            </a>
        ));
    }

    const locations = () => {
        return props.searchResults.sites.map(p => (
            <a href="JavaScript:void(0);" style={styles.searchResultsItem} key={p.siteId} onClick={() => {props.goToLocation(p)}}>
                <div style={styles.searchResultItemTitle}>{p.name}</div>
                <div style={styles.searchResultItemDistance}>{p.distance} {p.distanceLabel}</div>
            </a>
        ));
    }

    const payg = () => {
        const p = props.searchResults.payg;
        return  (
            <a href="JavaScript:void(0);" style={styles.searchResultsItem} onClick={() => {props.goToLocationPAYG(p)}}>
                <div><img src={Images.pins.open} style={{width: 24, marginRight: 16}} alt="Pins" /></div>
                <div style={styles.searchResultItemTitle}><b>UKEV{p.session.PAYGCode.replace('-', '')}</b><br />{p.site.name}</div>
                <div style={styles.searchResultItemDistance}>{p.site.distance} {p.site.distanceLabel}</div>
            </a>
        );
    }

    return (
        <GlobalContext.Consumer>
            {context => (
            <Translation>
            {
            t => 
            <div style={Object.assign({}, styles.searchContainer, !props.mapView && styles.searchContainerBg)}>
                <div style={{flex: '1 0 10px'}}></div>
                <div style={styles.searchField}>
                    <div style={styles.searchFieldContent}>
                        <div style={styles.searchInput}>
                            <img src={Images.homesearch} style={styles.searchIcon} alt="Search" />
                            <input type={'text'} style={styles.searchText} value={props.searchText} placeholder={t('SEARCH_PLACE')} onChange={props.searchLocations}/>
                            {props.searchText.length > 0 && <a href="JavaScript:void(0);" onClick={props.searchClear}><img src={Images.close_btn} style={styles.closeIcon} alt="Close" /></a>}
                            {props.searchText.length === 0 && <a href="JavaScript:void(0);" onClick={props.onPressScan}><img src={Images.homeQr} style={styles.closeIcon} alt="Scan QR" /></a>}
                        </div>
                        {context.loading.search && <div style={styles.searchResults}><div style={styles.loading}><ActivityIndicator /></div></div>}
                        {(props.searchResults && props.searchResults.payg === null) && 
                        <div style={styles.searchResults}>
                            {props.searchResults.points.length > 0 && <div style={styles.searchResultsHeader}>{t('SEARCH_POINTS')}</div>}
                            {points()}
                            {props.searchResults.sites.length > 0 && <div style={styles.searchResultsHeader}>{t('SEARCH_LOCATIONS')}</div>}
                            {locations()}
                        </div>}
                        {(props.searchResults && props.searchResults.payg !== null) && 
                        <div style={styles.searchResults}>
                            {props.searchResults.payg.session !== null && payg()}
                            {(props.searchResults.payg.session === null && props.searchResults.attempt < 20) && 
                                <div style={styles.loadingWithMsg}>
                                    <div style={styles.loading}><ActivityIndicator /></div>
                                    <p style={styles.loadingMsg}>{t('SEARCH_FINDING')}</p>
                                </div>
                            }
                            {(props.searchResults.payg.session === null && props.searchResults.attempt === 20) && <p style={styles.failMsg}></p>}
                            <p style={styles.infoMsg}>{t('SEARCH_PRESS')}</p>
                        </div>}
                    </div>
                </div>
                {context.isLoggedIn && <div style={{flex: '1 0 10px'}}></div>}
                {context.isLoggedIn &&
                    <a href="javascript:void(0);" style={styles.filterButton} onClick={props.onPressFilter}>
                        <div style={styles.filterButtonContent}>
                            <img src={Images.homefilter} style={styles.filterIcon} alt="Filter" />
                        </div>
                    </a>
                }
                <div style={{flex: '1 0 10px'}}></div>
            </div>
            }
            </Translation>
            )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    searchContainer: {
        minHeight: 50,
        maxWidth: 400,
        width: '100%',
        position: 'absolute',
        paddingTop: 12,
        paddingTop: 8,
        top: 0,
        right: 0,
        zIndex: 101,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    searchContainerBg: {
        backgroundColor: '#fff',
        paddingBottom: 10
    },
    searchField: {
        maxWidth: 320,
        width: '100%',
        minHeight: 50,
        backgroundColor: '#ffffff',
        borderRadius: 6,
        boxShadow: '0 0 4px rgba(0,0,0,.3)',
    },
    searchFieldContent: {
        width: '100%',
        minHeight: 50,
        maxHeight: 300,
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    searchInput: {
        width: '100%',
        minHeight: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    searchIcon: {
        width: 20,
        height: 20,
        margin: 14
    },
    closeIcon: {
        width: 20,
        height: 20,
        margin: 14
    },
    searchText: {
        fontSize: 16,
        flex: 1,
        minWidth: 100
    },
    searchResults: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    searchResultsHeader: {
        backgroundColor: '#eee',
        fontSize: 12,
        paddingTop: 4,
        paddingBottom: 4,
        padding: 10,
        padding: 10,
        color: '#444'
    },
    searchResultsItem: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#444',
        cursor: 'pointer',
        minHeight: 16
    },
    searchResultItemTitle: {
        flex: 1
    },
    searchResultItemDistance: {
        color: '#888'
    },
    filterButton: {
        width: 50,
        height: 50,
        boxShadow: '0 0 4px rgba(0,0,0,.3)',
        borderRadius: 4
    },
    filterButtonContent: {
        width: 50,
        height: 50,
        backgroundColor: '#ffffff',
        borderRadius: 6
    },
    filterIcon: {
        width: 20,
        height: 20,
        margin: 14
    },
    loadingWithMsg: {
        display: 'flex',
        flexDirection: 'row'
    },
    loading: {
      backgroundColor: '#aaa',
      opacity: .3,
      borderRadius: 25,
      height: 20,
      width: 20,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 10,
      marginBottom: 10
    },
    loadingMsg: {
      opacity: .6,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 10,
      marginBottom: 0,
      fontSize: 14
    },
    infoMsg: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 5,
        color: '#FE8103',
        cursor: 'pointer',
        minHeight: 16,
        fontSize: 12
    },
    failMsg: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 5,
        color: '#CB0A0C',
        cursor: 'pointer',
        minHeight: 16,
        fontSize: 14
    },
};

export default mapSearch;